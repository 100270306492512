import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { MENU_DEFAULT, //MENU_PERFIL_USUARIO 
} from '@/constants/optionsValues'
import { getMenuProfile } from '@/constants/requests'
import { RELOGIN } from '@/constants/parameters'
import {
  StyledMenuContainer,
} from './styles'
import { RedirectPage } from '@/constants/redirectPage'
import { useSelector } from 'react-redux'

const Menu = (props) => {
  const { onMenuClick } = props

  const permission = useSelector(state => state.permission);

  const [isAdmin, setIsAdmin] = useState(false)

  const checkProfile = useCallback(async () => {
    const url = window.location.pathname.split('/')
    const data = await getMenuProfile()
    if (
      url[1] !== RELOGIN
      && data.status.codigo === 'w91'
    ) {
      return window.location = RELOGIN
    }

    RedirectPage(1)
    setIsAdmin(1)
  }, [])

  useEffect(() => {
    checkProfile()
  }, [])

  const renderSubMenu = (item) => (
    <li key={item.id}>
      <NavLink
        to={item.id}
        onClick={onMenuClick}
      >
        <span>
          <i className="fas fa-long-arrow-alt-right" />
          {item.label}
        </span>
      </NavLink>
    </li>
  )

  const renderMenuItens = (item) => (
    <li key={item.id}>
      {/* <p>{item.sub.length}</p> */}
      {Boolean(item.sub.length) && (
        <NavLink
          to={item.id}
          onClick={onMenuClick}
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          <span>
            <i className={item.icon} />
            {item.label}
            {Boolean(item.sub) && (<i className="fas fa-sort-down" />)}
          </span>
        </NavLink>
      )}
      {Boolean(item.sub) && (
        <>
          <StyledMenuContainer>
            {item.sub.map((item) => renderSubMenu(item))}
          </StyledMenuContainer>
        </>
      )}
    </li>
  )

  function findMenu(object) {
    let arrayValues = Object.values(permission);
    let arrayKeys = Object.keys(permission);

    let id = object.id;
    let idxOf = arrayKeys.indexOf(id);
    let value = arrayValues[idxOf];
    if(idxOf >= 0) {
      return value.view
    }
    return false
  }

  const configMenu = () => {
    if (isAdmin) {
      let newOb = MENU_DEFAULT.map((item) => {
        let sub = item.sub.filter((itemInside) => (findMenu(itemInside)))
        item.sub = sub
        return item
      })
     
      return newOb.map((item) => renderMenuItens(item))
    }

    // return MENU_PERFIL_USUARIO.map((item) => renderMenuItens(item))
  }

  return (
    <StyledMenuContainer>
      {configMenu()}
    </StyledMenuContainer>
  )
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: null,
}

export default Menu
