import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { formatterMoney } from '@/utils/formatter'
import { validateFinanceFields } from '@/validators/registerValidations'
import Modal from '@/views/fragments/Modal'
import { StyledFormFinance } from '../styles'

const Finance = (props) => {
  const { onSave, finance, showSaveLabel } = props
  const [formValues, setFormValues] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [validateList, setValidateList] = useState({})
  const [isIntegrated, setIsIntegrated] = useState(true)
  const [isIndividual, setIsIndividual] = useState(false)

  useEffect(() => {
    setFormValues({
      ...finance,
      isIntegrated: (finance.searchType === 'integrated' || finance.searchType === 'both') ? true : false,
      isIndividual: (finance.searchType === 'individual' || finance.searchType === 'both') ? true : false,
    })
    finance.searchType === 'integrated' || finance.searchType === 'both' ? setIsIntegrated(true) : setIsIntegrated(false)
    finance.searchType === 'individual' || finance.searchType === 'both' ? setIsIndividual(true) : setIsIndividual(false)
  }, [finance])

  const onSearchTypeClick = (e, type, bool) => {
    e.preventDefault()
    setFormValues({})
    setValidateList({})
    setFormValues({
      ...finance,
    })
    
    if (type === 'both'){
      setIsIndividual(bool);
      setIsIntegrated(bool);
      return;
    } 
    if (type === 'individual') return setIsIndividual(bool)
    if (type === 'integrated') return setIsIntegrated(bool)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: formatterMoney(value),
    })
  }

  const handleDateError = () => {
    setShowModal(true)
  }

  const handleChangeBillDay = (e) => {
    const { name, value } = e.target

    if(Number(value) < 1 || Number(value) > 28) {
      return handleDateError()
    }

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const searchTypeReq = () => {
    if (isIndividual && isIntegrated) return 'both'
    if (isIndividual) return 'individual'
    if (isIntegrated) return 'integrated'
  }

  const handleSave = (e) => {
    e.preventDefault()
    const list = validateFinanceFields(
      formValues,
      isIntegrated,
      isIntegrated
        && isIndividual
    )

    if (list.status) {
      setValidateList(list)
      return
    }

    onSave({
      ...formValues,
      searchType: searchTypeReq()
    })
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const fieldIntegratedValue = () => (
    <InputText
      label="Valor Integrada"
      name="integratedValue"
      isRequired
      isError={validateList.integratedValue}
      hint={REQUIRED_FIELD}
      value={formValues.integratedValue || ''}
      onChange={handleChange}
    />
  )

  const fieldDriver = () => (
    <InputText
      label="Motorista"
      name="driver"
      isRequired
      isError={validateList.driver}
      hint={REQUIRED_FIELD}
      value={formValues.driver || ''}
      onChange={handleChange}
    />
  )

  const fieldTruck = () => (
    <InputText
      label="Veículo / Cavalo"
      name="truck"
      isRequired
      isError={validateList.truck}
      hint={REQUIRED_FIELD}
      value={formValues.truck || ''}
      onChange={handleChange}
    />
  )

  const fieldOwner = () => (
    <InputText
      label="Proprietário"
      name="owner"
      isRequired
      isError={validateList.owner}
      hint={REQUIRED_FIELD}
      value={formValues.owner || ''}
      onChange={handleChange}
    />
  )

  const fieldDriverAssistant = () => (
    <InputText
      label="Ajudante"
      name="driverAssistant"
      isRequired
      isError={validateList.driverAssistant}
      hint={REQUIRED_FIELD}
      value={formValues.driverAssistant || ''}
      onChange={handleChange}
    />
  )

  const fieldConsult = () => (
    <InputText
      label="Consulta"
      name="consult"
      isRequired
      isError={validateList.consult}
      hint={REQUIRED_FIELD}
      value={formValues.consult || ''}
      onChange={handleChange}
    />
  )

  const fieldBillDay = () => (
    <InputText
      label="Faturar Dia"
      name="billDay"
      isRequired
      isError={validateList.billDay}
      hint={REQUIRED_FIELD}
        type="number"
      value={formValues.billDay || ''}
      onChange={handleChangeBillDay}
    />
  )

  return (
    <StyledFormFinance>
      {showModal && (
        <Modal
          title="Dia não permitido."
          subTitle="Escolha valor entre 1 e 28"
          closeModal={onCloseModal}
        >
          <button
            className='button confirm'
            onClick={() => onCloseModal()}
          >
            Ok
          </button>
        </Modal>
      )}
      <div className='researchButtons'>
        <button
          className={isIntegrated ? 'active' : ''}
          onClick={(e) => onSearchTypeClick(e, 'integrated', !isIntegrated)}
        >
          <div>
            <i className={`far ${isIntegrated ? 'far fa-check-square' : 'far fa-square'}`} />
            <span>Pesquisa Integrada</span>
          </div>
        </button>
        <button
          className={!isIndividual ? '' : 'active'}
          onClick={(e) => onSearchTypeClick(e, 'individual', !isIndividual)}
        >
          <div>
            <span>Individual</span>
            <i className={`far ${!isIndividual ? 'fa-square' : 'far fa-check-square'}`} />
          </div>
        </button>
      </div>

      <article>
        {!isIntegrated && !isIndividual ? (
          <h3 className='info-no-search'> ao menos um dos campos</h3>
        ) : (
          <>
            {isIntegrated && !isIndividual &&(
              <>
                {fieldIntegratedValue()}
                {fieldDriverAssistant()}
                {fieldConsult()}
                {fieldBillDay()}
              </>
            )}
            {!isIntegrated && isIndividual &&(
              <>
                {fieldDriver()}
                {fieldTruck()}
                {fieldOwner()}
                {fieldDriverAssistant()}
                {fieldConsult()}
                {fieldBillDay()}
              </>
            )}
            {isIntegrated && isIndividual &&(
              <>
                {fieldIntegratedValue()}
                {fieldDriver()}
                {fieldTruck()}
                {fieldOwner()}
                {fieldDriverAssistant()}
                {fieldConsult()}
                {fieldBillDay()}
              </>
            )}
          </>
        )}
      </article>
      <button className="button confirm" onClick={handleSave} disabled={!isIndividual && !isIntegrated}>
        <i className="far fa-save" />
        {showSaveLabel ? 'Salvar' : 'Finalizar'}
      </button>
    </StyledFormFinance>
  )
}

Finance.propTypes = {
  onSave: PropTypes.func.isRequired,
  finance: PropTypes.objectOf(PropTypes.any),
  showSaveLabel: PropTypes.bool,
}

Finance.defaultProps = {
  finance: {},
  showSaveLabel: false,
}

export default Finance
