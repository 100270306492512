import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledAvatarContainer,
  StyledIcon,
} from './styles'

const Avatar = (props) => {
  const { onLogoutClick, user } = props

  return (
    <StyledAvatarContainer>
      <div>
        <StyledIcon>
          <i className="fas fa-user" />
        </StyledIcon>
        <span>Olá, <span>{user}</span>!</span>
      </div>
      <button onClick={onLogoutClick}>
        <i className="fas fa-sign-out-alt" />
        <span>Fazer Logout</span>
      </button>
    </StyledAvatarContainer>
  )
}

Avatar.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
}

export default Avatar
