import styled from '@emotion/styled'

export const StyledDateTime = styled.div`
  width: 50%;
  span {
    color: var(--color-primary);
  }

  .date-inputs {
    display: flex;  
    gap: 10px;
  }

  .end-date {
    margin-right: 10px;
    margin-left: 10px;
  }

  .date-selector {
    margin-top: 8px;
    gap: 5px;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      outline-color: var(--color-primary);
      border-radius: 5px;
      height: 45px;
      padding: 0 10px;
      font-size: 16px;
      border: 1px solid;
    }
  }
`

export const Field = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  vertical-align: top;

  & > span {
    color: var(--color-primary);

    i {
      font-size: 8px;
      vertical-align: top;
      color: red;
      margin-left: 3px;
    }
  }

  .buttons {
    float: right;
    margin-right: 5px;
  }

  input, select, textarea {
    margin-top: 8px;
    border-radius: 5px;
    height: 45px;
    padding: 0 10px;
    font-size: 16px;
    border: 1px solid;

    &.alert {
      border-color: var(--color-alert);
    }

    &.success {
      border-color: var(--color-success);
    }
  }

  textarea {
    height: 100px;
    padding: 15px;
    margin: 5px 0 0;
  }
`

export const CustomButtonToggle = styled.div`
  &.description {
    font-size: 30px;
    width: 70px;
    padding-top: 18px;
  }

  i {
    color: var(--color-gray-dark);
    vertical-align: middle;
    font-size: 30px;
    margin-top: 15px;

    &.toggleOn {
      color: var(--color-primary);
    }
  }

  span {
    font-size: 15px;
    margin-left: 10px;
    vertical-align: middle;
    color: var(--color-primary);
    font-weight: 600;
  }
`

export const CustomCheckBox = styled.div`
  &.description {
    font-size: 30px;
    width: 70px;
    padding-top: 18px;
  }

  i {
    color: var(--color-gray-dark);
    vertical-align: middle;

    &.toggleOn {
      color: var(--color-primary);
    }
  }

  span {
    font-size: 15px;
    margin-left: 10px;
    vertical-align: middle;
    color: var(--color-primary);
    font-weight: 600;
  }
`

export const CustomLoading = styled.i`
  @keyframes Loading {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 32px;
  z-index: 10;
  font-size: 30px;
  left: calc(50% - 15px);
  animation: Loading linear 1.5s infinite;
`

export const StyledHint = styled.span`
  display: block;
  color: var(--color-alert) !important;
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;

  &.success {
    color: var(--color-success) !important;
  }
`
