import React from 'react'
import LOGO from '@/img/HomeLogo.jpg'
import {
  StyledImg
} from './styles'

const Administracao = () => (
  <section className='container'>
    <StyledImg>
      <img src={LOGO} alt="Logo Mundial Risk Home" />
    </StyledImg>
  </section>
)


export default Administracao
