import styled from '@emotion/styled'

export const StyledHeaderContainer = styled.div`
  width: 100%;
  background-color: var(--color-bg-dark);
  left: 0;
  top: 0;
  position: absolute;
  color: var(--color-gray-light);
  min-height: 80px;
  z-index: 99;
`

export const StyledLogo = styled.div`
  width: 190px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  float: left;

  img {
    width: 150px;
    max-width: 80%;
  }

  @media (max-width: 1024px) {
    width: 220px;

    img {
      width: 240px;
    }
  }
`

export const StyledMenuContainer = styled.ul`
  display: inline-block;
  background-color: var(--color-bg-dark);
  width: calc(100% - 410px);
  vertical-align: top;

  & > li {
    color: var(--color-gray-light);
    display: inline-block;
    position: relative;
    margin-right: 20px;

    a {
      color: var(--color-gray-light);
      cursor: pointer;
      line-height: 80px;
      height: 80px;
      display: block;
      font-size: 14px;
      font-weight: 600;

      & > span {
        display: block;

        i {
          margin: 0 5px;
        }
      }

      // &.active, &:hover {
      //   box-shadow: 0px 5px inset var(--color-primary), 0px -6px inset var(--color-primary);
      // }
    }

    &:hover {
      ul {
        opacity: 1;
        z-index: 10;
        top: 100%;
        height: auto;
      }
    }

    ul {
      position: absolute;
      left: calc(50% - 115px);
      width: 230px;
      opacity: 0;
      z-index: -100;
      height: 0;
      overflow: hidden;
      top: -100%;
      transition: all .2s ease-in-out;
      padding: 0 10px 10px;

      & > li {
        display: block;
        margin: 0;

        a {
          height: auto;
          line-height: initial;
          padding: 10px;
          border-top: 1px solid;
          width: 100%;

          &:hover, &.active {
            box-shadow: none;
            background-color: var(--color-primary);
            color: var(--color-bg-dark);
          }
  
          & > span {
            display: block;
  
            i {
              font-size: 9px;
              vertical-align: top;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    background-color: transparent;
    padding: 10px;
    width: 100%;
    border-top: 1px solid var(--color-bg-dark);

    li {
      width: 100%;
      text-align: center;
      height: auto;
      background-color: var(--color-hover-black-light);
      margin: 0 0 10px;
      padding-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        height: auto;
        line-height: 40px;
        color: var(--color-bg-dark);
        padding: 10px 0;
        box-shadow: none !important;
      }

      ul {
        opacity: 1;
        z-index: 10;
        top: 100%;
        position: relative;
        left: 0;
        top: auto;
        border: none;
        padding: 0;
        
        li {
          background-color: transparent;
          a {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    width: 680px;
    vertical-align: middle  ;
  }
`

export const StyledAvatarContainer = styled.div`
  display: inline-block;
  padding-right: 15px;
  width: 220px;
  margin-top: 8px;

  & > div {
    display: inline-block;
    margin-right: 15px;

    span {
      & > span {
        text-transform: uppercase;
        font-size: 14px;
      }
    }
  }

  & > span {
    font-size: 13px;
    display: inline-block;
  }

  button {
    vertical-align: middle;
    background-color: transparent;
    border: none;
    font-size: 23px;
    color: var(--color-gray-light);

    span { display: none;}

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-bg-dark);
    }
  }

  @media (max-width: 1024px) {
    width: 100%;

    & > div {
      display: block;
      text-align: center;
      margin: 20px 0;
      color: var(--color-bg-dark);
      font-weight: 600;
    }

    button {
      width: 100%;
      margin-bottom: 20px;
      color: var(--color-bg-dark);
      font-weight: 600;

      i {
        vertical-align: middle;
      }

      span {
        display: inline-block;
        font-size: 15px;
        margin-left: 10px;
        vertical-align: middle;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
`

export const StyledIcon = styled.div`
  border-radius: 50%;
  background-color: var(--color-gray-light);
  color: var(--color-secondary);
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
`

export const StyledButtonMobile = styled.button`
  background-color: transparent;
  border: none;
  float: right;
  font-size: 21px;
  color: var(--color-gray-light);
  width: 80px;
  height: 80px;
  line-height: 80px;

  &:hover, &.active {
    background-color: var(--color-primary);
    color: var(--color-bg-dark);
  }
`

export const StyledMenuMobile = styled.div`
  width: 400px;
  position: absolute;
  top: 100%;
  max-width: 100%;
  background-color: var(--color-primary);
  right: 0;
`

export const StyledCostCentre = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  padding: 0 15px;
  text-align: center;
  font-weight: 600;


  & > span {
    color: var(--color-secondary);
    display: block;
    margin-bottom: 5px;
    font-weight: initial;
  }

  @media (min-width: 1024px) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 730px);
    text-align: right;

    & > span {
      color: var(--color-primary);
    }
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    position: absolute;
    font-size: 12px;
    right: 20px;
    top: 30px;
  }

  @media (min-width: 1200px) {
    vertical-align: middle;
    width: calc(100% - 1101px);
    margin: 0 10px 0 0;
    font-size: 13px;
  }
`
