import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomButtonToggle } from './styles'

const ToggleButton = (props) => {
  const {
    label,
    value,
    name,
    onClick,
    className,
    isRequired,
  } = props

  return (
    <Field className={className}>
      <span>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      {value ? (
        <CustomButtonToggle onClick={() => onClick(name, !value)}>
          <i className="fas fa-toggle-on toggleOn" />
        </CustomButtonToggle>
      ) : (
        <CustomButtonToggle onClick={() => onClick(name, !value)}>
          <i className="fas fa-toggle-off" />
        </CustomButtonToggle>
      )}
    </Field>
  )
}

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
}

ToggleButton.defaultProps = {
  className: '',
  isRequired: false,
}

export default ToggleButton
