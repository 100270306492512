import {
  BUSCAR,
  NOVA_BUSCA,
  FORBIDDEN,
  LOGIN,
  PLATE_CONSULT,
  RELOGIN,
} from '@/constants/parameters'

export const RedirectPage = (isAdmin) => {
  const url = window.location.pathname

  if (!isAdmin) return

  switch (url) {
    case `/${BUSCAR}`:
    case `/${NOVA_BUSCA}`:
    case `/${PLATE_CONSULT}`:
    case `/${FORBIDDEN}`:
    case `/${LOGIN}`:
    case `/${RELOGIN}`:
      return true
  
    default:
      if (!isAdmin) {
        return window.location = '/forbidden'
      }
  }
}
