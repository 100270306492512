import React, { useState, useEffect, useCallback } from 'react'
import Modal from '@/views/fragments/Modal/Modal'
import RegisterContainer from '@/views/Register/components/RegisterContainer'
import Acordeon from '@/views/fragments/Acordeon'
import RegisterCompanyData from '@/views/Register/components/RegisterCompanyData'
import RegisterUsers from '@/views/Register/components/RegisterUsers'
import Finance from '@/views/Register/fragments/Finance'
import { fakeRequest } from '@/constants/requests'

const DEFAULT_STATUS = {
  company: { show: false, locked: true },
  user: { show: false, locked: true },
  finance: { show: false, locked: true },
}

const EmbarcadorRegister = () => {
  const [formValues, setFormValues] = useState({})
  const [toShow, setToShow] = useState(DEFAULT_STATUS)
  const [showModal, setShowModal] = useState(false)

  const setDefaultValues = useCallback(() => {
    setToShow({
      ...DEFAULT_STATUS,
      company: { show: true, locked: false }
    })
    setFormValues({})
  },[])

  useEffect(() => {
    setDefaultValues()
  },[setDefaultValues])

  const handleSaveCompany = (form) => {
    setFormValues({
      ...formValues,
      company: form,
    })

    setToShow({
      ...toShow,
      company: { show: false, locked: false },
      user: { show: true, locked: false },
    })
  }

  const handleSaveUser = (form) => {
    setFormValues({
      ...formValues,
      user: form,
    })

    setToShow({
      ...toShow,
      user: { show: false, locked: false },
      finance: { show: true, locked: false },
    })
  }

  const handleSaveFinance = (form) => {
    setFormValues({
      ...formValues,
      finance: form,
    })

    fakeRequest({
      ...formValues,
      finance: form,
    })

    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
    setDefaultValues()
  }

  const renderModal = () => (
    <Modal
      title="Sucesso!"
      subTitle="Cadastro efetuado."
      type='success'
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const handleNavClick = (nav) => {
    if (toShow[nav].locked) return

    setToShow({
      ...toShow,
      [nav]: {
        ...toShow[nav].locked,
        show: !toShow[nav].show,
      },
    })
  }

  return (
    <RegisterContainer
      title="Cadastro"
      subTitle="Embarcador"
      label="Embarcador Selecionado"
      client="PARMENES INFORMATION TECHNOLOGY LTDA"
    >
      {showModal && renderModal()}
      <Acordeon
        title="Dados da Empresa"
        icon="far fa-building"
        locked={toShow.company.locked}
        showContent={toShow.company.show}
        handleClickNav={() => handleNavClick('company')}
    >
        <RegisterCompanyData
          onSave={handleSaveCompany}
          companyForm={formValues.company}
        />
      </Acordeon>

      <Acordeon
        title="Cadastro de Usuário"
        icon="fas fa-users"
        locked={toShow.user.locked}
        showContent={toShow.user.show}
        handleClickNav={() => handleNavClick('user')}
    >
        <RegisterUsers
          onSave={handleSaveUser}
          userList={formValues.user}
        />
      </Acordeon>

      <Acordeon
        title="Financeiro"
        icon="fas fa-hand-holding-usd"
        locked={toShow.finance.locked}
        showContent={toShow.finance.show}
        handleClickNav={() => handleNavClick('finance')}
    >
        <Finance
          onSave={handleSaveFinance}
          finance={formValues.finance}
        />
      </Acordeon>

    </RegisterContainer>
  )
}

export default EmbarcadorRegister
