export const LOGIN = 'login'
export const CADASTRO = 'cadastro'
export const PESSOA_FISICA_CADASTRO = 'pessoa-fisica-cadastro'
export const VEICULO_PROPRIETARIO_CADASTRO = 'veiculo-proprietario-cadastro'
export const TRANSPORTADOR_CADASTRO = 'transportador-cadastro'
export const EMBARCADOR_CADASTRO = 'embarcador-cadastro'
export const PESSOA_FISICA_CONSULTA = 'pessoa-fisica-consulta'
export const VEICULO_PROPRIETARIO_CONSULTA = 'veiculo-proprietario-consulta'
export const TRANSPORTADOR_CONSULTA = 'clients'
export const EMBARCADOR_CONSULTA = 'embarcador-consulta'
export const CONSULTA = 'consulta'
export const BUSCAR = 'monitor'
export const ADMINISTRACAO = 'administracao'
export const SOLICITACAO = 'solicitacao'
export const NOVA_BUSCA = 'newRequest'
export const NOVO_RDO = 'novo-rdo'
export const PESQUISA_AJUDANTE = 'pesquisa-ajudante'
export const PESQUISA_VEICULO = 'pesquisa-veiculo'
export const PESQUISA_PREMIUM = 'pesquisa-premium'
export const REQUIRED_FIELD = 'Preenchimento obrigatório!'
export const ADMIN_PROFILE = 'gerente'
export const USER_PROFILE = 'usuario'
export const FORBIDDEN = 'forbidden'
export const ANALYSIS_PROFILE = 'analysisProfile'
export const ANALYSIS_PROFILE_BUSCA = 'analysisProfileSearch'
export const PRIVILEGES = 'privileges'
export const BILLINGS = 'billingReport'
export const MANAGERIAL = 'managerialReport'
export const TERM = 'termReport'
export const RELOGIN = 'relogin'
export const MANUAL_BLOCK = 'manualBlock'
export const BLACK_LIST = 'black-list'
export const PLATE_CONSULT = 'check'
export const CAR_LIST_CONFIG = 'modelBrandEdition'
export const NOT_FOUND = 'not-found'
