import React, { useState } from 'react'
import { formatterCNPJ, formatterNumberOnly } from '@/utils/formatter'
import { MIN_LENGTH, MAX_LENGTH } from '@/utils/minMaxLength'
import InputText from '@/views/fragments/form/InputText'
import { getCNPJInfo } from '@/constants/requests'
import TransportadorCards from '../fragments/TransportadorCards'
import ContainerTemplate from '../fragments/ContainerTemplate'
import Warnin from '@/views/fragments/Warnin'
import TransportadorRegister from '@/views/Register/TransportadorRegister'
import { useSelector } from 'react-redux'


const SearchTransportador = () => {
  const [documentNumber, setDocumentNumber] = useState('')
  const [registerFound, setRegisterFound] = useState(null)
  const [showWarning, setShowWarning] = useState(null)
  const [isNewRegister, setIsNewRegister] = useState(false)

  const permission = useSelector(state => state.permission);

  const onChange = (e, ml) => {
    const { value } = e.target
    const newValue = formatterNumberOnly(value)
    if (newValue.length > ml) return

    setDocumentNumber(newValue)
    setShowWarning(null)
  }

  const handleSave = (form) => {
    setRegisterFound(form)
  }

  const handleSearch = async () => {
    setIsNewRegister(false)
    setRegisterFound(null)
    if (MIN_LENGTH.eleven > documentNumber.length) {
      setShowWarning(`Você deve preencher ao menos ${MIN_LENGTH.eleven} dígitos`)
      return
    }

    let data = await getCNPJInfo({ cnpj: formatterNumberOnly(documentNumber) })

    if (
      data?.request?.status === 500
      || data?.status?.tipo === 'novo'
      || !data?.status?.tipo
    ) {
      if(!permission.clients?.newClient) {
        setShowWarning(`Cliente não está cadastrado, e você não tem permissão para cadastrar um novo cliente!`)
        return
      }
      setIsNewRegister(true)
      setRegisterFound({
        company: {
          cnpj: formatterNumberOnly(documentNumber)
        },
        status: {
          tipo: 'novo'
        }
      })
      return
    }

    let emailString = ''
    let emailForm = ''

    for(let i = 0; i < data.emails.length; i++) {

      if(i !== data.emails.length - 1) {
        emailString += data.emails[i] + " / "
        emailForm += data.emails[i] + ";"
      } else {
        emailString += data.emails[i]
        emailForm += data.emails[i]
      }
    }

    let newData = {
      ...data,
      emails: {
        emails: data.emails,
        emailString: emailString,
        emailForm: emailForm
      }
    }

    setRegisterFound(newData)
  }

  return (
    <ContainerTemplate>
      <aside>
        <h3>Transportador / Embarcador</h3>
        <InputText
          label="Cadastre / Encontre"
          name="cnpj"
          className="cnpj"
          placeholder='digite o CNPJ'
          value={formatterCNPJ(documentNumber)}
          onChange={(e) => onChange(e, MAX_LENGTH.quatorze)}
        />
        {showWarning && (<Warnin msg={showWarning} />)}
        <button className='button' onClick={handleSearch}>
          <i className="fas fa-search" />
        </button>
      </aside>
      <section>
        {!registerFound ? (
          <h4>
            Nenhum Transportador ou Embarcador encontrado
            <i className="fas fa-truck-loading" />
          </h4>
        ) : (
          <>
            {isNewRegister ? (
              <TransportadorRegister
                registerFound={registerFound?.status?.tipo === 'novo' ? registerFound : null}
              />
            ) : (
              <TransportadorCards
                values={registerFound}
                onSave={handleSave}
              />
            )}
          </>
        )}
      </section>
    </ContainerTemplate>
  )
}

export default SearchTransportador
