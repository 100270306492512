import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import Modal from '@/views/fragments/Modal'
import RegisterCompanyData from '@/views/Register/components/RegisterCompanyData'
import Finance from '@/views/Register/fragments/Finance'
import AssociateShipper from '@/views/Register/fragments/AssociateShipper'
import RegisterUsers from '@/views/Register/components/RegisterUsers'
import RegisterEmails from '@/views/Register/components/RegisterEmails'
import {
  StyledCardContainer,
} from '@/views/Register/fragments/styles'
import EmailCard from '@/views/fragments/EmailCard'
import { 
  StyledForm,
  StyledButtonsModal 
} from './styles'
import { reqSubmitNewUser, reqActiveNewUser, reqSubmitDeleteEmail, reqSubmitChangeEmail, reqSubmitChangeUser, reqSubmitChangeFinance, reqSubmitChangeTransportador, reqSubmitNewEmbarcador, reqSubmitChangeEmbarcador } from '@/constants/requests'
import { useSelector } from 'react-redux'

const MODAL_TYPES = {
  employ: 'employ-data',
  finance: 'financial',
  email: 'email',
}

const TransportadorCards = (props) => {
  const {
    company,
    finance,
    shipper,
    user,
    emails,
    status,
  } = props.values
  const { onSave, values, showShipper } = props

  const [formInUse, setFormInUse] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [emailList, setEmailList] = useState([])
  const [editType, setEditType] = useState('')
  const [index, setIndex] = useState(-1)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  
  const permission = useSelector(state => state.permission);

  useEffect(async () => {
    if(status.active) {
      setShowMessageModal(true)
      setMessageModal("Cliente já cadastrado, será aberto o formulário para edição.")
      setEmailList(emails.emails)
    } 
  },[values])

  const handleCloseModal = () => {
    setShowEditModal(false)
    setShowEditModal(false)
    setEditType('')
    setFormInUse({})
  }

  const handleEditEmploye = () => {
    if(!permission.clients?.changeClientData) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para editar os dados da empresa!")
      return
    } 
    setEditType(MODAL_TYPES.company)
    setFormInUse(company)
    setShowEditModal(true)
  }

  const handleEditFinancial = () => {
    if(!permission?.clients?.changeClientFinance) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para alterar dados financeiros!")
      return
    }
    setEditType(MODAL_TYPES.finance)
    let newValues = {
      ...finance,
      consult: finance.consult.replaceAll('.', ','),
      driver: finance.driver.replaceAll('.', ','),
      driverAssistant: finance.driverAssistant.replaceAll('.', ','),
      integratedValue: finance.integratedValue.replaceAll('.', ','),
      owner: finance.owner.replaceAll('.', ','),
      truck: finance.truck.replaceAll('.', ','),    
    }
    setFormInUse(newValues)
    setShowEditModal(true)
  }

  const handleSaveUser = async (form) => {
    let newForm = {
      ...form,
      companyId: company.companyId,
      webservice: form?.webservice === true ? 'S' : 'N',
      active: form?.active === true ? 'S' : 'N',
    }

    if(newForm.edit) {
      let req = await reqSubmitNewUser(newForm)
      
      if(req.status.active) {
        setShowMessageModal(true)
        setMessageModal("Usuário cadastrado com sucesso!")
      } 
    } else {
      let req = await reqSubmitChangeUser(newForm)
      
      if(req.status.active) {
        setShowMessageModal(true)
        setMessageModal("Usuário atualizado com sucesso!")
      } 
    }
    
  }

  const handleEditEmails = async() => {
    if(!permission?.clients?.changeClientEmails) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para alterar emails!")
      return
    }
    setEditType(MODAL_TYPES.email)
    let newValues = {
      ...emails,
    }
    setFormInUse(newValues)
    setShowEditModal(true)
  }

  const handleSaveEmails = async(form) => {
    let newValues = {
      email: form,
      companyId: company.companyId,
    }

    let req = await reqSubmitChangeEmail(newValues)

    emails.emails = form
    let emailForm = ''

    for(let i = 0; i < emails.emails.length; i++) {
      if(i !== emails.emails.length - 1) {
        emailForm += emails.emails[i] + ";"
      } else {
        emailForm += emails.emails[i]
      }
    }

    emails.emailForm = emailForm
    setEmailList(form)


    if(req.status.active) {
      setShowMessageModal(true)
      handleCloseModal()
      setMessageModal("Emails cadastrados com sucesso!")
    } 
  }

  const handleActiveUser = (form) => {
    let newForm = {
      userId: form.userId,
      active: form?.active === '1' || form?.active === true || form?.active === 'S' ? 'S' : 'N',
    }
    reqActiveNewUser(newForm)
  }

  const handleSaveShipper = async(form, tipo) => {
    let newValues = {
      ...form,
      companyId: company?.companyId
    }
    if (tipo === 'cadastro') {
      let req = await reqSubmitNewEmbarcador(newValues)
      if(req.status.active) {
        setShowMessageModal(true)
        setMessageModal("Vínculo dos embarcadores atualizado com sucesso!")
      } 
    } else {
      let req = await reqSubmitChangeEmbarcador(newValues)
      if(req.status.active) {
        setShowMessageModal(true)
        setMessageModal("Embarcador atualizado com sucesso!")
      } 
    }
  }

  const onSaveEmploye = async (form) => {
    let newValues = {
      ...form,
      companyId: company?.companyId,
      linkedProfile: form?.linkedProfile === undefined ? company?.linkedProfile : form?.linkedProfile,
      registerStatus: form?.registerStatus === true ? "S" : "N",
      tipoEmpresa: form?.companyType === "transportador" ? "T" : "E",
      localidadeLabel: company?.localidadeLabel,
      ufLabel: company?.ufLabel,
    }

    let req = await reqSubmitChangeTransportador(newValues)
      
    if(req.status.active) {
      setShowMessageModal(true)
      onSave({
        ...values,
        company: newValues,
      })
      handleCloseModal()
      setMessageModal("Empresa atualizado com sucesso!")
    } else {
      setShowMessageModal(true)
      handleCloseModal()
      setMessageModal("Empresa não pode ser atualizada, contate administrador!")
    }
  }

  const onSaveFinance = async (form) => {
    let newForm = {
      ...form,
      companyId: company.companyId,
      consult: form.consult.replaceAll('.', '').replace(',', '.'),
      driver: form.driver.replaceAll('.', '').replace(',', '.'),
      driverAssistant: form.driverAssistant.replaceAll('.', '').replace(',', '.'),
      integratedValue: form.integratedValue.replaceAll('.', '').replace(',', '.'),
      owner: form.owner.replaceAll('.', '').replace(',', '.'),
      truck: form.truck.replaceAll('.', '').replace(',', '.'),
      type: form.searchType === 'integrated' ? 1 : form.searchType === 'individual' ? 2 : 3
    }
    
    let req = await reqSubmitChangeFinance(newForm)
    
    if(req.status.active) {
      setShowMessageModal(true)
      setMessageModal("Usuário cadastrado com sucesso!")
      
      onSave({
        ...values,
        finance: newForm,
      })
    } 
    handleCloseModal()
  }

  const renderEmployeModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterCompanyData
        onSave={onSaveEmploye}
        companyForm={formInUse}
        showSaveLabel
      />
    </Modal>
  )

  const renderFinancialModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <Finance
        onSave={onSaveFinance}
        finance={formInUse}
        showSaveLabel
      />
    </Modal>
  )

  const renderEmailModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterEmails
        onSave={handleSaveEmails}
        email={formInUse}
        showSaveLabel
      />
    </Modal>
  )

  const onCloseModal = () => {
    setShowMessageModal(false)
    setShowModalConfirmation(false)
    setMessageModal('')
  }

  const renderMessageModal = () => (
    <Modal
      subTitle={messageModal}
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const renderEditModal = () => {
    if (editType === MODAL_TYPES.company) {
      return renderEmployeModal()
    }

    if (editType === MODAL_TYPES.finance) {
      return renderFinancialModal()
    }

    if (editType === MODAL_TYPES.email) {
      return renderEmailModal()
    }
  }
  
  const handleRemove = (idx) => {
    if(!permission?.clients?.changeClientEmails) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para deletar emails!")
      return
    }
    setShowModalConfirmation(true)
    setIndex(idx)
  }
  
  const handleDeleteEmail = async () => {
    setShowModalConfirmation(true)

    let newValues = {
      email: emails.emails[index],
      companyId: company.companyId
    }

    emails.emails.splice(index, 1);
    
    let emailForm = ''

    for(let i = 0; i < emails.emails.length; i++) {
      if(i !== emails.emails.length - 1) {
        emailForm += emails.emails[i] + ";"
      } else {
        emailForm += emails.emails[i]
      }
    }

    emails.emailForm = emailForm

    let req = await reqSubmitDeleteEmail(newValues)

    if (req.status.active) {
      setShowMessageModal(true)
      setMessageModal("Email deletado com sucesso!")
      onCloseModal()
    }
  }
  
  const confirmationModal = () => (
    <Modal
      closeModal={onCloseModal}
    >
      <h3>Deseja realmente deletar o email?</h3>
      <StyledButtonsModal>
        <button className="button cancel" onClick={onCloseModal}>Não</button>
        <button className="button" onClick={handleDeleteEmail}>Sim</button>
      </StyledButtonsModal>
    </Modal>
  )

  const renderCard = (email, i) => (
    <EmailCard
      onDelete={handleRemove}
      index={i}
      key={i}
      email={email}
    />
  )

  return (
    <section>
      {showMessageModal && renderMessageModal()}
      {showEditModal && (renderEditModal())}
      {showModalConfirmation && confirmationModal()}
      <Card
        title="Dados da Empresa"
        onEditClick={handleEditEmploye}
      >
        <StyledForm className="employ-data">
          <div>
            <span>CNPJ:</span>
            <p>{company?.cnpj}</p>
          </div>
          <div>
            <span>Empresa:</span>
            <p>{company?.companyName}</p>
          </div>
          {/* <div>
            <span>País:</span>
            <p>{company?.pais}</p>
          </div> */}
          <div>
            <span>Embarcador ativo no sistema?:</span>
            <p>{company?.registerStatus ? 'Sim' : 'Não'}</p>
          </div>
          <div>
            <span>CM:</span>
            <p>{company?.cm === 'S' ? 'Sim' : 'Não'}</p>
          </div>
          <div>
            <span>CEP:</span>
            <p>{company?.cep}</p>
          </div>
          <div>
            <span>Rua:</span>
            <p>{company?.logradouro}</p>
          </div>
          <div>
            <span>Número:</span>
            <p>{company?.numero}</p>
          </div>
          <div>
            <span>Bairro:</span>
            <p>{company?.bairro}</p>
          </div>
          <div>
            <span>Cidade:</span>
            <p>{company?.localidadeLabel}</p>
          </div>
          <div>
            <span>Estado:</span>
            <p>{company?.ufLabel}</p>
          </div>
          <div>
            <span>Complemento:</span>
            <p>{company?.complemento}</p>
          </div>
        </StyledForm>
      </Card>

      {(showShipper && company?.tipoEmpresa === 'T') && (
        <Card
          title="Embarcadores Associados"
          isEditable={false}
        >
          <AssociateShipper
            onSave={handleSaveShipper}
            shipper={shipper}
            editFlow
          />
        </Card>
      )}

      <Card
        title="Usuários Cadastrados"
        isEditable={false}
      >
        <RegisterUsers
          onSave={handleSaveUser}
          onToggle={handleActiveUser}
          companyId={company.companyId}
          userList={user}
          editFlow
        />
      </Card>

      <Card
        title="Cadastro de Emails"
        onEditClick={handleEditEmails}
      >      
        <StyledCardContainer>
          {emailList?.map((email, index) => renderCard(email, index))}
        </StyledCardContainer>
      </Card>

      <Card
        title="Financeiro"
        onEditClick={handleEditFinancial}
      >
        <StyledForm className="finance">
          <>
            <div>
              <span>Tipo de Cobrança:</span>
              <p>{(finance?.searchType === 'both' ? 'Ambos' : finance?.searchType === 'individual' ? 'Individual' : 'Integrada')}</p>
            </div>
          </>
          {finance?.searchType !== 'individual' && (
            <>
              <div>
                <span>Valor Integrada:</span>
                <p>R$ {finance?.integratedValue}</p>
              </div>
            </>
          )}
          {(finance?.searchType === 'both' || finance?.searchType === 'individual') && (
            <>
              <div>
                <span>Motorista:</span>
                <p>R$ {finance?.driver}</p>
              </div>
              <div>
                <span>Veículo / Cavalo:</span>
                <p>R$ {finance?.truck}</p>
              </div>
              <div>
                <span>Proprietário:</span>
                <p>R$ {finance?.owner}</p>
              </div>            
            </>
          )}
          <div>
            <span>Ajudante:</span>
            <p>R$ {finance?.driverAssistant}</p>
          </div>
          <div>
            <span>Consulta:</span>
            <p>R$ {finance?.consult}</p>
          </div>
          <div>
            <span>Faturar Dia:</span>
            <p>{finance?.billDay}</p>
          </div>
        </StyledForm>
      </Card>
    </section>
  )
}

TransportadorCards.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  onSave: PropTypes.func.isRequired,
  showShipper: PropTypes.bool,
}

TransportadorCards.defaultProps = {
  showShipper: true,
}

export default TransportadorCards
