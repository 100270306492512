import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  background-color: #e5e5e5;
  background: linear-gradient(135deg, #cacaca55 25%, transparent 25%) -24px 0/ 48px 48px, linear-gradient(225deg, #cacaca 25%, transparent 25%) -24px 0/ 48px 48px, linear-gradient(315deg, #cacaca55 25%, transparent 25%) 0px 0/ 48px 48px, linear-gradient(45deg, #cacaca 25%, #e5e5e5 25%) 0px 0/ 48px 48px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`

export const StyledSection = styled.section`
  width: 100%;
  margin-top: 100px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  
  @media (min-width: 700px) {
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }
`

export const StyledLogin = styled.div`
  background-color: var(--color-secondary);
  max-width: 1000px;
  width: calc(100% - 40px);
  margin: 0 auto;
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
  overflow: hidden;
  color: var(--color-gray-light);
  box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 40%);

  .left, .right {
    position: relative;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 500px;

    @media (max-width: 700px) {
      width: 100%;
      height: auto;
    }
  }

  .left {
    border-right: 1px solid var(--color-gray-dark);

    @media (max-width: 700px) {
      border-right: none;
      border-bottom: 1px solid var(--color-gray-dark);
    }

    & > div {
      height: 500px;
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 700px) {
        height: auto;
      }

      article {
        h2 {
          margin-bottom: 30px;
          text-align: center;

          i {
            margin-right: 20px;
          }
        }

        select, input {
          border: 1px solid var(--color-gray-light);
          background-color: var(--color-bg-dark);
          color: var(--color-light);
        }
      }
    }
  }
`

export const StyledImage = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80%;
  }

  @media (max-width: 700px) {
    height: 300px;
  }
`

export const StyledBackButton = styled.button`
  color: var(--color-primary);
  background: none;
  border: 1px solid var(--color-secondary);
  padding: 10px;

  &:hover {
    border-color: var(--color-primary);
  }

  i {
    margin-right: 10px;
  }
`