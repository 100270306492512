import React from 'react'
import PropTypes from 'prop-types'
import { StyledSectionTable } from './styles'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ModalTable = (props) => {
  const {
    values,
  } = props
  
  return (
    <StyledSectionTable>
      <div className='flex-top'>
        <h2 className=''>Tabela de Prazos</h2>
        <ReactHTMLTableToExcel
          id="table-xls-button"
          className="create-report"
          table="tabela-xlsx"
          filename={'relatorio-prazos'}
          sheet="tablexls"
          buttonText="Gerar em Excel"/>
      </div>
      <hr></hr>
      <table className='table' id='tabela-xlsx'>
        <thead>
          <tr>
            <th scope="col">Solicitação</th>
            <th scope="col">Empresa Solicitante</th>
            <th scope="col">Empresa Bilhetada</th>
            <th scope="col">Objeto</th>
            <th scope="col">Prazo</th>
            <th scope="col">Status</th>
          </tr>      
        </thead>
        <tbody>
          {
            values.map((item) => (
              <tr key={item.solicitacao + '-' + item.objeto}>
                <td scope="row">{item.solicitacao}</td>
                <td>{item.empresa_solicitante}</td>
                <td>{item.empresa_bilhetada}</td>
                <td>{item.objeto}</td>
                <td>{item.prazo || '-'}</td>
                <td>{item.status || '-'}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </StyledSectionTable>
  )
}

ModalTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
}

export default ModalTable
