import styled from '@emotion/styled'

export const StyledTopContainer = styled.div`
  max-width: 1500px;
  padding: 0 10px 20px;
  margin: 15px auto 20px;
  color: var(--color-secondary);
  border-bottom: 1px solid var(--color-primary);
`

export const StyledInfo = styled.div`
  text-align: center;
  margin-bottom: 15px;

  h2 {
    font-size: 30px;
  }

  h4 {
    line-height: 18px;

    i {
      margin: 0 13px 0 9px;
      transform: rotate(90deg);
    }
  }

  @media (min-width: 724px) {
    text-align: left;
    width: 350px;
    display: inline-block;
    vertical-align: middle;
  }
`

export const StyledSelected = styled.div`
  font-weight: 600;
  text-align: center;

  span {
    display: block;
  }

  p {
    color: var(--color-primary);
    font-size: 20px;
    margin-bottom: 0;
  }

  @media (min-width: 724px) {
    display: inline-block;
    text-align: right;
    width: calc(100% - 350px);
    float: right;
  }
`
