import React, { useState, useEffect, useCallback } from 'react'
import InputText from '@/views/fragments/form/InputText'
import ContainerTemplate from '../fragments/ContainerTemplate'
import AnalysisProfileCard from '@/views/fragments/AnalysisProfileCard'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import AnalysisProfileRegister from '@/views/Register/fragments/AnalysisProfileRegister'
import Modal from '@/views/fragments/Modal'
import Card from '../fragments/Card'
import { getAnalysesProfiles, putAnalysisProfile } from '@/constants/requests'
import { StyledContainerCard } from './styles'
import { useSelector } from 'react-redux'

const MODAL_TYPE = {
  register: 'register',
  delete: 'delete',
}

const SearchAnalysisProfile = () => {
  const [currentName, setCurrentName] = useState(null)
  const [analyseProfileList, setAnalyseProfileList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [dataToEdit, setDataToEdit] = useState({})
  const [modalType, setModalType] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [messageToShow, setMessageToShow] = useState({})

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.analysisProfileSearch.view) {
      window.location.href = "/";
      return
    }
  }, [])

  const requestProfiles = (useCallback(async () => {
    const data = await getAnalysesProfiles()
    setAnalyseProfileList(data.perfis)
  }, []))

  useEffect(() => {
    requestProfiles()
  }, [])


  const onChange = (e) => {
    const { value } = e.target
    // registerFound.filter((reg) =>
    //   reg.nome.toLowerCase().includes(value.toLowerCase())).map((el) => list.push(el)
    // )
    setCurrentName(value)
  }

  const onCloseModal = () => {
    setShowModal(false)
    setModalType('')
    setShowSuccessModal(false)
    setShowErrorModal(false)
    setMessageToShow({})
  }

  const handleSave = async (form) => {
    onCloseModal()
    const req = await putAnalysisProfile(form)
    if (req.data.status.active) {
      setShowSuccessModal(true)
      setAnalyseProfileList(req.data.perfis)
      return
    }

    setShowErrorModal(true)
    setMessageToShow(req.data)
  }

  const handleEdit = (data) => {
    setDataToEdit(data)
    setShowModal(true)
    setModalType(MODAL_TYPE.register)
  }

  const renderSuccessModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg='Editado com sucesso'
    />
  )

  const renderErrorModal = () => {
    const msg = `Erro ${messageToShow.codigo}: ${messageToShow.mensage}`
    return (
      <DefaultModalAlert
        closeModal={onCloseModal}
        msg={msg}
      />
    )
  }

  const handleChange = (data) => {
    setDataToEdit(data)
  }

  const renderModal = () => {
    if (modalType === MODAL_TYPE.register) {
      return (
        <Modal
          closeModal={onCloseModal}
          title="Editar"
          size="lg"
        >
          <AnalysisProfileRegister
            formValues={dataToEdit}
            onSave={handleSave}
            handleChange={handleChange}
            isEdit
          />
        </Modal>
      )
    }
  }

  return (
    <ContainerTemplate>
      {showModal && renderModal()}
      {showSuccessModal && renderSuccessModal()}
      {showErrorModal && renderErrorModal()}
      <aside>
        <h3>Buscar Cliente</h3>
        <InputText
          label="Por nome"
          name="name"
          value={currentName || ''}
          onChange={(e) => onChange(e)}
        />
      </aside>
      <section>
        <section>
          <Card
            title="Perfis Encontrados"
            isEditable={false}
          >
            <StyledContainerCard>
              <AnalysisProfileCard
                onEdit={handleEdit}
                profileList={analyseProfileList}
              />
            </StyledContainerCard>
          </Card>
        </section>
      </section>
    </ContainerTemplate>
  )
}

export default SearchAnalysisProfile
