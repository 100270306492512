import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { validatePFAddressFields } from '@/validators/registerValidations'
import {
  StyledForm,
  StyledFormButton
} from '@/views/Register/fragments/styles'
import FormAddress from '@/views/Register/fragments/FormAddress'

const RegisterAddress = (props) => {
  const { onSave, address, showSaveLabel } = props

  const [formValues, setFormValues] = useState({})
  const [validateList, setValidateList] = useState({})

  useEffect(() => {
    setFormValues(address)
  },[address])

  const onSaveClick = (e) => {
    e.preventDefault()

    const list = validatePFAddressFields(formValues)
    if (list.status) {
      setValidateList(list)
      return
    }

    onSave(formValues)
  }

  const handleFormValues = (form) => {
    setFormValues(form)
  }

  const handleValidateList = (form) => {
    setValidateList(form)
  }

  return (
    <StyledForm>
      <FormAddress
        formValues={formValues}
        setFormValues={handleFormValues}
        validateList={validateList}
        setValidateList={handleValidateList}
      />

      <StyledFormButton className="button" onClick={onSaveClick}>
        <i className="fas fa-angle-double-right" />
        {showSaveLabel ? 'Salvar' : 'Continuar'}
      </StyledFormButton>
    </StyledForm>
  )
}

RegisterAddress.propTypes = {
  onSave: PropTypes.func.isRequired,
  showSaveLabel: PropTypes.bool.isRequired,
  address: PropTypes.objectOf(PropTypes.any),
}

RegisterAddress.defaultProps = {
  address: {},
}

export default RegisterAddress
