import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomLoading, StyledHint } from './styles'

const SelectText = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    onEdit,
    onNew,
    options,
    isRequired,
    isEditable,
    isNewable,
    notEmptyOption,
    isDisabled,
    className,
    isLoading,
    isError,
    isSuccess,
    hint,
    showcase,
  } = props

  const renderOptions = (item, index) => (
    <option key={index} value={item.value}>{item.label}</option>
  )

  const renderType = () => {
    if (isSuccess) return 'success'
    if (isError) return 'alert'
    return ''
  }
  
  const handleEdit = () => {
    if (!value) {
      onEdit(false)
      return;
    }

    let obj = options.filter((element) => {
      if (element.value === Number(value)) {
        return element;
      } 
    })

    onEdit(obj)
  } 

  const handleNew = () => {
    onNew()
  } 

  return (
    <Field className={className}>
      <span>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      {isNewable && (
        <i onClick={handleEdit} className="fa-solid fa-pencil buttons"></i>
      )}
      {isEditable && (
        <i onClick={handleNew} className="fa-solid fa-plus buttons"></i>
      )}
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={renderType()}
        required={isRequired}
        disabled={isDisabled || isLoading}
      >
        {!notEmptyOption && (
          <option value="nulo">
            {showcase ? showcase : 'Selecione uma opção'}
          </option>
        )}
        {options?.map((item, index) => renderOptions(item, index))}
      </select>
      {isLoading && (
        <CustomLoading className="fas fa-spinner" />
      )}
      {(isError && !isSuccess) && (
        <StyledHint className={isSuccess ? 'success' : ''}>
          {hint}
        </StyledHint>
      )}
    </Field>
  )
}

SelectText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isRequired: PropTypes.bool,
  isEditable: PropTypes.bool,
  isNewable: PropTypes.bool,
  notEmptyOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hint: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  showcase: PropTypes.string
}

SelectText.defaultProps = {
  isRequired: false,
  isEditable: false,
  isNewable: false,
  notEmptyOption: false,
  isDisabled: false,
  className: '',
  isLoading: false,
  hint: '',
  isError: false,
  isSuccess: false,
  options: null,
  showcase: null
}

export default SelectText
