import styled from '@emotion/styled'

export const StyledSectionTable = styled.table`
  table { 
    margin-top: 30px;
  }

  hr {
    margin-top: 0;
  }

  .flex-top {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .create-report {
    background-color: #d4e7f7;
    color: var(--color-gray-dark);
    padding: 15px;
    width: 155px;
    cursor: pointer;
    border-radius: 5px;  
    height: 50px;
    margin-top: 5px;  
    font-weight: 600;
  }

  th {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: var(--color-gray-dark);
    background-color: #d4e7f7;
    padding: 3px;
    border: 1px solid var(--color-gray-dark);
  }
  
  td {
    padding: 5px;
    border: 1px solid var(--color-gray-dark);
  }
`
