// export const path = 'http://localhost:5000/webrun5'
// export const path = 'https://devwebrun.permanence.com.br/cad'
// export const path = 'https://hmgwebrun.permanence.com.br/cad'
export const path = 'https://back.mundialrisk.com.br/cad'

export const API = {
  GET_CNPJ_INFO: 'https://www.receitaws.com.br/v1/cnpj',
  LOGIN_REQUEST: `${path}/wsValidaUsuario.rule?sys=CAD`,
  CONSULT_EMPLOYEE: `${path}/wsConsultaCnpj.rule?sys=CAD`,
  CONSULT_CPF: `${path}/wsConsultaCpf.rule?sys=CAD`,
  CONSULT_ARTICLES: `${path}/wsConsultaArtigos.rule?sys=CAD`,
  CONSULT_PROFILE: `${path}/wsConsultaPerfisAnalise.rule?sys=CAD`,
  PERSISTENCE_LOGIN: `${path}/wsValidaToken.rule?sys=CAD`,
  ANALYSIS_PROFILE: `${path}/wsInsereAlteraPerfisAnalise.rule?sys=CAD`,
  GET_RESEARCH: `${path}/wsConsultaPesquisas.rule?sys=CAD`,
  GET_OPTIONS: `${path}/wsConsultaParametros.rule?sys=CAD`,
  GET_LICENSE_PLATE: `${path}/wsConsultaPlaca.rule?sys=CAD`,
  GET_PRIVILEGES: `${path}/wsConsultaPrivilegio.rule?sys=CAD`,
  GET_GROUP_PRIVILEGES: `${path}/wsConsultaGrupoPrivilegio.rule?sys=CAD`,
  SUBMIT_CHANGE_PRIVILEGES: `${path}/wsAtualizarPrivilegio.rule?sys=CAD`,
  SUBMIT_GROUP_PRIVILEGES: `${path}/wsCadastrarGrupoPrivilegio.rule?sys=CAD`,
  REQUEST_CAR_MODEL: `${path}/wsConsultaMarcaModelo.rule?sys=CAD`,
  CONSULT_OWNER: `${path}/wsConsultaProprietario.rule?sys=CAD`,
  SUBMIT_NEW_SEARCH: `${path}/wsNovaPesquisa.rule?sys=CAD`,
  REQUEST_MANUAL_SEARCH: `${path}/wsRetornoPesquisasManuais.rule?sys=CAD`,
  REQUEST_LOCALIDADE: `${path}/wsConsultaLocalidade.rule?sys=CAD`,
  REQUEST_SOLICITATION: `${path}/wsConsultaPesquisaDetalhes.rule?sys=CAD`,
  REQUEST_MANUAL_SOLICITATION: `${path}/wsFinalizaSolicitacaoManual.rule?sys=CAD`,
  REQUEST_CONSULTA_EMBARCADOR: `${path}/wsConsultaEmbarcadores.rule?sys=CAD`,
  SUBMIT_TRANSPORTADOR: `${path}/wsTransportador.rule?sys=CAD`,
  GET_REGISTERED: `${path}/wsConsultaLogin.rule?sys=CAD`,
  GET_MENU_PROFILE: `${path}/wsMenus.rule?sys=CAD`,
  SUBMIT_NEW_USER: `${path}/wsCadastrarUsuario.rule?sys=CAD`,
  SUBMIT_ACTIVE_USER: `${path}/wsAtivarUsuario.rule?sys=CAD`,
  SUBMIT_CHANGE_USER: `${path}/wsAtualizarUsuario.rule?sys=CAD`,
  SUBMIT_CHANGE_FINANCE: `${path}/wsAtualizarFinanceiro.rule?sys=CAD`,
  SUBMIT_CHANGE_EMAIL: `${path}/wsAtualizarEmail.rule?sys=CAD`,
  SUBMIT_CHANGE_TRANSPORTADOR: `${path}/wsAtualizarTransportador.rule?sys=CAD`,
  SUBMIT_CHANGE_EMBARCADOR: `${path}/wsAtualizarEmbarcador.rule?sys=CAD`,
  SUBMIT_NEW_EMBARCADOR: `${path}/wsCadastrarEmbarcador.rule?sys=CAD`,
  SUBMIT_DELETE_EMBARCADOR: `${path}/wsDeletarEmbarcador.rule?sys=CAD`,
  SUBMIT_DELETE_EMAIL: `${path}/wsDeletarEmail.rule?sys=CAD`,
  SUBMIT_ACTIVE_BLACKLIST: `${path}/wsDeletarBlacklist.rule?sys=CAD`,
  GET_BLACKLIST: `${path}/wsConsultarBlacklist.rule?sys=CAD`,
  MANUAL_BLOCK: `${path}/wsBloqueioManual.rule?sys=CAD`,
  CONSULT_PLATE: `${path}/wsConsulta.rule?sys=CAD`,
  SUBMIT_NEW_BLACKLIST: `${path}/wsCadastrarBlacklist.rule?sys=CAD`,
  SUBMIT_CHANGE_BLACKLIST: `${path}/wsAtualizarBlacklist.rule?sys=CAD`,
  SUBMIT_NEW_MODEL: `${path}/wsCadastrarModelo.rule?sys=CAD`,
  SUBMIT_CHANGE_MODEL: `${path}/wsAtualizarModelo.rule?sys=CAD`,
  SUBMIT_NEW_MARCA: `${path}/wsCadastrarMarca.rule?sys=CAD`,
  SUBMIT_CHANGE_MARCA: `${path}/wsAtualizarMarca.rule?sys=CAD`,
  GET_REPORT: `${path}/wsGerarRelatorio.rule?sys=CAD`,
  GET_REPORT_EXCEL: `${path}/wsGerarRelatorioExcel.rule?sys=CAD`,
  GET_BILL_DAY: `${path}/wsConsultarFaturaDias.rule?sys=CAD`,
  GET_BILL_CLIENTS: `${path}/wsEmpresasFaturas.rule?sys=CAD`,
  GET_MANAGERIAL_TABLE: `${path}/wsConsultarFaturaDiasGerencial.rule?sys=CAD`,
  GET_MANAGERIAL_CLIENTS: `${path}/wsEmpresasFaturasGerencial.rule?sys=CAD`,
  GET_COMPANY_TERM: `${path}/wsVerificarEmpresaPrazos.rule?sys=CAD`,
  GET_LIST_TERM: `${path}/wsListaPrazos.rule?sys=CAD`,
  GET_TOTAL_TERM: `${path}/wsQuantidadePrazos.rule?sys=CAD`,
}
