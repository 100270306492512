import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import SelectText from '@/views/fragments/form/SelectText'
import InputText from '@/views/fragments/form/InputText'
import {
  formatterCPF,
  formatterPhone,
  formatterEmailField,
  formatterEmail,
  formatterNumberOnly,
} from '@/utils/formatter'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { validarCPF } from '@/validators/cpfValidator'
import { MAX_LENGTH, MIN_LENGTH } from '@/utils/minMaxLength'
import {
  StyledForm,
  StyledAddPhone,
  StyledPhoneField,
  StyledPhoneContainer,
} from '@/views/Register/fragments/stylesRegisterPf'
import { postConsultaCPF, reqLocalidade } from '@/constants/requests'

const RegisterPFForm = (props) => {
  const {
    isNewSearch,
    onChangeValues,
    formValues,
    onCpfValidate,
    onValidateEmail,
    cpfValidate,
    validateEmail,
    onValidateList,
    validateList,
    hasRegistered,
    defaultOptions,
    handleBond,
    handleHelper
  } = props
  const [stateList, setStateList] = useState([])

  useEffect(() => {
    const url = window.location.pathname
    const doc = url.split('/pessoa-fisica-cadastro/')
    if (doc[1]) {
      onChangeValues({
        ...formValues,
        cpf: doc[1],
      })
    }
  }, [])

  const setupStatesList = useCallback(async() => {
    const list = await reqLocalidade()
    setStateList(list.state)
  }, [])

  useEffect(() => {
    setupStatesList()
  }, [setupStatesList])

  const onChange = (e, ml) => {
    const { name, value } = e.target
    let newValue = value.replace(String.fromCharCode(9), " ");

    
    if (name === 'cpf') {
      newValue = formatterNumberOnly(newValue)
    }
    
    if (name === 'rg') {
      newValue = newValue.trim()
    }
    
    if (name === 'email') {
      newValue = formatterEmailField(newValue)
    }
    
    if (name === 'vinculo') {
      handleBond && handleBond(value === 'F')
    }
    
    if (name === 'userType') {
      handleHelper(value === 'A' || value === 'F')
    }
    
    if (newValue.length > ml) return

    onChangeValues({
      ...formValues,
      [name]: newValue,
    })

    onValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const onChangePhone = (e, i, ml) => {
    const { value } = e.target
    const newArray = [...formValues.phones]

    if (value.length > ml) return
    newArray[i] = formatterNumberOnly(value)

    onChangeValues({
      ...formValues,
      phones: newArray,
    })

    onValidateList({
      ...validateList,
      phone: false,
    })
  }

  const onAddPhoneClick = (e) => {
    e.preventDefault()
    const newArray = [...formValues.phones]
    newArray.push('')

    onChangeValues({
      ...formValues,
      phones: newArray,
    })
  }

  const removePhoneClick = (i) => {
    const newArray = [...formValues.phones]
    newArray.splice(i, 1)

    onChangeValues({
      ...formValues,
      phones: newArray,
    })
  }

  const renderPhones = (phone, index) => (
    <StyledPhoneField
      key={index}
      className={`phone ${formValues.phones.length > 1 ? 'hasTrash' : ''}`}
    >
      <InputText
        label={`Telefone ${index + 1}`}
        name="phone"
        isRequired={index === 0}
        isError={index === 0 ? validateList.phone : false}
        hint={index === 0 ? REQUIRED_FIELD : null}
        value={formatterPhone(phone) || ''}
        onChange={(e) => onChangePhone(e, index, MAX_LENGTH.quinze)}
      />
      {formValues.phones.length > 1 && (
        <i className="far fa-trash-alt" onClick={() => removePhoneClick(index)}/>
      )}
    </StyledPhoneField>
  )

  const handleCFPBlur = async (e) => {
    if (!e.target.value) return

    const valid = validarCPF(e.target.value)

    if (!valid) {
      onCpfValidate({
        msg: 'CPF inválido',
        status: true,
      })
      return
    }

    const secondValidation = await postConsultaCPF({cpf: formatterNumberOnly(e.target.value)})

    if (secondValidation?.cpf?.tipo_pessoa !== "M") {
      handleHelper(true)
    }

    if (secondValidation?.cpf) {
      hasRegistered(secondValidation.cpf)
    }

    onCpfValidate({})
  }

  const handleEmailBlur = (e) => {
    if (!e.target.value) {
      onValidateEmail(false)
      return
    }

    const valid = formatterEmail(e.target.value)
    onValidateEmail(!valid)
  }

  const handleNameBlur = (e) => {
    const { name, value } = e.target
    if (!value) return

    if (value.length < MIN_LENGTH.five) {
      onValidateList({
        ...validateList,
        [name]: true,
      })
    }
  }

  return (
    <>
      <StyledForm>
        <InputText
          label="CPF"
          name="cpf"
          className="cpf"
          isRequired
          isError={cpfValidate.status || validateList.cpf}
          hint={cpfValidate.msg}
          value={formatterCPF(formValues.cpf) || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
          onBlur={handleCFPBlur}
        />
        <InputText
          label="Nome"
          name="name"
          isRequired
          isError={validateList.name}
          hint={REQUIRED_FIELD}
          className="name"
          value={formValues.name || ''}
          onChange={onChange}
        />
        <InputText
          label="Data de Nascimento"
          name="birthdate"
          isRequired
          isError={validateList.birthdate}
          hint={REQUIRED_FIELD}
          className="birthdate"
          type="date"
          value={formValues.birthdate || ''}
          onChange={onChange}
        />
        <InputText
          label="Nome da Mãe"
          name="motherName"
          isRequired
          isError={validateList.motherName}
          hint={REQUIRED_FIELD}
          placeholder="Mínimo 5 caracteres"
          className="motherName"
          value={formValues.motherName || ''}
          onChange={onChange}
          onBlur={handleNameBlur}
        />
        <InputText
          label="Nome do Pai"
          name="fatherName"
          isRequired
          placeholder="Mínimo 5 caracteres"
          isError={validateList.fatherName}
          hint={REQUIRED_FIELD}
          className="fatherName"
          value={formValues.fatherName || ''}
          onChange={onChange}
          onBlur={handleNameBlur}
        />

        {isNewSearch && (
          <SelectText
            label="Perfil"
            name="userType"
            isRequired
            isError={validateList.userType}
            hint={REQUIRED_FIELD}
            className="userType"
            options={defaultOptions?.tipos_pessoa}
            value={formValues.userType || ''}
            onChange={onChange}
          />
        )}
        <SelectText
          label="Vínculo"
          name="vinculo"
          isRequired
          isError={validateList.vinculo}
          hint={REQUIRED_FIELD}
          className={isNewSearch ? 'userType second' : 'userType'}
          options={defaultOptions?.tipos_vinculo}
          value={formValues.vinculo || ''}
          onChange={onChange}
        />
        <InputText
          label="RG"
          name="rg"
          isRequired
          placeholder="Mínimo 1 caracteres"
          isError={validateList.rg}
          hint={REQUIRED_FIELD}
          className="rg"
          value={formValues.rg || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.trinta)}
        />
        <SelectText
          label="Estado Emissor"
          name="ufIssue"
          isRequired
          isError={validateList.ufIssue}
          hint={REQUIRED_FIELD}
          className="ufIssue"
          options={stateList}
          value={formValues.ufIssue || ''}
          onChange={onChange}
        />
        {!isNewSearch && (
          <InputText
            label="Email"
            name="email"
            className="email"
            isError={validateEmail}
            hint="Formato inválido"
            value={formValues.email || ''}
            onChange={onChange}
            onBlur={handleEmailBlur}
          />
        )}
      </StyledForm>
      <StyledPhoneContainer>
        {formValues?.phones?.map((phone, index) => renderPhones(phone, index))}
      </StyledPhoneContainer>
      {formValues?.phones?.length < 3 && (
        <StyledAddPhone className="button" onClick={(e) => onAddPhoneClick(e)}>
          <i className="fas fa-mobile-alt" />
          <i className="fas fa-plus" />
        </StyledAddPhone>
      )}
    </>
  )
}

RegisterPFForm.propTypes = {
  showSaveLabel: PropTypes.bool,
  isNewSearch: PropTypes.bool,
  onChangeValues: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onCpfValidate: PropTypes.func,
  onValidateEmail: PropTypes.func,
  cpfValidate: PropTypes.objectOf(PropTypes.any),
  validateEmail: PropTypes.bool,
  onValidateList: PropTypes.func,
  hasRegistered: PropTypes.func,
  validateList: PropTypes.objectOf(PropTypes.any),
  defaultOptions: PropTypes.objectOf(PropTypes.array),
  handleBond: PropTypes.func,
  handleHelper: PropTypes.func
}

RegisterPFForm.defaultProps = {
  showSaveLabel: false,
  isNewSearch: false,
  onChangeValues: null,
  onCpfValidate: null,
  onValidateEmail: null,
  cpfValidate: {},
  validateEmail: false,
  onValidateList: null,
  validateList: {},
  hasRegistered: null,
  defaultOptions: null,
  handleBond: null,
  handleHelper: null
}

export default RegisterPFForm
