import styled from '@emotion/styled'

export const StyledCard = styled.article`
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;

  & > h2 {
    color: var(--color-light);
    background-color: var(--color-primary);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 15px;

    button {
      background-color: transparent;
      border: none;
      float: right;
      color: var(--color-light);
    }
  }

  & > div {
    padding: 20px 10px;
  }
`
