import React from 'react'
import {
  StyledContainer,
} from './styles'

const ForbiddenPage = () => (
  <StyledContainer>
    <i className="far fa-sad-tear" />
    <h4>Ah Não!</h4>
    <p>Parece que você não tem permissão para acessar a página. Tente fazer o login novamente, ou contate um administrador.</p>
  </StyledContainer>
)

export default ForbiddenPage
