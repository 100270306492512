import styled from '@emotion/styled'

export const StyledButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 50px;
`

export const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px 10px;

  & > div {
    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
      display: block;
      margin-bottom: 5px;
    }
  }

  @media (min-width: 570px) {
    grid-template-columns: repeat(3, calc(33.33333% - 6.6666667px));
  }


  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, calc(25% - 7.5px));
  }
`

