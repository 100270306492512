export const setupOptionsList = (data) => {
  const carrocerias = []
  const categorias_cnh = []
  const tipos_pessoa = []
  const tipos_vinculo = []

  data.carrocerias.map((el) => {
    const list = {
      value: el.carroceria,
      label: el.carroceria
    }
    carrocerias.push(list)
  })

  data.categorias_cnh.map((el) => {
    const list = {
      value: el.categoria,
      label: el.categoria
    }
    categorias_cnh.push(list)
  })

  data.tipos_pessoa.map((el) => {
    const list = {
      value: el.abreviacao,
      label: el.descricao
    }
    tipos_pessoa.push(list)
  })

  data.tipos_vinculo.map((el) => {
    const list = {
      value: el.abreviacao,
      label: el.descricao
    }
    tipos_vinculo.push(list)
  })

  return {
    carrocerias,
    categorias_cnh,
    tipos_pessoa,
    tipos_vinculo,
  }
}
