import React, { useState, useEffect } from 'react'
import Acordeon from '@/views/fragments/Acordeon'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import { StyledSection } from './style'
import AnalysisProfileRegister from '../fragments/AnalysisProfileRegister'
import { postAnalysisProfile } from '@/constants/requests'
import { useSelector } from 'react-redux'

const AnalysisProfile = () => {
  const [formValues, setFormValues] = useState({})
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [messageToShow, setMessageToShow] = useState({})

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.analysisProfile.view) {
      window.location.href = "/";
      return
    }
  }, [])

  const handleChange = (data) => {
    setFormValues(data)
  }

  const handleSave = async (data) => {
    const req = await postAnalysisProfile(data)
    if (req.data.status.active) {
      setShowSuccessModal(true)
      return
    }

    setShowErrorModal(true)
    setMessageToShow(req.data)
  }

  const onCloseModal = (bool) => {
    setShowSuccessModal(false)
    setShowErrorModal(false)
    setMessageToShow({})
    if (bool) {
      setFormValues({})
    }
  }

  const renderSuccessModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg='Cadastrado com sucesso'
    />
  )

  const renderErrorModal = () => {
    const msg = `Erro ${messageToShow.codigo}: ${messageToShow.mensage}`
    return (
      <DefaultModalAlert
        closeModal={onCloseModal}
        msg={msg}
      />
    )
  }

  return (
    <StyledSection className='container'>
      {showSuccessModal && renderSuccessModal()}
      {showErrorModal && renderErrorModal()}
      <Acordeon
        title="Perfil de Análise - Cadastro"
        icon="fas fa-gavel"
        showContent
      >
        <AnalysisProfileRegister
          formValues={formValues}
          onSave={handleSave}
          handleChange={handleChange}
        />
      </Acordeon>
    </StyledSection>
  )
}

export default AnalysisProfile
