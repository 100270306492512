import styled from '@emotion/styled'

export const StyledCotainderAcordion = styled.div`
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  overflow: hidden;
  max-width: 1500px;
  margin: 0 auto 30px;

  &.lock {
    border-color: var(--color-gray-light);
  }
`

export const StyledAcordionNav = styled.nav`
  color: var(--color-light);
  padding: 0 10px;
  line-height: 60px;
  position: relative;
  cursor: pointer;

  h2 {
    font-size: 20px;
  }

  &.lock {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

  &.open {
    background-color: var(--color-primary);
  }

  @media (min-width: 420px) {
    padding: 0 30px;
  }

  @media (min-width: 724px) {
    h2 {
      font-size: 26px;
    }
  }
`

export const StyledTitle = styled.h3`
  display: inline-block;

  i {
    margin-right: 15px;
  }
`

export const StyledLockButton = styled.div`
  float: right;
`

export const StyledChildren = styled.div`
  padding: 10px;

  @media (min-width: 1024px) {
    padding: 20px;
  }
`