import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { formatterMoney } from '@/utils/formatter'
import Modal from '@/views/fragments/Modal'
import InputText from '@/views/fragments/form/InputText'
import SelectText from '@/views/fragments/form/SelectText'
import { YES_OR_NOT, SEARCH_OR_CONSULT } from '@/constants/optionsValues'
import { MAX_LENGTH, MIN_LENGTH } from '@/utils/minMaxLength'
import { getFullArticles } from '@/constants/requests'
import {
  BoxStyled,
  StyledLabel,
  StyledContainer,
  InputsStyleCustom,
  StyledAnttField
} from './styles'
import CheckboxField from '@/views/fragments/form/CheckboxField'

const ARTICLE_LIMIT = 25

const AnalysisProfileRegister = (props) => {
  const {
    formValues,
    handleChange,
    onSave,
    isEdit
  } = props

  const [fillList, setFillList] = useState([])
  const [usedList, setUsedList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [filterBy, setFilterBy] = useState('')
  const [modalType, setModalType] = useState('')

  const removeUsedArticles = useCallback((list) => {
    if (!formValues.artigos) return
    const newList = [...list]
    formValues.artigos.map((elem) => {
      const i = newList.findIndex((e) => elem.artigo === e.artigo)

      newList.splice(i, 1)
    })

    setFillList(newList)
  }, [setFillList])

  const requestForArticles = useCallback(async() => {
    const data = await getFullArticles()
    setFillList(data.artigos)
    removeUsedArticles(data.artigos)
  }, [])

  useEffect(() => {
    requestForArticles()
    setUsedList(formValues.artigos || [])
  }, [])

  const onToggleClick = (name, value) => {
    handleChange({
      ...formValues,
      [name]: value,
    })
  }

  const onChange = (e, ml) => {
    const {name, value} = e.target
    if (!value) return handleChange({
      ...formValues,
      [name]: value
    })

    if (name === 'cnh_pontos' 
      && (Number(value) > ml || Number(value) < MIN_LENGTH.one)) {
      setModalType('cnh')
      setShowModal(true)
      return
    }

    if (Number(value) > ml || Number(value) < MIN_LENGTH.one) {
      setModalType('data')
      setShowModal(true)
      return
    }

    handleChange({
      ...formValues,
      [name]: value
    })
  }

  const onCloseModal = () => {
    setShowModal(false)
    setModalType('')
  }

  const onAvailableClick = (item) => {
    if (usedList.length >= ARTICLE_LIMIT) {
      setShowModal(true)
      setModalType('article')
      return
    }
    const newList = [...usedList]
    const oldList = [...fillList]
    const i = oldList.findIndex((e) => item.artigo === e.artigo)

    oldList.splice(i, 1)
    newList.push(item)
    setUsedList(newList)
    setFillList(oldList)
  }

  const onChoiceClick = (item) => {
    const newList = [...usedList]
    const oldList = [...fillList]
    const i = newList.findIndex((e) => item.artigo === e.artigo)

    newList.splice(i, 1)
    oldList.push(item)
    setUsedList(newList)
    setFillList(oldList)
  }

  const renderAvailableList = () => {
    if (!filterBy) {
      return fillList.map((item, i) => (
        <li key={i} onClick={() => onAvailableClick(item)}>
          {`Art. ${item.artigo}`}
        </li>
      ))
    }

    return fillList.filter((l) => l.artigo.includes(filterBy)).map((item, i) => (
      <li key={i} onClick={() => onAvailableClick(item)}>
        {`Art. ${item.artigo}`}
      </li>
    ))
  }

  const renderChoiceList = (item, i) => {
    if (!item.artigo) return
    return (
      <li key={i} onClick={() => onChoiceClick(item)}>
        {`Art. ${item.artigo}`} <i className="fas fa-times" />
      </li>
    )
  }

  const handleSave = () => {
    if (
      !usedList.length
      || !formValues.nome
      || !formValues.fin_cheque
      || !formValues.fin_valor
      || !formValues.tempo_terceiro
      || !formValues.tempo_agregado
      || !formValues.tempo_frota
      || !formValues.cnh_pontos
      || !formValues.cnh_ignorar_pontos
    ) {
      setModalType('empty')
      setShowModal(true)
      return
    }

    const newValue = {
      ...formValues,
      fin_valor: formatterMoney(formValues.fin_valor),
      artigos: usedList
    }
    onSave(newValue)
  }

  const renderTextChoices = () => {
    if (!usedList.length) return 'Nenhum artigo selecionado'
    return usedList.length <= 1 ? '1 Artigo Selecionado' : `${usedList.length} Artigos Selecionados`
  }

  const renderModal = () => {
    let title = 'Hum...'
    let msg = ''

    if (modalType === 'article') {
      msg = `Permitido apenas ${ARTICLE_LIMIT} artigos e o limite ja foi atingido :)`
    }

    if (modalType === 'cnh') {
      msg = 'Favor preencha com numeros entre 1 e 40'
    }

    if (modalType === 'empty') {
      title = 'Há campos vazios...'
      msg = 'Selecione ao menos um artigo e preencha todos os campos marcados *'
    }

    if (modalType === 'data') {
      title = 'Valores errados'
      msg = 'Favor preencha com numeros entre 1 e 365'
    }

    return (
      <Modal
        closeModal={onCloseModal}
        title={title}
        subTitle={msg}
      />
    )
  }

  const onChangeFilter = (e) => {
    setFilterBy(e.target.value)
  }

  return (
    <>
      {showModal && renderModal()}
      <InputText
        label="Nome do Perfil"
        name="nome"
        value={formValues.nome || ''}
        onChange={onChange}
        isRequired
        isDisabled={isEdit}
      />
      <StyledContainer>
        <div>
          <StyledLabel>Artigos Disponíveis</StyledLabel>
          <InputText
            placeholder="Filtrar..."
            name="filterBy"
            value={filterBy || ''}
            onChange={onChangeFilter}
          />
          <BoxStyled>
            {renderAvailableList()}
          </BoxStyled>
          <span className="clear" />
        </div>
        <div><i className="fas fa-exchange-alt" /></div>
        <div>
          <StyledLabel>{renderTextChoices()}</StyledLabel>
          <BoxStyled>
            {usedList.map((item, index) => renderChoiceList(item, index))}
          </BoxStyled>
        </div>
      </StyledContainer>
      <InputsStyleCustom>
        <InputText
          label="Cheques (qtdd)"
          name="fin_cheque"
          type="number"
          placeholder="Quantidade de cheques"
          value={formValues.fin_cheque || ''}
          onChange={onChange}
          isRequired
        />
        <InputText
          label="Valor Acima de?"
          name="fin_valor"
          value={formatterMoney(formValues.fin_valor) || ''}
          onChange={onChange}
          isRequired
        />
        <InputText
          label="Tempo de Terceiro"
          name="tempo_terceiro"
          type="number"
          placeholder="Número entre 1 e 365"
          value={formValues.tempo_terceiro || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.tresmeiacinco)}
          isRequired
        />
        <InputText
          label="Tempo Agregado"
          name="tempo_agregado"
          type="number"
          placeholder="Número entre 1 e 365"
          value={formValues.tempo_agregado || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.tresmeiacinco)}
          isRequired
        />
        <InputText
          label="Tempo de Frota"
          name="tempo_frota"
          type="number"
          placeholder="Número entre 1 e 365"
          value={formValues.tempo_frota || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.tresmeiacinco)}
          isRequired
        />
        <InputText
          label="Pontos máximos da CNH"
          name="cnh_pontos"
          type="number"
          placeholder="Número entre 1 e 40"
          value={formValues.cnh_pontos || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.quarenta)}
          isRequired
        />
        <SelectText
          label="Ignorar pontos CNH"
          name="cnh_ignorar_pontos"
          value={formValues.cnh_ignorar_pontos || ''}
          onChange={onChange}
          isRequired
          options={YES_OR_NOT}
        />
        <span className='clear' />
        <CheckboxField
          label="Motorista: Realizar pesquisa financeira?"
          name="pesquisaFincanceira"
          className="checkbox-field"
          value={formValues.pesquisaFincanceira || 'N'}
          onClick={onToggleClick}
        />

        <CheckboxField
          label="Proprietario: Realizar pesquisa financeira?"
          name="pesquisaFincanceiraPJ"
          className="checkbox-field"
          value={formValues.pesquisaFincanceiraPJ || 'N'}
          onClick={onToggleClick}
        />

        <StyledAnttField>
          <CheckboxField
            label="Validar ANTT recente?"
            name="validar_antt"
            className="checkbox-field"
            value={formValues.validar_antt || 'N'}
            onClick={onToggleClick}
          />
          {formValues.validar_antt === 'S' && (
            <InputText
              label="Informe em dias"
              name="annt_dias"
              type="number"
              value={formValues.annt_dias || ''}
              onChange={onChange}
              isRequired
            />
          )}
        </StyledAnttField>

        <CheckboxField
          label="Validar CIOT"
          name="validar_ciot"
          className="checkbox-field"
          value={formValues.validar_ciot || 'N'}
          onClick={onToggleClick}
        />

        <CheckboxField
          label="Validar terceiros?"
          name="validar_terceiros"
          className="checkbox-field"
          value={formValues.validar_terceiros || 'N'}
          onClick={onToggleClick}
        />

        <CheckboxField
          label="Validar status do CPF?"
          name="validar_cpf_status"
          className="checkbox-field"
          value={formValues.validar_cpf_status || 'N'}
          onClick={onToggleClick}
        />

        {formValues.validar_terceiros === 'S' && (
          <SelectText
            label="Por Consulta ou Pesquisa"
            name="terceiro_por_consulta"
            value={formValues.terceiro_por_consulta || ''}
            onChange={onChange}
            isRequired
            options={SEARCH_OR_CONSULT}
          />
        )}

      </InputsStyleCustom>
      <button className="button" onClick={handleSave}>
        Salvar
      </button>
    </>
  )
}

AnalysisProfileRegister.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
}

AnalysisProfileRegister.defaultProps = {
  isEdit: false
}

export default AnalysisProfileRegister
