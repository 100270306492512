export const getAllParams = () => {
  const auth = window.localStorage.getItem('authentication')
  const costCentreName = window.localStorage.getItem('costCentre')
  const cnpjCostCentre = window.localStorage.getItem('cnpjCostCentre')
  const user = window.localStorage.getItem('user')
  const profile = window.localStorage.getItem('profile')

  return {
    auth,
    costCentreName,
    user,
    profile,
    cnpjCostCentre
  }
}

export const deleteParams = () => {
  window.localStorage.removeItem('costCentre')
  window.localStorage.removeItem('cnpjCostCentre')
  window.localStorage.removeItem('authentication')
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('profile')
}

export const setParams = (name, value) => {
  window.localStorage.setItem(name, value)
}
