import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import Modal from '@/views/fragments/Modal'
import { format } from 'date-fns'
import { StyledSolicitation, StyledDetailContent, StyledButton } from '../styles'
import { validateManualSolicitation } from '@/validators/registerValidations'
import { zonedTimeToUtc } from 'date-fns-tz'

const timeZone = 'America/Sao_Paulo'

const ModalSolicitation = (props) => {
  const {
    item,
    setReqInfoSolicitation,
    setShowPeopleContent,
    setShowForm,
    defaultStatus,
    showPeopleContent,
    showEntireItem,
    hasVariance,
    setShowEntireItem,
    showForm,
    onCloseModal,
    info,
    formSolicitation,
    onConfirmSubmit,
    setFormSolictation,
    loading,
    showErrorSize,
    showErrorType,
    permission
  } = props

  const [docType, setDocType] = useState(null)
  const [showDetail, setShowDetail] = useState(false)

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const onChangeDeadline = (e, i) => {
    const { value } = e.target

    if (i >= 0) {
      const newArray = [
        ...formSolicitation.veiculos
      ]

      newArray[i] = {
        ...formSolicitation.veiculos[i],
        prazoInput: value
      }

      if( e.target.name === 'prazoInput' ) {
        let data = new Date(value)
        let newData = addDays(data, 1)
        let today = new Date()
  
        let prazoBool = today >= newData
  
        newArray[i] = {
          ...newArray[i],
          prazoBoolean: prazoBool
        }
      }

      setFormSolictation({
        ...formSolicitation,
        veiculos: newArray
      })
      return
    }

    let data = {
      ...formSolicitation,
      pessoa: {
        ...formSolicitation.pessoa,
        prazoInput: value
      }
    }
    
    if( e.target.name === 'prazoInput' ) {
      let da = new Date(value)
      let newData = addDays(da, 1)
      let today = new Date()

      let prazoBool = today >= newData

      data = {
        ...data,
        pessoa: {
          ...data.pessoa,
          prazoBoolean: prazoBool
        }
      }
    }

    setFormSolictation(data)
  }

  const changePrazo = (e, i) => {
    
    if (i >= 0) {
      const newArray = [
        ...formSolicitation.veiculos
      ]

      newArray[i] = {
        ...formSolicitation.veiculos[i]
      }
  
      newArray[i] = {
        ...newArray[i],
        prazoModify: true
      }
      
      setFormSolictation({
        ...formSolicitation,
        veiculos: newArray
      })

      setReqInfoSolicitation({
        ...item,
        veiculos: newArray
      })
      return
    }

    let data = {
      ...formSolicitation,
      pessoa: {
        ...formSolicitation.pessoa,
      }
    }
    
    data = {
      ...data,
      pessoa: {
        ...data.pessoa,
        prazoModify: true
      }
    }
    
    setFormSolictation(data)
  }

  const handleChangeJustification = (e) => {
    let value = e.target.value;

    var newValue = value.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");

    const data = {
      ...formSolicitation,
      motivo: newValue
    }

    setFormSolictation(data)
  }

  const handleChangeFile = (e) => {
    const splited = e.target.files[0].type.split('/')
    const type = splited[1].toLowerCase()

    if (e.target.files[0].size > 10485760) {
      showErrorSize()
      return
    }

    if (
      type === 'pdf'
      || type === 'png'
      || type === 'jpg'
      || type === 'jpeg'
      || type === 'tiff'
    ) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])

      reader.onload = () => {
        const data = {
          ...formSolicitation,
          arquivo: reader.result
        }

        setFormSolictation(data)
        setDocType(e.target.files[0].type)
      }

      return
    }

    showErrorType()
  }

  const renderStatus = (status) => {
    if (status === defaultStatus.ok) return 'is-ok'
    if (status === defaultStatus.notOk) return 'not-ok'
    return ''
  }

  const validatePlate = (placa, isAccordeon) => {
    let hasPlate
    if (isAccordeon) {
      hasPlate = showEntireItem.some((e) => e === placa)
    } else {
      hasPlate = hasVariance.some((e) => e === placa)
    }

    return hasPlate
  }

  const onClosePlateItem = (plate) => {
    const newArray = [...showEntireItem]
    const index = newArray.indexOf(plate)
    newArray.splice(index, 1)
    setShowEntireItem(newArray)
  }

  const onOpenPlateItem = (plate) => {
    const newArray = [...showEntireItem]
    newArray.push(plate)
    setShowEntireItem(newArray)
  }

  const renderVehicleSolicitations = (item, index) => {
    return (
      <div key={index} className="vehicle">
        <div className="title">
          Veículo placa:
          <span>{item.placa}</span>
          {validatePlate(item.placa) ? (<i className="fas fa-times" />) : (<i className="fas fa-check" />)}
          <div className="eyeButton">
            {validatePlate(item.placa, true) ? (
              <i onClick={() => onClosePlateItem(item.placa)} className="far fa-eye-slash" />
            ) : (
              <i onClick={() => onOpenPlateItem(item.placa)} className="far fa-eye" />
            )}
          </div>
        </div>

        {validatePlate(item.placa, true) && (
          <>
            <ul>
              <li className={item.vinculo}>
                Vínculo: 
                <span>{item.vinculo} - {item.tempo} dias</span>
              </li>
              <li className={item.PesquisaFinanceira ? 'is-ok' : 'not-ok'}>
                {!item.PesquisaFinanceira && (<i className="fas fa-hand-point-right" />)}
                Realizado pesquisa financeira?
                <span>{item.PesquisaFinanceira ? 'Sim' : 'Não'}</span>
              </li>
              <li className={item.PesquisaCriminal ? 'is-ok' : 'not-ok'}>
                {!item.PesquisaCriminal && (<i className="fas fa-hand-point-right" />)}
                Realizado pesquisa criminal?
                <span>{item.PesquisaCriminal ? 'Sim' : 'Não'}</span>
              </li>
            </ul>
            <div className="title margin-top">
              Steps:
            </div>
            <ul>
              {item.steps?.map((el, index) => (
                <li key={index} title={el.message} className={renderStatus(el.status)}>
                  {el.status === defaultStatus.notOk && (<i className="fas fa-hand-point-right" />)}{el.step}
                  <span>{el.status === defaultStatus.ok ? 'Sim' : 'Não'}</span>
                </li>
              ))}
            </ul>
            <div className='topo'>
              <div>
                <span>Prazo:</span>
                {showForm && (
                  <>
                    {(item?.prazoModify) && (
                      <InputText
                        name="prazo"
                        type="date"
                        onChange={(e) => onChangeDeadline(e, index)}
                        value={formSolicitation.veiculos[index]?.prazoInput}
                        minDate={true}
                      />
                    )}
                    {(item?.prazoBoolean) && (
                      <p style={{color: "red"}}>Prazo menor que o dia atual</p>
                    )}
                  </>
                )}
                {!item?.prazoModify && (
                  format(new Date(`${item.prazoShow}T00:00`), 'dd/MM/yyyy')
                )}
              </div>
              
            </div>
            {(!item?.prazoModify && showForm) && (
              <button className="button" type='button' onClick={(e) => changePrazo(e, index)}>
                Modificar Prazo?              
              </button>
            )}
          </>
        )}
      </div>
    )
  }

  const renderImg = () => {

    if (formSolicitation.arquivo && docType === 'application/pdf') {
      return <i className="fas fa-file-pdf" />
    }

    if (formSolicitation.arquivo) {
      return <img src={formSolicitation.arquivo} />
    }

    return <i className="fas fa-image" />
  }

  const checkDisabledButton = () => {
    let disabled = true
    disabled = validateManualSolicitation(formSolicitation)

    return disabled
  }

  return (
    <Modal
      closeModal={onCloseModal}
      size="md"
    >
      <StyledSolicitation>
        <h3>Solicitação: {info?.protocolo}</h3>
        <div className='topo'>
          {item.pessoa.status && (
            <>
              <div className={item.pessoa.status === defaultStatus.ok ? 'is-ok' : 'not-ok'}>
                <span>Pessoa CPF:</span>
                {info?.pessoa?.cpf}
                {item.pessoa.status === defaultStatus.ok ? (<i className="fas fa-check" />) : (<i className="fas fa-times" />)}
              </div>
              <div>
                <span>Prazo:</span>
                {showForm && (
                  <>
                    {(formSolicitation.pessoa?.prazoModify) && (
                      <InputText
                        name="prazo"
                        type="date"
                        onChange={onChangeDeadline}
                        value={formSolicitation.pessoa?.prazoInput}
                        minDate={true}
                      />
                    )}
                    {(formSolicitation.pessoa?.prazoBoolean) && (
                      <p style={{color: "red"}}>Prazo menor que o dia atual</p>
                    )}
                    
                  </>
                )}
                {!formSolicitation.pessoa?.prazoModify && (
                  format(new Date(`${item.pessoa?.prazoShow}T00:00`), 'dd/MM/yyyy')
                )}
                {/* colocado T00:00 para definir que o horario é zero e não configurar a data com dia anterior */}
                <div className="eyeButton people">
                  {showPeopleContent ? (
                    <i onClick={() => setShowPeopleContent(!showPeopleContent)} className="far fa-eye-slash" />
                  ) : (
                    <i onClick={() => setShowPeopleContent(!showPeopleContent)} className="far fa-eye" />
                  )}
                </div>
              </div>
            </>
          )}       
          {(!formSolicitation.pessoa?.prazoModify && showForm) && (                     
            <button className="button" type='button' onClick={changePrazo}>
              Modificar Prazo?              
            </button>                      
          )}
          {item.showButton && !showForm && (
            <div>
              <button className='button' onClick={() => setShowForm(true)} disabled={!permission?.monitor.openActionSend}>
                Liberar Manualmente
              </button>
            </div>
          )}
          {item.showDetailButton && !showForm && (
            <StyledButton className={showDetail ? 'active' : ''}>
              <button className='button' onClick={() => setShowDetail(!showDetail)}>
                {showDetail ? 'Fechar Visualização' : 'Ver Liberação Manual'}
              </button>
            </StyledButton>
          )}
        </div>
        {showDetail && (
          <StyledDetailContent>
            <p>
              <span>Liberação manual liberada em:</span>
              {format(new Date(zonedTimeToUtc(item.showExibirLiberacaoManual.data, timeZone)), 'dd/MM/yyyy - HH:mm', { timeZone })}
            </p>
            <p><span>Por:</span>{item.showExibirLiberacaoManual.nome}</p>
            <p><span>Motivo:</span>{item.showExibirLiberacaoManual.motivo}</p>
            <a className='button' href={item.showExibirLiberacaoManual.arquivo} download={`Motivo-protocolo-nro-${item.showExibirLiberacaoManual.protocolo}`}>Baixar Motivo</a>
          </StyledDetailContent>
        )}

        {showPeopleContent && (
          <>
            <ul>
              <li className={item.pessoa.vinculo}>
                Vínculo: 
                <span>{item.pessoa.vinculo} - {item.pessoa.tempo} dias</span>
              </li>
              <li className={item.pessoa.PesquisaFinanceira ? 'is-ok' : 'not-ok'}>
                {!item.pessoa.PesquisaFinanceira && (<i className="fas fa-hand-point-right" />)}
                Realizado pesquisa financeira?
                <span>{item.pessoa.PesquisaFinanceira ? 'Sim' : 'Não'}</span>
              </li>
              <li className={item.pessoa.PesquisaCriminal ? 'is-ok' : 'not-ok'}>
                {!item.pessoa.PesquisaCriminal && (<i className="fas fa-hand-point-right" />)}
                Realizado pesquisa criminal?
                <span>{item.pessoa.PesquisaCriminal ? 'Sim' : 'Não'}</span>
              </li>
            </ul>
            <div className="title margin-top">
              Steps:
            </div>
            <ul>
              {item.pessoa.steps.map((el, index) => (
                <li key={index} title={el.message} className={renderStatus(el.status)}>
                  {el.status === defaultStatus.notOk && (<i className="fas fa-hand-point-right" />)}{el.step}
                  <span>{el.status === defaultStatus.ok ? 'Sim' : 'Não'}</span>
                </li>
              ))}
            </ul>
          </>
        )}
        <hr />
        {item.veiculos.map((item, index) => renderVehicleSolicitations(item, index))}
        {showForm && (
          <>
            <hr />
            <div className='topo'>
              <div>
                <span>Justifique o motivo da liberação:</span>
                <textarea
                  value={formSolicitation.motivo}
                  placeholder='Digite aqui...'
                  onChange={handleChangeJustification}
                />
              </div>
            </div>
            <div className='imageContent'>
              <div>
                <span>Envie uma imagem:</span>
                <label className='file button'>
                  <input type="file" onChange={handleChangeFile}/>
                  Selecionar imagem
                </label>
              </div>

              <div>
                {renderImg()}
              </div>
            </div>

            <hr />
            <button
              className={loading ? 'button loading' : 'button'}
              onClick={onConfirmSubmit}
              title={checkDisabledButton() ? 'Preencher todos os campos obrigatórios' : ''}
              disabled={checkDisabledButton() && loading}
            >
              {loading ? (<i className="fa-solid fa-spinner" />) : 'Salvar'}
            </button>
          </>
        )}
      </StyledSolicitation>
    </Modal>
  )
}

ModalSolicitation.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  setShowPeopleContent: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
  defaultStatus: PropTypes.objectOf(PropTypes.string).isRequired,
  showPeopleContent: PropTypes.bool.isRequired,
  showEntireItem: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasVariance: PropTypes.arrayOf(PropTypes.any).isRequired,
  setShowEntireItem: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirmSubmit: PropTypes.func.isRequired,
  setFormSolictation: PropTypes.func.isRequired,
  setReqInfoSolicitation: PropTypes.func.isRequired,
  info: PropTypes.objectOf(PropTypes.any),
  formSolicitation: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  showErrorSize: PropTypes.func.isRequired,
  showErrorType: PropTypes.func.isRequired,
  permission: PropTypes.any.isRequired,
}

ModalSolicitation.defaultProps = {
  info: null,
  loading: false
}

export default ModalSolicitation
