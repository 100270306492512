import React, { useState, useEffect } from 'react'
import Acordeon from '@/views/fragments/Acordeon'
import { StyledSection } from './styles'
import SelectText from '../fragments/form/SelectText'
import { reqGetPrivileges, reqGetGroupPrivileges, reqChangePrivileges, postGroupPrivileges } from '@/constants/requests'
import { useSelector } from 'react-redux'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import InputText from '../fragments/form/InputText'
import { BLOCK_TYPE } from '@/constants/optionsValues'

const Privileges = () => {  
  const [grupo, setGrupo] = useState(null)
  const [privilegios, setPrivilegios] = useState(null)
  const [grupos, setGrupos] = useState({})
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [submitForm, setSubmitForm] = useState({type: BLOCK_TYPE[0].value})

  const permission = useSelector(state => state.permission);

  useEffect(async () => {
    const init = async () => {
      if (!permission?.privileges.view) {
        window.location.href = "/";
        return;
      }

      await searchGroups();
      setLoading(false);
    };

    init();
  }, [permission])

  const searchGroups = async() => {
    let objeto = {
      cliente: "0"
    }

    let response = await reqGetGroupPrivileges(objeto)

    let newGroup = response.grupos.map((value) => ({ 
      'value': value.id_grupo,
      'label': value.nome, 
    }));
    
    setGrupos(newGroup)
  }

  const searchPrivileges = async(grupo) => {
    let response = await reqGetPrivileges({grupo: grupo});
    return response
  }

  const onChangeGroup = async(e) => {
    const { value } = e.target
    if(value === 'nulo') {
      return
    }
    setGrupo(value)
    let res = await searchPrivileges(value)
    setPrivilegios(res.privilegios);
  }

  const handleOnChange = async(campo, tela, value) => {
    let updatedPrivilegios = privilegios.map((item) =>
      item.nome === tela ? {...item, [`${campo}`]: !value} : item
    );
    
    updatedPrivilegios.map(async(item) => {
      if (item.nome === tela) {
        await reqChangePrivileges(item)
      } 
    })

    setPrivilegios(updatedPrivilegios)
  }

  const onChangeSubmitForm = (e) => {
    const { name, value } = e.target

    setSubmitForm({
      ...submitForm,
      [name]: value
    })
  }

  function validateSubmit(form) {
    if (form.nome === undefined) {
      setShowInfoModal('Informe um nome para inserir!')
      return false
    }

    return true
  }

  const handleSubmit = async() => {
    let form = {
      nome: submitForm?.nome,
    }
    
    if (!validateSubmit(form)) {
      return
    }

    let returnInsert = await postGroupPrivileges(form);
    
    if (!returnInsert.status.active) {
      setShowInfoModal(returnInsert.status.message)
    } else {
      setShowInfoModal(returnInsert.status.message)
      setSubmitForm({type: BLOCK_TYPE[0].value})
      await searchGroups()
      closeModal()
    }
  }
  
  const renderModal = () => (
    <Modal
      closeModal={closeModal}
    >
      <InputText
        label="Nome"
        value={submitForm?.nome || ''}
        isRequired
        name="nome"
        onChange={onChangeSubmitForm}
      />
      <button className="button" onClick={handleSubmit}>
        Salvar Grupo
      </button>
    </Modal>
  )


  const closeModal = () => {
    setShowModal(false)
  }

  const closeInfoModal = () => {
    setShowInfoModal(null)
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={closeInfoModal}
      msg={showInfoModal}
    />
  )

  return (
    <StyledSection className='container'>
      {showInfoModal && renderInfoModal()}
      {showModal && renderModal()}
      <Acordeon
        title="Gestão de Privilégios"
        icon="fa-solid fa-road-barrier"
        showContent
      >
        <div>
          <button className="button" onClick={() => setShowModal(true)} 
          // disabled={!permission?.manualBlock.addBlock}
          >
            <i className="fa-solid fa-plus" />
            Novo Grupo de Privilégios
          </button>
        </div>
        {grupos.length >= 1 ? (
          <SelectText
            label="Selecione um grupo para visualizar seus privilégios"
            name="grupo"
            options={grupos || {}}
            value={grupo || ''}
            onChange={onChangeGroup}
            isLoading={loading}
          />
        ) : null}
        <div>
          {privilegios !== null 
            ? privilegios.map( (privilegio) => 
              <div key={ privilegio.id_tela}>
                <h5>{ privilegio.nome }</h5>
                <div className='tela'>
                  { privilegio.priv1label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv1label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          disabled={((privilegio.nome === 'privileges') && (Number(grupo) === 3))}
                          checked={Number(privilegio.priv1)}
                          onChange={() => handleOnChange('priv1', privilegio.nome, Number(privilegio.priv1))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv2label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv2label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv2)}
                          onChange={() => handleOnChange('priv2', privilegio.nome, Number(privilegio.priv2))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv3label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv3label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv3)}
                          onChange={() => handleOnChange('priv3', privilegio.nome, Number(privilegio.priv3))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv4label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv4label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv4)}
                          onChange={() => handleOnChange('priv4', privilegio.nome, Number(privilegio.priv4))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv5label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv5label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv5)}
                          onChange={() => handleOnChange('priv5', privilegio.nome, Number(privilegio.priv5))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv6label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv6label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv6)}
                          onChange={() => handleOnChange('priv6', privilegio.nome, Number(privilegio.priv6))}
                        />
                      </div>
                    </div>
                  ) : null}
                  { privilegio.priv7label !== null ? (
                    <div className='item'>
                      <div className='card principal'>{ privilegio.priv7label }</div>
                      <div className='card'>
                        <input
                          type="checkbox"
                          id="Privileges"
                          name="Privileges"
                          value="Privileges"
                          checked={Number(privilegio.priv7)}
                          onChange={() => handleOnChange('priv7', privilegio.nome, Number(privilegio.priv7))}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null
          }
        </div>
      </Acordeon>
    </StyledSection>
  )
}

export default Privileges
