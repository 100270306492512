import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 30px;
`

export const StyledEmailsToValidate = styled.div`
  width: 100%;

  .bottom {
    display: flex;
    align-items: start;

    button {
      margin: 0;
      width: 225px;
    }
  }
`

export const StyledList = styled.div`
  width: 100%;

  span {
    color: var(--color-primary);
  }

  ul {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0 0;
    min-height: 163px;

    li {
      background-color: var(--color-primary);
      color: var(--color-gray-lighter);
      padding: 6px 10px;
      display: inline-block;
      border-radius: 6px;
      margin: 5px;

      i {
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
`
