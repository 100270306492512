import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCotainderAcordion,
  StyledTitle,
  StyledLockButton,
  StyledChildren,
  StyledAcordionNav
} from './styles'

const Acordeon = (props) => {
  const {
    children,
    locked,
    showContent,
    title,
    icon,
    handleClickNav,
  } = props
  return (
    <StyledCotainderAcordion className={locked ? 'lock' : ''}>
      <StyledAcordionNav
        className={locked ? 'lock' : 'open'}
        onClick={handleClickNav}
      >
        <StyledTitle>
          <i className={icon} />
          {title}
        </StyledTitle>
        <StyledLockButton>
          {locked ? (
            <i className="fas fa-lock" />
            ) : (
            <i className="fas fa-lock-open" />
          )}
        </StyledLockButton>
      </StyledAcordionNav>
      {showContent && (
        <StyledChildren>
          {children}
        </StyledChildren>
      )}
    </StyledCotainderAcordion>
  )
}

Acordeon.propTypes = {
  children: PropTypes.any.isRequired,
  locked: PropTypes.bool,
  showContent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClickNav: PropTypes.func,
}

Acordeon.defaultProps = {
  showContent: false,
  locked: false,
  handleClickNav: null,
}

export default Acordeon
