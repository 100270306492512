import styled from '@emotion/styled'

export const StyledForm = styled.form`
  label {
    display: inline-block;
    width: 100%;

    &.embarcador-toggle {
      width: calc(100% - 75px);
    }

    &.checkbox-field {
      width: 75px;
    }

    @media (min-width: 724px) {
      &.empresa, &.cnpj, &.integration-code {
        width: calc(50% - 10px);
      }

      &.embarcador {
        width: calc(100% - 190px);
      }

      &.pais {
        width: calc(100% - 315px);
      }

      &.cnpj, &.pais {
        margin-right: 20px;
      }

      &.embarcador-toggle {
        width: 280px;
      }
    }

    @media (min-width: 1280px) {
      &.cnpj, &.pais {
        width: 200px;
      }

      &.empresa {
        width: calc(100% - 755px);
        margin-right: 20px;
      }
    }
  }
`

export const StyledProfileList = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 15px;

    & > div {
      &.content-head {
        grid-template-columns: calc(100% - 200px) 200px;
        padding-right: 10px;

        button {
          margin: 0;
        }
      }
    }
  }
`

export const StyledInputProfile = styled.div`
  display: inline-block;
  width: calc(50% - 10px);
  margin-right: 20px;

  & > div {
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;

    label {
      width: calc(100% - 280px);
    }

    button {
      width: 270px;
      padding: 2px 10px;
      margin: 0;
      height: 45px;
      font-size: 16px;
      background-color: var(--color-secondary);
      border-radius: 3px;
    }
  }

  @media (max-width: 1024px) {
    & > div {
      label {
        width: calc(100% - 150px);
      }

      button {
        width: 150px;
        height: auto;
        padding: 10px;
      }
    }
  }

  @media (max-width: 724px) {
    width: 100%; 
    & > div {
      label {
        width: calc(100% - 280px);
      }

      button {
        width: 270px;
        height: 45px;
      }
    }
  }

  @media (max-width: 520px) {
    & > div {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;

      label {
        width: 100%;
        margin: 0;
      }

      button {
        width: 100%;
        height: auto;
      }
    }
  }
`

export const StyledRadioCompany = styled.div`
  width: 50%;
  display: inline-block;

  p {
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--color-primary);

    span {
      color: var(--color-alert);
    }
  }

  label {
    width: 50%;
    display: inline-block;;
    margin-bottom: 10px;
    color: var(--color-primary);

    input {
      margin: 0 10px 0 0;
      width: 20px;
      height: 20px;
    }
  }

  b {
    font-weight: 600;
    color: var(--color-alert);
    margin-bottom: 20px;
    display: block;
  }
`
