import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import SelectText from '@/views/fragments/form/SelectText'
import LOGO from '@/img/logo-mundial-risk.png'
import Modal from '../fragments/Modal'
import { hashGenerator } from '@/constants/hashGenerator'
import { ADMIN_PROFILE, BUSCAR, REQUIRED_FIELD } from '@/constants/parameters'
import { getLoginAccess } from '@/constants/requests'
import { useNavigate } from 'react-router-dom'
import {
  StyledSection,
  StyledLogin,
  StyledImage,
  StyledBackButton,
  StyledContainer,
} from './styles'
import { useDispatch} from 'react-redux';
import { addData } from '@/utils/actions'

const Login = (props) => {
  const { costCentre, userInfo } = props
  const [formValues, setFormValues] = useState({})
  const [showCostCenter, setShowCostCenter] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedCost, setSelectedCost] = useState(null)
  const [needFill, setNeedFill] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleChangeCostCentre = (e) => {
    const { name, value } = e.target

    const item = options.find((e) => {
      return Number(value) === Number(e.value)
    })

    setNeedFill(false)
    setSelectedCost(item || null)
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handlechangeUser = () => {
    setShowCostCenter(false)
  }

  const setupOptions = (data) => {
    let opt = []

    data.map((item) => (
      opt.push({
        label: item.nome,
        value: item.id,
        cnpj: item.cnpj
      })
    ))

    setOptions(opt)
  }

  const noCusterCentre = (empresas) => {
    const option = {
      label: empresas[0].nome || 'Nenhum centro definido',
      cnpj: empresas[0].cnpj || 'cnpj não enviado'
    }
    costCentre(option)
    navigate(`/${BUSCAR}`)
  }

  const handleSent = async () => {
    const data = {
      usuario: formValues.user,
      senha: formValues.password,
      type: 'login',
    }
    setIsLoading(true)

    await getLoginAccess(data)
      .then((res) => {
        const { status, empresas, permissoes } = res.data
        const profile = hashGenerator(ADMIN_PROFILE, status.token)
        
        if (!status.active) {
          return setShowModal(true)
        }
        dispatch(addData(permissoes))

        userInfo(status.token, formValues.user, profile)

        if (empresas.length === 1) {
          return noCusterCentre(empresas)
        }

        setupOptions(empresas)
        setShowCostCenter(true)
      })
      .finally(() => setIsLoading(false))
  }

  const handleLogin = () => {
    if (!selectedCost.label) {
      setNeedFill(true)
      return
    }

    setNeedFill(false)
    costCentre(selectedCost)

    navigate(`/${BUSCAR}`)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const renderModal = () => (
    <Modal
      type="alert"
      title="Opa!"
      subTitle="Parece que o usuário não está ativo"
      closeModal={closeModal}
    />
  )

  const submitUser = (e) => {
    if (e.key === 'Enter') {
      handleSent()
    }
  }

  const submitToLogin = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <StyledContainer>
      {showModal && renderModal()}
      <StyledSection>
        <StyledLogin>
          <div className='left'>
            <div>
              <article>
                <h2><i className="fas fa-sign-in-alt" />Fazer Login</h2>

                {showCostCenter ? (
                  <div onKeyDown={submitToLogin}>
                    <SelectText
                      options={options}
                      value={formValues.costCenter || ''}
                      onChange={handleChangeCostCentre}
                      name="costCenter"
                      label="Centro de custos"
                      hint={REQUIRED_FIELD}
                      isError={needFill}
                    />
                    <button className='button' onClick={handleLogin}>
                      Entrar
                    </button>
                    <StyledBackButton
                      onClick={handlechangeUser}
                    >
                      <i className="fas fa-angle-double-left" />
                      trocar usuário
                    </StyledBackButton>
                  </div>
                ) : (
                  <div onKeyDown={submitUser}>
                    <InputText
                      label="Nome"
                      value={formValues.user || ''}
                      name="user"
                      onChange={handleChange}
                    />

                    <InputText
                      label="Senha"
                      value={formValues.password || ''}
                      name="password"
                      type="password"
                      onChange={handleChange}
                    />
                    <button
                      className={`button ${isLoading && 'loading'}`}
                      onClick={handleSent}
                      disabled={isLoading}
                    >
                      {isLoading ? (<i className="fas fa-spinner" />) : 'Logar'}
                    </button>
                  </div>
                )}
              </article>
            </div>
          </div>
          <div className='right'>
            <StyledImage>
              <img src={LOGO} alt="logo" />
            </StyledImage>
          </div>
        </StyledLogin>
      </StyledSection>
    </StyledContainer>
  )
}

Login.propTypes = {
  costCentre: PropTypes.func.isRequired,
  userInfo: PropTypes.func.isRequired,
}

export default Login
