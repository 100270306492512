import React, { useState } from 'react'
import RegisterContainer from '@/views/Register/components/RegisterContainer'
import Acordeon from '@/views/fragments/Acordeon'
import RegisterCarForm from '@/views/Register/components/RegisterCarForm'
import RegisterOwnerForm from '@/views/Register/components/RegisterOwnerForm'
import Modal from '@/views/fragments/Modal'
import { fakeRequest } from '@/constants/requests'

const DEFAULT_STATUS = {
  car: { show: true, locked: false },
  owner: { show: false, locked: true },
}

const DEFAULT_VALUES = {
  car: {
    country: '76',
  },
  owner: {
    phones: [''],
    docCountry: '76',
  }
}

const CarOwnerRegister = () => {
  const [formValues, setFormValues] = useState(DEFAULT_VALUES)
  const [showModal, setShowModal] = useState(false)
  const [toShow, setToShow] = useState(DEFAULT_STATUS)

  const handleSaveCar = (form) => {
    setFormValues({
      ...formValues,
      car: form,
    })

    setToShow({
      ...toShow,
      car: { show: false, locked: false },
      owner: { show: true, locked: false },
    })
  }

  const handleSaveOwner = (form) => {
    setFormValues({
      ...formValues,
      owner: form,
    })

    fakeRequest({
      ...formValues,
      owner: form,
    })

    setShowModal(true)
  }

  const onCloseModal = () => {
    setFormValues(DEFAULT_VALUES)
    setShowModal(false)
  }

  const renderModal = () => (
    <Modal
      title="Sucesso!"
      subTitle="Cadastro efetuado."
      type='success'
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const handleNavClick = (nav) => {
    if (toShow[nav].locked) return

    setToShow({
      ...toShow,
      [nav]: {
        ...toShow[nav],
        show: !toShow[nav].show,
      },
    })
  }

  return (
    <RegisterContainer
      title="Cadastro"
      subTitle="Veículo"
      label="Embarcador Selecionado"
      client="PARMENES INFORMATION TECHNOLOGY LTDA"
    >
      {showModal && renderModal()}
      <Acordeon
        title="Veículo"
        icon="fas fa-car-side"
        locked={toShow.car.locked}
        showContent={toShow.car.show}
        handleClickNav={() => handleNavClick('car')}
      >
        <RegisterCarForm
          onSave={handleSaveCar}
          car={formValues.car}
        />
      </Acordeon>

      <Acordeon
        title="Proprietário"
        icon="fas fa-user"
        locked={toShow.owner.locked}
        showContent={toShow.owner.show}
        handleClickNav={() => handleNavClick('owner')}
      >
        <RegisterOwnerForm
          onSave={handleSaveOwner}
          owner={formValues.owner}
        />
      </Acordeon>
    </RegisterContainer>
  )
}

export default CarOwnerRegister
