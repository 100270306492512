import React, { useState, useEffect, useCallback } from 'react'
import Acordeon from '@/views/fragments/Acordeon'
import { REQUIRED_FIELD } from '@/constants/parameters'
import SelectText from '@/views/fragments/form/SelectText'
import { getVehicles, getVehiclesType, reqSubmitChangeMarca, reqSubmitChangeModel, reqSubmitNewMarca, reqSubmitNewModel } from '@/constants/requests'
import InputText from '@/views/fragments/form/InputText'
import Modal from '@/views/fragments/Modal'
import {
  StyledSection,
  StyledForm,
} from './style'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import { useSelector } from 'react-redux'

const CarListConfig = () => {
  const [formValues, setFormValues] = useState([])
  const [typeList, setTypeList] = useState([])
  const [submitForm, setSubmitForm] = useState({})
  const [validateList, setValidateList] = useState([])
  const [loadingCar, setLoadingCar] = useState(false)
  const [carList, setCarList] = useState([])
  const [modelList, setModelList] = useState([])
  const [marcaList, setMarcaList] = useState([])
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showModalMarca, setShowModalMarca] = useState(false)
  const [showModalModelo, setShowModalModelo] = useState(false)
  const [marca, setMarca] = useState(null)
  const [tipo, setTipo] = useState(null)
  const [edit, setEdit] = useState(null)

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.modelBrandEdition.view) {
      window.location.href = "/";
      return
    }
  }, [])

  const setupCarList = useCallback(async() => {
    setLoadingCar(true)
    const req = await getVehicles()
    const reqMarcas = await getVehiclesType()

    const list = []

    if (reqMarcas?.status?.active) {
      reqMarcas.marcas.map((el) => {
        const model = {
          label: el.marca + ' - ' + el.tipo_veiculo,
          value: el.codigo_marca,
          tipo: el.tipo_veiculo
        }

        list.push(model)
      })
    }

    setLoadingCar(false)
    
    setMarcaList(list)
    setTypeList(req.tipos)
  }, [])

  const handleCarList = async(value) => {
    const list = []
    const req = await getVehiclesType({ tipo: value})
    if (req?.status?.active) {
      req.marcas.map((el) => {
        const model = {
          label: el.marca,
          value: el.codigo_marca,
          tipo: el.tipo_veiculo
        }

        list.push(model)
      })
    }

    setCarList(list)
  }

  const closeModal = () => {
    setShowModalMarca(false)
    setShowModalModelo(false)
    setShowInfoModal(false)
    setEdit(false)
    setSubmitForm({})
  }

  const onChangeModel = async (e) => {
    const { name, value } = e.target
    if (value === 'nulo') {
      setFormValues({
        ...formValues,
        [name]: '',
      })
      return
    }

    setLoadingCar(true)

    setFormValues({
      ...formValues,
      [name]: value,
    })
    setLoadingCar(false)
    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const setupModel = useCallback(async (name, value, form) => {
    setLoadingCar(true)

    handleCarList(value)
    setTipo(value)

    setLoadingCar(false)
    setFormValues({
      ...form,
      [name]: value,
    })
    setLoadingCar(false)
    setValidateList({
      ...validateList,
      [name]: false,
    })
  }, [])

  useEffect(async () => {
    setupCarList()
  }, [setupCarList])

  const onChangeCarType = async (e) => {
    const { name, value } = e.target
    setupModel(name, value, formValues)
  }

  const handleModelList = async(value) => {    
    const list = []
    const req = await getVehiclesType({ marca: value})
    setLoadingCar(false)
    if (req.status.active) {
      req.modelos.map((el) => {
        const model = {
          label: el.modelo,
          value: el.codigo_modelo,
          tipo: el.tipo_veiculo,
        }

        list.push(model)
      })
    }

    setModelList(list)
  }

  const onChangeCar = async (e) => {
    const { name, value } = e.target
    if (value === 'nulo') {
      setFormValues({
        ...formValues,
        [name]: '',
      })
      return
    }
    setLoadingCar(true)
    
    handleModelList(value)
    setMarca(value)
    
    setFormValues({
      ...formValues,
      [name]: value,
    })
    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const handleNewMarca = async () => {
    if (!tipo) {
      setShowInfoModal('Selecione o tipo no campo anterior para criar uma marca!')
      return
    }
    
    setSubmitForm({tipo: tipo})
    setShowModalMarca(true)
  }

  const handleNewModelo = async () => {
    if (!marca) {
      setShowInfoModal('Selecione a marca no campo anterior para criar um modelo!')
      return
    }

    setSubmitForm({marca: marca})
    setShowModalModelo(true)
  }

  const handleEditMarca = async (value) => {
    if (!value) {
      setShowInfoModal('Selecione a marca no campo abaixo para editar!')
      return
    }

    setEdit(true)

    let obj = typeList.filter((element) => {
      if (element.label === value[0].tipo) {
        return element;
      } 
    })

    let newValues = {
      marca: value[0]?.label,
      tipo: obj[0]?.value,
      codigo_marca: value[0]?.value,
    }

    setSubmitForm(newValues)
    setShowModalMarca(true)
    handleCarList(tipo)
  }

  const handleEditModelo = async (value) => {
    if (!value) {
      setShowInfoModal('Selecione o modelo no campo abaixo para editar!')
      return
    }

    setEdit(true)

    let newValues = {
      modelo: value[0].label,
      codigo_modelo: value[0]?.value,
      marca: marca,
    }

    setSubmitForm(newValues)
    setShowModalModelo(true)
    handleModelList(marca)
  }

  const onChangeSubmitForm = (e) => {
    const { name, value } = e.target

    setSubmitForm({
      ...submitForm,
      [name]: value
    })
  }
  
  const handleSubmitMarca = async() => {
    let newValues = {
      tipo: submitForm?.tipo,
      marca: submitForm?.marca,
      codigo_marca: submitForm?.codigo_marca,
    }

    let req;

    if (!edit) {
      req = await reqSubmitNewMarca(newValues);
    } else {
      req = await reqSubmitChangeMarca(newValues);
    }

    if(req.status.active) {
      closeModal()
      setSubmitForm({})
      setShowInfoModal(req.status.message)
      handleCarList(tipo)
    } else {
      setShowInfoModal('Erro ao cadastrar , contate o administrador!')
    }
  } 

  const handleSubmitModelo = async() => {
    let newValues = {
      modelo: submitForm?.modelo,
      marca: submitForm?.marca,
      codigo_modelo: submitForm?.codigo_modelo
    }

    let req;

    if (!edit) {
      req = await reqSubmitNewModel(newValues);
    } else {
      req = await reqSubmitChangeModel(newValues);
    }

    if(req.status.active) {
      closeModal()
      setSubmitForm({})
      setShowInfoModal(req.status.message)
      handleModelList(marca)
    } else {
      setShowInfoModal('Erro ao cadastrar , contate o administrador!')
    }
  } 

  const renderModalMarca = () => (
    <Modal
      closeModal={closeModal}
    >
      <InputText
        label="Marca"
        value={submitForm?.marca || ''}
        name="marca"
        onChange={onChangeSubmitForm}
      />
      <SelectText
        label="Tipo"
        name="tipo"
        isDisabled
        options={typeList}
        value={submitForm.tipo || ''}
        onChange={onChangeSubmitForm}
        isLoading={loadingCar}
      />
      <button className="button" onClick={handleSubmitMarca}>
        Salvar Marca
      </button>
    </Modal>
  )

  const renderModalModelo = () => (
    <Modal
      closeModal={closeModal}
    >
      <SelectText
        label="Marca"
        name="marca"
        options={marcaList}
        isDisabled
        value={submitForm.marca || ''}
        onChange={onChangeSubmitForm}
        isLoading={loadingCar}
      />
      <InputText
        label="Modelo"
        value={submitForm?.modelo || ''}
        name="modelo"
        onChange={onChangeSubmitForm}
      />
      <button className="button" onClick={handleSubmitModelo}>
        Salvar Modelo
      </button>
    </Modal>
  )

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => closeModal()}
      msg={showInfoModal}
    />
  )

  return (
    <StyledSection className='container'>
      {showModalMarca && renderModalMarca()}
      {showInfoModal && renderInfoModal()}
      {showModalModelo && renderModalModelo()}
      <Acordeon
        title="Edição de Veículos - Marca / Modelo"
        icon="fa-solid fa-car-side"
        showContent
      >
        <StyledForm>
          <SelectText
            label="Tipo"
            name="tipo_veiculo"
            isRequired
            hint={REQUIRED_FIELD}
            isError={validateList.tipo_veiculo}
            options={typeList}
            value={formValues.tipo_veiculo || ''}
            onChange={onChangeCarType}
            isLoading={loadingCar}
          />
          <SelectText
            label="Marca"
            name="marca"
            isRequired
            isNewable
            isEditable
            onEdit={handleEditMarca}
            onNew={handleNewMarca}
            hint={REQUIRED_FIELD}
            isError={validateList.marca}
            options={carList}
            value={formValues.marca || ''}
            onChange={onChangeCar}
            isLoading={loadingCar}
          />
          <SelectText
            label="Modelo"
            name="modelo"
            isRequired
            isNewable
            isEditable
            onEdit={handleEditModelo}
            onNew={handleNewModelo}
            hint={REQUIRED_FIELD}
            isError={validateList.modelo}
            options={modelList}
            value={formValues.modelo || ''}
            onChange={onChangeModel}
            isLoading={loadingCar}
          />
        </StyledForm>
      </Acordeon>
    </StyledSection>
  )
}

export default CarListConfig
