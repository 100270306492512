import styled from '@emotion/styled'

export const StyledSection = styled.section`
  max-width: 1000px;
`

export const StyledTopContent = styled.div`
  display: flex;
  gap: 30px;
  align-items: end;

  & > div {
    position: relative;

    .button {
      margin: 0;
      height: 45px;
    }

    label {
      margin: 0;
    }

    &:nth-of-type(1n + 2) {
      width: 33.33333%;
    }

    &:last-of-type {
      margin-left: auto;

      input {
        padding-right: 50px;
      }
    }

    button {
      &.searchButton {
        position: absolute;
        background-color: transparent;
        border: none;
        width: 43px;
        height: 43px;
        bottom: 1px;
        right: 1px;
        font-size: 20px;
        border-radius: 0 5px 5px 0;

        &:hover {
          background-color: var(--color-primary);
          color: var(--color-light);
        }
      }
    }
  }

  @media (max-width: 780px) {
    & > div {
      &:first-of-type {
        width: 200px;
      }

      &:nth-of-type(1n+2) {
        width: calc(100% - 230px);
      }
    }
  }

  @media (max-width: 520px) {
    flex-wrap: wrap;
    gap: 15px 30px;

    & > div {
      width: 100% !important;
    }
  }
`

export const StyledList = styled.ul`
  margin: 20px 0;
  border: 1px solid var(--color-primary);
  border-radius: 7px;
  overflow: hidden;

  li {
    display: grid;
    gap: 10px;
    grid-template-columns: 160px calc(88% - 240px) 80px 80px;
    align-items: center;

    &:nth-of-type(odd) {
      background-color: var(--color-gray-light);
    }

    & > div {
      padding: 10px;

      & > span {
        font-weight: 800;
        font-size: 11px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
      }

      button {
        margin: 0;
        padding: 5px 7px;
        border-radius: 4px;
        font-size: 18px;
        border: none;
        background-color: transparent;
        color: var(--color-alert);
      }
    }

    &.header-content {
      background-color: var(--color-primary);
      color: var(--color-light);
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 500;
    }

    @media (max-width: 780px) {
      grid-template-columns: repeat(2, calc(50% - 40px));
      padding: 10px;

      & > div {
        padding: 0;

        &.action {
          text-align: center;
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 3;
          width: 60px;
        }
      }
    }

    @media (max-width: 520px) {
      grid-template-columns: calc(100% - 70px);

      & > div {
        &.action {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 5;
        }
      }
    }
  }
`

export const StyledButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 50px;
`
