import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledP } from './styles'

const FIRST_PAGE = 1

const Pagination = (props) => {
  const {
    params,
    requestPage
  } = props

  const PAGE = {
    current: Number(params.pagina),
    totalPages: Number(params.total_de_paginas),
    totalItems: Number(params.total_de_registros),
    amountPerPage: Number(params.total_por_pagina),
  }

  const handleClick = (number) => {
    if (number === PAGE.current) return
    requestPage(number)
  }

  const renderButton = (number) => (
    <button
      type='button'
      className={number === PAGE.current ? 'active' : ''}
      onClick={() => handleClick(number)}
    >
      {number}
    </button>
  )

  const renderTwoPages = () => (
    <>
      {renderButton(1)}
      {renderButton(2)}
    </>
  )

  const renderPages = () => {
    if (PAGE.totalPages === 2) return renderTwoPages()
    const minus = PAGE.current - 1
    const plus = PAGE.current + 1
    let first
    let second
    let third

    if (minus <= 1) {
      first = 1
      second = 2
      third = 3
    }

    if (minus > 1) {
      first = minus
      second = PAGE.current
      third = plus
    }

    if (plus > PAGE.totalPages) {
      first = minus - 1
      second = minus
      third = PAGE.current
    }

    return (
      <>
        {renderButton(first)}
        {renderButton(second)}
        {renderButton(third)}
      </>
    )
  }

  return PAGE.totalPages > 1 && (
    <>
      <StyledContainer>
        <button
          type='button'
          onClick={() => handleClick(FIRST_PAGE)}
          disabled={PAGE.current === FIRST_PAGE}
        >
          <i className="fas fa-angle-double-left" />
        </button>
        <button
          type='button'
          disabled={PAGE.current === FIRST_PAGE}
          onClick={() => handleClick(PAGE.current - 1)}
        >
          <i className="fas fa-angle-left" />
        </button>
        {renderPages()}
        <button
          type='button'
          disabled={PAGE.current === PAGE.totalPages}
          onClick={() => handleClick(PAGE.current + 1)}
        >
          <i className="fas fa-angle-right" />
        </button>
        <button
          type='button'
          disabled={PAGE.current === PAGE.totalPages}
          onClick={() => handleClick(PAGE.totalPages)}
        >
          <i className="fas fa-angle-double-right" />
        </button>
      </StyledContainer>
      <StyledP>Exibindo <span>{PAGE.amountPerPage}</span> de <span>{PAGE.totalItems}</span></StyledP>
    </>
  )
}

Pagination.propTypes = {
  params: PropTypes.any.isRequired,
  requestPage: PropTypes.func.isRequired,
}

export default Pagination
