import React from 'react'
import PropTypes from 'prop-types'
import { StyledBox } from '../styles'
import { format, } from 'date-fns-tz'
import { zonedTimeToUtc } from 'date-fns-tz'
import { formatterLetterOnly } from '@/utils/formatter'

const timeZone = 'America/Sao_Paulo'

const RowList = (props) => {
  const {
    item,
    permission,
    handleEditClick,
    handleSolicitationClick
  } = props

  return (
    <StyledBox className={formatterLetterOnly(item.status_nome).toLowerCase()}>
      <div className='container-info'>
        <div className='row1'>
          <span>Protoc</span>
          <p>{item.protocolo}</p>
        </div>
        <div className='row2'>
          <span>Solicitado</span>
          <p>
            {format(
              new Date(zonedTimeToUtc(item.data_solicitacao, timeZone)), 'dd/MM/yyyy - HH:mm', { timeZone }
            )}
          </p>
        </div>
        <div className='row3'>
          <span>Pessoa</span>
          <p title={item?.pessoa?.nome}>{item?.pessoa?.nome}</p>
          {item?.pessoa?.fontes?.map((fonte) => {
            return (
              <>
                {Number(fonte.status) === 1 && (
                  <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass-start fonte' style={{color: fonte.status_cor}}></i>
                )}
                {Number(fonte.status) === 2 && (
                  <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass-half fonte' style={{color: fonte.status_cor}}></i>
                )}
                {Number(fonte.status) === 3 && (
                  <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label} className='fas fa-hourglass fonte' style={{color: fonte.status_cor}}></i>
                )}
                {Number(fonte.status) === 4 && (
                  <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass fonte' style={{color: fonte.status_cor}}></i>
                )}
                {(Number(fonte.status) === 5 || Number(fonte.status) === 6 || Number(fonte.status) === 7 || Number(fonte.status) === 8) && (
                  <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass fonte'  style={{color: fonte.status_cor}}></i>
                )}
              </>  
              )
            })
          }
        </div>
        <div className="row4">
          {item.veiculos ? item?.veiculos.map((car, i) => (
            <div key={i}>
              <span>Veículo {i + 1}</span>
              <p>{car.placa || '-'}</p>
              {car.fontes?.map((fonte) => {
                return ( 
                  <>
                    {Number(fonte.status) === 1 && (
                      <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass-start fonte' style={{color: fonte.status_cor}}></i>
                    )}
                    {Number(fonte.status) === 2 && (
                      <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass-half fonte' style={{color: fonte.status_cor}}></i>
                    )}
                    {Number(fonte.status) === 3 && (
                      <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label} className='fas fa-hourglass fonte' style={{color: fonte.status_cor}}></i>
                    )}
                    {Number(fonte.status) === 4 && (
                      <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass fonte' style={{color: fonte.status_cor}}></i>
                    )}
                    {(Number(fonte.status) === 5 || Number(fonte.status) === 6 || Number(fonte.status) === 7 || Number(fonte.status) === 8) && (
                      <i title={"Nome: " + fonte.nome + "\nRecurso: " + fonte.recurso + "\nStatus: " + fonte.status_label + "\nMensagens: \n" + fonte.status_fonte_msg.map((el) => {return el.msg + "\n"})} className='fas fa-hourglass fonte'  style={{color: fonte.status_cor}}></i>
                    )}
                  </>
                )
              })}
            </div>
          )) : (
            <div>
              <span>Sem Veículo</span>
              <p>-</p>
            </div>
          )}
        </div>
        <div className='row8'>
          <span>Solicitante</span>
          <p>{item.solicitante}</p>
        </div>
        <div className='row9'>
          <span>Empresa solicitante</span>
          <p title={item.empresa_solicitante}>{item.empresa_solicitante}</p>
        </div>
        <div className='row10'>
          <span>Centro de custos</span>
          <p title={item.centro_de_custos}>{item.centro_de_custos}</p>
        </div>
        <div className='row11'>
          <span>SLA</span>
          <p title={item.sla}>
            {item.sla}
          </p>
        </div>
        <div className='row12 status'>  
          <span>Status</span>
          <p>
            {item.status_nome}
          </p>
        </div>
        <div className='row13'>
          {Number(item.status_fonte) === 1 && (
            <i title={"Status: " + item.status_fonte_label} className='fas fa-hourglass-start' style={{color: item.status_fonte_cor}}></i>
          )}
        </div>
      </div>
      <div className='buttons'>
        {Number(item.exibir_alterar) === 1 && (
          <button className='button' onClick={() => handleEditClick(item)}>
            <i className="fas fa-hand-point-right" />
            <i className="far fa-sticky-note" />
          </button>
        )}
        {(Number(item.exibir_alterar) === 2 && item.status_nome === "EM CONFORMIDADE")&& (
          <button className='button' onClick={() => handleSolicitationClick(item)} disabled={!permission?.monitor.viewAcceptAction}>
            <i className="fas fa-thumbs-up" />
            <i className="far fa-eye" />
          </button>
        )}
        {(Number(item.exibir_alterar) === 2 && item.status_nome === "DESACORDO")&& (
          <button className='button' onClick={() => handleSolicitationClick(item)} disabled={!permission?.monitor.viewNegateAction}>
            <i className="fas fa-thumbs-up" />
            <i className="far fa-eye" />
          </button>
        )}
        {Number(item.exibir_alterar) === 3 && (
          <button className='button' onClick={() => handleEditClick(item, true)} disabled={!permission?.monitor.viewOpenAction}>
            <i className="fas fa-thumbs-up" />
            <i className="fa-solid fa-file-pen" />
          </button>
        )}
      </div>
    </StyledBox>
  )
}

RowList.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleSolicitationClick: PropTypes.func.isRequired,
  permission: PropTypes.any.isRequired
}

export default RowList
