import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import { StyledForm } from './styles'
import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import Modal from '@/views/fragments/Modal'
import RegisterPF from '@/views/Register/components/RegisterPF'
import RegisterAddress from '@/views/Register/components/RegisterAddress'
import RegisterCnh from '@/views/Register/components/RegisterCnh'
import {
  formatterCPF,
  formatterPhone
} from '@/utils/formatter'

const timeZone = 'America/Sao_Paulo'
const MODAL_TYPES = {
  basic: 'basic-data',
  cnh: 'cnh',
  address: 'address'
}

const PhysicalPersonCards = (props) => {
  const {
    address,
    basicData,
    cnh,
  } = props.values
  const { onSave, values } = props

  const [formInUse, setFormInUse] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [editType, setEditType] = useState('')

  const renderPhones = (phone, i) => (
    <div key={i}>
      <span>Telefone {i + 1}:</span>
      <p>{formatterPhone(phone)}</p>
    </div>
  )

  const handleEditBasicData = () => {
    setEditType(MODAL_TYPES.basic)
    setFormInUse(basicData)
    setShowEditModal(true)
  }
  
  const handleEditAddress = () => {
    setEditType(MODAL_TYPES.address)
    setFormInUse(address)
    setShowEditModal(true)
  }
  
  const handleEditCnh = () => {
    setEditType(MODAL_TYPES.cnh)
    setFormInUse(cnh)
    setShowEditModal(true)
  }

  const onSaveBasic = (form) => {
    onSave({
      ...values,
      basicData: form,
    })
    handleCloseModal()
  }

  const onSaveAddress = (form) => {
    onSave({
      ...values,
      address: form,
    })
    handleCloseModal()
  }

  const onSaveCnh = (form) => {
    onSave({
      ...values,
      cnh: form,
    })
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setShowEditModal(false)
    setEditType('')
    setFormInUse({})
  }

  const renderBasicModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterPF
        basicData={formInUse}
        onSave={onSaveBasic}
        showSaveLabel
      />
    </Modal>
  )

  const renderAddressModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterAddress
        address={formInUse}
        onSave={onSaveAddress}
        showSaveLabel
      />
    </Modal>
  )

  const renderCnhModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterCnh
        cnh={formInUse}
        onSave={onSaveCnh}
        showSaveLabel
      />
    </Modal>
  )

  const renderEditModal = () => {
    if (editType === MODAL_TYPES.basic) {
      return renderBasicModal()
    }

    if (editType === MODAL_TYPES.address) {
      return renderAddressModal()
    }
    
    if (editType === MODAL_TYPES.cnh) {
      return renderCnhModal()
    }
  }

  return (
    <section>
      {showEditModal && (renderEditModal())}
      <Card
        title="Dados Básicos"
        onEditClick={handleEditBasicData}
      >
        <StyledForm className="basic-data">
          <div className="item-1">
            <span>CPF:</span>
            <p>{formatterCPF(basicData.cpf)}</p>
          </div>
          <div className="item-2">
            <span>Nome:</span>
            <p>{basicData.name}</p>
          </div>
          <div className="item-3">
            <span>Data de Nascimento:</span>
            <p>{format(
              new Date(zonedTimeToUtc(basicData.birthdate, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-4">
            <span>Tipo:</span>
            <p>{basicData.userType}</p>
          </div>
          <div className="item-5">
            <span>País de Orígem:</span>
            <p>{basicData.country}</p>
          </div>
          <div className="item-6">
            <span>Sexo:</span>
            <p>{basicData.userGenre}</p>
          </div>
          <div className="item-7">
            <span>Estado Civil:</span>
            <p>{basicData.maritalStatus}</p>
          </div>
          <div className="item-8">
            <span>Nome da Mãe:</span>
            <p>{basicData.motherName}</p>
          </div>
          <div className="item-9">
            <span>Nome do Pai:</span>
            <p>{basicData.fatherName}</p>
          </div>
          <div className="item-10">
            <span>Estado de Nascimento:</span>
            <p>{basicData.ufBirth}</p>
          </div>
          <div className="item-11">
            <span>Cidade de Nascimento:</span>
            <p>{basicData.cityOfBirth}</p>
          </div>
          <div className="item-12">
            <span>RG:</span>
            <p>{basicData.rg}</p>
          </div>
          <div className="item-13">
            <span>Emissão de RG:</span>
            <p>{format(
              new Date(zonedTimeToUtc(basicData.rgIssue, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-14">
            <span>Órgão Emissor:</span>
            <p>{basicData.issueDpto}</p>
          </div>
          <div className="item-15">
            <span>Estado Emissor:</span>
            <p>{basicData.ufIssue}</p>
          </div>
          {basicData.phones.map((phone, index) => renderPhones(phone, index))}
        </StyledForm>
      </Card>

      <Card
        title="Endereço"
        onEditClick={handleEditAddress}
      >
        <StyledForm className="address">
          <div className="item-1">
            <span>CEP:</span>
            <p>{address.cep}</p>
          </div>
          <div className="item-2">
            <span>Rua:</span>
            <p>{address.logradouro}</p>
          </div>
          <div className="item-3">
            <span>Número:</span>
            <p>{address.numero}</p>
          </div>
          <div className="item-4">
            <span>Bairro:</span>
            <p>{address.bairro}</p>
          </div>
          <div className="item-5">
            <span>Cidade:</span>
            <p>{address.localidade}</p>
          </div>
          <div className="item-6">
            <span>Estado:</span>
            <p>{address.uf}</p>
          </div>
          <div className="item-7">
            <span>Complemento:</span>
            <p>{address.complemento}</p>
          </div>
        </StyledForm>
      </Card>

      {cnh && (
      <Card
        title="CNH"
        onEditClick={handleEditCnh}
      >
        <StyledForm className="cnh">
          <div className="item-1">
            <span>CNH:</span>
            <p>{cnh.cnh}</p>
          </div>
          <div className="item-2">
            <span>Registro CNH:</span>
            <p>{cnh.cnhRegister}</p>
          </div>
          <div className="item-3">
            <span>Validade:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.cnhDateExpire, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-4">
            <span>Data de Emissão:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.cnhIssueDate, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-5">
            <span>Categoria:</span>
            <p>{cnh.cnhCategory}</p>
          </div>
          <div className="item-6">
            <span>1ª Habilitação:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.firstCnh, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-7">
            <span>Órgão Emissor:</span>
            <p>{cnh.cityFromCnh}</p>
          </div>
          <div className="item-8">
            <span>Vencimento ASO:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.asoDateEnd, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-9">
            <span>Vencimento Psicoténico:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.psychoDateEnd, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-10">
            <span>Vencimento Reciclagem:</span>
            <p>{format(
              new Date(zonedTimeToUtc(cnh.recycleDateEnd, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-11">
            <span>Cód. Segurança CNH:</span>
            <p>{cnh.securityCode}</p>
          </div>
          <div className="item-12">
            <span>Renach CNH:</span>
            <p>{cnh.renachCnh}</p>
          </div>
          <div className="item-13">
            <span>Registro Mopp:</span>
            <p>{cnh.registroMopp}</p>
          </div>
          <div className="item-14">
            <span>Emissor Mopp:</span>
            <p>{cnh.emissorMopp}</p>
          </div>
        </StyledForm>
      </Card>
      )}
    </section>
  )
}

PhysicalPersonCards.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default PhysicalPersonCards
