import styled from '@emotion/styled'

export const StyledForm = styled.form`
  label {
    display: inline-block;
    width: 100%;

    &.embarcador-toggle {
      width: calc(100% - 33px);
    }

    &.checkbox-field {
      width: 33px;
    }

    @media (min-width: 724px) {
      &.embarcador {
        width: calc(100% - 190px);
        margin-right: 20px;
      }

      &.embarcador-toggle {
        width: 260px;
      }

      &.empresa {
        width: calc(100% - 313px);
        margin-right: 20px;
      }
    }

    @media (min-width: 1280px) {
      &.cnpj {
        width: 170px;
        margin-right: 20px;
      }

      &.empresa {
        width: calc(100% - 574px);
      }

      &.embarcador-toggle {
        width: 330px;
      }

      &.pais {
        width: calc(100% - 260px);
      }
    }
  }

  &.cnh {
    label {
      @media (max-width: 480px) {
        width: 100%;
        margin: 0 0 10px;
      }

      @media (min-width: 480px) {
        width: calc(50% - 5px);

        &:nth-of-type(odd) {
          margin-right: 10px;
        }
      }
    }

    @media (min-width: 724px) {
      display: grid;
      grid-template-columns: repeat(3, calc(33.3333333% - 6.66666667px));
      grid-gap: 10px;

      label {
        width: 100%;
        margin: 0;
      }
    }

    @media (min-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(4, calc(25% - 15px));
      grid-gap: 20px;
    }
  }

  &.veiculo {
    label {
      width: 100%;
      margin: 0 0 20px;
    }

    @media (min-width: 540px) {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5px));
      grid-gap: 10px;

      label {
        margin: 0;
      }
    }

    @media (min-width: 724px) {
      display: grid;
      grid-template-columns: repeat(3, calc(33.3333333% - 6.66666667px));
      grid-gap: 10px;
    }

    @media (min-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(5, calc(20% - 16px));
      grid-gap: 20px;
    }
  }

  &.owner {
    .cnpj {
      vertical-align: top;

      button {
        width: 45px;
        height: 45px;
        padding: 0;
        margin-top: 24px;
        background-color: var(--color-bg-dark);
        border-radius: 0 5px 5px 0;
      }

      @media (min-width: 724px) {
        width: calc(50% - 10px);
        display: inline-block;
      }

      @media (min-width: 1280px) {
        width: calc(33.33333% - 13.333333333px);
        display: inline-block;
      }
    }

    .buttonCopy {
      width: 320px;
      cursor: pointer;
    }

    label {
      @media (min-width: 724px) {
        &.companyName {
          width: calc(50% - 10px);
          margin-left: 20px;
        }
      }

      @media (min-width: 1280px) {
        &.docCountry, &.companyName, &.pais {
          width: calc(33.33333% - 13.333333333px);
          margin-left: 0;
        }
  
        &.companyName {
          margin: 0 20px 20px;
        }
      }
    }
  }

  .pfFields {
    @media (min-width: 460px) {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-gap: 20px;
    }
    @media (min-width: 720px) {
      display: flex;
      gap: 20px;
    }
  }
`

export const StyledFormButton = styled.button`
  display: block;
  clear: both;
  width: 100%;

  @media (min-width: 724px) {
    width: 250px;
  }
`

export const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 5px;
  margin-bottom: 5px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, calc(50% - 2.5px));
    grid-gap: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 920px) {
    grid-template-columns: repeat(3, calc(33.3333334% - 6.666667px));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, calc(25% - 7.5px));
  }
`

export const StyledCard = styled.div`
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  padding: 35px 10px 30px;
  position: relative;

  .pencil, .trash, .toggle {
    position: absolute;
    font-size: 18px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .toggle {
    top: 0;
    left: 0;

    .fa-toggle-on {
      color: var(--color-primary);
    }
  }

  .pencil {
    top: 0;
    right: 0;

    &:hover {
      color: var(--color-primary);
    }
  }

  .trash {
    bottom: 0;
    right: 0;
    
    &:hover {
      color: var(--color-alert);
    }
  }

  p {
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-of-type {
      margin: 0;
    }

    span {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
    }

    &.strong{
      font-weight: 600;
    }

    i {
      margin-right: 5px;
    }
  }
`

export const StyledNav = styled.nav`
  & > div, .button {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
  }

  & > div {
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    input {
      margin: 0 0 10px;
      height: 50px;
      padding-right: 60px;
    }

    i {
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-color: var(--color-secondary);
      color: var(--color-light);
      text-align: center;
      line-height: 50px;
      font-size: 23px;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 3;
      border-radius: 3px;
    }
  }

  .button {
    font-size: 16px;
    background-color: var(--color-secondary);
    border-radius: 3px;
  }

  @media (min-width: 480px) {
    & > div, .button {
      width: calc(50% - 5px);
    }

    & > div {
      margin: 0 0 0 10px;
    }
  }

  @media (min-width: 920px) {
    .button {
      width: 220px;
    }

    & > div {
      width: calc(100% - 230px);
    }
  }

  @media (min-width: 1280px) {
    & > div {
      max-width: 600px;
    }
  }
`

export const StyledButtons = styled.div`
  .button {
    width: calc(50% - 5px);

    &.cancel {
      margin-right: 10px;
    }
  }
`

export const StyledFormRegister = styled.form`
  .toggled {
    width: calc(50% - 5px);
    display: inline-block;

    &:nth-of-type(even) {
      margin-right: 10px;
    }
  }

  .half-two {
    width: calc(50% - 5px);
    display: inline-block;
  }

  .full {
    width: 100%
  }

  @media (min-width: 520px) {
    .half {
      width: calc(50% - 5px);
      display: inline-block;
    }

    .left {
      margin-right: 10px;
    }
  }
`

export const StyledButtonsConfirm = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;

  .button {
    margin: 0 5px;

    &.secondary {
      background-color: var(--color-secondary);
    }
  }
`

export const StyledContinueButton = styled.button`
  @media (min-width: 520px) {
    width: auto;
  }
`

export const StyledFormFinance = styled.form`
  .researchButtons {
    margin-bottom: 20px;

    button {
      width: 50%;
      display: inline-block;
      height: 45px;
      border: none;
      border-radius: 0 6px 6px 0;
      background-color: var(--color-gray-light);
      vertical-align: top;
      color: var(--color-gray-dark);

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      i {
        font-size: 23px;
        width: 23px;
      }

      span {
        width: calc(100% - 28px);
        display: inline-block;
        font-size: 16px;
        text-align: left;
        padding: 0 0 0 7px;
      }

      &:first-of-type {
        span {
          padding: 0 7px 0 0;
          text-align: right;
        }

        border-radius: 6px 0 0 6px;
      }

      &.active {
        background-color: var(--color-bg-dark);
        color: var(--color-light);
      }
    }
  
    @media (min-width: 520px) {
      max-width: 480px;
      margin: 0 auto 20px;

      button {
        span {
          padding: 0 0 0 30px;
        }

        &:first-of-type {
          span {
            padding: 0 30px 0 0;
          }
        }
      }
    }
  }

  .info-no-search {
    padding: 20px;
    margin: 30px 0;
    text-align: center;
    border: 1px solid var(--color-primary);
    grid-column-start: 2;
    grid-column-end: 3;
  }

  article {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, calc(50% - 2.5px));

    label {
      input {
        text-align: right;
      }
    }

    @media (min-width: 520px) {
      grid-template-columns: repeat(2, calc(50% - 7.5px));
      grid-gap: 15px;

      label {
        margin: 0;
      }
    }

    @media (min-width: 720px) {
      grid-template-columns: repeat(3, calc(33.333333% - 10px));
    }

    @media (min-width: 980px) {
      max-width: 940px;
      margin: 0 auto;
    }
  }

  @media (min-width: 980px) {
    button.confirm {
      width: 200px;
      display: block;
      margin: 20px auto 0;
    }
  }
`

export const StyledAlertMessage = styled.div`
  background-color: var(--color-warning);
  padding: 10px 10px;
  margin: -15px 0 20px 0;
  display: block;
  
  i {
    float: right;
    cursor: pointer;
  }
`

export const StyledShowMsg = styled.div`
  background-color: var(--color-warning);
  color: var(--color-secondary);
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`


export const StyledPhoneContainer = styled.div`
  margin-top: 20px;

  @media (min-width: 460px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-gap: 20px;
  }

  @media (min-width: 724px) {
    display: grid;
    grid-template-columns: repeat(3, calc(33.3333333% - 13.3333334px));
    grid-gap: 20px;
  }
`

export const StyledPhoneField = styled.div`
  position: relative;

  label {
    margin: 0 0 20px;
  }

  &.hasTrash {
    label {
      display: inline-block;
      width: calc(100% - 40px);
      margin-right: 10px;

      @media (min-width: 1280px) {
        width: calc(100% - 40px) !important;
      }
    }

    .fa-trash-alt {
      clear: right;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center;
      vertical-align: middle;
      font-size: 24px;
      line-height: 30px;
      margin-top: 30px;
      color: var(--color-gray-light);

      &:hover {
        color: var(--color-alert);
      }
    }
  }
`

export const StyledAddPhone = styled.button`
  background-color: var(--color-bg-dark);
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  clear: both;
  display: block;

  i {
    margin-left: 10px;
  }

  @media (min-width: 460px) {
    width: 200px;
  }
`

export const StyledAlertMsg = styled.div`
  padding: 10px;
  background-color: var(--color-warning);
  border: 2px solid rgb(163, 143, 27);
  color: rgb(116 102 16);
  border-radius: 3px;
  margin-bottom: 10px;
`
