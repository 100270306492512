import React, { useState, useEffect, useCallback } from 'react'
import { FILTER_MANUAL } from '@/constants/optionsValues'
import PropTypes from 'prop-types'
import Acordeon from '@/views/fragments/Acordeon'
import SelectText from '@/views/fragments/form/SelectText'
import { BLOCK_TYPE } from '@/constants/optionsValues'
import InputText from '@/views/fragments/form/InputText'
import Modal from '@/views/fragments/Modal'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import CheckboxField from '@/views/fragments/form/CheckboxField'
import { reqGetManualBlock, postManualBlock, reqSubmitDeleteManualBlock } from '@/constants/requests'
import {
  StyledSection,
  StyledTopContent,
  StyledList,
  StyledButtonsModal,
  StyledFilter
} from './style'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useSelector } from 'react-redux'

const ManualBlock = (props) => {
  const { isMobile } = props
  const [submitForm, setSubmitForm] = useState({type: BLOCK_TYPE[0].value})
  const [deleteForm, setDeleteForm] = useState({type: BLOCK_TYPE[0].value})
  const [showModal, setShowModal] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [manualBlock, setManualBlock] = useState([])
  const [clients, setClients] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterForm, setFilterForm] = useState({})

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.manualBlock.view) {
      window.location.href = "/";
      return
    }
    getManualBlock();
  }, [])

  const getManualBlock = useCallback(async(param) => {
    let paramFilter = param || null;
    let manualBlocks = await reqGetManualBlock(paramFilter);
    // Verifica se teve retorno de objetos
    if(manualBlocks.objetos instanceof Array) {
      setManualBlock(manualBlocks.objetos);
    } else {
      setShowInfoModal('Não existe registros com esse filtro!')
    }
    // Verifica se teve retorno de clientes
    if(manualBlocks.clientes) {
      let novoArray = manualBlocks.clientes.map(function(item){
        return { id: item.id_cliente, name: item.nome };
      });
      setClients(novoArray);
    }    
  }); 

  function checkFirstAlphaNum(char) {
    let alphaReg = new RegExp(/^[a-z]/i);
    let numReg = new RegExp(/^[0-9]/);
    let newAlpha = char.charAt(0)
    if (alphaReg.test(newAlpha)) {
      return "A";
    } else if (numReg.test(newAlpha)) {
      return "N";
    } else {
      return "O";
    }
  }

  const onChangeSubmitForm = (e) => {
    if(e.name) {
      setSubmitForm({
        ...submitForm,
        cliente: e.id
      })
      return
    }
    const { name, value } = e.target

    let newValue = value.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");

    if (name === 'objeto') {
      if (checkFirstAlphaNum(newValue) === "A") {
        if (newValue.length > 7) {
          return
        }
      } else if (checkFirstAlphaNum(newValue) === "N") {
        if (newValue.length > 11) {
          return
        }
      }
    }

    setSubmitForm({
      ...submitForm,
      [name]: newValue
    })
  }

  const onChangeDeleteForm = (e) => {
    const { name, value } = e.target

    let newValue = value.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");

    setDeleteForm({
      ...deleteForm,
      [name]: newValue
    })
  }

  const onChangeCheckbox = (name, value) => {
    setSubmitForm({
      ...submitForm,
      [name]: value
    })
  }

  const closeModal = () => {
    setShowModal(false)
    setShowModalConfirmation(false)
  }

  function validateSubmit(form) {
    if (form.objeto === undefined) {
      setShowInfoModal('Informe um objeto para inserir!')
      return false
    }

    if (form.motivo === undefined) {
      setShowInfoModal('Informe um motivo para inserir!')
      return false
    }

    if (!form.cliente_bloquear_todos) {
      if(form.cliente_id === "null") {
        setShowInfoModal('Informe um cliente para inserir!')   
        return false    
      }
    }

    if (!form.prazo_indeterminado) {
      if(form.prazo === "null") {
        setShowInfoModal('Informe um prazo para inserir!')   
        return false   
      }
    }

    return true
  }

  const handleSubmit = async() => {
    let form = {
      objeto: submitForm?.objeto,
      motivo: submitForm?.motivo,
      cliente_id: submitForm?.cliente || "null",
      prazo: submitForm?.prazo || "null",
      prazo_indeterminado: submitForm?.prazo_indeterminado === "S" ? true : false,
      cliente_bloquear_em_conformidade: submitForm?.cliente_bloquear_em_conformidade === "S" ? true : false,
      cliente_bloquear_todos: submitForm?.cliente_bloquear_todos === "S" ? true : false,
    }
    
    if (!validateSubmit(form)) {
      return
    }

    let returnInsert = await postManualBlock(form);
    
    if (!returnInsert.status.active) {
      setShowInfoModal(returnInsert.status.message)
    } else {
      setShowInfoModal(returnInsert.status.message)
      setSubmitForm({type: BLOCK_TYPE[0].value})
      getManualBlock()
      closeModal()
    }
  }

  const onTrashClick = (objeto) => {
    setDeleteForm({objeto: objeto, ...deleteForm})
    setShowModalConfirmation(true)
  }

  const renderRow = (el, i) => (
    <li key={i}>
      <div>
        {isMobile && (<span>Objeto</span>)}
        <p>{el.objeto}</p>
      </div>
      <div>
        {isMobile && (<span>Nome</span>)}
        <p>{el.nome}</p>
      </div>
      <div>
        {isMobile && (<span>Motivo</span>)}
        <p>{el.motivo}</p>
      </div>
      <div>
        {isMobile && (<span>Prazo</span>)}
        <p>{el.indeterminado ? 'Indeterminado' : el.prazo}</p>
      </div>
      <div>
        {isMobile && (<span>Cliente</span>)}
        <p>{el.cliente}</p>
      </div>
      <div className="action">
        {(!permission?.manualBlock.deleteBlock) ? 
          <button type="button" onClick={() => onTrashClick(el.objeto)}>
            <i style={{color: "grey"}} className="fa-regular fa-trash-can" />
          </button>
        : 
          <button type="button" onClick={() => onTrashClick(el.objeto)} 
          // disabled={!permission?.manualBlock.deleteBlock}
          >
            <i className="fa-regular fa-trash-can" />
          </button>
        }
      </div>
    </li>
  )

  const renderRowList = () => {
    let newArray = [...manualBlock]

    return newArray.map((el, index) => renderRow(el, index))
  }

  const renderList = () => (
    <StyledList>
      {!isMobile && (
        <li className="header-content">
          <div><span>Objeto</span></div>
          <div><span>Nome</span></div>
          <div><span>Motivo</span></div>
          <div><span>Prazo</span></div>
          <div><span>Cliente</span></div>
          <div><span>Ação</span></div>
        </li>
      )}
      {renderRowList()}
    </StyledList>
  )

  const onFilterChange = (e) => {
    const {name, value} = e.target
    
    let newValue = value.replaceAll(String.fromCharCode(9), " ");

    setFilterForm({
      ...filterForm,
      [name]: newValue
    })
  }

  const handleSubmitFilter = async () => {
    if(!filterForm?.type) {
      setShowInfoModal('Informe um tipo de filtro para filtrar!')
      return
    }

    if(!filterForm?.text) {
      setShowInfoModal('Informe um texto para filtrar!')
      return
    }

    let newForm = {
      tipo_filtro: filterForm?.type || null,
      filtro: filterForm?.text || null,
    }
    
    await getManualBlock(newForm)    
  }

  const setShowFilterManual = async(boolFilter) => {
    setShowFilter(boolFilter)
    
    if (!boolFilter) {
      await getManualBlock();
    }
  }

  const renderFilter = () => (
    <StyledFilter>
      <div>
        <SelectText
          label="Filtrar por:"
          options={FILTER_MANUAL}
          name="type"
          notEmptyOption
          onChange={onFilterChange}
          value={filterForm?.type || ''}
        />
        <InputText
          label="Digite aqui..."
          name="text"
          value={filterForm?.text || ''}
          onChange={onFilterChange}
        />
      </div>
      <div>
        <button
          className={showFilter ? 'active button' : 'button'}
          onClick={handleSubmitFilter}
        >
          Buscar
        </button>
      </div>
    </StyledFilter>
  )

  const renderTop = () => (
    <>
      <StyledTopContent>
        <div>
          <button className="button" onClick={() => setShowModal(true)} 
          // disabled={!permission?.manualBlock.addBlock}
          >
            <i className="fa-solid fa-plus" />
            Novo Bloqueio
          </button>
        </div>

        <div>
          <button className={showFilter ? 'active button' : 'button'} onClick={() => setShowFilterManual(!showFilter)}>Filtrar</button>
        </div>
      </StyledTopContent>
      {showFilter && renderFilter()}
    </>
  )

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  const renderModal = () => (
    <Modal
      closeModal={closeModal}
    >
      <InputText
        label="Objeto Placa/CPF"
        value={submitForm?.objeto || ''}
        isRequired
        name="objeto"
        onChange={onChangeSubmitForm}
      />
      <>
        <div>
          <span style={{color: "var(--color-primary)"}}>Motivo</span>
          <textarea
            value={submitForm?.motivo || ''}
            style={{marginTop: "10px", resize: "vertical"}}
            required={true}
            name="motivo"
            placeholder='Digite aqui...'
            onChange={onChangeSubmitForm}
          />
        </div>
      </>
      <CheckboxField
        label='Período indeterminado?'
        name='prazo_indeterminado'
        value={submitForm.prazo_indeterminado || 'N'}
        onClick={onChangeCheckbox}
        hideDescription
      />
      {(submitForm?.prazo_indeterminado === 'N' || submitForm?.prazo_indeterminado === undefined)  && (
        <InputText
          label="Prazo"
          value={submitForm?.prazo || ''}
          name="prazo"
          minDate={true}
          type='date'
          onChange={onChangeSubmitForm}
        />
        )}
      <CheckboxField
        label='Bloquear para todos os clientes?'
        name='cliente_bloquear_todos'
        value={submitForm.cliente_bloquear_todos || 'N'}
        onClick={onChangeCheckbox}
        hideDescription
      />
      {(submitForm?.cliente_bloquear_todos === 'N' || submitForm?.cliente_bloquear_todos === undefined) && (
        <>
          <div className='margin-client'>
            <span style={{color: "var(--color-primary)"}}>Cliente</span>
            <ReactSearchAutocomplete
              items={clients}
              onSelect={onChangeSubmitForm}
              autoFocus
              formatResult={formatResult}
              showIcon={false}
              showClear={false}
              styling={{
                height: "40px",
                borderRadius: "5px",
                color: "black",
                border: "black 1px solid",
                padding: "0 10px",
                fontSize: "16px",
                fontWeight: "800",
                zIndex: 2,
              }}
            />
          </div> 
        </>
      )}
      <CheckboxField
        label='Bloquear todas as solicitações em conformidade?'
        name='cliente_bloquear_em_conformidade'
        value={submitForm?.cliente_bloquear_em_conformidade || 'N'}
        onClick={onChangeCheckbox}
        hideDescription
      />
      <button className="button" onClick={handleSubmit}>
        Salvar Bloqueio
      </button>
    </Modal>
  )

  const handleDelete = async() => {
    if(deleteForm.motivo === undefined) {
      setShowInfoModal('Coloque um motivo!')
    } else {
      let deleteManual = await reqSubmitDeleteManualBlock(deleteForm)
      if (!deleteManual.status.active) {
        setShowInfoModal(deleteManual.status.message)
      } else {
        setShowInfoModal(deleteManual.status.message)
        setDeleteForm({type: BLOCK_TYPE[0].value})
        getManualBlock()
      }
      closeModal()
    }
  }

  const closeInfoModal = () => {
    setShowInfoModal(null)
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={closeInfoModal}
      msg={showInfoModal}
    />
  )

  const renderConfirmationModal = () => (
    <Modal
      closeModal={closeModal}
    >
      <h4>Deseja realmente excluir o bloqueio de objeto {deleteForm.objeto}?</h4>
      <hr></hr>
      <div>
        <span style={{color: "var(--color-primary)", fontSize: "800"}}>Motivo</span>
        <textarea
          value={deleteForm?.motivo || ''}
          style={{marginTop: "10px", resize: "vertical"}}
          name="motivo"
          placeholder='Digite aqui...'
          onChange={onChangeDeleteForm}
        />
      </div>    
      <StyledButtonsModal>
        <button className="button cancel" onClick={closeModal}>Não</button>
        <button className="button" onClick={() => handleDelete()}>Sim</button>
      </StyledButtonsModal>
    </Modal>
  )

  return (
    <StyledSection className='container'>
      {showModalConfirmation && renderConfirmationModal()}
      {showInfoModal && renderInfoModal()}
      {showModal && renderModal()}
      <Acordeon
        title="Bloqueio Manual"
        icon="fa-solid fa-road-barrier"
        showContent
      >
        {renderTop()}
        {renderList()}
      </Acordeon>
    </StyledSection>
  )
}

ManualBlock.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default ManualBlock
