import styled from '@emotion/styled'

export const StyledImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);

  img {
    max-width: 100%;
  }
`
