import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import Modal from '@/views/fragments/Modal/Modal'
import FormRegisterUsers from '@/views/Register/fragments/FormRegisterUsers'
import {
  StyledCardContainer,
  StyledNav,
  StyledButtonsConfirm,
  StyledContinueButton,
} from '@/views/Register/fragments/styles'
import UserCard from '@/views/fragments/UserCard'
import { useSelector } from 'react-redux'

const MSG = {
  register: 'Complete os campos abaixo para cadastrar usuário'
}

const MODAL_TYPE = {
  register: 'register',
  delete: 'delete',
}

const RegisterUsers = (props) => {
  const { userList, onSave, onToggle, editFlow, companyId } = props
  const [showModal, setShowModal] = useState(false)
  const [toEditData, setToEditData] = useState({})
  const [editMsg, setEditMsg] = useState(false)
  const [edit, setEdit] = useState(false)
  const [formValues, setFormValues] = useState([])
  const [indexEdit, setIndexEdit] = useState()
  const [indexRemove, setIndexRemove] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [fullValues, setFullValues] = useState([])
  const [modalType, setModalType] = useState('')
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  
  const permission = useSelector(state => state.permission);

  useEffect(() => {
    setFormValues(userList)
    setFullValues(userList)
  }, [userList])

  const onEditClick = (i) => {
    if(!permission?.clients?.changeUser) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para editar usuários!")
      return
    }
    setEditMsg(true)
    let newValues = {
      ...formValues[i],
      psw: null,
    }
    setToEditData(newValues)
    setIndexEdit(i)
    setEdit(true)
    setShowModal(true)
    setModalType(MODAL_TYPE.register)
  }

  const onCloseModal = () => {
    setShowModal(false)
    setModalType('')
    setIndexEdit(null)
    setIndexRemove(null)
    setShowMessageModal(false)
    setMessageModal('')
  }

  const handleAdd = () => {
    if(!permission?.clients?.addUser) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para cadastrar usuários!")
      return
    }
    setEditMsg(false)
    setEdit(false)
    setToEditData({})
    setIndexEdit()
    setShowModal(true)
    setModalType(MODAL_TYPE.register)
  }

  const handleSaveRegister = async (form) => {
    const newArray = [...formValues]

    if (indexEdit >= 0) {
      newArray[indexEdit] = form
      setFullValues(newArray)
      setFormValues(newArray)
    } else {
      newArray.push(form)
      setFullValues(newArray)
      setFormValues(newArray)
    }

    if (editFlow) {
      await onSave(form)
    } 

    onCloseModal(false)
  }

  const onToggleClick = (i) => {
    if(!permission?.clients?.changeUser) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para editar usuários!")
      return
    }
    const newArray = [...formValues]

    newArray[i] = {
      ...formValues[i],
      active: !formValues[i].active,
    }

    setFullValues(newArray)
    setFormValues(newArray)

    if (editFlow) {
      onToggle(newArray[i])
    }
  }

  const onConfirmDelete = () => {
    const newArray = [...formValues]
    newArray.splice(indexRemove, 1)
    setFullValues(newArray)
    setFormValues(newArray)
    onCloseModal()
  }

  const handleRemove = (index) => {
    setModalType(MODAL_TYPE.delete)
    setShowModal(true)
    setIndexRemove(index)
  }

  const renderCard = (user, i) => (
    <UserCard
      onEdit={onEditClick}
      onDelete={handleRemove}
      index={i}
      key={i}
      user={user}
      handleToggle={onToggleClick}
    />
  )

  const handleChangeSearch = (e) => {
    const { value } = e.target
    setSearchInput(value)
  }

  const handleSearch = () => {
    const newArray = [...fullValues]
    const data = newArray.filter((user) => {
      const { fullName, userLogin } = user
      if (fullName.toLowerCase().includes(searchInput.toLowerCase())
        || userLogin.toLowerCase().includes(searchInput.toLowerCase())) {
        return user
      }

      return null
    })

    setFormValues(data)
  }

  const renderMessageModal = () => (
    <Modal
      subTitle={messageModal}
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const renderModal = () => {
    if (modalType === MODAL_TYPE.register) {
      return (
        <Modal
          closeModal={onCloseModal}
          title={editMsg ? 'Editar' : 'Cadastro'}
          size="md"
          subTitle={editMsg ? null : MSG.register}
        >
          <FormRegisterUsers
            handleCancel={onCloseModal}
            formValues={toEditData}
            onSave={handleSaveRegister}
            onToggle={onToggleClick}
            companyId={companyId}
            isEdit={edit}
          />
        </Modal>
      )
    }

    if (modalType === MODAL_TYPE.delete) {
      return (
        <Modal
          title="Excluir?"
          subTitle="Clique em confirmar para remover o usuário selecionado"
          type='alert'
          closeModal={onCloseModal}
        >
          <StyledButtonsConfirm>
            <button
              className='button secondary'
              onClick={() => onCloseModal()}
            >
              Cancelar
            </button>
            <button
              className='button'
              onClick={() => onConfirmDelete()}
            >
              Confirmar
            </button>
          </StyledButtonsConfirm>
        </Modal>
      )
    }
  }

  const handleSave = () => {
    onSave(formValues)
  }

  return (
    <>
      {showModal && renderModal()}
      {showMessageModal && renderMessageModal()}
      <StyledNav>
        <button className='button' onClick={() => handleAdd()}>
          <i className="fas fa-user-plus" />
          Add Usuário
        </button>
        <div>
          <InputText
            name="search"
            value={searchInput}
            onChange={handleChangeSearch}
          />
          <i className="fas fa-search" onClick={() => handleSearch()}/>
        </div>
      </StyledNav>
      
      <StyledCardContainer>
        {formValues?.map((user, index) => renderCard(user, index))}
      </StyledCardContainer>

      {!editFlow && (
        <StyledContinueButton className='button continuar' onClick={handleSave}>
          <i className="fas fa-angle-double-right" />
          Continuar
        </StyledContinueButton>
      )}
    </>
  )
}

RegisterUsers.propTypes = {
  onSave: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.any),
  editFlow: PropTypes.bool,
  companyId: PropTypes.text,
}

RegisterUsers.defaultProps = {
  userList: [],
  editFlow: false,
  companyId: "0"
}

export default RegisterUsers
