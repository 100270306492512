import styled from '@emotion/styled'

export const StyledSection = styled.section`
  max-width: 1000px;
`

export const StyledForm = styled.div`
  display: flex;
  gap: 30px;
`
