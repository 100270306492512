import styled from '@emotion/styled'

export const StyledBGModal = styled.div`
  background-color: var(--color-hover-black-dark);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  &.absolute-zindex {
    z-index: 1100;
  }
`

export const StyledModalContent = styled.div`
  background-color: var(--color-light);
  max-height: 90%;
  padding: 10px 10px;
  max-width: 90%;
  overflow-x: auto;
  position: relative;

  & > div {
    h2 {
      color: var(--color-primary);
      margin-bottom: 20px;

      &.alert {
        color: var(--color-alert);
      }

      &.success {
        color: var(--color-success);
      }

      &.warning {
        color: var(--color-warning);
      }
    }

    p {
      &.title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  &.sm {
    width: 350px;
    position: relative;
  }

  &.md {
    width: 650px;
    position: relative;
  }

  &.lg {
    width: 1280px;
    position: relative;
  }

  &.xlg {
    width: 1600px;
    position: relative !important;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 30px;
    line-height: 30px;
    padding: 0;
    width: 80px;
    border-radius: 0 0 0 20px;

    &:hover {
      box-shadow: 100px 100px inset var(--color-hover-black-light);
    }

    &.alert {
      background-color: var(--color-alert);
    }

    &.success {
      background-color: var(--color-success);
    }

    &.warning {
      background-color: var(--color-warning);
    }
  }

  &.alert {
    background-color: #fde3e3;
  }

  &.success {
    background-color: #d2ffd4;
  }

  &.warning {
    background-color: #fdf8bf;
  }

  @media (min-width: 620px) {
    padding: 30px 30px 20px;
  }
`
