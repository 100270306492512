import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import CheckboxField from '@/views/fragments/form/CheckboxField'
import FormAddress from '@/views/Register/fragments/FormAddress'
import Warnin from '@/views/fragments/Warnin'
import AnalysisProfileCard from '@/views/fragments/AnalysisProfileCard'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { formatterCNPJ, formatterNumberOnly } from '@/utils/formatter'
import { validateCompanyFields } from '@/validators/registerValidations'
import { MAX_LENGTH, MIN_LENGTH } from '@/utils/minMaxLength'
import { StyledFormButton } from '@/views/Register/fragments/styles'
import { getAnalysesProfiles, getCNPJInfo } from '@/constants/requests'
import Modal from '@/views/fragments/Modal'
import {
  StyledForm,
  StyledProfileList,
  StyledInputProfile,
  StyledRadioCompany
} from './styles'

const RegisterCompanyData = (props) => {
  const { companyForm, onSave, showSaveLabel } = props
  const [formValues, setFormValues] = useState({})
  const [analyseProfileList, setAnalyseProfileList] = useState([])
  const [validateList, setValidateList] = useState({})
  const [isFilled, setIsFilled] = useState(false)
  const [showWarning, setShowWarning] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [linkledProfile, setLinkedProfile] = useState({})
  const [disabledCNPJ, setDisabledCNPJ] = useState(false)

  const requestProfiles = (useCallback(async () => {
    const data = await getAnalysesProfiles()
    
    if(companyForm?.linkedProfile !== undefined || companyForm?.linkedProfile !== null) {
      var obj = data.perfis.find(e => (e.id === Number(companyForm?.linkedProfile)))
      setLinkedProfile(obj)
    }

    setAnalyseProfileList(data.perfis)
  }, []))

  useEffect(async () => {
    await requestProfiles()
    setFormValues(companyForm)
    await handleCNPJFields(companyForm.cnpj)
    if(companyForm.cnpj !== null || companyForm.cnpj !== undefined) {
      setDisabledCNPJ(true)
    }
  
  },[companyForm])

  const onChange = (e, ml) => {
    const { name, value } = e.target
    
    let newValue = value.replace(String.fromCharCode(9), " ");

    if (name === 'cnpj') {
      newValue = formatterNumberOnly(newValue)
    }

    if (name === 'integrationCode') {
      newValue = formatterNumberOnly(newValue)
    }

    if (newValue.length > ml) return

    setShowWarning(null)
    setFormValues({
      ...formValues,
      [name]: newValue,
    })

    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const onToggleClick = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSelectProfile = (profile) => {
    setFormValues({
      ...formValues,
      linkedProfile: profile.id,
    })
    setLinkedProfile(profile)
    onCloseModal()
  }

  const handleSave = (e) => {
    e.preventDefault()
    const list = validateCompanyFields(formValues)

    if (list.status) {
      setValidateList(list)
      return
    }

    const arr = formValues.companyName.split(' ')
    const words = arr.filter(word => word !== '').length

    if (words < MIN_LENGTH.two) {
      setShowWarning(`Você deve preencher ao menos ${MIN_LENGTH.two} nomes.`)
      return
    }

    onSave(formValues)
  }

  const handleFormValues = (form) => {
    setFormValues(form)
  }

  const handleValidateList = (form) => {
    setValidateList(form)
  }

  const handleCNPJBlur = async (e) => {
    const { value } = e.target
    if (!value) return

    handleCNPJFields(value)
  }
  
  const handleCNPJFields = async (value) => {
    const data = await getCNPJInfo({cnpj: formatterNumberOnly(value) })
    if(!data.status.active) {
      return
    }
    const employee = data.company

    const form = {
      ...formValues,
      cnpj: employee.cnpj,
      companyName: employee.companyName,
      cep: formatterNumberOnly(employee.cep),
      logradouro: employee.logradouro,
      localidade: employee.localidade,
      uf: employee.uf,
      bairro: employee.bairro,
      numero: employee.numero,
      complemento: employee.complemento,
      integrationCode: companyForm.integrationCode,
      registerStatus: companyForm.registerStatus === 'S' ? true : false,
      companyType: companyForm.companyType,
      cm: companyForm.cm
    }

    setFormValues(form)
    setIsFilled(true)
  }

  const onAnalysesClick = () => {
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const renderModal = () => (
    <Modal
      closeModal={onCloseModal}
      title="Vincular Perfil de Análise"
      size="lg"
    >
      <StyledProfileList>
        <AnalysisProfileCard
          onSelectProfile={handleSelectProfile}
          profileList={analyseProfileList}
        />
      </StyledProfileList>
    </Modal>
  )

  return (
    <StyledForm>
      {showModal && renderModal()}
      {showWarning && (<Warnin msg={showWarning} />)}
      <InputText
        label="CNPJ"
        name="cnpj"
        className="cnpj"
        isRequired
        isError={validateList.cnpj}
        hint={REQUIRED_FIELD}
        value={formatterCNPJ(formValues.cnpj) || ''}
        onChange={(e) => onChange(e, MAX_LENGTH.quatorze)}
        onBlur={handleCNPJBlur}
        isDisabled={disabledCNPJ}
      />
      <InputText
        label="Empresa"
        name="companyName"
        isRequired
        className="empresa"
        isError={validateList.companyName}
        hint={REQUIRED_FIELD}
        value={formValues.companyName || ''}
        onChange={onChange}
      />

      <StyledRadioCompany>
        <p>Tipo de Registro <span>*</span></p>
        <label>
          <input
            name="companyType"
            type='radio'
            value="embarcador"
            onChange={onChange}
            checked={formValues.companyType === "embarcador"}
          />
          Embarcador
        </label>
        <label>
          <input
            name="companyType"
            type='radio'
            value="transportador"
            onChange={onChange}
            checked={formValues.companyType === "transportador"}
          />
          Transportador
        </label>
        {validateList.companyType && (<b>{REQUIRED_FIELD}</b>)}
      </StyledRadioCompany>

      <ToggleButton
        label="Cliente ativo?"
        name="registerStatus"
        isRequired
        className="embarcador-toggle"
        value={formValues.registerStatus || false}
        onClick={onToggleClick}
      />
      <CheckboxField
        label="CM"
        name="cm"
        className="checkbox-field"
        value={formValues.cm || false}
        onClick={onToggleClick}
      />

      <FormAddress
        formValues={formValues}
        setFormValues={handleFormValues}
        validateList={validateList}
        setValidateList={handleValidateList}
        isFilled={isFilled}
      />

      <StyledInputProfile>
        <div>
          <InputText
            label="Perfil Vinculado"
            name="linkedProfile"
            isRequired
            readOnly
            value={linkledProfile?.nome || ''}
            onChange={onChange}
          />

          <button className="button" type='button' onClick={onAnalysesClick}>
            <i className="fa-solid fa-link" />
            {`${linkledProfile?.nome ? 'Alterar' : 'Vincular'} Perfil de Análise`}
          </button>
        </div>
      </StyledInputProfile>

      <InputText
        label="Código de Integração"
        name="integrationCode"
        isRequired
        className="integration-code"
        isError={validateList.integrationCode}
        hint={REQUIRED_FIELD}
        value={formValues.integrationCode || ''}
        onChange={onChange}
      />

      <StyledFormButton className="button" onClick={handleSave}>
        <i className="fas fa-angle-double-right" />
        {showSaveLabel ? 'Salvar' : 'Continuar'}
      </StyledFormButton>
    </StyledForm>
  )
}

RegisterCompanyData.propTypes = {
  onSave: PropTypes.func.isRequired,
  companyForm: PropTypes.objectOf(PropTypes.any),
  showSaveLabel: PropTypes.bool,
}

RegisterCompanyData.defaultProps ={
  companyForm: {},
  showSaveLabel: false,
}

export default RegisterCompanyData
