import React from 'react'
import PropTypes from 'prop-types'
import { StyledSectionTable } from './styles'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ModalTable = (props) => {
  const {
    type,
    values,
  } = props

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  return (
    <StyledSectionTable>
      <div className='flex-top'>
        <h2 className=''>Tabela de Relatório - {capitalizeFirstLetter(type)}</h2>
        <ReactHTMLTableToExcel
          id="table-xls-button"
          className="create-report"
          table="tabela-xlsx"
          filename={'relatorio-gerencial-'+type}
          sheet="tablexls"
          buttonText="Gerar em Excel"/>
      </div>
      <hr></hr>
      <table className='table' id='tabela-xlsx'>
        <thead>
          {(type === 'consultas') ?
            <tr>
              <th scope="col">Código da Consulta</th>
              <th scope="col">Empresa Bilhetada</th>
              <th scope="col">Empresa Solicitante</th>
              <th scope="col">Data da Consulta</th>
              <th scope="col">Pessoa</th>
              <th scope="col">Veículo 1</th>
              <th scope="col">Veículo 2</th>
              <th scope="col">Veículo 3</th>
              <th scope="col">Veículo 4</th>
              <th scope="col">Solicitante</th>
              <th scope="col">Consulta Automática</th>
              <th scope="col">Solicitação</th>
            </tr>
          : 
            <tr>
              <th scope="col">Solicitação</th>
              <th scope="col">Data da Solicitação</th>
              <th scope="col">Empresa Solicitante</th>
              <th scope="col">Pessoa</th>
              <th scope="col">Cavalo</th>
              <th scope="col">Carreta 1</th>
              <th scope="col">Carreta 2</th>
              <th scope="col">Carreta 3</th>
              <th scope="col">Solicitante</th>
              <th scope="col">Status</th>
              <th scope="col">Data Finalização</th>
              <th scope="col">Qtde. Objetos</th>
            </tr>
          }
        </thead>
        <tbody>
          {(type === 'consultas') ? (
            values.map((item) => (
              <tr key={item.codigo_da_consulta}>
                <td scope="row">{item.codigo_da_consulta}</td>
                <td>{item.empresa_bilhetada}</td>
                <td>{item.empresa_solicitante}</td>
                <td>{item.data_consulta}</td>
                <td>{item.objeto1 || '-'}</td>
                <td>{item.objeto2 || '-'}</td>
                <td>{item.objeto3 || '-'}</td>
                <td>{item.objeto4 || '-'}</td>
                <td>{item.objeto5 || '-'}</td>
                <td>{item.solicitante}</td>
                <td>{item.consulta_automática}</td>
                <td>{item.solicitacao || '-'}</td>
              </tr>
            ))
          ) : (
            values.map((item) => (
              <tr key={item.solicitacao}>
                <td scope="row">{item.solicitacao}</td>
                <td>{item.data_solicitacao}</td>
                <td>{item.empresa_solicitante}</td>
                <td>{item.Nome || '-'}</td>
                <td>{item.Cavalo || '-'}</td>
                <td>{item.Carreta1 || '-'}</td>
                <td>{item.Carreta2 || '-'}</td>
                <td>{item.Carreta3 || '-'}</td>
                <td>{item.Solicitante}</td>
                <td>{item.Status}</td>
                <td>{item.data_finalizacao || '-'}</td>
                <td>{item.qtde_objetos}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </StyledSectionTable>
  )
}

ModalTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
}

export default ModalTable
