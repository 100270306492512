import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  padding: 20px;
  margin: 100px auto 30px;
  max-width: 1500px;

  h2 {
    color: var(--color-primary);
    margin-bottom: 30px;
  }
`

export const StyledContent = styled.div`
  padding: 15px;
  border: 1px solid var(--color-primary);
  border-radius: 0 0 10px 10px;

  form {
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const StyledTab = styled.ul`
  width: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  overflow: hidden;

  li {
    display: inline-block;
    width: 20%;
    text-align: center;
    line-height: 40px;
    border-left: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 700;
    cursor: pointer;

    &:first-of-type {
      border: none;
    }

    i {
      margin-right: 5px;
    }

    &:hover, &.active {
      background-color: var(--color-primary);
      color: var(--color-light);
    }

    @media (max-width: 820px) {
      span {
        display: none;
      }
    }

    @media (min-width: 390px) and (max-width: 820px) {
      width: 50px;
      font-size: 15px;

      &.active {
        width: calc(100% - 200px);

        span {
          display: initial;
        }
      }
    }
  }
`

export const ButtonsContent = styled.div`
  text-align: center;

  button {
    width: auto;
    margin: 30px 15px;
  }
`

export const StyledButtonSave = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

  button {
    width: auto;

    span {
      display: block;
      font-style: italic;
      font-size: 13px;
      margin-top: 4px;
    }
  }
`
