import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { validateUserFields, } from '@/validators/registerValidations'
import {
  StyledButtons,
  StyledFormRegister,
  StyledShowMsg,
  StyledAlertMsg,
} from '../styles'
import { postRegistered, reqGetGroupPrivileges } from '@/constants/requests'
import { formatterEmailField } from '@/utils/formatter'
import SelectText from '@/views/fragments/form/SelectText'

const FormRegisterUsers = (props) => {
  const {
    handleCancel,
    formValues,
    onSave,
    isEdit,
    companyId,
  } = props

  const [form, setForm] = useState({})
  const [validateList, setValidateList] = useState({})
  const [isWrongPsw, setIsWrongPsw] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [grupos, setGrupos] = useState({})

  useEffect(async() => {
    setForm(formValues ? formValues : {})
    await searchGroups()
  }, [formValues])

  const searchGroups = async() => {
    let objeto = {
      cliente: "1"
    }

    if (Number(companyId) === 1) {
      objeto.cliente = "0"
    }

    let response = await reqGetGroupPrivileges(objeto)

    let newGroup = response.grupos.map((value) => ({ 
      'value': value.id_grupo,
      'label': value.nome, 
    }));
    
    setGrupos(newGroup)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    let newValue = value.replace(String.fromCharCode(9), " ");

    if (name === 'email') {
      newValue = formatterEmailField(newValue)
    }

    if (name === 'userLogin') {
      newValue = newValue.replace(" ", "")
    }

    setForm({
      ...form,
      [name]: newValue,
    })
    setValidateList({
      ...validateList,
      [name]: false,
    })
    setErrorAlert(false)
  }

  const handleChangeToggle = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSave = (e) => {
    e.preventDefault()
    const list = validateUserFields(form)

    if(!isEdit) {
      if (list.status ) {
        setValidateList(list)
        return
      }
  
      if (form.psw !== form.pswConfirm) {
        setIsWrongPsw(true)
        return
      }
    }

    if(form.changePassword) {
      if (form.psw !== form.pswConfirm) {
        setIsWrongPsw(true)
        return
      }
    }

    let newForm = {
      ...form,
      active: form.active === "1" ? true : Number(form.active) === '0' ? false : form.active,
      webservice: form.webservice === "1" ? true : Number(form.webservice) === '0' ? false : form.webservice,
      edit: !isEdit ? 1 : 0
    }

    onSave(newForm)
  }

  const handleBlurLogin = async (e) => {
    const { value } = e.target

    const req = await postRegistered({ login: value })

    if (req.status.active) {
      return
    }

    setForm({
      ...form,
      userLogin: '',
    })
    setValidateList({
      ...validateList,
      userLogin: true,
    })

    setErrorAlert(true)
  }

  return (
    <StyledFormRegister action="submit">
      {errorAlert && (
        <StyledAlertMsg>
          Este usuário já está em uso, favor escolher usuário diferente para cadastro.
        </StyledAlertMsg>
      )}
      <InputText
        label="Nome Completo"
        name="fullName"
        isRequired
        isError={validateList.fullName}
        hint={REQUIRED_FIELD}
        value={form.fullName || ''}
        onChange={handleChange}
      />
      <InputText
        label="Email"
        name="email"
        isRequired
        isError={validateList.email}
        hint={REQUIRED_FIELD}
        className="half left"
        value={form.email || ''}
        onChange={handleChange}
      />
      <InputText
        label="Login"
        name="userLogin"
        isDisabled={isEdit}
        isRequired
        isError={validateList.userLogin}
        hint={REQUIRED_FIELD}
        className="half"
        onBlur={handleBlurLogin}
        value={form.userLogin || ''}
        onChange={handleChange}
      />
      {grupos.length >= 1 ? (
        <SelectText
          label="Selecione um grupo para visualizar seus privilégios"
          name="id_grupo"
          options={grupos || {}}
          isRequired
          value={form.id_grupo || ''}
          onChange={handleChange}
          hint={REQUIRED_FIELD}
        />
      ) : null}
      {isEdit && (
        <ToggleButton
          label="Trocar a senha?"
          name="changePassword"
          className="toggled full"
          value={form.changePassword || false}
          onClick={handleChangeToggle}
        />
      )}
      
      {(isEdit && form.changePassword) && (
        <InputText
          label="Senha"
          name="psw"
          type="password"
          isRequired
          isError={validateList.psw}
          hint={REQUIRED_FIELD}
          className="half-two left"
          value={form.psw || ''}
          onChange={handleChange}
        />
      )}
      {(isEdit && form.changePassword) && (
        <InputText
          label="Confirmar Senha"
          name="pswConfirm"
          type="password"
          className="half-two"
          isRequired
          isError={validateList.pswConfirm}
          hint={REQUIRED_FIELD}
          value={form.pswConfirm || ''}
          onChange={handleChange}
        />
      )}

      {(!isEdit) && (
        <InputText
          label="Senha"
          name="psw"
          type="password"
          isRequired
          isError={validateList.psw}
          hint={REQUIRED_FIELD}
          className="half left"
          value={form.psw || ''}
          onChange={handleChange}
        />
      )}
      {(!isEdit) && (
        <InputText
          label="Confirmar Senha"
          name="pswConfirm"
          type="password"
          className="half"
          isRequired
          isError={validateList.pswConfirm}
          hint={REQUIRED_FIELD}
          value={form.pswConfirm || ''}
          onChange={handleChange}
        />
      )}
      <ToggleButton
        label="Ativo?"
        name="active"
        className="toggled"
        value={Number(form.active) || false}
        onClick={handleChangeToggle}
      />
      <ToggleButton
        label="Webservice?"
        name="webservice"
        className="toggled"
        value={Number(form.webservice) || false}
        onClick={handleChangeToggle}
      />
      {isWrongPsw && (
        <StyledShowMsg>
          As senhas não estão iguais.
        </StyledShowMsg>
      )}
      <StyledButtons>
        <button className='button cancel' onClick={handleCancel}>Cancelar</button>
        <button className='button' onClick={handleSave}>Salvar</button>
      </StyledButtons>
    </StyledFormRegister>
  )
}

FormRegisterUsers.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any),
  isEdit: PropTypes.bool,
  companyId: PropTypes.text,
}

FormRegisterUsers.defaultProps = {
  formValues: null,
  companyId: "0"
}

export default FormRegisterUsers
