import styled from "@emotion/styled"

export const StyledAddresContainer = styled.div`
  label {
    @media (min-width: 460px) {
      &.cep {
        width: 100%;
      }

      &.logradouro {
        width: calc(100% - 120px);
      }

      &.numero {
        width: 100px;
        margin-left: 20px;
      }

      &.bairro, &.cidade,
      &.estado, &.complemento {
        width: calc(50% - 10px);
      }

      &.cidade, &.complemento {
        margin-left: 20px;
      }
    }

    @media (min-width: 724px) {
      &.bairro, &.cidade,
      &.estado, &.complemento {
        margin-right: 0;
      }

      &.cep {
        width: 110px;
      }

      &.numero {
        width: 110px;
        margin-left: 0;
      }

      &.logradouro {
        width: calc(100% - 260px);
        margin: 0 20px;
      }
    }

    @media (min-width: 1280px) {
      &.cidade, &.bairro,
      &.complemento, &.estado {
        width: calc(25% - 15px);
        margin: 0 20px 20px 0;
      }

      &.complemento {
        margin: 0 0 20px;
      }
    }
  }
`