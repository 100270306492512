import styled from '@emotion/styled'

export const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px 10px;

  & > div {
    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
      display: block;
      margin-bottom: 5px;
    }
  }

  @media (min-width: 570px) {
    grid-template-columns: repeat(3, calc(33.33333% - 6.6666667px));
    &.basic-data {
      & > div {
        &.item-2 {
          grid-column-start: 2;
          grid-column-end: 4;
        }
      }
    }

    &.address {
      & > div {
        &.item-1 {
          grid-column-start: 1;
          grid-column-end: 4;
        }

        &.item-2 {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, calc(25% - 7.5px));

    &.basic-data {
      & > div {
        &.item-8 {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &.item-9 {
          grid-column-start: 3;
          grid-column-end: 5;
        }
      }
    }
  }
`

