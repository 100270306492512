import React from 'react'
import PropTypes from 'prop-types'
import { ContainerSearch } from './styles'

const ContainerTemplate = (props) => {
  const { children } = props

  return (
    <ContainerSearch>
      {children}
    </ContainerSearch>
  )
}

ContainerTemplate.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ContainerTemplate
