import React, { useState, useEffect } from 'react'
import { StyledBox, StyledSection, CustomLoading } from './styles'
import { useSelector } from 'react-redux'
import SelectText from '../fragments/form/SelectText'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import {
  reqGetTermCompany,
  reqGetTermList,
  reqGetTermTotal,
} from '@/constants/requests'
import ModalTable from './ModalTable'
import Modal from '../fragments/Modal'

const Term = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSpecific, setLoadingSpecific] = useState(true)
  const [initialData, setInitialData] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showTableModal, setShowTableModal] = useState(null)
  const [especifica, setEspecifica] = useState([])
  const [selectedEmpresa, setSelectedEmpresa] = useState(null)
  const [valoresModal, setValoresModal] = useState([])

  const permission = useSelector(state => state.permission);

  useEffect(async () => {
    if (!permission?.termReport?.view) {
      window.location.href = "/";
      return
    }

    await getCompanyId()
    setLoading(false)
    setLoadingSpecific(false)
  }, [])

  const getCompanyId = async () => {
    let req = await reqGetTermCompany()

    if(req.mundial_boolean) {
      let object = {
        value: 'todos',
        label: 'Todos'
      }
  
      req.empresas.unshift(object)
    } else {
      await handleSpecific(req.empresa_id)
    }

    setInitialData(req)
  }

  // MODAL

  const onCloseModal = (typeSuccess) => {
    setShowTableModal(false)
    if (typeSuccess) {
      setShowInfoModal(null)
      return
    }
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg={showInfoModal}
    />
  )

  // Render

  const renderTermList = (item, index) => {
    return <StyledBox key={index}>
      <div className='row6'>
        <span>Cliente</span>
        <p>{item.empresa}</p>
      </div>
      <div className='row1'>
        <span>Quantidade</span>
        <p>{item.quantidade || 0}</p>
      </div>
      <div className='row3'>
        <span>Tabela - Excel</span>
        <p onClick={() => renderTable(item.id)} title='Abrir modal com tabela para gerar excel'><i className="fa-solid fa-file-excel"></i></p>
      </div>
    </StyledBox>
  }

  const renderTable = async (empresa) => {
    let param = {
      empresa: empresa,
    }

    let req = await reqGetTermList(param)

    setValoresModal(req.prazos)
    setShowTableModal(true)
  }

  const renderTableModal = () => (
    <Modal
      closeModal={onCloseModal}
      size="xlg"
    >
      <ModalTable
        values={valoresModal}>
      </ModalTable>
    </Modal>
  )

  // On field change

  const onFilterChange = async (e) => {
    const { value } = e.target
    if(value === 'nulo') {
      setEspecifica([])
      return
    }

    setSelectedEmpresa(value)
    console.log(selectedEmpresa)

    setLoadingSpecific(true)

    await handleSpecific(value)
  }

  const handleSpecific = async (empresa) => {
    let req = await reqGetTermTotal({ empresa: empresa });

    let object = {
      term: req.prazos,
    }
     
    setEspecifica(object)
    setLoadingSpecific(false)
  }

  return (
    <StyledSection className='container'>
      {showTableModal && renderTableModal()}
      {showInfoModal && renderInfoModal()}
      <h2>Relatório de Prazos</h2>
      <hr></hr>
      
      <div className='container-specifics'>
        <div className='top'>
          {(initialData.mundial_boolean) ? (
            <p className='title'>Buscar por Cliente e Período</p>
          ) : 
            <p className='title'>Buscar por periodo</p>
          }
        </div>
        <div className='grid-specifics'>
          {(initialData.mundial_boolean) && (
            <SelectText
              label="Cliente"
              name="cliente"
              options={initialData.empresas}
              isLoading={loading}
              onChange={onFilterChange}
            />
          )}
        </div>
        {loadingSpecific ?
          (<CustomLoading className="fas fa-spinner" />)
          :
          <div className='father-specific'>
            <div className='specific'>
              <span>Prazos</span>
              <div>
                {especifica?.term?.length > 0 ?
                  especifica?.term?.map((item, index) => renderTermList(item, index, "specific"))
                  : <p>Não existe prazos geradas para esta empresa e/ou data</p>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </StyledSection>
  )
}

export default Term
