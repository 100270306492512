import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import SelectText from '@/views/fragments/form/SelectText'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { MAX_LENGTH } from '@/utils/minMaxLength'
import { validateShipperFields } from '@/validators/registerValidations'
import {
  StyledButtons,
  StyledFormRegister,
} from '../styles'
import { getEmbarcadores } from '@/constants/requests'
import { formatterCNPJ } from '@/utils/formatter'

const FormRegisterShipper = (props) => {
  const {
    handleCancel,
    formValues,
    onSave,
  } = props

  const [form, setForm] = useState({})
  const [validateList, setValidateList] = useState({})
  const [optionsList, setupOptionsList] = useState([])

  const setupOptions = useCallback(async () => {
    const req = await getEmbarcadores()
    const list = req.shippers.map((el) => {
      return {
        label: `${el.shipper} - ${formatterCNPJ(el.cnpj)}`,
        value: el.shipperId
      }
    })

    setupOptionsList(list)
  }, [])

  useEffect(() => {
    setupOptions()
    setForm(formValues ? formValues : {})
  }, [formValues])

  const handleChange = (e, ml) => {
    const { name, value } = e.target
    if (value.length > ml) return

    setForm({
      ...form,
      [name]: value,
    })

    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const handleSave = (e) => {
    e.preventDefault()
    const list = validateShipperFields(form)

    if (list.status) {
      setValidateList(list)
      return
    }

    const item = optionsList.find((el) => el.value === Number(form.shipperId))

    onSave({
      ...form,
      shipper: item.label
    })
  }

  return (
    <StyledFormRegister action="submit">
      <SelectText
        label="Embarcador"
        name="shipperId"
        isRequired
        isError={validateList.shipperId}
        hint={REQUIRED_FIELD}
        className="shipper"
        options={optionsList}
        value={form.shipperId || ''}
        onChange={handleChange}
      />
      <InputText
        label="Cód. Integração"
        name="integrationCode"
        isRequired
        isError={validateList.integrationCode}
        hint={REQUIRED_FIELD}
        className="integrationCode"
        value={form.integrationCode || ''}
        onChange={(e) => handleChange(e, MAX_LENGTH.quatorze)}
      />
      <StyledButtons>
        <button className='button cancel' onClick={handleCancel}>Cancelar</button>
        <button className='button' onClick={handleSave}>Salvar</button>
      </StyledButtons>
    </StyledFormRegister>
  )
}

FormRegisterShipper.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any),
}

FormRegisterShipper.defaultProps = {
  formValues: null,
}

export default FormRegisterShipper
