import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCard
} from './styles'

const UserCard = (props) => {
  const {
    onEdit,
    //onDelete,
    index,
    user,
    handleToggle,
  } = props

  const onToggleClick = () => {
    handleToggle(index)
  }

  const onEditClick = () => {
    onEdit(index)
  }

  // const handleRemove = () => {
  //   onDelete(index)
  // }

  return (
    <StyledCard>
      <div className='toggle' onClick={() => onToggleClick()}>
        {Number(user.active) ? (
          <i className="fas fa-toggle-on" />
          ) : (
          <i className="fas fa-toggle-off" />
        )}
      </div>
      <div className='pencil' onClick={() => onEditClick()}>
        <i className="fas fa-pencil-alt" />
      </div>
      <p className='strong' title={user.fullName}>
        <i className="far fa-user" />
        {user.fullName}
      </p>
      <p className='strong' title={user.email}>
        <i className="far fa-envelope" />
        {user.email}
      </p>
      <p><span>Login:</span> {user.userLogin}</p>
      <p><span>Webservice:</span> {Number(user.webservice) ? 'Sim' : 'Não'}</p>
      {/* <div className='trash'
        onClick={() => handleRemove()}
      >
        <i className="far fa-trash-alt" />
      </div> */}
    </StyledCard>
  )
}

UserCard.propTypes = {
  user: PropTypes.any.isRequired,
  handleToggle: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default UserCard
