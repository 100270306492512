import React, { useEffect, useState } from 'react'
import { formatterCPF, formatterNumberOnly } from '@/utils/formatter'
import { MIN_LENGTH, MAX_LENGTH } from '@/utils/minMaxLength'
import InputText from '@/views/fragments/form/InputText'
import { PF_REGISTER_SEARCH } from '@/constants/mockOptions'
import PhysicalPersonCards from '../fragments/PhysicalPersonCards'
import ContainerTemplate from '../fragments/ContainerTemplate'
import Warnin from '@/views/fragments/Warnin'

const SearchPhysicalPerson = () => {
  const [documentNumber, setDocumentNumber] = useState(null)
  const [registerFound, setRegisterFound] = useState(null)
  const [showWarning, setShowWarning] = useState(null)

  useEffect(() => {
    const url = window.location.pathname
    const cpf = url.split('/pessoa-fisica-consulta/')
    if (cpf[1]) {
      setRegisterFound(PF_REGISTER_SEARCH)
    }
  }, [])

  const onChange = (e, ml) => {
    const { value } = e.target
    const newValue = formatterNumberOnly(value)

    if (newValue.length > ml) return

    setDocumentNumber(newValue)
    setShowWarning(null)
  }

  const handleSave = (form) => {
    setRegisterFound(form)
  }

  const handleSearch = () => {
    if (MIN_LENGTH.nine > documentNumber.length) {
      setShowWarning(`Você deve preencher ao menos ${MIN_LENGTH.nine} dígitos`)
      return
    }
    if (!documentNumber) return setRegisterFound(null)
    setRegisterFound(PF_REGISTER_SEARCH)
  }

  return (
    <ContainerTemplate>
      <aside>
        <h3>Buscar Cliente</h3>
        <InputText
          label="CPF"
          name="cpf"
          className="cpf"
          value={formatterCPF(documentNumber)}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
        />
        {showWarning && (<Warnin msg={showWarning} />)}
        <button className='button' onClick={handleSearch}>
          <i className="fas fa-search" />
        </button>
      </aside>
      <section>
        {registerFound ? (
          <PhysicalPersonCards
            values={registerFound}
            onSave={handleSave}
          />
        ) : (
          <h4>
            Nenhum Cliente Encontrado
            <i className="fas fa-users-slash" />
          </h4>
        )}
      </section>
    </ContainerTemplate>
  )
}

export default SearchPhysicalPerson
