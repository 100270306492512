import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getAllParams } from '@/constants/localStorage'
import Pagination from '@/views/fragments/Pagination'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import Acordeon from '@/views/fragments/Acordeon'
import { BLOCK_TYPE } from '@/constants/optionsValues'
import InputText from '@/views/fragments/form/InputText'
import Modal from '@/views/fragments/Modal'
import { 
  reqSubmitNewBlacklist, 
  reqSubmitChangeBlacklist,
  reqGetBlacklist, 
} from '@/constants/requests'
import {
  StyledSection,
  StyledTopContent,
  StyledList,
  StyledButtonsModal
} from './style'

const defaultValues = {
  pagina: 1,
  total_por_pagina: 50
}

const BlackList = (props) => {
  const { isMobile } = props
  const [form, setForm] = useState({type: BLOCK_TYPE[0].value})
  const [submitForm, setSubmitForm] = useState({type: BLOCK_TYPE[0].value})
  const [showModal, setShowModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  // const [searchBy, setSearchBy] = useState('')
  const [blacklist, setBlacklist] = useState([])
  const [ativar, setAtivar] = useState({})
  const [boolAtivar, setBoolAtivar] = useState(false)
  const [pagination, setPagination] = useState(defaultValues)

  const handleChangePage = async (page) => {
    const data = {
      pagina: page,
      total_por_pagina: pagination.total_por_pagina,
      objeto: form.searchFor.toLowerCase(),
    }
    let req = await getResearchList(data)
    setBlacklist(req.blacklist)
    setPagination(req.paginacao)
  }

  const getResearchList = useCallback(async (data) => {
    const req = await reqGetBlacklist(data)
    setBlacklist(req.blacklist)
    setPagination(req.paginacao)
  }, [])

  useEffect(() => {
    getResearchList(defaultValues) 
  }, [])

  const onChangeForm = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }

  function checkFirstAlphaNum(char) {
    let alphaReg = new RegExp(/^[a-z]/i);
    let numReg = new RegExp(/^[0-9]/);
    let newAlpha = char.charAt(0)
    if (alphaReg.test(newAlpha)) {
      return "A";
    } else if (numReg.test(newAlpha)) {
      return "N";
    } else {
      return "O";
    }
  }

  const onChangeSubmitForm = (e) => {
    const { name, value } = e.target;    

    let newValue = value.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");

    if (name === 'objeto') {
      if (checkFirstAlphaNum(newValue) === "A") {
        if (newValue.length > 7) {
          return
        }
      } else if (checkFirstAlphaNum(newValue) === "N") {
        if (newValue.length > 11) {
          return
        }
      }
    }

    setSubmitForm({
      ...submitForm,
      [name]: newValue
    })
  }

  const closeModal = () => {
    setShowModal(false)
    setShowModalConfirmation(false)
  }

  const closeInfoModal = () => {
    setShowInfoModal(null)
  }

  const handleSubmit = async() => {
    const params = getAllParams()

    let newSubmitForm = {
      ...submitForm,
      usuario: params.user
    }

    let req = await reqSubmitNewBlacklist(newSubmitForm)
    if(req.status.active) {
      setShowInfoModal('Objeto cadastrado na blacklist com sucesso!')
      getResearchList(defaultValues)
      renderRowList()
      closeModal()
    } else {
      setShowInfoModal('Erro ao cadastrar na blacklist, contate o administrador!')
    }
  } 

  const onTrashClick = (objeto, bool) => {
    // aqui precisa passar o id do item
    setBoolAtivar(bool)
    setAtivar(objeto)
    setShowModalConfirmation(true)
  }

  const renderRow = (el, i) => (
    <li key={i}>
      <div>
        {isMobile && (<span>Objeto</span>)}
        <p>{el.objeto}</p>
      </div>
      <div>
        {isMobile && (<span>Motivo</span>)}
        <p>{el.motivo}</p>
      </div>
      <div>
        {isMobile && (<span>Ativo</span>)}
        {el.ativo ? (<p>Sim</p>) : <p>Não</p>}
      </div>
      <div className="action">
        {el.ativo ?
          (<button type="button" onClick={() => onTrashClick(el, false)}>
            <i className="fa-sharp fa-solid fa-power-off"></i>
          </button>) :
          (<button type="button" style={{color: "green"}} onClick={() => onTrashClick(el, true)}>
            <i className="fa-sharp fa-solid fa-power-off"></i>
          </button>
        )}
      </div>
    </li>
  )

  const renderRowList = () => {
    let newArray = [...blacklist]

    return newArray.map((el, index) => renderRow(el, index))
  }

  const renderList = () => (
    <StyledList>
      {!isMobile && (
        <li className="header-content">
          <div><span>Objeto</span></div>
          <div><span>Motivo</span></div>
          <div><span>Ativo</span></div>
          <div><span>Ação</span></div>
        </li>
      )}
      {renderRowList()}
    </StyledList>
  )

  const handleSearch = async () => {
    let formValues = {
      ...defaultValues,
      objeto: form.searchFor.toLowerCase(),
    }

    let req = await getResearchList(formValues)
    setBlacklist(req.blacklist)
    setPagination(req.paginacao)
  }

  const renderTop = () => (
    <StyledTopContent>
      <div>
        <button className="button" onClick={() => setShowModal(true)}>
          <i className="fa-solid fa-plus" />
          Novo Black List
        </button>
      </div>

      <div>
        <InputText
          label="Informar o Objeto para busca"
          value={form?.searchFor || ''}
          name="searchFor"
          onChange={onChangeForm}
        />
        <button
          className="searchButton"
          onClick={handleSearch}
        >
          <i className="fa-solid fa-magnifying-glass" />
        </button>
      </div>
    </StyledTopContent>
  )

  const renderModal = () => (
    <Modal
      closeModal={closeModal}
    >
      <InputText
        label="Objeto Placa/CPF"
        value={submitForm?.objeto || ''}
        name="objeto"
        onChange={onChangeSubmitForm}
      />
      <>
        <div>
          <span style={{color: "var(--color-primary)"}}>Motivo</span>
          <textarea
            value={submitForm?.motivo || ''}
            style={{marginTop: "10px", resize: "vertical"}}
            name="motivo"
            placeholder='Digite aqui...'
            onChange={onChangeSubmitForm}
          />
        </div>
      </>
      <button className="button" onClick={handleSubmit}>
        Adicionar no Black List
      </button>
    </Modal>
  )

  const handleDelete = async() => {
    const params = getAllParams()

    let newSubmitForm = {
      ...ativar,
      usuario: params.user
    }
    
    let req = await reqSubmitChangeBlacklist(newSubmitForm)
    
    if(req.status.active) {
      setShowInfoModal(`Objeto atualizado na blacklist com sucesso!`)
      closeModal()
      getResearchList(defaultValues)
      renderRowList()
    } else {
      setShowInfoModal('Erro ao atualizar na blacklist, contate o administrador!')
    }
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => closeInfoModal()}
      msg={showInfoModal}
    />
  )

  const confirmationModal = () => (
    <Modal
      closeModal={closeModal}
    >
      <h4>Deseja realmente {boolAtivar ? "ativar" : "desativar"} no black list?</h4>
      <StyledButtonsModal>
        <button className="button cancel" onClick={closeModal}>Não</button>
        <button className="button" onClick={handleDelete}>Sim</button>
      </StyledButtonsModal>
    </Modal>
  )

  return (
    <StyledSection className='container'>
      {showModalConfirmation && confirmationModal()}
      {showInfoModal && renderInfoModal()}
      {showModal && renderModal()}
      <Acordeon
        title="Black List"
        icon="fa-solid fa-ban"
        showContent
      >
        {renderTop()}
        {renderList()}
        <Pagination
          params={pagination}
          requestPage={handleChangePage}
        />
      </Acordeon>
    </StyledSection>
  )
}

BlackList.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default BlackList
