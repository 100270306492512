import { formatterNumberOnly } from "@/utils/formatter"

const document = (form, list) => {
  let item = list
  if (!form?.document) {
    item = {
      ...list,
      status: true,
      document: true,
    }
  }

  return item
}

const docCountry = (form, list) => {
  let item = list
  if (!form?.docCountry) {
    item = {
      ...list,
      status: true,
      docCountry: true,
    }
  }

  return item
}

const phoneNumber = (form, list) => {
  let item = list
  if (!form?.phones[0] || formatterNumberOnly(form?.phones[0]).length < 10) {
    item = {
      ...item,
      status: true,
      phone: true,
    }
  }

  return item
}

const cep = (form, list) => {
  let item = list
  if (!form?.cep) {
    item = {
      ...item,
      status: true,
      cep: true,
    }
  }

  return item
}

const logradouro = (form, list) => {
  let item = list
  if (!form?.logradouro || form.logradouro.length < 1) {
    item = {
      ...item,
      status: true,
      logradouro: true,
    }
  }

  return item
}

const numero = (form, list) => {
  let item = list
  if (!form?.numero) {
    item = {
      ...item,
      status: true,
      numero: true,
    }
  }

  return item
}

const bairro = (form, list) => {
  let item = list
  if (!form?.bairro || form.bairro.length < 1) {
    item = {
      ...item,
      status: true,
      bairro: true,
    }
  }

  return item
}

const localidade = (form, list) => {
  let item = list
  if (!form?.localidade) {
    item = {
      ...item,
      status: true,
      localidade: true,
    }
  }
  
  return item
}

const uf = (form, list) => {
  let item = list
  if (!form?.uf) {
    item = {
      ...item,
      status: true,
      uf: true,
    }
  }

  return item
}

const companyName = (form, list) => {
  let item = list

  if (!form?.companyName || form.companyName.length < 5) {
    item = {
      ...item,
      status: true,
      companyName: true,
    }
  }

  return item
}

const fullAddress = (form, item) => {
  let list = item
  list = cep(form, list)
  list = logradouro(form, list)
  list = numero(form, list)
  list = bairro(form, list)
  list = localidade(form, list)
  list = uf(form, list)

  return list
}

const pfFields = (form, list, isNewSearch) => {
  if (!form?.name) {
    list = {
      ...list,
      status: true,
      name: true,
    }
  }

  if (!form?.birthdate) {
    list = {
      ...list,
      status: true,
      birthdate: true,
    }
  }

  if (!form?.motherName || form.motherName.length < 5) {
    list = {
      ...list,
      status: true,
      motherName: true,
    }
  }

  if (!form?.fatherName || form.fatherName.length < 5) {
    list = {
      ...list,
      status: true,
      fatherName: true,
    }
  }

  if (isNewSearch && !form?.userType) {
    list = {
      ...list,
      status: true,
      userType: true,
    }
  }

  if (!form?.rg || form?.rg?.length < 5) {
    list = {
      ...list,
      status: true,
      rg: true,
    }
  }

  if (!form?.ufIssue) {
    list = {
      ...list,
      status: true,
      ufIssue: true,
    }
  }

  return list
}

const cnhFields = (form, list) => {
  if (!form?.cnh || form?.cnh.length < 10) {
    list = {
      ...list,
      status: true,
      cnh: true,
    }
  }

  if (!form?.cnhRegister) {
    list = {
      ...list,
      status: true,
      cnhRegister: true,
    }
  }

  if (!form?.cnhDateExpire) {
    list = {
      ...list,
      status: true,
      cnhDateExpire: true,
    }
  }

  if (!form?.cnhCategory) {
    list = {
      ...list,
      status: true,
      cnhCategory: true,
    }
  }

  if (!form?.firstCnh) {
    list = {
      ...list,
      status: true,
      firstCnh: true,
    }
  }

  if (!form?.cityFromCnh) {
    list = {
      ...list,
      status: true,
      cityFromCnh: true,
    }
  }

  if (!form?.securityCode || form?.securityCode.length < 11) {
    list = {
      ...list,
      status: true,
      securityCode: true,
    }
  }

  if (!form?.renachCnh || form?.renachCnh.length < 11) {
    list = {
      ...list,
      status: true,
      renachCnh: true,
    }
  }

  return list
}

const carFields = (form, list, isBond) => {
  if (!form?.placa || form?.placa?.length < 7) {
    list = {
      ...list,
      status: true,
      placa: true,
    }
  }

  if (!form?.chassi || form?.chassi?.length < 5) {
    list = {
      ...list,
      status: true,
      chassi: true,
    }
  }

  if (!form?.marca) {
    list = {
      ...list,
      status: true,
      marca: true,
    }
  }

  if (!form?.modelo) {
    list = {
      ...list,
      status: true,
      modelo: true,
    }
  }

  if (!form?.anoFab) {
    list = {
      ...list,
      status: true,
      anoFab: true,
    }
  }

  if (!form?.anoModelo) {
    list = {
      ...list,
      status: true,
      anoModelo: true,
    }
  }

  if (!form?.carroceria) {
    list = {
      ...list,
      status: true,
      carroceria: true,
    }
  }

  if (!form?.renavam) {
    list = {
      ...list,
      status: true,
      renavam: true,
    }
  }

  if (!isBond && !form?.vinculo) {
    list = {
      ...list,
      status: true,
      vinculo: true,
    }
  }

  if (!form?.uf_vehicle) {
    list = {
      ...list,
      status: true,
      uf_vehicle: true,
    }
  }

  if (!form?.city) {
    list = {
      ...list,
      status: true,
      city: true,
    }
  }

  if (form?.rntrc && form?.rntrc?.length < 8) {
    list = {
      ...list,
      status: true,
      rntrc: true,
    }
  }

  return list
}

export const validatePFFields = (form) => {
  let list = {status: false}

  list = pfFields(form, list)
  list = phoneNumber(form, list)

  return list
}

export const validatePFAddressFields = (form) => {
  let list = {status: false}

  list = fullAddress(form, list)

  return list
}

export const validateCNHFields = (form) => {
  let list = {status: false}

  list = cnhFields(form, list)
  return list
}

export const validateCarFields = (form) => {
  let list = {status: false}

  list = carFields(form, list)

  return list
}

export const validateOwnerFields = (form) => {
  let list = {status: false}

  list = document(form, list)
  list = companyName(form, list)
  list = fullAddress(form, list)
  list = docCountry(form, list)

  if (form?.phones) {
    list = phoneNumber(form, list)
  }

  return list
}

export const validateCompanyFields = (form) => {
  let list = {status: false}

  if (!form?.cnpj) {
    list = {
      ...list,
      status: true,
      cnpj: true,
    }
  }

  if (!form?.companyType) {
    list = {
      ...list,
      status: true,
      companyType: true,
    }
  }

  list = companyName(form, list)
  list = fullAddress(form, list)

  return list
}

export const validateFinanceFields = (form, isIntegrated, both) => {
  let list = {status: false}

  if (!form?.integratedValue && isIntegrated) {
    list = {
      ...list,
      status: true,
      integratedValue: true,
    }
  }

  if (!form?.driver && (!isIntegrated || both)) {
    list = {
      ...list,
      status: true,
      driver: true,
    }
  }

  if (!form?.truck && (!isIntegrated || both)) {
    list = {
      ...list,
      status: true,
      truck: true,
    }
  }

  if (!form?.owner && (!isIntegrated || both)) {
    list = {
      ...list,
      status: true,
      owner: true,
    }
  }

  if (!form?.driverAssistant) {
    list = {
      ...list,
      status: true,
      driverAssistant: true,
    }
  }

  if (!form?.consult) {
    list = {
      ...list,
      status: true,
      consult: true,
    }
  }

  if (!form?.billDay) {
    list = {
      ...list,
      status: true,
      billDay: true,
    }
  }

  return list
}

export const validateUserFields = (form) => {
  let list = {status: false}

  if (!form?.fullName) {
    list = {
      ...list,
      status: true,
      fullName: true,
    }
  }

  if (!form?.email) {
    list = {
      ...list,
      status: true,
      email: true,
    }
  }

  if (!form?.userLogin) {
    list = {
      ...list,
      status: true,
      userLogin: true,
    }
  }

  if (!form?.psw) {
    list = {
      ...list,
      status: true,
      psw: true,
    }
  }

  if (!form?.pswConfirm) {
    list = {
      ...list,
      status: true,
      pswConfirm: true,
    }
  }

  if (form?.psw !== form?.pswConfirm) {
    list = {
      ...list,
      status: true,
      pswConfirm: true,
      psw: true,
    }
  }

  return list
}

export const validateShipperFields = (form) => {
  let list = {status: false}

  if (!form?.shipperId) {
    list = {
      ...list,
      status: true,
      shipperId: true,
    }
  }

  if (!form?.integrationCode) {
    list = {
      ...list,
      status: true,
      integrationCode: true,
    }
  }

  return list
}

export const validateCompanyData = (form, isHelper, isNewSearch) => {
  let list = {status: false}

  if (!form?.cpf) {
    list = {
      ...list,
      status: true,
      cpf: true,
    }
  }

  if (!form?.vinculo) {
    list = {
      ...list,
      status: true,
      vinculo: true,
    }
  }

  list = pfFields(form, list, isNewSearch)
  list = fullAddress(form, list)
  if (!isHelper) {
    list = cnhFields(form, list)
  }
  list = phoneNumber(form, list)

  return list
}

export const validateCarListData = (form, isBond) => {
  let list = {status: false}

  list = carFields(form, list, isBond)
  list = document(form, list)
  list = companyName(form, list)
  list = fullAddress(form, list)
  list = phoneNumber(form, list)

  return list
}

export const validateManualSolicitation = (form) => {
  let bool = false

  if (form?.pessoa.pessoa_id && !form?.pessoa?.status) {
    return bool = true
  }

  if (form?.pessoa.pessoa_id && form?.pessoa.status === '3' && !form.pessoa?.prazo) {
    bool = true
  }
  
  if (form?.pessoa.pessoa_id && form?.pessoa.status === '3' && (form.pessoa?.prazoBoolean === true)) {
    bool = true
  }

  if (form?.pessoa.pessoa_id && form?.pessoa.status === '4' && !form.pessoa.motivo) {
    bool = true
  }

  form?.veiculos && form?.veiculos.map((el) => {
    if (!el.status) return bool = true
  })

  form?.veiculos && form?.veiculos.map((el) => {
    if (el.status === '3' && !el.prazo) {
      bool = true
    }
  
    if (el.status === '3' && (el.prazoBoolean === true)) {
      bool = true
    }

    if (el.status === '4' && !el.motivo) {
      bool = true
    }
  })

  return bool
}

export const validateSolicitation = (form) => {
  let bool = false

  if (form?.pessoa.pessoa_id && !form?.pessoa?.validade) {
    bool = true
  }

  if (form?.pessoa.pessoa_id && !form?.pessoa?.pontos) {
    bool = true
  }

  form?.veiculos && form?.veiculos.map((el) => {
    if (!el.licenciamento) {
      bool = true
    }

    if (!el.rntrc) {
      bool = true
    }

    if (el.restricao_adm === 'S' && !el.restricao_detalhe) {
      bool = true
    }
  })

  return bool
}
