import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  StyledCard,
  StyledFieldset,
} from './styles'
import { formatterMoney } from '@/utils/formatter'

const AnalysisProfileCard = (props) => {
  const {
    onEdit,
    onSelectProfile,
    profileList
  } = props

  const [filteredList, setFilteredList] = useState(null)

  const setupList = useCallback((data) => {
    let perfis = []
    data.map((e) => {
      const artigosPreparation = [
        {artigo: e?.art1}, {artigo: e?.art2}, {artigo: e?.art3}, {artigo: e?.art4},
        {artigo: e?.art5}, {artigo: e?.art6}, {artigo: e?.art7}, {artigo: e?.art8},
        {artigo: e?.art9}, {artigo: e?.art10}, {artigo: e?.art11}, {artigo: e?.art12},
        {artigo: e?.art13}, {artigo: e?.art14}, {artigo: e?.art15}, {artigo: e?.art16},
        {artigo: e?.art17}, {artigo: e?.art18}, {artigo: e?.art19}, {artigo: e?.art20},
        {artigo: e?.art21}, {artigo: e?.art22}, {artigo: e?.art23}, {artigo: e?.art24},
        {artigo: e?.art25}
      ]

      const artigos = artigosPreparation.filter((e) => e.artigo)

      const perfil = {
        cnh_ignorar_pontos: e.cnh_ignorar_pontos,
        cnh_pontos: e.cnh_pontos,
        fin_cheque: e.fin_cheque,
        fin_valor: e.fin_valor,
        id: e.id,
        nome: e.nome,
        tempo_agregado: e.tempo_agregado,
        tempo_frota: e.tempo_frota,
        tempo_terceiro: e.tempo_terceiro,
        pesquisaFincanceira: e.pesquisaFincanceira,
        pesquisaFincanceiraPJ: e.pesquisaFincanceiraPJ,
        validar_terceiros: e.validar_terceiros,
        terceiro_por_consulta: e.terceiro_por_consulta,
        validar_ciot: e.validar_ciot,
        validar_antt: e.validar_antt,
        validar_cpf_status: e.validar_cpf_status,
        annt_dias: e.antt_dias,
        artigos
      }

      perfis.push(perfil)
    })

    setFilteredList(perfis)
  }, [])

  useEffect(() => {
    setupList(profileList)
  }, [setupList, profileList])

  const onEditClick = (info) => {
    onEdit(info)
  }

  const renderArticles = (item, i) => {
    if (!item.artigo) return

    return (
      <li key={i}>
        {`Art. ${item.artigo}`}
      </li>
    )
  }

  const renderCard = (info, i) => (
    <StyledCard key={i}>
      <div className='content-head'>
        <div className='textHead'>
          <p className='strong' title={info.nome}>
            <i className="far fa-user" />
            {info.nome}
          </p>
          <p className='strong' title={info.fin_cheque}>
            <i className="fas fa-money-check-alt" />
            {info.fin_cheque} Cheques
          </p>
        </div>
        {onEdit && (
          <div className='buttons'>
            <div className='pencil' onClick={() => onEditClick(info)}>
              <i className="fas fa-pencil-alt" />
            </div>
          </div>
        )}
        {onSelectProfile && (
          <button
            className="button"
            type="button"
            onClick={() => onSelectProfile(info)}
          >Vincular Este</button>
        )}
      </div>
      <div className='contentInfo'>
        <div>
          <p><span>Valor Acima de?</span> R$ {formatterMoney(info.fin_valor)}</p>
          <p><span>Tempo de Terceiro:</span> {info.tempo_terceiro}</p>
          <p><span>Tempo Agregado:</span> {info.tempo_agregado}</p>
          <p><span>Tempo de Frota:</span> {info.tempo_frota}</p>
          <p><span>Pontos máximos da CNH:</span> {info.cnh_pontos}</p>
          <p>
            <span>Ignorar pontos CNH?</span>
            {info.cnh_ignorar_pontos === 'S' ? 'Sim' : 'Não'}
          </p>
          <p>
            <span>Motorista: Realizar pesquisa financeira?</span>
            {info.pesquisaFincanceira === 'S' ? 'Sim' : 'Não'}
          </p>
          <p>
            <span>Proprietario: Realizar pesquisa financeira?</span>
            {info.pesquisaFincanceiraPJ === 'S' ? 'Sim' : 'Não'}
          </p>
        </div>
        <StyledFieldset>
          <legend>Artigos relacionados</legend>
          <ul>
            {info.artigos.map((item, i) => renderArticles(item, i))}
          </ul>
        </StyledFieldset>
      </div>
    </StyledCard>
  )

  return (
    <>
      {filteredList ? filteredList.map((el, i) => renderCard(el, i)) : (
        <h4>
          Nenhum Perfil Encontrado
          <i className="fas fa-users-slash" />
        </h4>
      )}
    </>
  )
}

AnalysisProfileCard.propTypes = {
  onEdit: PropTypes.func,
  onSelectProfile: PropTypes.func,
  profileList: PropTypes.arrayOf(PropTypes.object).isRequired
}

AnalysisProfileCard.defaultProps = {
  onEdit: null,
  onSelectProfile: null
}

export default AnalysisProfileCard
