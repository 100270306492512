import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import SelectText from '@/views/fragments/form/SelectText'
import InputText from '@/views/fragments/form/InputText'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { MAX_LENGTH } from '@/utils/minMaxLength'
import {
  StyledForm,
  StyledPhoneContainer,
  StyledPhoneField,
  StyledAddPhone
} from '@/views/Register/fragments/styles'
import { formatterPhone } from '@/utils/formatter'
import { getLicensePlate, getVehicles, getVehiclesType, reqLocalidade } from '@/constants/requests'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'

const RegisterCarInputs = (props) => {
  const {
    formValues,
    validateList,
    handleFormValues,
    handleValidateList,
    defaultOptions,
    hasPlate,
    showcase,
    brandHasChanged,
    isTruck,
    isBond
  } = props
  const [stateList, setStateList] = useState([])
  const [carList, setCarList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [cityList, setCityList] = useState([])
  const [modelList, setModelList] = useState([])
  const [loadingCar, setLoadingCar] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(null)

  const sortList = useCallback((data) => {
    data.sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }
      if (a.label < b.label) {
        return -1
      }

      return 0
    })

    return data
  }, [])

  const handleModelList = async(value) => {    
    const list = []
    const req = await getVehiclesType({ marca: value})
    setLoadingCar(false)
    if (req.status.active) {
      req.modelos.map((el) => {
        const model = {
          label: el.modelo,
          value: el.codigo_modelo,
          tipo: el.tipo_veiculo
        }

        list.push(model)
      })
    }

    setModelList(list)
  }

  const handleCarList = async(value) => {
    const list = []
    const req = await getVehiclesType({ tipo: value})
    if (req?.status?.active) {
      req.marcas.map((el) => {
        const model = {
          label: el.marca,
          value: el.codigo_marca,
          tipo: el.tipo_veiculo
        }

        list.push(model)
      })
    }

    setCarList(list)
  }

  const setupModel = useCallback(async (name, value, form) => {
    setLoadingCar(true)

    handleCarList(value)

    setLoadingCar(false)
    handleFormValues({
      ...form,
      [name]: value,
    })
    setLoadingCar(false)
    handleValidateList({
      ...validateList,
      [name]: false,
    })
    brandHasChanged && brandHasChanged()
  }, [])

  async function getLocalidade(estado = null) {
    let localidade = await reqLocalidade({state: estado})
    if(!localidade.status.active) {
      setShowInfoModal(localidade.status.mensagem)
    }
    return localidade
  }

  const setupStatesList = useCallback(async() => {
    setLoadingCity(true)
    const list = await getLocalidade()
    setStateList(list.state)
    setLoadingCity(false)
  }, [])

  const setupCarList = useCallback(async() => {
    setLoadingCar(true)
    const req = await getVehicles()
    handleCity()

    setLoadingCar(false)
    if (isTruck) {
      setupModel('Carga', 4, formValues)
      setTypeList([{value: 4, label: 'Carga'}])
      return
    }
    req.tipos = req.tipos.filter((item) => item.value !== 4)    

    setTypeList(req.tipos)
  }, [sortList])

  const handleLicensePlate = useCallback(async () => {    
    if(formValues?.placa === null || formValues?.placa === undefined) return
    const placa = await getLicensePlate({ placa: formValues.placa })

    if(!placa?.active) {
      setShowInfoModal(placa.mensagem)
      return
    }
    
    handleModelList(placa.veiculo.marca)
    handleCarList(placa.veiculo.tipo_veiculo)
    
    if (placa.status.active) {
      const list = await getLocalidade(placa.veiculo.estado)
      setCityList(list.city)
      hasPlate(placa)
    }
  }, [])

  useEffect(() => {
    setupStatesList()
    setupCarList()
    handleLicensePlate()
  }, [setupStatesList, setupCarList, handleLicensePlate])

  const onChange = (e, ml) => {
    const { name, value } = e.target
    if (value.length > ml) return

    let newValue = value.replace(String.fromCharCode(9), " ");

    handleFormValues({
      ...formValues,
      [name]: newValue,
    })

    handleValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const onChangeUF = async (e) => {
    setLoadingCity(true)
    const { name, value } = e.target
    const list = await getLocalidade(value)
    setCityList(list.city)

    let newValue = value.replace(String.fromCharCode(9), " ");

    handleFormValues({
      ...formValues,
      [name]: newValue,
    })
    setLoadingCity(false)
    handleValidateList({
      ...validateList,
      [name]: false,
    })
  }
  const onChangeCarType = async (e) => {
    const { name, value } = e.target
    
    let newValue = value.replace(String.fromCharCode(9), " ");

    setupModel(name, newValue, formValues)
  }

  const onChangeCar = async (e) => {
    const { name, value } = e.target
    if (value === 'nulo') {
      handleFormValues({
        ...formValues,
        [name]: '',
      })
      return
    }
    setLoadingCar(true)

    let newValue = value.replace(String.fromCharCode(9), " ");
    
    handleModelList(newValue)
    
    handleFormValues({
      ...formValues,
      [name]: newValue,
    })
    handleValidateList({
      ...validateList,
      [name]: false,
    })
    brandHasChanged && brandHasChanged()
  }

  const onChangeModel = async (e) => {
    const { name, value } = e.target
    if (value === 'nulo') {
      handleFormValues({
        ...formValues,
        [name]: '',
      })
      return
    }

    setLoadingCar(true)

    let newValue = value.replace(String.fromCharCode(9), " ");

    handleFormValues({
      ...formValues,
      [name]: newValue,
    })
    setLoadingCar(false)
    handleValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const removePhoneClick = (i) => {
    const newArray = [...formValues.phones]
    newArray.splice(i, 1)

    handleFormValues({
      ...formValues,
      phones: newArray,
    })
  }

  const onChangePhone = (e, i, ml) => {
    const { value } = e.target
    const newArray = [...formValues.phones]

    if (value.length > ml) return
    newArray[i] = value

    handleFormValues({
      ...formValues,
      phones: newArray,
    })

    handleValidateList({
      ...validateList,
      phone: false,
    })
  }

  const renderPhones = (phone, index) => (
    <StyledPhoneField
      key={index}
      className={`phone ${formValues.phones.length > 1 ? 'hasTrash' : ''}`}
    >
      <InputText
        label={`Telefone ${index + 1}`}
        name="phone"
        isRequired={index === 0}
        isError={index === 0 ? validateList.phone : false}
        hint={index === 0 ? REQUIRED_FIELD : null}
        value={formatterPhone(phone) || ''}
        onChange={(e) => onChangePhone(e, index, MAX_LENGTH.quinze)}
      />
      {formValues.phones.length > 1 && (
        <i className="far fa-trash-alt" onClick={() => removePhoneClick(index)}/>
      )}
    </StyledPhoneField>
  )

  const onAddPhoneClick = (e) => {
    e.preventDefault()
    const newArray = [...formValues.phones]
    newArray.push('')

    handleFormValues({
      ...formValues,
      phones: newArray,
    })
  }

  const handleBlurLicensePlate = async (e) => {
    if (!e.target.value) return
    
    const placa = await getLicensePlate({ placa: e.target.value })

    if(!placa?.status.active) {
      setShowInfoModal(placa.mensagem)
      return
    }
    
    handleModelList(placa.veiculo.marca)
    handleCarList(placa.veiculo.tipo_veiculo)

    if (placa.status.active) {
      const list = await getLocalidade(placa.veiculo.estado)
      setCityList(list.city)
      hasPlate(placa)
    }
  }

  const handleCity = async () => {
    if(cityList.length) return
    
    const list = await getLocalidade(formValues.uf_vehicle)
    setCityList(list.city)    
  }

  const setupOptions = (year) => {
    if (!year) return []
    return [{
      value: year,
      label: year
    }, {
      value: Number(year) - 1,
      label: Number(year) - 1
    }]
  }

  const closeInfoModal = () => {
    setShowInfoModal(null)
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={closeInfoModal}
      msg={showInfoModal}
    />
  )


  return (
    <>
      <StyledForm className='veiculo'>
        {showInfoModal && renderInfoModal()}
        <InputText
          label="Placa"
          name="placa"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.placa}
          placeholder="Mínimo de 7 caracteres"
          value={formValues.placa || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.sete)}
          onBlur={handleBlurLicensePlate}
        />

        <InputText
          label="Chassi"
          name="chassi"
          isRequired
          placeholder="Mínimo de 5 caracteres"
          hint={REQUIRED_FIELD}
          isError={validateList.chassi}
          value={formValues.chassi || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.dezessete)}
        />
        <SelectText
          label="Tipo"
          name="tipo_veiculo"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.tipo_veiculo}
          options={typeList}
          value={formValues.tipo_veiculo || ''}
          onChange={onChangeCarType}
          isLoading={loadingCar}
          showcase={showcase.tipo_veiculo}
          notEmptyOption={isTruck}
        />
        <SelectText
          label="Marca"
          name="marca"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.marca}
          options={carList}
          value={formValues.marca || ''}
          onChange={onChangeCar}
          isLoading={loadingCar}
          showcase={showcase.marca}
        />
        <SelectText
          label="Modelo"
          name="modelo"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.modelo}
          options={modelList}
          value={formValues.modelo || ''}
          onChange={onChangeModel}
          isLoading={loadingCar}
          showcase={showcase.modelo}
        />
        <InputText
          label="Ano Modelo"
          name="anoModelo"
          type="number"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.anoModelo}
          value={formValues.anoModelo || ''}
          onChange={onChange}
        />
        <SelectText
          label="Ano Fabricação"
          name="anoFab"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.anoFab}
          options={setupOptions(formValues.anoModelo)}
          value={formValues.anoFab || ''}
          onChange={onChange}
          showcase={showcase.anoFab}
        />
        <SelectText
          label="Carroceria"
          name="carroceria"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.carroceria}
          options={defaultOptions?.carrocerias}
          value={formValues.carroceria || ''}
          onChange={onChange}
        />
        <InputText
          label="Cor"
          name="cor"
          value={formValues.cor || ''}
          onChange={onChange}
        />
        <InputText
          label="Renavam"
          name="renavam"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.renavam}
          value={formValues.renavam || ''}
          onChange={onChange}
        />
        <SelectText
          label="Vínculo"
          name="vinculo"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.vinculo}
          options={defaultOptions?.tipos_vinculo}
          value={isBond ? 'F' : (formValues.vinculo || '')}
          onChange={onChange}
          isDisabled={isBond}
        />
        <SelectText
          label="Estado"
          name="uf_vehicle"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.uf_vehicle}
          options={stateList}
          value={formValues.uf_vehicle || ''}
          onChange={onChangeUF}
          isLoading={loadingCity}
        />
        <SelectText
          label="Cidade"
          name="city"
          isRequired
          hint={REQUIRED_FIELD}
          isError={validateList.city}
          options={cityList}
          value={formValues.city || ''}
          onChange={onChange}
          isLoading={loadingCity}
        />
        {((formValues.tipo_veiculo === '3' || formValues.tipo_veiculo === 3) || isTruck) && (
          <InputText
            label="RNTRC - ANTT"
            name="rntrc"
            hint={REQUIRED_FIELD}
            isError={validateList.rntrc}
            placeholder="Mínimo de 8 caracteres"
            value={formValues.rntrc || ''}
            onChange={(e) => onChange(e, MAX_LENGTH.doze)}
          />
        )}
      </StyledForm>
      <StyledPhoneContainer>
        {formValues?.phones?.map((phone, index) => renderPhones(phone, index))}
      </StyledPhoneContainer>
      {formValues?.phones?.length < 3 && (
        <StyledAddPhone className="button" onClick={(e) => onAddPhoneClick(e)}>
          <i className="fas fa-mobile-alt" />
          <i className="fas fa-plus" />
        </StyledAddPhone>
      )}
    </>
  )
}

RegisterCarInputs.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  validateList: PropTypes.objectOf(PropTypes.any),
  handleFormValues: PropTypes.func,
  handleValidateList: PropTypes.func,
  defaultOptions: PropTypes.objectOf(PropTypes.array),
  hasPlate: PropTypes.func,
  showcase: PropTypes.any,
  brandHasChanged: PropTypes.func,
  isTruck: PropTypes.bool,
  isBond: PropTypes.bool
}

RegisterCarInputs.defaultProps = {
  validateList: {},
  handleFormValues: null,
  handleValidateList: null,
  defaultOptions: null,
  hasPlate: null,
  showcase: [],
  brandHasChanged: null,
  isTruck: false,
  isBond: false
}

export default RegisterCarInputs
