export const SHIPPER_LIST = [
  {
    shipper: 'DANIEL GARCIA NOZKI GESTAO DE RISCO',
    integrationCode: 'DANIEL GARCIA NOZKI GESTAO DE RISCO',
  }, {
    shipper: 'DOLLAR BRASIL REPRESENTACOES LTDA',
    integrationCode: 'DOLLAR BRASIL REPRESENTACOES LTDA',
  }, {
    shipper: 'PARMENES INFORMATION TECHNOLOGY LTDA',
    integrationCode: 'PARMENES INFORMATION TECHNOLOGY LTDA',
  }
]

export const PF_REGISTER_SEARCH = {
  address: {
    bairro: 'Parque das Nações',
    cep: '09210000',
    complemento: 'asdasd',
    localidade: 'Santo André',
    logradouro: 'Rua Suíça',
    numero: '123',
    uf: 'SP',
  },
  basicData: {
    birthdate: '1984-07-07',
    cityOfBirth: 'São Paulo',
    country: 'Brasil',
    cpf: '35357869839',
    email: 'jaciaugoli@gmail.com',
    fatherName: 'Jaci Augusto de Oliveira',
    issueDpto: 'SSP',
    maritalStatus: 'Casado(a)',
    motherName: 'Jaci Augusto de Oliveira',
    name: 'IVAN AUGUSTO QUEVEDO OLIVEIRA',
    phones: ['11983082169', '11983082169'],
    rg: '27.172.210-1',
    rgIssue: '1984-12-12',
    ufBirth: 'SP',
    ufIssue: 'BA',
    userGenre: 'Homem',
    userType: 'Motorista',
  },
  cnh: {
    asoDateEnd: '2000-04-04',
    cityFromCnh: 'ssp',
    cnh: '123123',
    cnhCategory: 'A + B',
    cnhDateExpire: '2000-01-01',
    cnhIssueDate: '2000-02-02',
    cnhRegister: '12321321321',
    emissorMopp: '321321321321321',
    firstCnh: '2000-03-03',
    psychoDateEnd: '2000-05-05',
    recycleDateEnd: '2000-06-06',
    registroMopp: '123123',
    renachCnh: '321321321321321',
    securityCode: '1232132132132132132',
  }
}

export const CAR_OWNER_SEARCH = {
  car: {
    anoFab: '2010-1',
    anoModelo: '2010-1',
    carroceria: 'Sei lá',
    chassi: 'ascbasd1231542436',
    city: '2900603',
    codSeguranca: '43221',
    country: 'Brasil',
    emissao: '2000-11-12',
    marca: 'Mercedes-Benz',
    modelo: 'c-200 Kompressor',
    placa: 'abc1234',
    renavam: '1234567890',
    rntrc: '123542542',
    transferencia: '2001-12-13',
    uf: 'BA',
  },
  owner: {
    bairro: 'Pacaembu',
    cep: '01234010',
    companyName: 'Leroy Merlin',
    complemento: 'complemento aqui',
    docCountry: 'Brasil',
    document: '30861259858',
    localidade: 'São Paulo',
    logradouro: 'Praça Charles Miller',
    numero: '124',
    phones: ['11983082169', '(11) 2345-67890'],
    uf: 'SP',
  }
}


export const TRANSPORTADOR = {
  company: {
    bairro: 'Pacaembu',
    cep: '01234010',
    cm: true,
    cnpj: '12345678901233',
    companyName: 'siemens',
    complemento: 'compl',
    localidade: 'São Paulo',
    logradouro: 'Praça Charles Miller',
    numero: '321',
    pais: 'Brasil',
    registerStatus: true,
    uf: 'SP',
  },
  // finance: {
  //   billDay: '12',
  //   bulkTrailer: '12,34',
  //   driver: '1,23',
  //   driverAssistant: '234,55',
  //   integratedValue: '',
  //   isIntegrated: false,
  //   owner: '123,45',
  //   premiumSearch: '567,56',
  //   rdo: '345,65',
  //   truck: '12,31',
  // },
  finance: {
    billDay: '23',
    driverAssistant: '76,54',
    integratedValue: '87,65',
    isIntegrated: true,
    premiumSearch: '54,32',
    rdo: '65,43',
  },
  shipper: [{
    shipper: 'Matriz - Belo Horizonte',
    integrationCode: '123534554',
  }, {
    shipper: 'Centro de custos de Santando Paranaíba',
    integrationCode: '345676756',
  }],
  user: [{
    active: true,
    email: 'ivan@msn.com',
    fullName: 'ivan',
    psw: '123',
    pswConfirm: '123',
    userLogin: 'login',
  }, {
    active: false,
    email: 'ata@msn.com',
    fullName: 'ata',
    psw: '321',
    pswConfirm: '321',
    userLogin: 'atal',
    webservice: true,
  }]
}

export const ANALYSIS_PROFILE_MOCK = [{
  nome: 'Ivan Oliveira',
  fin_cheque: '12',
  cnh_ignorar_pontos: false,
  fin_valor: '123.456,78',
  cnh_pontos: '34',
  tempo_frota: '1',
  tempo_agregado: '2',
  tempo_terceiro: '3',
  artigos: [{
    artigo: "1",
  },
  {
    artigo: "2",
  },
  {
    artigo: "3",
  },
  {
    artigo: "4",
  },
  {
    artigo: "5",
  },
  {
    artigo: "6",
  },
  {
    artigo: "7",
  },
  {
    artigo: "8",
  },
  {
    artigo: "9",
  },
  {
    artigo: "10",
  },
  {
    artigo: "11",
  },
  {
    artigo: "12",
  },
  {
    artigo: "13",
  }]}, {
  nome: 'Edson Arantes',
  fin_cheque: '3',
  cnh_ignorar_pontos: true,
  fin_valor: '4.456,78',
  cnh_pontos: '34',
  tempo_frota: '11',
  tempo_agregado: '12',
  tempo_terceiro: '13',
  artigos: [{
    artigo: "6",
  }]}, {
  nome: 'Patricia Abravanel',
  fin_cheque: '5',
  cnh_ignorar_pontos: true,
  fin_valor: '54.111,00',
  cnh_pontos: '21',
  tempo_frota: '21',
  tempo_agregado: '22',
  tempo_terceiro: '23',
  artigos: [{
    artigo: "1",
  },
  {
    artigo: "2",
  },
  {
    artigo: "3",
  }]}
]
