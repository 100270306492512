import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import RegisterPFForm from './RegisterPFForm'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { validatePFFields } from '@/validators/registerValidations'
import { StyledFormButton } from '@/views/Register/fragments/stylesRegisterPf'
import { getOptions } from '@/constants/requests'
import { setupOptionsList } from '@/constants/setupOptions'

const RegisterPF = (props) => {
  const {
    basicData,
    onSave,
    showSaveLabel,
  } = props

  const [formValues, setFormValues] = useState({})
  const [validateList, setValidateList] = useState({})
  const [cpfValidate, setCpfValidate] = useState({})
  const [validateEmail, setValidateEmail] = useState(false)
  const [defaultOptions, setDefaultOptions] = useState(null)

  const setupOptions = useCallback(async () => {
    const options = await getOptions()
    const list = setupOptionsList(options)

    if (options.status.active) {
      setDefaultOptions(list)
    }
  }, [])

  useEffect(() => {
    setupOptions()
  }, [])

  useEffect(() => {
    setFormValues(basicData)
  },[basicData])

  useEffect(() => {
    const url = window.location.pathname
    const doc = url.split('/pessoa-fisica-cadastro/')
    if (doc[1]) {
      setFormValues({
        ...formValues,
        cpf: doc[1],
      })
    }
  }, [])

  const onContinueClick = () => {
    const list = validatePFFields(formValues)

    if (validateEmail) return

    if (!formValues.cpf || cpfValidate.status) {
      setCpfValidate({
        msg: REQUIRED_FIELD,
        status: true,
      })
      return
    }

    if (list.status) {
      setValidateList(list)
      return
    }

    onSave(formValues)
  }

  const onChangeValues = (data) => {
    setFormValues(data)
  }

  const onCpfValidate = (data) => {
    setCpfValidate(data)
  }

  const onValidateEmail = (data) => {
    setValidateEmail(data)
  }

  const onValidateList = (data) => {
    setValidateList(data)
  }

  return (
    <>
      <RegisterPFForm
        validateList={validateList}
        formValues={formValues}
        onChangeValues={onChangeValues}
        onCpfValidate={onCpfValidate}
        onValidateEmail={onValidateEmail}
        onValidateList={onValidateList}
        cpfValidate={cpfValidate}
        validateEmail={validateEmail}
        defaultOptions={defaultOptions}
      />
      <StyledFormButton className="button" onClick={onContinueClick}>
        <i className="fas fa-angle-double-right" />
        {showSaveLabel ? 'Salvar' : 'Continuar'}
      </StyledFormButton>
    </>
  )
}

RegisterPF.propTypes = {
  basicData: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func.isRequired,
  showSaveLabel: PropTypes.bool,
}

RegisterPF.defaultProps = {
  basicData: {},
  showSaveLabel: false,
}

export default RegisterPF
