import styled from '@emotion/styled'

export const StyledSection = styled.section`
  max-width: 1000px;
`

export const StyledContainer = styled.div`
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 45% 10% 45%;
  grid-template-rows: 450px;

  & > div {
    padding: 10px;
    text-align: center;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    &:first-of-type {
      &:after {
        content:'';
        display:block;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        position: absolute;
        width: calc(100% - 30px);
        height: 40px;
        left: 0;
        bottom: 0;
        pointer-events: none;
      }
    }

    &:nth-of-type(2) {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: var(--color-secondary);
        font-size: 20px;
      }
    }
  }
`

export const BoxStyled = styled.ul`
  text-align: center;
  width: 100%;
  overflow: auto;
  height: 318px;
  padding-bottom: 40px;

  li {
    background-color: var(--color-primary);
    padding: 10px 10px;
    display: inline-block;
    margin: 0 8px 8px 0;
    color: var(--color-light);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    i {
      margin-left: 5px;
      vertical-align: middle;
    }

    @media (min-width: 820px) {
      &:hover {
        background-color: var(--color-secondary);

        i {
          color: var(--color-alert)
        }
      }
    }
  }

  @media (min-width: 480px) {
    height: 334px;
  }
`

export const StyledLabel = styled.h4`
  color: var(--color-secondary);
  margin-bottom: 15px;
`

export const InputsStyleCustom = styled.div`
  @media (min-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 7.5px));
    grid-gap: 0 15px;
  }
`

export const StyledAnttField = styled.div`
  width: 100%;
  display: block;;
`
