import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import RegisterOwnerInputs from './RegisterOwnerInputs'
import { validateOwnerFields } from '@/validators/registerValidations'
import {
  StyledFormButton,
} from '@/views/Register/fragments/styles'

const RegisterOwnerForm = (props) => {
  const { owner, onSave } = props
  const [formValues, setFormValues] = useState({})
  const [validateList, setValidateList] = useState({})

  useEffect(() => {
    setFormValues(owner)
  },[owner])

  const handleSave = () => {
    const list = validateOwnerFields(formValues)

    if (list.status) {
      setValidateList(list)
      return
    }

    onSave(formValues)
  }

  const handleFormValues = (form) => {
    setFormValues(form)
  }

  const handleValidateList = (form) => {
    setValidateList(form)
  }

  return (
    <>
      <RegisterOwnerInputs
        handleFormValues={handleFormValues}
        handleValidateList={handleValidateList}
        validateList={validateList}
        formValues={formValues}
      />
      <StyledFormButton className="button" onClick={handleSave}>
        <i className="far fa-save" />
        Finalizar
      </StyledFormButton>
    </>
  )
}

RegisterOwnerForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  owner: PropTypes.objectOf(PropTypes.any),
}

RegisterOwnerForm.defaultProps = {
  owner: {},
}

export default RegisterOwnerForm
