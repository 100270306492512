import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  overflow: hidden;
  width: calc(100% - 20px);
  max-width: calc(100% - 40px);
  margin: 100px auto 30px;

  h2 {
    color: var(--color-light);
    padding: 0 10px;
    line-height: 60px;
    position: relative;
    background-color: var(--color-primary);

    & > div {
      display: inline-block;
      width: calc(100% - 120px);

      button {
        width: auto;
        border: 1px solid var(--color-light);
        margin-left: 30px;

        &.active {
          border: 2px solid var(--color-secondary);
          color: var(--color-secondary);
          background-color: var(--color-gray-light);
        }
      }
    }

    @media (max-width: 520px) {
      text-align: center;

      & > div {
        width: 100%;
        text-align: left;
        padding: 0 15px;

        button {
          margin: 0;

          &.active {
            box-shadow: 0 17px var(--color-secondary);
          }
        }
      }
    }
  }
`

export const StyledcontentBox = styled.div`
  padding: 10px;
`

export const StyledBox = styled.div`
  display: flex;

  @media (max-width: 520px) {
    flex-wrap: wrap;
  }

  &:nth-of-type(odd) {
    background-color: #d4e7f7;
  }

  border-right: 6px solid var(--color-gray-dark);

  div.status {
    p {
      font-weight: 700;
      font-size: 13px;
      color: var(--color-gray-dark);
    }
  }

  &.emanalise {
    border-right: 6px solid #ebae01;

    div.status {
      p {
        color: #ebae01;
      }
    }
  }

  &.cancelado, &.desacordo {
    border-right: 6px solid var(--color-alert);

    div.status {
      p {
        color: var(--color-alert);
      }
    }
  }

  &.emconformidade {
    border-right: 6px solid #008000;

    div.status {
      p {
        color: #008000;
      }
    }
  }

  &.liberadomanualmente {
    border-right: 6px solid #100091;

    div.status {
      p {
        color: #100091;
      }
    }
  }

  @media (min-width: 1200px) {
    border-right: 10px solid var(--color-gray-dark);

    &.cancelado, &.desacordo {
      border-right: 10px solid var(--color-alert);
    }

    &.emanalise {
      border-right: 10px solid #ebae01;
    }

    &.aprovado, &.emconformidade {
      border-right: 10px solid #008000;
    }
  }

  .fonte {
    margin-top: 3px;
    margin-right: 3px;
    padding-right: 3px;
    border-right: 2px solid lightgray;
  }

  .container-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 10px;

    & > div {
      position: relative;
      width: 100%;

      span {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        display: block;
        margin-bottom: 5px;
        color: var(--color-gray-dark);
      }

      &.row1, &.row2 {
        width: calc(50% - 5px);
      }

      &.row4 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        & > div {
          width: calc(50% - 5px);
          text-transform: uppercase;
        }
      }

      &.row3, &.row8, &.row9, &.row10, &.row11 {
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      p {
        margin: 0;

        i {
          position: absolute;
          right: 10px;
          font-size: 20px;
        }
      }
    }

    @media (min-width: 520px) {
      width: calc(100% - 70px);

      & > div {
        &.row4 {
          & > div {
            width: calc(25% - 7.5px);
          }
        }
      }
    }

    @media (min-width: 720px) {
      padding: 10px;
      & > div {
        &.row1, &.row2, &.row3{
          width: calc(33.333% - 6.666px);
        }

        &.row8, &.row9, &.row10, &.row12, &.row13 {
          width: calc(50% - 5px);
        }
      }
    }

    @media (min-width: 1024px) {
      & > div {
        &.row1 {
          width: 75px;
        }

        &.row13 {
          width: 20px;
        }
        
        &.row2, &.row12 {
          width: 170px;
        }

        &.row3 {
          width: calc(100% - 550px);
        }

        &.row4 {
          width: 360px;
        }

        &.row8, &.row9, &.row10 {
          width: calc(33.3333% - 50px);
        }

        &.row11 {
          width: 160px
        }
      }
    }

    @media (min-width: 1200px) {
      &.aguardando {
        border-right: 10px solid #ebae01;
      }

      &.cancelado {
        border-right: 10px solid var(--color-alert);
      }

      &.concluido {
        border-right: 10px solid #008f07;
      }
    }

    @media (min-width: 1366px) {
      flex-wrap: nowrap;

      & > div {
        &.row1 {
          width: 50px;
        }
        &.row2 {
          width: 85px;
        }
        &.row3, &.row8, &.row9, &.row10 {
          width: calc(25% - 216px);
        }
      }
    }
  }

  .buttons {
    width: 70px;
    padding-right: 10px;
    justify-content: center;
    display: flex;
    align-items: center;

    button {
      width: 60px;
      height: 30px;
      padding: 0;
      margin: 0;
    }

    @media (max-width: 520px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`

export const StyledFieldset = styled.fieldset`
  border: 1px solid;
  padding: 10px 20px;
  margin-top: 20px;
  position: relative;

  &:first-of-type {
    margin-top: 0
  }

  .content-veiculo {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    .first-step, .second-step {
      width: 100%;
    }

    .second-step {
      background-color: #d4e7f7;
      padding: 10px;
    }
  }
`

export const QttPerPage = styled.div`
  float: right;

  @media (min-width: 520px) {
    margin-top: 7px;
  }

  label {
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    span {
      font-size: 13px;
      color: var(--color-light) !important;
      display: block;
      width: 100%;

      @media (max-width: 400px) {
        display: none;
      }
    }
  }

  select {
    width: 50px;
    margin-left: 10px;
    text-align: center;
  }
`

export const FloatButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 3px;
  right: 12px;
  font-size: 20px;
  z-index: 10;
`

export const StyledCard = styled.div`
  background-color: var(--color-gray-light);
  padding: 10px;
  display: grid;
  grid-gap: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;

  & > div {
    span {
      display: block;

      &:first-of-type {
        font-weight: 700;
        font-size: 12px;
        color: var(--color-gray-dark);
      }
    }
  }

  @media (min-width: 620px) {
    grid-template-columns: repeat(2, calc(50% - 5px));
  }
`

export const StyledSolicitation = styled.div`
  width: 100%;

  h3 {
    color: var(--color-primary);
  }

  .vehicle {
    border: 1px solid var(--color-gray-dark);
    border-radius: 7px;
    padding: 10px;
    background-color: var(--color-gray-lighter);
    margin: 10px 0;
  }

  .topo {
    display: flex;
    width: 100%;
    align-items: center;

    & > div {
      width: 100%;
      padding: 10px;
      font-weight: 600;
      position: relative;

      span {
        display: block;
        font-weight: 800;
        margin-bottom: 5px;
        color: var(--color-primary);
      }

      &.is-ok, &.not-ok {
        i {
          margin-left: 10px;
          font-size: 20px;
          vertical-align: middle;
        }
      }

      &.is-ok {
        color: var(--color-success);
      }

      &.not-ok {
        color: var(--color-alert);
      }
    }

    button {
      margin: 0;
    }

    textarea{
      padding: 10px;
      font-size: 16px;
    }

    input[type="date"] {
      max-width: 160px;
      margin-top: 0;
    }
  }

  .imageContent {
    border: 2px dashed var(--color-primary);
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      font-weight: 800;
      margin-bottom: 5px;
      color: var(--color-primary);
      width: 100%;
    }

    div {
      width: 100%;
    }

    input[type="file"] {
      display: none;
    }

    label.file{
      width: auto;
      margin: 0;
    }

    img {
      max-height: 150px;
      margin: 0 auto;
      display: block;
    }

    i {
      font-size: 40px;
      color: var(--color-primary);
      padding: 30px;
      border: 1px dashed;
      display: block;
      margin: 0 auto;
      width: 102px;
      height: 102px;
    }
  }

  .title {
    color: var(--color-primary);
    font-weight: 600;
    position: relative;

    &.margin-top {
      margin-top: 20px;
    }

    span {
      font-weight: 800;
      margin-left: 10px;
    }

    i.fa-check, i.fa-times {
      color: var(--color-success);
      margin-left: 10px;
      font-size: 20px;
    }

    i.fa-times {
      color: var(--color-alert);
    }
  }


  .eyeButton {
    position: absolute !important;
    right: 10px;
    top: 0;

    i {
      cursor: pointer;
      font-size: 20px;
      color: var(--color-primary);
    }

    &.people {
      top: 7px;
      right: 30px;
    }
  }

  ul {
    padding-left: 10px;

    li {
      font-weight: 600;
      cursor: help;

      i {
        margin-right: 10px;
      }

      span {
        margin-left: 20px;
        font-weight: 800;
      }

      padding: 5px;
      margin-bottom: 2px;

      &.is-ok {
        span {
          color: var(--color-success);
        }
      }

      &.not-ok {
        color: var(--color-alert);
      }
    }
  }

  hr {
    background-color: var(--color-primary);
    height: 1px;
    border: none;
    margin: 20px 0;
  }
`

export const StyledDetailContent = styled.div`
  border: 2px solid var(--color-primary);
  padding: 20px;
  border-radius: 8px;
  margin-top: -2px;
  margin-bottom: 15px;

  a {
    display: flex;
    width: 150px;
    margin: 0 0 0 auto;
  }

  p {
    margin-bottom: 10px;

    span {
      font-weight: 600;
      display: inline-block;
      margin-right: 5px;
    }
  }
`

export const StyledButton = styled.div`
  &.active {
    padding-bottom: 0 !important;

    button {
      background-color: transparent;
      color: var(--color-primary);
      border: 2px solid;
      border-bottom: 2px solid var(--color-light);
      border-radius: 8px 8px 0 0;
    }
  }
`

export const StyledFilter = styled.div`
  padding: 20px 20px 0;
  display: grid;
  border: 2px solid var(--color-secondary);
  border-radius: 10px;
  width: calc(100% - 20px);
  margin: 10px 0 0 10px;
  gap: 20px;
  background-color: var(--color-gray-light);

  .active {
    border: 2px solid var(--color-secondary);
    color: var(--color-secondary);
    background-color: var(--color-gray-light);
  }

  .lessMargin {
    margin-bottom: 10px;
  }

  & > div {
    &:last-of-type {
      align-items: end;
      display: flex;
      padding-bottom: 20px;
      justify-content: end;
    }
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    color: var(--color-primary);
    margin-bottom: 10px;
  }

  button {
    margin: 0;
    width: 100px;
  }

  @media (min-width: 720px) {
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 20px;
  }

  @media (min-width: 1150px) {
    padding-bottom: 15px;
    grid-template-columns:
      calc(50% - 182px)
      200px
      calc(50% - 182px)
      100px;
    gap: 20px;

    & > div {
      &:nth-of-type(1), &:nth-of-type(3) {
        display: flex;
        gap: 20px;
      }

      &:last-of-type {
        align-items: normal;
        padding-top: 24px;
      }
    }
  }
`
