import axios from "axios"
import { RELOGIN } from "./parameters"
import { API } from "@/utils/paths"

export const fakeRequest = async (data) => {
  return await axios.post('fakeURLaqui', data)
}

const defaultGetConfig = async (url, needRelogin) => {
  const config = {
    method: 'get',
    headers: { 'Authorization': window.localStorage.getItem('authentication') },
    url,
  }

  return await axios(config)
    .then((data) => {
      if (needRelogin && data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const getLoginAccess = async (params) => {
  return await axios.post(API.LOGIN_REQUEST, params)
}

export const getFullArticles = async () => {
  const needRelogin = true
  return defaultGetConfig(API.CONSULT_ARTICLES, needRelogin)
}

export const getAnalysesProfiles = async () => {
  const needRelogin = true
  return defaultGetConfig(API.CONSULT_PROFILE, needRelogin)
}

export const postResearch = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.GET_RESEARCH, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const getPersistenceAuthentication = async () => {
  return defaultGetConfig(API.PERSISTENCE_LOGIN)
}

export const postAnalysisProfile = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.ANALYSIS_PROFILE, data, config)
}

export const putAnalysisProfile = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.put(API.ANALYSIS_PROFILE, data, config)
}

export const postConsultaCPF = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.CONSULT_CPF, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const postManualBlock = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.MANUAL_BLOCK, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const postConsult = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  let json = {
    ...data,
    cnpj_centro_custos: window.localStorage.getItem('cnpjCostCentre') 
  }

  return await axios.post(API.CONSULT_PLATE, json, config)
    .then((data) => {
      let result = data.data

      if (result.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return result
    })
    .catch((err) => {
      return err
    })
}

export const getOptions = async () => {
  const needRelogin = true
  return defaultGetConfig(API.GET_OPTIONS, needRelogin)
}

export const getLicensePlate = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.GET_LICENSE_PLATE, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const getVehicles = async () => {
  const needRelogin = true
  return defaultGetConfig(API.REQUEST_CAR_MODEL, needRelogin)
}

export const getVehiclesType = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.REQUEST_CAR_MODEL, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const requestOwner = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.CONSULT_OWNER, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewSearch = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.SUBMIT_NEW_SEARCH, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewUser = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_NEW_USER, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewBlacklist = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_NEW_BLACKLIST, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewMarca = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_NEW_MARCA, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewModel = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_NEW_MODEL, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetBlacklist = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.GET_BLACKLIST, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetManualBlock = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.MANUAL_BLOCK}&pagina=1&tipo_filtro=${param.tipo_filtro}&filtro=${param.filtro}` : `${API.MANUAL_BLOCK}&pagina=1`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetPrivileges = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_PRIVILEGES}&grupo=${param.grupo}` : `${API.GET_PRIVILEGES}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetBillDay = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_BILL_DAY}&dia_atual=${param.dia}` : `${API.GET_BILL_DAY}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetBillClients = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_BILL_CLIENTS}&empresas=${param.empresas}&data_inicio=${param.dataInicio}&data_final=${param.dataFinal}` : `${API.GET_BILL_CLIENTS}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetManagerialClients = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_MANAGERIAL_CLIENTS}&empresas=${param.empresas}&data_inicio=${param.dataInicio}&data_final=${param.dataFinal}` : `${API.GET_MANAGERIAL_CLIENTS}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetManagerialTable = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_MANAGERIAL_TABLE}&empresas=${param.empresas}&data_inicio=${param.dataInicio}&data_final=${param.dataFinal}&tipo=${param.tipo}` : `${API.GET_MANAGERIAL_TABLE}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetTermList = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_LIST_TERM}&empresa=${param.empresa}&data_inicio=${param.dataInicio}&data_final=${param.dataFinal}` : `${API.GET_LIST_TERM}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetTermTotal = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_TOTAL_TERM}&empresa=${param.empresa}&data_inicio=${param.dataInicio}&data_final=${param.dataFinal}` : `${API.GET_TOTAL_TERM}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetTermCompany = async () => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = `${API.GET_COMPANY_TERM}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetBillReport = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_REPORT}&empresa_bilhetada=${param.empresa_bilhetada}&inicial=${param.inicial}&final_data=${param.final}&excel=${param.excel}&tipo=${param.tipo}` : `${API.GET_REPORT}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetBillReportExcel = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  let url = param ? `${API.GET_REPORT_EXCEL}&empresa_bilhetada=${param.empresa_bilhetada}&inicial=${param.inicial}&final_data=${param.final}&excel=${param.excel}` : `${API.GET_REPORT_EXCEL}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqGetGroupPrivileges = async (param) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  let url = param ? `${API.GET_GROUP_PRIVILEGES}&cliente=${param.cliente}` : `${API.GET_GROUP_PRIVILEGES}`;
  return await axios.get(url, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqChangePrivileges = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.put(API.SUBMIT_CHANGE_PRIVILEGES, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const postGroupPrivileges = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.SUBMIT_GROUP_PRIVILEGES, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitNewEmbarcador = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_NEW_EMBARCADOR, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeUser = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_USER, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeBlacklist = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_BLACKLIST, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeModel = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_MODEL, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeMarca = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_MARCA, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqActiveNewUser = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_ACTIVE_USER, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeFinance = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_FINANCE, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeEmail = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_EMAIL, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeTransportador = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_TRANSPORTADOR, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitChangeEmbarcador = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_CHANGE_EMBARCADOR, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitDeleteEmbarcador = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_DELETE_EMBARCADOR, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitActiveBlacklist = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_ACTIVE_BLACKLIST, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitDeleteEmail = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }
  
  return await axios.post(API.SUBMIT_DELETE_EMAIL, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSubmitDeleteManualBlock = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') },
    data: data
  }
  
  return await axios.delete(API.MANUAL_BLOCK, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqManualSearch = async (data, isManual) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  let url = API.REQUEST_MANUAL_SEARCH

  if (isManual) {
    url = API.REQUEST_MANUAL_SOLICITATION
  }

  return await axios.post(url, data, config)
    .then((data) => {
      if (data?.data?.status?.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqLocalidade = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.REQUEST_LOCALIDADE, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const reqSolicitation = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.REQUEST_SOLICITATION, data, config)
    .then((data) => {
      if (data.data?.status?.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const putSolicitation = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.put(API.REQUEST_SOLICITATION, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const getCNPJInfo = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.CONSULT_EMPLOYEE, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const getEmbarcadores = async () => {
  const needRelogin = false
  return defaultGetConfig(API.REQUEST_CONSULTA_EMBARCADOR, needRelogin)
}

export const getMenuProfile = async () => {
  const needRelogin = false
  return defaultGetConfig(API.GET_MENU_PROFILE, needRelogin)
}

export const postTransportador = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.SUBMIT_TRANSPORTADOR, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}

export const postRegistered = async (data) => {
  const config = {
    headers: { 'Authorization': window.localStorage.getItem('authentication') }
  }

  return await axios.post(API.GET_REGISTERED, data, config)
    .then((data) => {
      if (data.data.status.codigo === 'w91') {
        return window.location = RELOGIN
      }

      return data.data
    })
    .catch((err) => {
      return err
    })
}
