import React, { useState, useEffect } from 'react'
import { StyledBox, StyledSection, CustomLoading } from './styles'
import { useSelector } from 'react-redux'
import SelectText from '../fragments/form/SelectText'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import DateSelector from '../fragments/form/DateSelector'
import {
  reqGetBillDay,
  reqGetManagerialClients,
  reqGetManagerialTable,
} from '@/constants/requests'
import ModalTable from './ModalTable'
import Modal from '../fragments/Modal'

const Managerial = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSpecific, setLoadingSpecific] = useState(true)
  const [initialData, setInitialData] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showTableModal, setShowTableModal] = useState(null)
  const [especifica, setEspecifica] = useState([])
  const [selectedEmpresa, setSelectedEmpresa] = useState(null)
  const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null })
  const [valoresModal, setValoresModal] = useState([])
  const [tipo, setTipo] = useState(null)

  const permission = useSelector(state => state.permission);

  useEffect(async () => {
    if (!permission?.managerialReport.view) {
      window.location.href = "/";
      return
    }

    await getBillDay()
    setLoading(false)
    setLoadingSpecific(false)

  }, [])

  // Formatar datas para consulta

  function formatarData(data, tipo) {
    var ano = data.getFullYear();
    var mes = ("0" + (data.getMonth() + 1)).slice(-2);
    var dia = ("0" + data.getDate()).slice(-2);
    var horas, minutos, segundos;
    if (tipo === 'inicio') {
      horas = ("0" + 0).slice(-2);
      minutos = ("0" + 0).slice(-2);
      segundos = ("0" + 0).slice(-2);
    } else {
      horas = ("0" + 23).slice(-2);
      minutos = ("0" + 59).slice(-2);
      segundos = ("0" + 59).slice(-2);        
    }
    return ano + "-" + mes + "-" + dia + " " + horas + ":" + minutos + ":" + segundos;
  }

  const dateForBillClient = (dates) => {
    let dataInicio, dataFinal;
    
    if(dates.endDate === null) {
      dataFinal = new Date()
    } else {
      dataFinal = dates.endDate
    }
    
    if(dates.startDate === null) {
      dataInicio = new Date()
    } else {
      dataInicio = dates.startDate
    }

    return {
      dataFinal: dataFinal,
      dataInicio: dataInicio
    }
  }

  const getBillDay = async () => {
    const today = new Date()
    let todayDay = today.getDate()
    let req = await reqGetBillDay({ dia: todayDay })

    let params = req?.empresas.map((el) => `${el.value}`).join(',');
    let object = {
      value: params,
      label: 'Todos'
    }

    req.empresas.unshift(object)

    let data = {
      today: req.today,
      yesterday: req.yesterday,
      week: req.week,
      empresas: req.empresas
    }

    setInitialData(data);
  }

  // MODAL

  const onCloseModal = (typeSuccess) => {
    setShowTableModal(false)
    if (typeSuccess) {
      setShowInfoModal(null)
      return
    }
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg={showInfoModal}
    />
  )

  // Render

  const renderProductList = (item, index) => {
    return <StyledBox key={index}>
      <div className='row6'>
        <span>Cliente</span>
        <p>{item.nome}</p>
      </div>
      <div className='row1'>
        <span>Quantidade</span>
        <p>{item.quantidade || 0}</p>
      </div>
      <div className='row3'>
        <span>Tabela - Excel</span>
        <p onClick={() => renderTable(item.empresa_bilhetada, 'pesquisas')} title='Abrir modal com tabela para gerar excel'><i className="fa-solid fa-file-excel"></i></p>
      </div>
    </StyledBox>
  }

  const renderConsultList = (item, index) => {
    return <StyledBox key={index}>
      <div className='row6'>
        <span>Cliente</span>
        <p>{item.nome}</p>
      </div>
      <div className='row1'>
        <span>Quantidade</span>
        <p>{item.quantidade || 0}</p>
      </div>
      <div className='row3'>
        <span>Tabela - Excel</span>
        <p onClick={() => renderTable(item.empresa_bilhetada, 'consultas')} title='Abrir modal com tabela para gerar excel'><i className="fa-solid fa-file-excel"></i></p>
      </div>
    </StyledBox>
  }

  const renderTable = async (empresa, tipo) => {
    let datesForBill = dateForBillClient(selectedDates)

    let dataInicio = datesForBill.dataInicio
    let dataFinal = datesForBill.dataFinal

    let param = {
      empresas: empresa,
      dataInicio: formatarData(dataInicio, 'inicio'), 
      dataFinal: formatarData(dataFinal, 'final'),
      tipo: tipo
    }

    let req = await reqGetManagerialTable(param)
    setValoresModal(req.tabela)
    setTipo(tipo)
    setShowTableModal(true)
  }

  const renderTableModal = () => (
    <Modal
      closeModal={onCloseModal}
      size="xlg"
    >
      <ModalTable
        type={tipo}
        values={valoresModal}>
      </ModalTable>
    </Modal>
  )

  // On field change

  const onFilterChange = async (e) => {
    const { value } = e.target
    if(value === 'nulo') {
      setEspecifica([])
      return
    }

    setSelectedEmpresa(value)

    if(selectedDates.startDate === null || selectedDates.endDate === null) {
      setEspecifica([])
      return
    } 

    setLoadingSpecific(true)

    let datesForBill = dateForBillClient(selectedDates)

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    await handleSpecific(value, dataInicio, dataFinal)
  }

  const handleDateChange = async (dates) => {
    setSelectedDates(dates);

    let companys;

    if (selectedEmpresa === null) {   
      return
    } else {
      companys = selectedEmpresa
    }

    if(dates.startDate === null || dates.endDate === null) {
      return
    }

    setLoadingSpecific(true)

    let datesForBill = dateForBillClient(dates)

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    await handleSpecific(companys, dataInicio, dataFinal)
  }

  const handleSpecific = async (empresa, dataInicio, dataFinal) => {
    let req = await reqGetManagerialClients({ empresas: empresa, dataInicio: formatarData(dataInicio, 'inicio'), dataFinal: formatarData(dataFinal, 'final') });

    let object = {
      search: req.empresas,
      consult: req.consultas,
    }
     
    setEspecifica(object)
    setLoadingSpecific(false)
  }

  return (
    <StyledSection className='container'>
      {showTableModal && renderTableModal()}
      {showInfoModal && renderInfoModal()}
      <h2>Relatório Gerencial</h2>
      <hr></hr>
      
      <div className='container-specifics'>
        <div className='top'>
          <p className='title'>Buscar por Cliente e Período</p>

          <div className="tooltip">
            <span className="tooltiptext">Informe o cliente, a data de fim e a data de ínicio para que o relatório seja retornado.</span>
            <i className="exclamation-icon">!</i>
          </div>
        </div>
        <div className='grid-specifics'>
          <SelectText
            label="Cliente"
            name="cliente"
            options={initialData.empresas}
            isLoading={loading}
            onChange={onFilterChange}
          />
          <DateSelector onDateChange={handleDateChange} ></DateSelector>
        </div>
        {loadingSpecific ?
          (<CustomLoading className="fas fa-spinner" />)
          :
          <div className='father-specific'>
            <div className='specific'>
              <span>Pesquisas</span>
              <div>
                {especifica?.search?.length > 0 ?
                  especifica?.search?.map((item, index) => renderProductList(item, index, "specific"))
                  : <p>Não existe pesquisas geradas para esta empresa e/ou data</p>
                }
              </div>
            </div>
            <div className='specific'>
              <span>Consulta</span>
              <div>
                {especifica?.consult?.length > 0 ?
                  especifica?.consult?.map((item, index) => renderConsultList(item, index, "specific"))
                  : <p>Não existe consultas geradas para esta empresa e/ou data</p>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </StyledSection>
  )
}

export default Managerial
