import styled from '@emotion/styled'

export const StyledSection = styled.section`
  max-width: 1200px;

  h2 {
    color: var(--color-primary);
    margin-top: 20px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
  }
  
  .title-period{
    width: 50%;
    line-height: 60px;
  }

  .period-total {
    display: flex;
    align-items: center; 
    justify-content: center;
    gap: 35px;

    div:first-child {
      width: auto;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .tooltip .exclamation-icon {
    font-size: 24px;
    background-color: lightgray;
    color: white;
    padding: 5px 15px;
    border-radius: 50%;
    text-align: center;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -100px; 
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  hr {
    margin-top: 30px;
  }
  
  .container-values {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;  
  }
  
  .value-item {
    display: flex;
    width: 190px;
    height: 120px;
    border: solid 0.5px black;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
  }

  .value-item:hover {
    background-color: rgba(0, 0, 0, 0.1); 
  }

  .label-box {
    font-size: small;
    text-align: center;
    margin-top: 5px;
  }

  .container-products {
    margin-top: 20px;
    display: flex;  
    justify-content: space-around;
  }

  .container-specifics {
    margin-top: 20px;
  }

  .father-specific {
    display: flex;  
    justify-content: space-around;
    gap: 5px;
    margin-top: 10px;
  }

  .title {
    font-size: 2rem;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .fa-solid {
    cursor: pointer;
  }

  .grids {  
    & > span {
      color: var(--color-primary);
    }
    & > div {
      margin-top: 5px;
    }
  }

  .grid-specifics {
    display: flex;
    justify-content: space-between; 
    gap: 20px;   
  }
  
  .specific {

    & > span {
      color: var(--color-primary);
    }

    & > div {
      margin-top: 5px;
    }
  }

  @media (max-width: 540px) {
    .container-products {
      display: block;
    }

    .father-specific {
      display: block;
    }

    .grid-specifics {
      display: block;
    }
    
    .exclamation-icon .tooltip .tooltiptext {
      display: none;
      visibility: hidden;
    }
  }
`

export const StyledBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;

  @media (max-width: 520px) {
    flex-wrap: wrap;
  }

  &:nth-of-type(odd) {
    background-color: #d4e7f7;
  }

  border-right: 6px solid var(--color-gray-dark);

  div.status {
    p {
      font-weight: 700;
      font-size: 13px;
      color: var(--color-gray-dark);
    }
  }

  @media (min-width: 1200px) {
    border-right: 10px solid var(--color-gray-dark);
  }

  .fonte {
    margin-top: 3px;
    margin-right: 3px;
    padding-right: 3px;
    border-right: 2px solid lightgray;
  }

    & > div {
      position: relative;
      width: 100%;

      span {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        display: block;
        margin-bottom: 5px;
        color: var(--color-gray-dark);
      }

      p {
        margin: 0;

        i {
          font-size: 20px;
        }
      }
    }
    
    .grid-specifics & > div {
      &.row1 {
        width: 105px;
      }
      &.row2 {
        width: 100px;
      }
      &.row3, &.row4 {
        width: 80px;
      }
      &.row5 {
        width: 65px;
      }
    }

    .grids & > div {
      &.row1 {
        width: 95px;
      }
      &.row2 {
        width: 85px;
      }
      &.row3, &.row4 {
        width: 50px;
      }
      &.row5 {
        width: 80px;
      }
      &.row6 {
        width: 155px;
      }
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    @media (max-width: 540px) {
      padding: 10px;

      .container-products {
        display: block;
      }
    }
  
`

export const CustomLoading = styled.i`
  @keyframes Loading {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  display: flex;
  justify-content: center;
  top: 32px;
  z-index: 10;
  font-size: 30px;
  left: calc(50% - 15px);
  animation: Loading linear 1.5s infinite;
`
