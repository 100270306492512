import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import { validarCPF } from '@/validators/cpfValidator'
import FormAddress from '@/views/Register/fragments/FormAddress'
import {
  formatterCPForCNPJ,
  formatterPhone,
  formatterNumberOnly,
 } from '@/utils/formatter'
 import { REQUIRED_FIELD } from '@/constants/parameters'
import { MAX_LENGTH, MIN_LENGTH } from '@/utils/minMaxLength'
import {
  StyledAddPhone,
  StyledPhoneField,
  StyledPhoneContainer,
} from '@/views/Register/fragments/stylesRegisterPf'
import {
  StyledForm,
  StyledAlertMessage,
} from '@/views/Register/fragments/styles'
import SelectText from '@/views/fragments/form/SelectText'
import { reqLocalidade, requestOwner } from '@/constants/requests'

const RegisterOwnerInputs = (props) => {
  const {
    formValues,
    validateList,
    handleFormValues,
    handleValidateList,
    initialDisabled,
    hasRegister,
    onCopyButtonClick,
    isNewSearch
  } = props

  const [showMsg, setShowMsg] = useState(false)
  const [cpfValidate, setCpfValidate] = useState({})
  const [stateList, setStateList] = useState([])
  const [isCPF, setIsCPF] = useState(false)

  const checkIsCPF = useCallback((cpf) => {
    if (formatterNumberOnly(cpf).length > 11) {
      setIsCPF(false)
      return false
    }

    setIsCPF(true)
    return true
  }, [])

  const setupStatesList = useCallback(async() => {
    const list = await reqLocalidade()
    setStateList(list.state)
  }, [])

  useEffect(() => {
    if (formValues.document) {
      checkIsCPF(formValues.document)
    }
    setupStatesList()
  }, [setupStatesList])

  const onChange = (e, ml) => {
    const { name, value } = e.target

    let newValue = value.replace(String.fromCharCode(9), " ");

    if (name === 'document') {
      newValue = formatterNumberOnly(newValue)
    }

    if (newValue.length > ml) return

    handleFormValues({
      ...formValues,
      [name]: newValue,
    })

    handleValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const onChangePhone = (e, i, ml) => {
    const { value } = e.target
    const newArray = [...formValues.phones]

    if (value.length > ml) return
    newArray[i] = value

    handleFormValues({
      ...formValues,
      phones: newArray,
    })

    handleValidateList({
      ...validateList,
      phone: false,
    })
  }

  const onAddPhoneClick = (e) => {
    e.preventDefault()
    const newArray = [...formValues.phones]
    newArray.push('')

    handleFormValues({
      ...formValues,
      phones: newArray,
    })
  }

  const removePhoneClick = (i) => {
    const newArray = [...formValues.phones]
    newArray.splice(i, 1)

    handleFormValues({
      ...formValues,
      phones: newArray,
    })
  }

  const renderPhones = (phone, index) => (
    <StyledPhoneField
      key={index}
      className={`phone owner ${formValues.phones.length > 1 ? 'hasTrash' : ''}`}
    >
      <InputText
        label={`Telefone ${index + 1}`}
        name="phone"
        isRequired={index === 0}
        isError={index === 0 ? validateList.phone : false}
        hint={index === 0 ? REQUIRED_FIELD : null}
        value={formatterPhone(phone) || ''}
        onChange={(e) => onChangePhone(e, index, MAX_LENGTH.quinze)}
      />
      {formValues.phones.length > 1 && (
        <i className="far fa-trash-alt" onClick={() => removePhoneClick(index)}/>
      )}
    </StyledPhoneField>
  )

  const handleCFPBlur = async (e) => {
    const { value } = e.target
    if (!value) return

    const req = await requestOwner({cpf_cnpj: formatterNumberOnly(value)})
    const chekIsCPF = checkIsCPF(value)

    if (req?.status?.active) {
      hasRegister(req.proprietario)
      return
    }

    if (!chekIsCPF) return

    const valid = validarCPF(value)
    if (!valid) {
      setCpfValidate({
        msg: 'CPF inválido',
        status: true,
      })
      setShowMsg(true)
      return
    }

    setCpfValidate({})
    setShowMsg(false)
  }

  const closeWarning = () => {
    setShowMsg(false)
    setCpfValidate({})
  }

  const handleNameBlur = (e) => {
    const { name, value } = e.target
    if (!value) return

    if (value.length < MIN_LENGTH.five) {
      handleValidateList({
        ...validateList,
        [name]: true,
      })
    }
  }

  const handleMinNumberNeeded = (e) => {
    const { name, value } = e.target
    if (!value) return

    if (value.length < MIN_LENGTH.five) {
      handleValidateList({
        ...validateList,
        [name]: true,
      })
    }
  }

  const handleCopyClick = () => {
    onCopyButtonClick()
    checkIsCPF(isNewSearch)
  }

  return (
    <>
      <StyledForm className="owner">
        {Boolean(isNewSearch) && (
          <div className='buttonCopy'>
            <a className='button' onClick={handleCopyClick}>
              <i className="far fa-copy" />
              Copiar dados do Motorista
            </a>
          </div>
        )}
        <div className="cnpj">
          <InputText
            label="CPF / CNPJ"
            name="document"
            isRequired
            isError={validateList.document}
            hint={REQUIRED_FIELD}
            value={formatterCPForCNPJ(formValues.document) || ''}
            onChange={(e) => onChange(e, MAX_LENGTH.quatorze)}
            onBlur={handleCFPBlur}
          />
          {showMsg && (
            <StyledAlertMessage>
              {cpfValidate.status ? 'CPF incorreto.' : 'Cliente não cadastrado/encontrado.'}
              <i className="far fa-times-circle" onClick={() => closeWarning()}/>
            </StyledAlertMessage>
          )}
        </div>

        <InputText
          label="Nome / Razão Social"
          name="companyName"
          className="companyName"
          isRequired
          isError={validateList.companyName}
          hint={REQUIRED_FIELD}
          value={formValues.companyName || ''}
          onChange={onChange}
          placeholder="Mínimo 5 caracteres"
          onBlur={handleMinNumberNeeded}
        />

        {isCPF && (
          <>
            <div className="pfFields">
              <InputText
                label="RG"
                name="rg"
                isRequired
                isError={validateList.rg}
                hint={REQUIRED_FIELD}
                className="rg"
                value={formValues.rg || ''}
                onChange={(e) => onChange(e, MAX_LENGTH.doze)}
              />
              <SelectText
                label="Estado Emissor"
                name="ufIssue"
                isRequired
                isError={validateList.ufIssue}
                hint={REQUIRED_FIELD}
                className="ufIssue"
                options={stateList}
                value={formValues.ufIssue || ''}
                onChange={onChange}
              />
              <InputText
                label="Nome da Mãe"
                name="motherName"
                isRequired
                isError={validateList.motherName}
                hint={REQUIRED_FIELD}
                placeholder="Mínimo 5 caracteres"
                className="motherName"
                value={formValues.motherName || ''}
                onChange={onChange}
                onBlur={handleNameBlur}
              />
              <InputText
                label="Nome do Pai"
                name="fatherName"
                isRequired
                placeholder="Mínimo 5 caracteres"
                isError={validateList.fatherName}
                hint={REQUIRED_FIELD}
                className="fatherName"
                value={formValues.fatherName || ''}
                onChange={onChange}
                onBlur={handleNameBlur}
              />
            </div>
          </>
        )}

        <FormAddress
          formValues={formValues}
          setFormValues={handleFormValues}
          validateList={validateList}
          setValidateList={handleValidateList}
          defaultDisabled={initialDisabled}
          isFilled={initialDisabled}
        />

        <StyledPhoneContainer>
          {formValues.phones?.map((phone, index) => renderPhones(phone, index))}
        </StyledPhoneContainer>
        {formValues?.phones?.length < 3 && (
          <StyledAddPhone className="button" onClick={(e) => onAddPhoneClick(e)}>
            Add
            <i className="fas fa-plus" />
            <i className="fas fa-mobile-alt" />
          </StyledAddPhone>
        )}
      </StyledForm>
    </>
  )
}

RegisterOwnerInputs.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  validateList: PropTypes.objectOf(PropTypes.any),
  handleFormValues: PropTypes.func,
  handleValidateList: PropTypes.func,
  onCopyButtonClick: PropTypes.func,
  hasRegister: PropTypes.func,
  initialDisabled: PropTypes.bool,
  isNewSearch: PropTypes.string,
}

RegisterOwnerInputs.defaultProps = {
  validateList: {},
  handleFormValues: null,
  handleValidateList: null,
  onCopyButtonClick: null,
  initialDisabled: false,
  isNewSearch: null
}

export default RegisterOwnerInputs
