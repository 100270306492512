import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@/views/fragments/Modal'
import { CustomModal } from './styles'

const DefaultModalAlert = (props) => {
  const {
    closeModal,
    msg,
  } = props

  return (
    <Modal closeModal={closeModal} zindexAbsolute>
      <CustomModal>
        <h4>
          {msg}
        </h4>
        <button
          className="button"
          onClick={closeModal}
        >
          Ok
        </button>
      </CustomModal>
    </Modal>
  )
}

DefaultModalAlert.propTypes = {
  closeModal: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired
}

export default DefaultModalAlert
