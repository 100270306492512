import styled from '@emotion/styled'

export const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse
`

export const StyledInputs = styled.div`
  & > div {
    position: relative;
    margin-top: 15px;

    button {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 36px;
      right: 5px;
      font-size: 18px;
      color: var(--color-gray-light);

      &:hover {
        color: var(--color-alert);
      }
    }
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      top: 29px;
      background-color: var(--color-primary);
      position: absolute;
    }

    p {
      margin: 0;
      padding: 0 10px;
      color: var(--color-primary);
      background-color: var(--color-light);
      position: relative;
      z-index: 3;
    }
  }
`

export const StyledCard = styled.div`
  width: 100%;

  @media print {
    @page { 
      size: 1100px 1000px;
    }
  }

  & > div {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    & > div {

      &:nth-of-type(1) {
        width: 160px; 
      }
      &:nth-of-type(2) {
        width: 155px; 
      }
      &:nth-of-type(3) {
        width: 265px; 
      }
      &:nth-of-type(4) {
        width: 265px;
      }
      &:nth-of-type(5) {
        width: 200px; 
      }

      & > span {
        &:first-of-type {
          font-weight: 700;
          display: block;
          text-transform: uppercase;
          font-size: 12px;
        }

        &:last-of-type {
          font-size: 15px;
          display: block;
          margin-top: 5px;
        }
      }
    }
  }

  & > ul {
    margin-top: 20px;
    border-top: 1px solid var(--color-gray-light);
    padding-top: 20px;

    li {
      display: flex;
      margin-bottom: 20px;

      div {
        width: 190px;

        span {
          &:first-of-type {
            font-weight: 700;
            display: block;
            text-transform: uppercase;
            font-size: 12px;
          }
  
          &:last-of-type {
            font-size: 15px;
            display: block;
            margin-top: 5px;
          }
        }
      }

    }
  }
`
