import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCard
} from './styles'

const ShipperCard = (props) => {
  const {
    onEdit,
    onDelete,
    index,
    name,
    code,
  } = props

  const onEditClick = () => {
    onEdit(index)
  }

  const handleRemove = () => {
    onDelete(index)
  }

  return (
    <StyledCard>
      <div className='pencil' onClick={() => onEditClick()}>
        <i className="fas fa-pencil-alt" />
      </div>

      <p className='strong' title={name}>
        <i className="far fa-user" />
        {name}
      </p>
      <p><span>Cód. Integração:</span> {code}</p>

      <div className='trash'
        onClick={() => handleRemove()}
      >
        <i className="far fa-trash-alt" />
      </div>
    </StyledCard>
  )
}

ShipperCard.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

export default ShipperCard
