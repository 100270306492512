import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  max-width: 570px;
  text-align: center;
  margin: 150px auto 100px;
  padding: 0 20px;

  i {
    font-size: 60px;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 34px;
    margin-bottom: 20px;
  }

  p {
    font-size: 23px;
    line-height: 30px;
  }
`
