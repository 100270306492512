import React, { useState, useEffect } from 'react'
import { StyledBox, StyledSection, CustomLoading } from './styles'
import { useSelector } from 'react-redux'
import SelectText from '../fragments/form/SelectText'
import { path } from "@/utils/paths"
import {
  reqGetBillDay,
  reqGetBillClients,
  reqGetBillReport,
} from '@/constants/requests'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import DateSelector from '../fragments/form/DateSelector'

const Billings = () => {
  const [loading, setLoading] = useState(true)
  const [loadingReports, setLoadingReports] = useState(true)
  const [loadingSpecific, setLoadingSpecific] = useState(true)
  const [dateField, setDate] = useState('today')
  const [initialData, setInitialData] = useState({})
  const [empresas, setEmpresas] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [especifica, setEspecifica] = useState([])
  const [selectedEmpresa, setSelectedEmpresa] = useState(null)
  const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null })

  const urlToDownload = `${path}/Relatorios/`;

  const permission = useSelector(state => state.permission);

  useEffect(async () => {
    if (!permission?.billingReport.view) {
      window.location.href = "/";
      return
    }

    await getBillDay()
    setLoading(false)
    setLoadingReports(false)
    setLoadingSpecific(false)

  }, [])

  useEffect(async () => {
    await handleDateForReport("today")
  }, [initialData])

  useEffect(async () => {
    await handleDateForReport(dateField || "today")
  }, [dateField])

  // Utilitários para array
  
  function crossArrays(array1, array2) {
    const crossed = [];
    
    array1.forEach(obj1 => {
      if(obj1.label === 'Todos') {
        return
      }
      const obj2 = array2.find(item => item.empresa_bilhetada === obj1.empresa_bilhetada);
      crossed.push({ ...obj1, ...(obj2 ? obj2 : {}) });
    });
    
    array2.forEach(obj2 => {
      if(obj2.label === 'Todos') {
        return
      }
      const obj1 = array1.find(item => item.empresa_bilhetada === obj2.empresa_bilhetada);
      if (!obj1) {
        crossed.push({ ...(obj1 ? obj1 : {}), ...obj2 });
      }
    });

    return crossed;
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  // Formatar datas para consulta

  function formatarData(data, tipo) {
    var ano = data.getFullYear();
    var mes = ("0" + (data.getMonth() + 1)).slice(-2);
    var dia = ("0" + data.getDate()).slice(-2);
    var horas, minutos, segundos;
    if (tipo === 'inicio') {
      horas = ("0" + 0).slice(-2);
      minutos = ("0" + 0).slice(-2);
      segundos = ("0" + 0).slice(-2);
    } else {
      horas = ("0" + 23).slice(-2);
      minutos = ("0" + 59).slice(-2);
      segundos = ("0" + 59).slice(-2);        
    }
    return ano + "-" + mes + "-" + dia + " " + horas + ":" + minutos + ":" + segundos;
  }

  function calcularDiaInicioFim(diaFatura) {
    let dataInicio = new Date() 
    let dataFinal = new Date()
    let hoje = new Date()
    
    dataInicio.setMonth(hoje.getMonth() - 1)
    dataInicio.setDate(diaFatura)
    
    dataFinal.setDate(diaFatura - 1)
  
    return {
      dataFinal: dataFinal,
      dataInicio: dataInicio
    }
  } 

  const dateForBillClient = (dates) => {
    let dataInicio, dataFinal;
    
    if(dates.endDate === null) {
      dataFinal = new Date()
    } else {
      dataFinal = dates.endDate
    }
    
    if(dates.startDate === null) {
      dataInicio = new Date()
    } else {
      dataInicio = dates.startDate
    }

    return {
      dataFinal: dataFinal,
      dataInicio: dataInicio
    }
  }

  // Consulta de dados para mostrar na tela

  const handleDateForReport = async (dateField) => {
    setDate(dateField)

    let params;
    let empresasToCross;
    let billDay;
    let today = new Date();

    if (initialData === undefined || isEmpty(initialData)) {
      setEmpresas([])
      return
    }

    setLoadingReports(true)

    if (dateField === "today") {
      if (isEmpty(initialData?.today)) {
        setEmpresas([])
        setLoadingReports(false)
        return
      }
      empresasToCross = initialData?.today;
      billDay = today.getDate()
      params = initialData?.today.map((el) => `${el.empresa_bilhetada}`).join(',');
    } else if (dateField === "yesterday") {
      if (isEmpty(initialData?.yesterday)) {
        setEmpresas([])
        setLoadingReports(false)
        return
      }
      empresasToCross = initialData?.yesterday;
      billDay = today.getDate() - 1
      params = initialData?.yesterday.map((el) => `${el.empresa_bilhetada}`).join(',');
    } 
    
    let datesForBill = calcularDiaInicioFim(billDay)

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    let req = await reqGetBillClients({ empresas: params, dataInicio: formatarData(dataInicio, 'inicio'), dataFinal: formatarData(dataFinal, 'final') });

    let crosseds = formatCrosseds(req, empresasToCross)

    let object = {
      search: crosseds.search,
      consult: crosseds.consult
    }

    setEmpresas(object);
    setLoadingReports(false)
  }

  function formatCrosseds(req, empresas) {
    let searchBills;
    let consultBills;

    if(isEmpty(req.empresas)) {
      searchBills = []
    } else {    
      searchBills = req.empresas;
    }

    if(isEmpty(req.consultas)) {
      consultBills = []
    } else {    
      consultBills = req.consultas;
    }
    
    let crossedSearch = crossArrays(empresas, searchBills)
    let crossedConsult = crossArrays(empresas, consultBills)

    return {
      search: crossedSearch,
      consult: crossedConsult,
    }
  }

  const getBillDay = async () => {
    const today = new Date()
    let todayDay = today.getDate()
    let req = await reqGetBillDay({ dia: todayDay })

    let params = req?.empresas.map((el) => `${el.value}`).join(',');
    let object = {
      value: params,
      label: 'Todos'
    }

    req.empresas.unshift(object)

    let data = {
      today: req.today,
      yesterday: req.yesterday,
      week: req.week,
      empresas: req.empresas
    }

    setInitialData(data);
  }

  // Gerar relatórios

  const onReportClick = async (item, type, typeReport) => {
    if(item.quantidade === undefined) {
      setShowInfoModal('Relatório não pode ser gerado, pois não tem valores para essa data!')
      return
    }

    let billDay;
    let today = new Date();

    if (dateField === "today") {
      billDay = today.getDate()
    } else if (dateField === "yesterday") {
      billDay = today.getDate() - 1
    } 
    
    let datesForBill;

    if (type === "all") {
      datesForBill = calcularDiaInicioFim(billDay)
    } else {
      datesForBill = dateForBillClient(selectedDates)
    }

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    let params = {
      inicial: formatarData(dataInicio, 'inicio'),
      final: formatarData(dataFinal, 'final'),
      empresa_bilhetada: item.empresa_bilhetada,
      excel: 0,
      tipo: typeReport
    }

    setShowInfoModal('Relatório gerado com sucesso, irá abrir em uma nova guia!')

    let req = await reqGetBillReport(params)

    setTimeout(() => {
      window.open((urlToDownload + req.status.caminho), '_blank');
    }, 1000);
  }

  const onReportExcelClick = async (item, type, typeReport) => {
    if(item.quantidade === undefined) {
      setShowInfoModal('Relatório não pode ser gerado, pois não tem valores para essa data!')
      return
    }

    let billDay;
    let today = new Date();

    if (dateField === "today") {
      billDay = today.getDate()
    } else if (dateField === "yesterday") {
      billDay = today.getDate() - 1
    } 
    
    let datesForBill;
    if (type === "all") {
      datesForBill = calcularDiaInicioFim(billDay)
    } else {
      datesForBill = dateForBillClient(selectedDates)
    }

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    let params = {
      inicial: formatarData(dataInicio, 'inicio'),
      final: formatarData(dataFinal, 'final'),
      empresa_bilhetada: item.empresa_bilhetada,
      excel: 1,
      tipo: typeReport
    }

    setShowInfoModal('Relatório gerado com sucesso, download será realizado!')

    let req = await reqGetBillReport(params)

    setTimeout(() => {
      window.open((urlToDownload + req.status.caminho), '_blank');
    }, 1000);
  }

  // MODAL

  const onCloseModal = (typeSuccess) => {
    if (typeSuccess) {
      setShowInfoModal(null)
      return
    }
  }

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg={showInfoModal}
    />
  )

  // Render

  const renderProductList = (item, index, type) => {
    return <StyledBox key={index}>
      <div className='row6'>
        <span>Cliente</span>
        <p>{item.nome}</p>
      </div>
      <div className='row1'>
        <span>Quantidade</span>
        <p>{item.quantidade || 0}</p>
      </div>
      <div className='row2'>
        <span>Total</span>
        <p>R$ {item.valor || 0}</p>
      </div>
      <div className='row5'>
        <span>Dia Fatura</span>
        <p>{item.faturar_dia}</p>
      </div>
      <div className='row3'>
        <span>PDF</span>
        <p onClick={() => onReportClick(item, type, 'search')}><i className="fa-solid fa-file-pdf"></i></p>
      </div>
      <div className='row4'>
        <span>Excel</span>
        <p onClick={() => onReportExcelClick(item, type, 'search')}><i className="fa-solid fa-file-excel"></i></p>
      </div>
    </StyledBox>
  }

  const renderConsultList = (item, index, type) => {
    return <StyledBox key={index}>
      <div className='row6'>
        <span>Cliente</span>
        <p>{item.nome}</p>
      </div>
      <div className='row1'>
        <span>Quantidade</span>
        <p>{item.quantidade || 0}</p>
      </div>
      <div className='row2'>
        <span>Total</span>
        <p>R$ {item.valor || 0}</p>
      </div>
      <div className='row5'>
        <span>Dia Fatura</span>
        <p>{item.faturar_dia}</p>
      </div>
      <div className='row3'>
        <span>PDF</span>
        <p onClick={() => onReportClick(item, type, 'consult')}><i className="fa-solid fa-file-pdf"></i></p>
      </div>
      <div className='row4'>
        <span>Excel</span>
        <p onClick={() => onReportExcelClick(item, type, 'consult')}><i className="fa-solid fa-file-excel"></i></p>
      </div>
    </StyledBox>
  }

  // On field change

  const onFilterChange = async (e) => {
    const { value } = e.target
    if(value === 'nulo') {
      setEspecifica([])
      return
    }

    setSelectedEmpresa(value)

    if(selectedDates.startDate === null || selectedDates.endDate === null) {
      setEspecifica([])
      return
    } 

    setLoadingSpecific(true)

    let datesForBill = dateForBillClient(selectedDates)

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    await handleSpecific(value, dataInicio, dataFinal)
  }

  const handleDateChange = async (dates) => {
    setSelectedDates(dates);

    let companys;

    if (selectedEmpresa === null) {   
      return
    } else {
      companys = selectedEmpresa
    }

    if(dates.startDate === null || dates.endDate === null) {
      return
    }

    setLoadingSpecific(true)

    let datesForBill = dateForBillClient(dates)

    let dataFinal = datesForBill.dataFinal
    let dataInicio = datesForBill.dataInicio

    await handleSpecific(companys, dataInicio, dataFinal)
  }

  const handleSpecific = async (empresa, dataInicio, dataFinal) => {
    let req = await reqGetBillClients({ empresas: empresa, dataInicio: formatarData(dataInicio, 'inicio'), dataFinal: formatarData(dataFinal, 'final') });

    let object;

    if (empresa.includes(',')) {
      let crosseds = formatCrosseds(req, initialData?.empresas)
      
      object = {
        search: crosseds.search,
        consult: crosseds.consult
      }
    } else {
      object = {
        search: req.empresas,
        consult: req.consultas,
      }
    }    

    setEspecifica(object)
    setLoadingSpecific(false)
  }

  return (
    <StyledSection className='container'>
      {showInfoModal && renderInfoModal()}
      <h2>Relatório de Faturamento</h2>
      <hr></hr>
      <div className='container-values'>
        <div>
          <div className='value-item' onClick={() => handleDateForReport('yesterday')}>{initialData?.yesterday?.length || 0}</div>
          <p className='label-box'>Clientes Faturados Ontem</p>
        </div>
        <div>
          <div className='value-item' onClick={() => handleDateForReport('today')}>{initialData?.today?.length || 0}</div>
          <p className='label-box'>Clientes para Faturar Hoje</p>
        </div>
      </div>
      <hr></hr>

      <div className='top'>
        {dateField === 'today' ? (
          <p className='title title-period'>Clientes Hoje</p>
        ) : dateField === 'week' ? (
          <p className='title title-period'>Clientes para essa semana</p>
        ) : dateField === 'yesterday' ? (
          <p className='title title-period'>Clientes Ontem</p>
        )  
        : null}
        <div className='period-total'>
          <div className="tooltip">
            <span className="tooltiptext">Relatório gerado conforme dia da fature, no PDF você pode ter a data referente a pesquisa.</span>
            <i className="exclamation-icon">!</i>
          </div>
        </div>
      </div>

      {loadingReports ?
        (<CustomLoading className="fas fa-spinner" />)
        :
        <div className='container-products'>
          <div className='grids'>
            <span>Pesquisas</span>
            <div>
              {empresas?.search?.length > 0 ?
                empresas?.search?.map((item, index) => renderProductList(item, index, "all"))
                : <p>Não existe clientes para esta data</p>
              }
            </div>
          </div>
          <div className='grids'>
            <span>Consulta</span>
            <div>
              {empresas?.consult?.length > 0 ?
                empresas?.consult?.map((item, index) => renderConsultList(item, index, "all"))
                : <p>Não existe clientes para esta data</p>
              }
            </div>
          </div>
        </div>

      }
      <hr></hr>
      <div className='container-specifics'>
        <div className='top'>
          <p className='title'>Buscar por Cliente e/ou Período</p>

          <div className="tooltip">
            <span className="tooltiptext">Informe o cliente, a data de fim e a data de ínicio para que o relatório seja retornado.</span>
            <i className="exclamation-icon">!</i>
          </div>
        </div>
        <div className='grid-specifics'>
          <SelectText
            label="Cliente"
            name="cliente"
            options={initialData.empresas}
            isLoading={loading}
            onChange={onFilterChange}
          />
          <DateSelector onDateChange={handleDateChange} ></DateSelector>
        </div>
        {loadingSpecific ?
          (<CustomLoading className="fas fa-spinner" />)
          :
          <div className='father-specific'>
            <div className='specific'>
              <span>Pesquisas</span>
              <div>
                {especifica?.search?.length > 0 ?
                  especifica?.search?.map((item, index) => renderProductList(item, index, "specific"))
                  : <p>Não existe pesquisas geradas para esta empresa e/ou data</p>
                }
              </div>
            </div>
            <div className='specific'>
              <span>Consulta</span>
              <div>
                {especifica?.consult?.length > 0 ?
                  especifica?.consult?.map((item, index) => renderConsultList(item, index, "specific"))
                  : <p>Não existe consultas geradas para esta empresa e/ou data</p>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </StyledSection>
  )
}

export default Billings
