import React, {useState } from 'react';
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDateTime } from './styles';

registerLocale('pt-BR', ptBR);

const DateSelector = (props) => {
  const {
    onDateChange
  } = props
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onDateChange({ startDate: date, endDate });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onDateChange({ startDate, endDate: date });
  }; 

  return (
    <StyledDateTime>
      <div className="date-selector-container">
        <span>Selecione o Período</span>
        <div className='date-inputs'>
          <div className="date-selector">
            <DatePicker
              id="start-date"
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione a data de início"
              className="date-picker"
            />
          </div>
          <div className="date-selector">
            <DatePicker
              id="end-date"
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              locale="pt-BR"
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione a data de fim"
              className="date-picker"
            />
          </div>
        </div>
      </div>
    </StyledDateTime>
  );
};

DateSelector.propTypes = {
  onDateChange: PropTypes.func,
}

DateSelector.defaultProps = {
  
}

export default DateSelector;
