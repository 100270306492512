import React from 'react'
import Modal from '@/views/fragments/Modal'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import CheckboxField from '@/views/fragments/form/CheckboxField'
import { MANUAL_OPTIONS, MANUAL_OPTIONS_BLOCK } from '@/constants/optionsValues'
import { format, } from 'date-fns-tz'
import { validateManualSolicitation, validateSolicitation } from '@/validators/registerValidations'
import {
  StyledFieldset,
  FloatButton,
  StyledCard
} from '../styles'
import {
  formatterCPF,
  formatterCPForCNPJ,
  formatterRG
} from '@/utils/formatter'
import SelectText from '@/views/fragments/form/SelectText'
import { zonedTimeToUtc } from 'date-fns-tz'

const timeZone = 'America/Sao_Paulo'

const VehicleOwnerLink = (props) => {
  const {
    itemToEdit,
    onCloseModal,
    onSubmitClick,
    showPersonInfo,
    setShowPersonInfo,
    formValues,
    onInputChange,
    onCheckboxClick,
    showCarInfo,
    setShowCarInfo,
    permission,
    isManual,
  } = props

  const renderCarInfo = (car) => (
    <StyledCard>
      <div>
        <span>Placa:</span>
        <span>{car.placa || '-'}</span>
      </div>
      <div>
        <span>Nome / Razão Social:</span>
        <span>{car.nome_razao || '-'}</span>
      </div>
      <div>
        <span>CPF/CNPJ:</span>
        <span>{formatterCPForCNPJ(car.cpf_cnpj) || '-'}</span>
      </div>
      <div>
        <span>RG:</span>
        <span>{formatterRG(car.prop_rg) || '-'}</span>
      </div>
      <div>
        <span>Data de Emissão:</span>
        <span>{car?.emissao ? format(new Date(zonedTimeToUtc(car.emissao, timeZone)), 'dd/MM/yyyy', { timeZone }) : '-'}</span>
      </div>
      <div>
        <span>Mão:</span>
        <span>{car.prop_mae || '-'}</span>
      </div>
      <div>
        <span>Pai:</span>
        <span>{car.prop_pai || '-'}</span>
      </div>
      <div>
        <span>Antt:</span>
        <span>{car.antt || '-'}</span>
      </div>
      <div>
        <span>Chassi:</span>
        <span>{car.chassi || '-'}</span>
      </div>
      <div>
        <span>Cidade:</span>
        <span>{car.prop_cidade || '-'}</span>
      </div>
      <div>
        <span>Estado:</span>
        <span>{car.prop_uf || '-'}</span>
      </div>
      <div>
        <span>Estado Proprietario:</span>
        <span>{car.prop_uf_rg || '-'}</span>
      </div>
      <div>
        <span>Renavam:</span>
        <span>{car.renavam || '-'}</span>
      </div>
      <div>
        <span>UF do Veículo:</span>
        <span>{car.veiculo_uf || '-'}</span>
      </div>
      <div>
        <span>Vínculo:</span>
        <span>{car.vinculo  + ' - ' + car.tempo + ' dias' || '-'}</span>
      </div>
    </StyledCard>
  )
  
  const renderManualVeiculo = (car, i) => (
    <StyledFieldset key={i}>
      {showCarInfo === car.veiculo_id && renderCarInfo(car)}
      <legend>Veículo {i + 1}</legend>
      <FloatButton
        onClick={() => setShowCarInfo(
          showCarInfo === car.veiculo_id ? null : car.veiculo_id
        )}
      >
        {car.bloqueio_manual ? (
          <i style={{marginRight: "5px"}} className="fa-solid fa-lock" />
        ): null}
        {showCarInfo === car.veiculo_id ? (
          <>
            <i className="far fa-times-circle" />
          </>
        ) : (
          <>
            <i className="fas fa-info-circle" />
          </>
        )}
      </FloatButton>
      {car.bloqueio_manual ? (
        <SelectText
          options={MANUAL_OPTIONS_BLOCK}
          label="Status"
          name="status"
          value={formValues.veiculos[i]?.status || ''}
          onChange={(e) => onInputChange(e, i)}
          isRequired
        />
      ): (
        <SelectText
          options={MANUAL_OPTIONS}
          label="Status"
          name="status"
          value={formValues.veiculos[i]?.status || ''}
          onChange={(e) => onInputChange(e, i)}
          isRequired
        />
      )}
      {Number(formValues.veiculos[i]?.status) === 3 && (
        <InputText
          value={formValues?.veiculos[i]?.prazo || ''}
          name="prazo"
          type="date"
          minDate={true}
          onChange={(e) => onInputChange(e, i)}
          label="Prazo"
          isRequired
        />
      )}
      {(Number(formValues.veiculos[i]?.status) === 3 && formValues.veiculos[i]?.prazoBoolean) && (
        <p style={{color: "red"}}>Prazo menor que o dia atual</p>
      )}
      {Number(formValues.veiculos[i]?.status) === 4 && (
        <>
          <div>
            <span style={{color: "var(--color-primary)"}}>Motivo</span>
            <textarea
              value={formValues.veiculos[i]?.motivo || ''}
              style={{marginTop: "10px", resize: "vertical"}}
              name="motivo"
              placeholder='Digite aqui...'
              onChange={(e) => onInputChange(e, i)}
            />
          </div>
        </>
      )}
    </StyledFieldset>
  )

  const renderVeiculo = (car, i) => (
    <StyledFieldset key={i}>
      {showCarInfo === car.veiculo_id && renderCarInfo(car)}
      <legend>Veículo {i + 1}</legend>
      <FloatButton
        onClick={() => setShowCarInfo(
          showCarInfo === car.veiculo_id ? null : car.veiculo_id
        )}
      >
        {showCarInfo === car.veiculo_id ? (
          <i className="far fa-times-circle" />
        ) : (
          <i className="fas fa-info-circle" />
        )}
      </FloatButton>
      <div className='content-veiculo'>
        <div className='first-step'>
          <InputText
            value={formValues.veiculos[i]?.licenciamento || ''}
            name="licenciamento"
            onChange={(e) => onInputChange(e, i)}
            label="Licenciamento"
            placeholder="Ano em 4 dígitos (ex: 2020)"
            isRequired
          />
          <CheckboxField
            value={formValues.veiculos[i]?.restricao_adm || 'N'}
            name="restricao_adm"
            onClick={(name, value) => onCheckboxClick(name, value, i)}
            label="Restrição administrativa?"
            isRequired
          />
          {formValues.veiculos[i]?.restricao_adm === 'S' && (
            <InputText
              value={formValues.veiculos[i]?.restricao_detalhe || ''}
              name="restricao_detalhe"
              onChange={(e) => onInputChange(e, i)}
              label="Motivo"
              isRequired
            />
          )}
        </div>
        <div className='second-step'>
          <InputText
            value={formValues.veiculos[i]?.rntrc || ''}
            name="rntrc"
            onChange={(e) => onInputChange(e, i)}
            label="RNTRC"
            isRequired
          />
          <CheckboxField
            value={formValues.veiculos[i]?.rntrc_invalida || 'N'}
            name="rntrc_invalida"
            onClick={(name, value) => onCheckboxClick(name, value, i)}
            label="RNTRC Inválida?"
            isRequired
          />
          <CheckboxField
            value={formValues.veiculos[i]?.habilitado || 'N'}
            name="habilitado"
            onClick={(name, value) => onCheckboxClick(name, value, i)}
            label="Habilitado para transporte de carga?"
            isRequired
          />
          <CheckboxField
            value={formValues.veiculos[i]?.ativo || 'N'}
            name="ativo"
            onClick={(name, value) => onCheckboxClick(name, value, i)}
            label="Ativo?"
            isRequired
          />
        </div>
      </div>
    </StyledFieldset>
  )

  const renderInfoPerson = (person) => (
    <StyledCard>
      <div>
        <span>Perfil:</span>
        <span>{person.perfil || '-'}</span>
      </div>
      <div>
        <span>Nome:</span>
        <span>{person.nome || '-'}</span>
      </div>
      <div>
        <span>CPF:</span>
        <span>{formatterCPF(person.cpf) || '-'}</span>
      </div>
      <div>
        <span>RG:</span>
        <span>{formatterRG(person.rg) || '-'}</span>
      </div>
      <div>
        <span>Nascimento:</span>
        <span>{person?.nascimento ? format(new Date(zonedTimeToUtc(person.nascimento, timeZone)), 'dd/MM/yyyy', { timeZone }) : '-'}</span>
      </div>
      <div>
        <span>Telefone:</span>
        <span>{person.telefone1 || '-'}</span>
      </div>
      <div>
        <span>Mãe:</span>
        <span>{person.mae || '-'}</span>
      </div>
      <div>
        <span>Pai:</span>
        <span>{person.pai || '-'}</span>
      </div>
      <div>
        <span>CNH:</span>
        <span>{person.cnh || '-'}</span>
      </div>
      <div>
        <span>Cód. Seg. CNH:</span>
        <span>{person.cod_seguranca_cnh || '-'}</span>
      </div>
      <div>
        <span>Categoria:</span>
        <span>{person.categoria || '-'}</span>
      </div>
      <div>
        <span>Emissão da CNH:</span>
        <span>{person?.emissao_cnh ? format(new Date(zonedTimeToUtc(person?.emissao_cnh, timeZone)), 'dd/MM/yyyy', { timeZone }) : '-'}</span>
      </div>
      <div>
        <span>Primeira CNH:</span>
        <span>{person?.primeira_cnh ? format(new Date(zonedTimeToUtc(person?.primeira_cnh, timeZone)), 'dd/MM/yyyy', { timeZone }) : '-'}</span>
      </div>
      <div>
        <span>Registro da CNH:</span>
        <span>{person.registro_cnh || '-'}</span>
      </div>
      <div>
        <span>RENACH:</span>
        <span>{person.renach_cnh || '-'}</span>
      </div>
      <div>
        <span>UF da CNH:</span>
        <span>{person.uf_cnh || '-'}</span>
      </div>
      <div>
        <span>UF do RG:</span>
        <span>{person.uf_rg || '-'}</span>
      </div>
      <div>
        <span>CEP:</span>
        <span>{person.cep || '-'}</span>
      </div>
      <div>
        <span>Logradouro:</span>
        <span>{person.logradouro || '-'}</span>
      </div>
      <div>
        <span>Número:</span>
        <span>{person.numero || '-'}</span>
      </div>
      <div>
        <span>Cidade:</span>
        <span>{person.cidade_moradia || '-'}</span>
      </div>
      <div>
        <span>Bairo:</span>
        <span>{person.bairro_moradia || '-'}</span>
      </div>
      <div>
        <span>Estado:</span>
        <span>{person.uf_moradia || '-'}</span>
      </div>
      <div>
        <span>Complemento:</span>
        <span>{person.complemento || '-'}</span>
      </div>
      <div>
        <span>Vínculo:</span>
        <span>{person.vinculo + ' - ' + person.tempo + ' dias' || '-'}</span>
      </div>
    </StyledCard>
  )

  const renderPersonManualForm = () => (
    <>
      {itemToEdit.pessoa?.bloqueio_manual ? (
        <SelectText
          options={MANUAL_OPTIONS_BLOCK}
          label="Status"
          name="status"
          value={formValues.pessoa?.status || ''}
          onChange={onInputChange}
          isRequired
        />
      ): (
        <SelectText
          options={MANUAL_OPTIONS}
          label="Status"
          name="status"
          value={formValues.pessoa?.status || ''}
          onChange={onInputChange}
          isRequired
        />
      )}
      {Number(formValues?.pessoa?.status) === 3 && (
        <InputText
          value={formValues?.pessoa?.prazo || ''}
          name="prazo"
          type="date"
          minDate={true}
          onChange={(e) => onInputChange(e, -1)}
          label="Prazo"
        />
      )}
      {(Number(formValues.pessoa.status) === 3 && formValues.pessoa.prazoBoolean) && (
        <p style={{color: "red"}}>Prazo menor que o dia atual</p>
      )}
      {Number(formValues?.pessoa?.status) === 4 && (
        <>
          <div>
            <span style={{color: "var(--color-primary)"}}>Motivo</span>
            <textarea
              value={formValues.pessoa.motivo || ''}
              style={{marginTop: "10px", resize: "vertical"}}
              name="motivo"
              placeholder='Digite aqui...'
              onChange={(e) => onInputChange(e, -1)}
            />
          </div>
        </>
      )}
    </>
  )

  const checkDisabledButton = () => {
    let disabled = true
    
    if (isManual) {
      disabled = validateManualSolicitation(formValues)
    } else {
      disabled = validateSolicitation(formValues)
    }

    return disabled || !permission?.monitor.openActionSend
  }

  return (
    <Modal
      closeModal={onCloseModal}
      size="md"
    >
      {itemToEdit.pessoa && (
        <StyledFieldset>
          {showPersonInfo && renderInfoPerson(itemToEdit.pessoa)}
          <legend>CNH</legend>
          <FloatButton
            onClick={() => setShowPersonInfo(!showPersonInfo)}
          >
            {itemToEdit.pessoa?.bloqueio_manual ? (
              <i style={{marginRight: "5px"}} className="fa-solid fa-lock" />
            ): null}
            {showPersonInfo ? (
              <i className="far fa-times-circle" />
            ) : (
              <i className="fas fa-info-circle" />
            )}
          </FloatButton>
          {isManual ? (renderPersonManualForm()) : (<>
            <InputText
              value={formValues?.pessoa?.validade || ''}
              name="validade"
              type="date"
              onChange={onInputChange}
              label="Validade"
              isRequired
            />
            <InputText
              value={formValues?.pessoa?.pontos || ''}
              name="pontos"
              type="number"
              onChange={onInputChange}
              label="Pontos"
              isRequired
            />
            <CheckboxField
              value={formValues?.pessoa?.situacao_ok || 'N'}
              name="situacao_ok"
              onClick={onCheckboxClick}
              label="Situação OK?"
              isRequired
            />
          </>)}
        </StyledFieldset>
      )}
      {isManual && itemToEdit?.veiculos && itemToEdit.veiculos.map((car, i) => renderManualVeiculo(car, i))}
      {!isManual && itemToEdit?.veiculos && itemToEdit.veiculos.map((car, i) => renderVeiculo(car, i))}
      <button
        className='button'
        onClick={onSubmitClick}
        title={checkDisabledButton() ? 'Preencher todos os campos obrigatórios' : ''}
        disabled={checkDisabledButton()}
      >
        Enviar
      </button>
    </Modal>
  )
}

VehicleOwnerLink.propTypes = {
  itemToEdit: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  showPersonInfo: PropTypes.bool.isRequired,
  setShowPersonInfo: PropTypes.func.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  showCarInfo: PropTypes.number,
  setShowCarInfo: PropTypes.func.isRequired,
  isManual: PropTypes.bool.isRequired,
  permission: PropTypes.any.isRequired,
}

VehicleOwnerLink.defaultProps = {
  showCarInfo: null
}

export default VehicleOwnerLink
