import React, { useState, useEffect, useCallback } from "react"
import PropTypes from 'prop-types'
import SelectText from '@/views/fragments/form/SelectText'
import InputText from '@/views/fragments/form/InputText'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { formatterNumberOnly } from '@/utils/formatter'
import { MAX_LENGTH } from '@/utils/minMaxLength'
import { StyledAddresContainer } from './styles'
import { reqLocalidade } from "@/constants/requests"

const FormAddress = (props) => {
  const {
    formValues,
    setFormValues,
    validateList,
    setValidateList,
    isFilled,
    defaultDisabled
  } = props

  const [cepValidation, setCepValidation] = useState({})
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [loadingCEP, setLoadingCEP] = useState(false)
  const [cepField, setCepField] = useState(false)
  const [disableList, setDisableList] = useState({})  

  const setupCity = useCallback(async (city) => {
    const list = await reqLocalidade({state: city})
    setCityList(list.city)
  }, [])

  useEffect(() => {
    if ((!cepField && isFilled) && formValues.uf) {
      setupCity(formValues.uf)
      const newDisable = {
        bairro: Boolean(formValues.bairro),
        localidade: Boolean(formValues.localidade),
        logradouro: Boolean(formValues.logradouro),
        uf: Boolean(formValues.uf),
        cep: Boolean(defaultDisabled ? false : formValues.cep),
      }
      setDisableList(newDisable)
      setLoadingCEP(false)
    }
  }, [isFilled, formValues])


  const setupStatesList = useCallback(async() => {
    const list = await reqLocalidade()
    setStateList(list.state)
  }, [])

  useEffect(() => {
    setupStatesList()
  }, [setupStatesList])

  const resetCep = () => {
    setFormValues({
      ...formValues,
      bairro: '',
      localidade: '',
      logradouro: '',
      uf: '',
    })
    setDisableList({})
    setCepValidation({})
  }

  const getCEPError = () => {
    // setCepValidation({
    //   msg: 'CEP inválido',
    //   status: true,
    // })
    setLoadingCEP(false)
    // setDisableList({})
  }

  const handleBlur = async (e) => {
    if (!e.target.value) {
      resetCep()
      return
    }

    setLoadingCEP(true)
    const { cep } = formValues
    const req = await reqLocalidade({zipcode: formatterNumberOnly(cep)})
    
    if (!req || !req?.status?.active) {
      getCEPError()
      return
    }

    const list = await reqLocalidade({state: req.zipcode.uf})
    setCityList(list.city)

    const newForm = {
      ...formValues,
      bairro: req.zipcode.bairro,
      localidade: req.zipcode.localidade,
      logradouro: req.zipcode.logradouro,
      uf: req.zipcode.uf,
    }

    setFormValues(newForm)

    setCepValidation({
      msg: '',
      status: false,
    })

    setLoadingCEP(false)

    setDisableList({
      bairro: Boolean(req.zipcode.bairro),
      localidade: Boolean(req.zipcode.localidade),
      logradouro: Boolean(req.zipcode.logradouro),
      uf: Boolean(req.zipcode.uf),
    });

    setValidateList({
      ...validateList,
      bairro: Boolean(!req.zipcode.bairro),
      localidade: Boolean(!req.zipcode.localidade),
      logradouro: Boolean(!req.zipcode.logradouro),
      uf: Boolean(!req.zipcode.uf),
    })

    setCepField(true)
  }

  const onChangeUF = async (e) => {
    const { name, value } = e.target
    setLoadingCEP(true)

    const list = await reqLocalidade({state: value})

    setCityList(list.city)

    setFormValues({
      ...formValues,
      [name]: value,
    })
    setLoadingCEP(false)
    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  const onChange = (e, ml) => {
    let { name, value } = e.target;
    var blockSpecialRegex = /[-]/g;
    
    let newValue = value.replace(String.fromCharCode(9), " ");

    if (name === 'cep') {
      if (newValue.includes("-")) {
        newValue = value.replace(blockSpecialRegex, "")
        if (newValue.length > ml) return
      }
    } else {
      if (newValue.length > ml) return
    }

    setFormValues({
      ...formValues,
      [name]: newValue,
    })

    setValidateList({
      ...validateList,
      [name]: false,
    })
  }

  return (
    <StyledAddresContainer className="address">
      <InputText
        label="CEP"
        name="cep"
        isRequired
        hint={cepValidation.msg || REQUIRED_FIELD}
        isError={cepValidation.status || validateList.cep}
        className="cep"
        value={formValues.cep || ''}
        onChange={(e) => onChange(e, MAX_LENGTH.oito)}
        isLoading={loadingCEP}
        onBlur={handleBlur}
        isDisabled={disableList.cep}
      />
      <InputText
        label="Logradouro"
        name="logradouro"
        isRequired
        isError={validateList.logradouro}
        hint={REQUIRED_FIELD}
        className="logradouro"
        value={formValues.logradouro || ''}
        isLoading={loadingCEP}
        isDisabled={disableList.logradouro}
        onChange={onChange}
        placeholder="Mínimo 1 caracteres"
      />
      <InputText
        label="Número"
        name="numero"
        isRequired
        isError={validateList.numero}
        hint={REQUIRED_FIELD}
        className="numero"
        value={formValues.numero || ''}
        onChange={onChange}
        isDisabled={disableList.numero}
      />
      <SelectText
        label="Estado"
        name="uf"
        isRequired
        isError={validateList.uf}
        hint={REQUIRED_FIELD}
        className="estado"
        options={stateList}
        isLoading={loadingCEP}
        isDisabled={disableList.uf}
        value={formValues.uf || ''}
        onChange={onChangeUF}
      />
      <SelectText
        label="Cidade"
        name="localidade"
        isRequired
        options={cityList}
        hint={REQUIRED_FIELD}
        className="cidade"
        isError={validateList.localidade}
        value={formValues.localidade || ''}
        isLoading={loadingCEP}
        onChange={onChange}
        isDisabled={disableList.localidade}
      />
      <InputText
        label="Bairro"
        name="bairro"
        isRequired
        isError={validateList.bairro}
        hint={REQUIRED_FIELD}
        className="bairro"
        isDisabled={disableList.bairro}
        value={formValues.bairro || ''}
        isLoading={loadingCEP}
        onChange={onChange}
        placeholder="Mínimo 1 caracteres"
      />
      <InputText
        label="Complemento"
        name="complemento"
        className="complemento"
        value={formValues.complemento || ''}
        onChange={onChange}
      />
    </StyledAddresContainer>
  )
}

FormAddress.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  validateList: PropTypes.objectOf(PropTypes.any).isRequired,
  setFormValues: PropTypes.func.isRequired,
  setValidateList: PropTypes.func.isRequired,
  isFilled: PropTypes.bool,
  defaultDisabled: PropTypes.bool,
}

FormAddress.defaultProps = {
  isFilled: false,
  defaultDisabled: null
}

export default FormAddress
