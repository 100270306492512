import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from '@/views/fragments/Modal/Modal'
import FormRegisterShipper from '../FormRegisterShipper'
import {
  StyledCardContainer,
  StyledNav,
  StyledButtonsConfirm,
  StyledContinueButton,
} from '../styles'
import ShipperCard from '@/views/fragments/ShipperCard'
import { reqSubmitDeleteEmbarcador } from '@/constants/requests'
import { useSelector } from 'react-redux'

const MSG = {
  register: 'Associar Embarcador'
}

const MODAL_TYPE = {
  register: 'register',
  delete: 'delete',
}

const AssociateShipper = (props) => {
  const { onSave, shipper, editFlow } = props
  const [showModal, setShowModal] = useState(false)
  const [toEditData, setToEditData] = useState({})
  const [editMsg, setEditMsg] = useState(false)
  const [formValues, setFormValues] = useState([])
  const [indexEdit, setIndexEdit] = useState()
  const [indexRemove, setIndexRemove] = useState(null)
  const [modalType, setModalType] = useState('')
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    setFormValues(shipper)
  }, [shipper])

  const onEditClick = (i) => {
    setEditMsg(true)
    setToEditData(formValues[i])
    setIndexEdit(i)
    setShowModal(true)
    setModalType(MODAL_TYPE.register)
  }

  const onCloseModal = () => {
    setShowModal(false)
    setModalType('')
    setIndexRemove(null)
    setShowMessageModal(false)
    setMessageModal('')
  }

  const handleAdd = () => {
    if(!permission?.clients?.changeClientData) {
      setShowMessageModal(true)
      setMessageModal("Você não tem permissão para cadastrar embarcadores!")
      return
    }
    setEditMsg(false)
    setToEditData({})
    setIndexEdit()
    setShowModal(true)
    setModalType(MODAL_TYPE.register)
  }

  const handleSaveRegister = (form) => {
    const newArray = [...formValues]
    onCloseModal()

    if (indexEdit >= 0) {
      newArray[indexEdit] = form

      setFormValues(newArray)
      if (editFlow) {
        onSave(form, 'edicao' )
      }
      return
    }

    newArray.push(form)
    setFormValues(newArray)

    if (editFlow) {
      if(editMsg) {
        onSave(form, 'edicao')
      } else {
        onSave(form, 'cadastro')
      }
    }
    return
  }

  const onConfirmDelete = async () => {
    const newArray = [...formValues]  
    await reqSubmitDeleteEmbarcador(newArray[indexRemove])
    newArray.splice(indexRemove, 1)
    setFormValues(newArray)
    onCloseModal()
  }

  const handleRemove = (index) => {
    setModalType(MODAL_TYPE.delete)
    setShowModal(true)
    setIndexRemove(index)
  }

  const renderCard = (user, i) => (
    <ShipperCard
      index={i}
      onEdit={onEditClick}
      onDelete={handleRemove}
      key={i}
      name={user.shipper}
      code={user.integrationCode}
    />
  )

  const renderModal = () => {
    if (modalType === MODAL_TYPE.register) {
      return (
        <Modal
          closeModal={onCloseModal}
          title={editMsg ? 'Editar' : 'Cadastro'}
          size="md"
          subTitle={editMsg ? null : MSG.register}
        >
          <FormRegisterShipper
            handleCancel={onCloseModal}
            formValues={toEditData}
            onSave={handleSaveRegister}
          />
        </Modal>
      )
    }

    if (modalType === MODAL_TYPE.delete) {
      return (
        <Modal
          title="Excluir?"
          subTitle="Clique em confirmar para remover o embarcador selecionado"
          type='alert'
          closeModal={onCloseModal}
        >
          <StyledButtonsConfirm>
            <button
              className='button secondary'
              onClick={() => onCloseModal()}
            >
              Cancelar
            </button>
            <button
              className='button'
              onClick={() => onConfirmDelete()}
            >
              Confirmar
            </button>
          </StyledButtonsConfirm>
        </Modal>
      )
    }
  }

  const renderMessageModal = () => (
    <Modal
      subTitle={messageModal}
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const handleSave = (e) => {
    e.preventDefault()
    onSave(formValues)
  }

  return (
    <>
      {showModal && renderModal()}
      {showMessageModal && renderMessageModal()}
      <StyledNav>
        <button className='button' onClick={() => handleAdd()}>
          <i className="fas fa-map-marker-alt" />
          Associar novo
        </button>
      </StyledNav>
      
      <StyledCardContainer>
        {formValues.map((user, index) => renderCard(user, index))}
      </StyledCardContainer>

      {!editFlow && (
        <StyledContinueButton className='button continuar' onClick={handleSave}>
          <i className="fas fa-angle-double-right" />
          Continuar
        </StyledContinueButton>
      )}
    </>
  )
}

AssociateShipper.propTypes = {
  onSave: PropTypes.func.isRequired,
  shipper: PropTypes.arrayOf(PropTypes.any),
  editFlow: PropTypes.bool,
}

AssociateShipper.defaultProps = {
  shipper: [],
  editFlow: false,
}

export default AssociateShipper
