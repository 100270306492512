import styled from '@emotion/styled'

export const StyledCard = styled.div`
  border: 1px solid var(--color-gray-dark);
  border-radius: 5px;
  position: relative;

  .pencil, .trash, .toggle {
    font-size: 18px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .toggle {
    top: 0;
    left: 0;

    .fa-toggle-on {
      color: var(--color-primary);
    }
  }

  .pencil {
    top: 0;
    right: 0;

    &:hover {
      color: var(--color-warning);
    }
  }

  .trash {
    bottom: 0;
    right: 0;
    
    &:hover {
      color: var(--color-alert);
    }
  }

  p {
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-of-type {
      margin: 0;
    }

    span {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
    }

    &.strong{
      font-weight: 600;
    }

    i {
      margin-right: 5px;
    }
  }

  .content-head{
    width: 100%;
    background-color: var(--color-gray-dark);
    color: var(--color-light);
    padding: 10px 0 10px 10px;
    display: grid;
    grid-template-columns: calc(100% - 40px) 40px;

    .textHead {
      display: grid;
      grid-template-columns: 100%;

      @media (min-width: 820px) {
        grid-template-columns: 50% 50%;
        align-items: center;

        p {
          margin: 0;
        }
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 50% 50%;
      align-content: center;
    }
  }

  .contentInfo {
    padding: 10px;

    div > p {
      padding-right: 10px;
      text-overflow: initial;
      overflow: initial;
      white-space: normal;
    }

    @media (min-width: 520px) {
      padding-top: 20px;
      display: grid;
      grid-template-columns: 230px calc(100% - 230px);
    }

    @media (min-width: 820px) {
      grid-template-columns: 66% 33%;

      & > div {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
  }
`

export const StyledFieldset = styled.fieldset`
  border: 1px solid var(--color-gray-dark);
  padding: 10px 10px 0;
  border-radius: 7px;
  margin: 20px 0 0;

  legend {
    color: var(--color-gray-dark);
    font-size: 15px;
    padding: 0 5px;
  }

  ul {
    text-align: center;

    li {
      background-color: var(--color-primary);
      padding: 10px 10px;
      display: inline-block;
      margin: 0 8px 8px 0;
      color: var(--color-light);
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (min-width: 520px) {
    margin-top: 0;
  }
`
