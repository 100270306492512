import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectText from '@/views/fragments/form/SelectText'
import InputText from '@/views/fragments/form/InputText'
import { REQUIRED_FIELD } from '@/constants/parameters'
import { formatterNumberOnly } from '@/utils/formatter'
import { MAX_LENGTH } from '@/utils/minMaxLength'
import { StyledForm } from '@/views/Register/fragments/styles'
import { reqLocalidade } from '@/constants/requests'

const RegisterCnhForm = (props) => {
  const {
    formValues,
    validateList,
    handleFormValues,
    handleValidateList,
    defaultOptions
  } = props

  const [stateList, setStateList] = useState([])

  const setupStatesList = useCallback(async() => {
    const list = await reqLocalidade()
    setStateList(list.state)
  }, [])

  useEffect(() => {
    setupStatesList()
  }, [setupStatesList])


  const onChange = (e, ml) => {
    const { name, value } = e.target
    if (value.length > ml) return

    let newValue = value.replace(String.fromCharCode(9), " ");

    handleFormValues({
      ...formValues,
      [name]: newValue,
    })

    handleValidateList({
      ...validateList,
      [name]: false,
    })
  }

  return (
    <>
      <StyledForm className='cnh'>
        <InputText
          label="CNH"
          name="cnh"
          className="cnh"
          placeholder="10 dígitos"
          isRequired
          isError={validateList.cnh}
          hint={REQUIRED_FIELD}
          value={formatterNumberOnly(formValues.cnh) || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.dez)}
        />
        <InputText
          label="Registro CNH"
          name="cnhRegister"
          isRequired
          isError={validateList.cnhRegister}
          hint={REQUIRED_FIELD}
          className="cnhRegister"
          value={formatterNumberOnly(formValues.cnhRegister) || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
        />
        <InputText
          label="Validade"
          name="cnhDateExpire"
          isRequired
          isError={validateList.cnhDateExpire}
          hint={REQUIRED_FIELD}
          type="date"
          className="cnhDateExpire"
          value={formValues.cnhDateExpire || ''}
          onChange={onChange}
        />
        <SelectText
          label="Categoria"
          name="cnhCategory"
          isRequired
          isError={validateList.cnhCategory}
          hint={REQUIRED_FIELD}
          className="cnhCategory"
          options={defaultOptions?.categorias_cnh}
          value={formValues.cnhCategory || ''}
          onChange={onChange}
        />
        <InputText
          label="1ª Habilitação"
          name="firstCnh"
          isRequired
          isError={validateList.firstCnh}
          hint={REQUIRED_FIELD}
          type="date"
          className="firstCnh"
          value={formValues.firstCnh || ''}
          onChange={onChange}
        />
        <SelectText
          label="Estado Emissor"
          name="cityFromCnh"
          isRequired
          isError={validateList.cityFromCnh}
          hint={REQUIRED_FIELD}
          className="cityFromCnh"
          options={stateList}
          value={formValues.cityFromCnh || ''}
          onChange={onChange}
        />
        <InputText
          label="Cód. Segurança CNH"
          name="securityCode"
          isRequired
          isError={validateList.securityCode}
          hint={REQUIRED_FIELD}
          className="securityCode"
          placeholder="11 dígitos"
          value={formValues.securityCode || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
        />
        <InputText
          label="Renach CNH"
          name="renachCnh"
          isRequired
          isError={validateList.renachCnh}
          hint={REQUIRED_FIELD}
          className="renachCnh"
          placeholder="11 dígitos"
          value={formValues.renachCnh || ''}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
        />
      </StyledForm>
    </>
  )
}

RegisterCnhForm.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  validateList: PropTypes.objectOf(PropTypes.any),
  handleFormValues: PropTypes.func,
  handleValidateList: PropTypes.func,
  defaultOptions: PropTypes.objectOf(PropTypes.array)
}

RegisterCnhForm.defaultProps = {
  validateList: {},
  handleFormValues: null,
  handleValidateList: null,
  defaultOptions: null
}

export default RegisterCnhForm
