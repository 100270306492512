import React, { useRef, useState, useEffect } from 'react'
import { formatterCPF, formatterNumberOnly } from '@/utils/formatter'
import { MAX_LENGTH } from '@/utils/minMaxLength'
import Acordeon from '@/views/fragments/Acordeon'
import InputText from '@/views/fragments/form/InputText'
import ContainerTemplate from '../fragments/ContainerTemplate'
import Warnin from '@/views/fragments/Warnin'
import { StyledButtons, StyledInputs, StyledCard } from './styles'
import { postConsult } from '@/constants/requests'
import ReactToPrint from 'react-to-print'
import Modal from '@/views/fragments/Modal'
import { useSelector } from 'react-redux'

const PlateConsult = () => {
  const [documentNumber, setDocumentNumber] = useState('')
  const [registerFound, setRegisterFound] = useState(null)
  const [showWarning, setShowWarning] = useState(null)
  const [plates, setPlates] = useState([{placa: ''}])
  const [showModal, setShowModal] = useState(false)
  const componentRef = useRef();

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.check.view) {
      window.location.href = "/";
      return
    }
  }, [])

  const onChange = (e, ml) => {
    const { value } = e.target
    const newValue = formatterNumberOnly(value)

    if (newValue.length > ml) return

    setDocumentNumber(newValue)
    setShowWarning(null)
  }

  const onChangePlate = (e, i) => {
    let { value } = e.target
    const newArray = [...plates]

    if (value.length > 7) {
      return
    }

    value = value.replaceAll(' ', '')

    newArray[i] = {
      ...newArray[i],
      'placa': value
    }

    setPlates(newArray)
    setShowWarning(null)
  }

  const handleSearch = async () => {
    if (!documentNumber.length && !plates[0].placa) {
      setShowWarning(`Você deve preencher ao menos um dos campos`)
      return
    }

    setShowModal(true)
  }

  const executeSearch = async () => {

    let consultas = []

    if (documentNumber !== null && documentNumber !== "" && documentNumber !== undefined) {
      consultas.push({"objeto": documentNumber})
    } 

    for (let i = 0; i < plates.length; i++) {
      const element = plates[i];
      if(element.placa !== null && element.placa !== "" && element.placa !== undefined) {
        consultas.push({"objeto": element.placa})      
      }
    }

    let result = await postConsult({"consultas": consultas})

    if (result.objetos[0].cpf !== undefined) {
      result.pessoa = result.objetos[0]
      result.objetos.shift()
    }
    
    setRegisterFound(result)
    setShowModal(false)
  }

  const handleAddInput = () => {
    const newArray = [...plates]

    newArray.push({placa: ''})
    setPlates(newArray)
  }

  const handleDeletePhone = (i) => {
    const newArray = [...plates]
    newArray.splice(i, 1)
    setPlates(newArray)
  }

  const renderCars = (el, i) => (
    <li key={i}>
      <div>
        <span>Veículo {i + 1}:</span>
        <span>{el.placa || '-'}</span>
      </div>
      <div>
        <span>Status:</span>
        <span>{el.status || 'não existe pesquisa para o objeto'}</span>
      </div>
      <div>
        <span>Vinculo:</span>
        <span>{el.vinculo || '-'}</span>
      </div>
      {el.status === 'DESACORDO' ? (
        <div>
          <span>Motivo:</span>
          <span>{el.motivo || '-'}</span>
        </div>
      ) : (
        <div>
          <span>Prazo:</span>
          <span>{el.prazo || '-'}</span>
        </div>
      )}
    </li>
  )

  const renderCard = () => (
    <StyledCard>
      <div>
        <div>
          <span>Código da Consulta:</span>
          <span>{registerFound.codigo_consulta || '-'}</span>
        </div>
        <div>
          <span>Solicitado:</span>
          <span>{registerFound.solicitado || '-'}</span>
        </div>
        <div>
          <span>Empresa Bilhetada:</span>
          <span>{registerFound.empresa_bilhetada}</span>
        </div>
        <div>
          <span>Empresa Solicitante:</span>
          <span>{registerFound.empresa_solicitante || '-'}</span>
        </div>
        <div>
          <span>Usuário Solicitante:</span>
          <span>{registerFound.solicitante || '-'}</span>
        </div>
      </div>
        <ul>
          {registerFound.pessoa !== undefined && (
            <li>
              <div>
                <span>Pessoa:</span>
                <span>{registerFound.pessoa.cpf || '-'}</span>
              </div>
              <div>
                <span>Status:</span>
                <span>{registerFound.pessoa.status || '-'}</span>
              </div>
              <div>
                <span>Vinculo:</span>
                <span>{registerFound.pessoa.vinculo || '-'}</span>
              </div>
              {registerFound.pessoa.status === 'DESACORDO' ? (
                <div>
                  <span>Motivo:</span>
                  <span>{registerFound.pessoa.motivo || '-'}</span>
                </div>
              ) : (
                <div>
                  <span>Prazo:</span>
                  <span>{registerFound.pessoa.prazo || '-'}</span>
                </div>
              )}
            </li>
          )}
          {registerFound.objetos.map((el, i) => renderCars(el, i))}
        </ul>
      <div style={{"justifyContent": "right"}}>        
        <ReactToPrint
          trigger={() => <button className='button'> <i className="fa-solid fa-print"></i> </button>}
          content={() => componentRef.current}
        />
      </div>
    </StyledCard>
  )

  const renderModal = () => (
    <Modal
      closeModal={() => setShowModal(false)}
      subTitle="Consulta sujeita a cobrança, confirma?"
    >
      <button
        className='button cancel'
        onClick={() => setShowModal(false)}
      >Não</button>

        <button
        className='button'
        onClick={executeSearch}
      >Sim</button>
    </Modal>
  )

  return (
    <ContainerTemplate>
      {showModal && renderModal()}
      <aside>
        <h3>Nova Consulta</h3>
        <InputText
          label="CPF"
          name="cpf"
          className="cpf"
          value={formatterCPF(documentNumber)}
          onChange={(e) => onChange(e, MAX_LENGTH.onze)}
        />
        {showWarning && (<Warnin msg={showWarning} />)}
        <StyledInputs>
          <span><p>e / ou</p></span>
          {plates.map((e, index) => (
            <div key={index}>
              <InputText
                label={`Placa ${index + 1}`}
                name="placa"
                value={e.placa}
                onChange={(el) => onChangePlate(el, index)}
              />
              {plates.length >= 2 && (
                <button type='button' onClick={() => handleDeletePhone(index)}>
                  <i className="fa-solid fa-trash" />
                </button>
              )}
            </div>
          ))}
        </StyledInputs>

        <StyledButtons>
          <button className='button' onClick={handleSearch}>
            <i className="fas fa-search" />
          </button>

          {plates.length < 4 && (
            <button className='button' onClick={handleAddInput}>
              <i className="fa-solid fa-plus" />
              Placa
            </button>
          )}
        </StyledButtons>
      </aside>
      <section id="consulta" ref={componentRef}>
        {registerFound ? (
          <Acordeon
            title="Resultado da Consulta"
            icon="fa-brands fa-searchengin"
            showContent
          >
            {renderCard()}
          </Acordeon>
        ) : (
          <h4>
            Nenhum Cadastro Encontrado
            <i className="fa-brands fa-searchengin" />
          </h4>
        )}
      </section>
    </ContainerTemplate>
  )
}

export default PlateConsult
