const vehicleMold = (el, isBond) => {
  const vehicle = {
    placa: el.car.placa,
    chassi: el.car.chassi,
    renavam: el.car.renavam,
    marca: el.car.marca,
    modelo: el.car.modelo,
    carroceria: el.car.carroceria,
    estado_veiculo: el.car.uf_vehicle,
    cidade_veiculo: el.car.city,
    ano: el.car.anoFab,
    ano_modelo: el.car.anoModelo,
    rntrc: el.car.rntrc,
    cpf_cnpj: el.owner.document,
    rg: el.owner?.rg,
    estado_rg: el.owner?.ufIssue,
    mae: el.owner?.motherName,
    pai: el.owner?.fatherName,
    nome_proprietario: el.owner.companyName,
    estado_proprietario: el.owner.uf,
    cidade_proprietario: el.owner.localidade,
    logradouro_proprietario: el.owner.logradouro,
    numero_proprietario: el.owner.numero,
    cep_proprietario: el.owner.cep,
    bairro_proprietario: el.owner.bairro,
    telefone1: el.car.phones[0],
    telefone2: el.car.phones[1] || null,
    telefone3: el.car.phones[2] || null,
    vinculo: isBond ? 'F' : el.car.vinculo,
    complemento: el.owner?.complemento,
    cor: el.car?.cor,
    tipo: el.car?.tipo_veiculo || el.car?.Carga
  }

  return vehicle
}

export const submitNewSearch = (data, isBond) => {
  let pessoa = null
  let veiculos = null

  if (data.motorista) {
    pessoa = {
      cpf: data.motorista.cpf,
      nome: data.motorista.name,
      nascimento: data.motorista.birthdate,
      mae: data.motorista.motherName,
      pai: data.motorista.fatherName,
      rg: data.motorista.rg,
      estado_rg: data.motorista.ufIssue,
      estado_moradia: data.motorista.uf,
      cidade_moradia: data.motorista.localidade,
      bairro: data.motorista.bairro,
      logradouro: data.motorista.logradouro,
      cep: data.motorista.cep,
      numero: data.motorista.numero,
      complemento: data.motorista.complemento,
      cnh: data.motorista.cnh,
      registro_cnh: data.motorista.cnhRegister,
      primeira_cnh: data.motorista.firstCnh,
      estado_cnh: data.motorista.cityFromCnh,
      vencimento_cnh: data.motorista.cnhDateExpire,
      codigo_cnh: data.motorista.securityCode,
      renach_cnh: data.motorista.renachCnh,
      categoria: data.motorista.cnhCategory,
      telefone1: data.motorista.phones[0],
      telefone2: data.motorista.phones[1] || null,
      telefone3: data.motorista.phones[2] || null,
      perfil: data.motorista.userType,
      vinculo: data.motorista.vinculo,
    }
  }

  if (data.veiculos?.length === undefined) {
    return {
      usuario: data.usuario,
      cnpj_centro_custos: data.cnpj_centro_custos,
      pessoa,
      veiculos
    }
  }

  let veiculosFilter = null
  veiculosFilter = data.veiculos?.filter((el) => el.car.phones[0] !== "")

  if (veiculosFilter.length) {
    veiculos = veiculosFilter.map((el) => vehicleMold(el, isBond))
  }

  return {
    usuario: data.usuario,
    cnpj_centro_custos: data.cnpj_centro_custos,
    pessoa,
    veiculos
  }
}

