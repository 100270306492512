import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextArea from '@/views/fragments/form/TextArea'
import { StyledContinueButton } from '@/views/Register/fragments/styles'
import {
  StyledEmailsToValidate,
  StyledContainer,
  StyledList,
} from './styles'
import { formatterEmailField } from '@/utils/formatter'

const RegisterEmails = (props) => {
  const { onSave, email, showSaveLabel } = props

  const [formEmails, setFormEmails] = useState('')
  const [loadPage, setLoadPage] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [emailList, setEmailList] = useState([])
  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(async() => {
    if(!loadPage) { 
      setFormEmails(email.emailForm)
      await handleFillEmails()
      setLoadPage(true)
      setFormEmails('')
    }
  }, [email, formEmails, loadPage])

  const setFormEmail = (value) => {
    let newValue = formatterEmailField(value)
    newValue = newValue.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");
    setFormEmails(newValue)
  }

  const renderEmailList = (email, i) => (
    <li key={i}>
      {email}
    </li>
  )

  const checkSemicolon = (text) => {
    return text.includes(';')
  }

  const checkAtSign = (text) => {
    return text.includes('@')
  }

  const checkDot = (text) => {
    return text.includes('.')
  }

  const checkGreaterOrLess = (text) => {
    const greater = text.includes('>')
    const less = text.includes('<')
    return greater || less
  }

  const clearDuplicate = (list) => {
    return list.filter((el, index) => list.indexOf(el) === index)
  }

  const greaterOrLessSetup = (list) => {
    const reg = /[^< ]+(?=>)/g
    const newList = [...list]
    formEmails.match(reg).map((el) => {
      if (checkAtSign(el)) return newList.push(el)
    })

    setEmailList(clearDuplicate(newList))
    setFormEmails('')
    setDisabledButton(false)
  }

  const handleSave = () => {
    onSave(emailList)
  }

  const handleFillEmails = async () => {
    setShowErrorMsg(false)
    
    const hasSemicolon = checkSemicolon(formEmails)
    const hasAtSign = checkAtSign(formEmails)
    const greaterOrLessThan = checkGreaterOrLess(formEmails)
    const newList = [...emailList]

    if(!hasSemicolon && !hasAtSign) return setShowErrorMsg(true)

    if (greaterOrLessThan) return greaterOrLessSetup(newList)

    if (!hasSemicolon && hasAtSign) {
      const splited = formEmails.split('@')
      const hasDot = checkDot(splited[1])
      if (!hasDot) return setShowErrorMsg(true)
      setEmailList(clearDuplicate([
        ...newList,
        formEmails
      ]))
      setFormEmails('')
      setDisabledButton(false)
      return
    }

    let emailsToCheck = new Array()
    emailsToCheck = formEmails.split(';')

    emailsToCheck.map(((el) => {
      const thiEmailHasAtSign = checkAtSign(el)
      if (!thiEmailHasAtSign) return
      const complement = el.split('@')
      const thisEmailHasDot = checkDot(complement[1])
      if (thisEmailHasDot) {
        return newList.push(el.trim())
      }
    }))

    const clearList = newList.filter((el) => {
      return el !== undefined
    })

    setEmailList(clearDuplicate(clearList))
    setDisabledButton(false)
    setFormEmails('')
  }

  return (
    <>
      <StyledContainer>
        <StyledEmailsToValidate>
          <TextArea
            value={formEmails}
            name="emails"
            label="Para cadastro"
            placeholder="Insira uma lista separada por ;"
            onChange={(e) => setFormEmail(e.target.value)}
            isError={showErrorMsg}
            hint="Um ou mais emails no formato errado."
          />

          <div className='bottom'>
            <span>
              * Somente emails dentro do padrão &quot;nome@dominio.xx&quot; será considerado.
            </span>
            <button
              className="button"
              onClick={handleFillEmails}
            >
              Validar Emails
            </button>
          </div>
        </StyledEmailsToValidate>

        <StyledList>
          <span>Email Validado para Envio</span>
          <ul>
            {emailList.map((email, index) => renderEmailList(email, index))}
          </ul>
        </StyledList>
      </StyledContainer>
      <StyledContinueButton
        className="button"
        onClick={handleSave}
        disabled={disabledButton}
      >
        <i className="fas fa-angle-double-right" />
        {showSaveLabel ? 'Salvar' : 'Continuar'}
      </StyledContinueButton>
    </>
  )
}

RegisterEmails.propTypes = {
  onSave: PropTypes.func.isRequired,
  email: PropTypes.objectOf(PropTypes.any),
  showSaveLabel: PropTypes.bool,
}

RegisterEmails.defaultProps = {
  email: {},
  showSaveLabel: false,
}

export default RegisterEmails
