import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import Modal from '@/views/fragments/Modal'
import {
  formatterPhone,
  formatterCPForCNPJ,
} from '@/utils/formatter'
import RegisterCarForm from '@/views/Register/components/RegisterCarForm'
import RegisterOwnerForm from '@/views/Register/components/RegisterOwnerForm'
import { StyledForm } from './styles'

const timeZone = 'America/Sao_Paulo'
const MODAL_TYPES = {
  car: 'register-car',
  owner: 'owner',
}

const CarOwnerCards = (props) => {
  const {
    car,
    owner,
  } = props.values
  const { onSave, values } = props

  const [editType, setEditType] = useState('')
  const [showEditModal, setShowEditModal] = useState(false)
  const [formInUse, setFormInUse] = useState({})

  const handleCloseModal = () => {
    setShowEditModal(false)
    setEditType('')
    setFormInUse({})
  }

  const handleEditCar = () => {
    setEditType(MODAL_TYPES.car)
    setFormInUse(car)
    setShowEditModal(true)
  }

  const handleEditOwner = () => {
    setEditType(MODAL_TYPES.owner)
    setFormInUse(owner)
    setShowEditModal(true)
  }

  const renderPhones = (phone, i) => (
    <div key={i}>
      <span>Telefone {i + 1}:</span>
      <p>{formatterPhone(phone)}</p>
    </div>
  )

  const onSaveCar = (form) => {
    onSave({
      ...values,
      car: form,
    })
    handleCloseModal()
  }

  const onSaveOwner = (form) => {
    onSave({
      ...values,
      owner: form,
    })
    handleCloseModal()
  }

  const renderCarModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterCarForm
        onSave={onSaveCar}
        car={formInUse}
      />
    </Modal>
  )

  const renderOwnerModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="lg"
    >
      <RegisterOwnerForm
        onSave={onSaveOwner}
        owner={formInUse}
      />
    </Modal>
  )

  const renderEditModal = () => {
    if (editType === MODAL_TYPES.car) {
      return renderCarModal()
    }

    if (editType === MODAL_TYPES.owner) {
      return renderOwnerModal()
    }
  }

  return (
    <section>
      {showEditModal && (renderEditModal())}
      <Card
        title="Carro cadastrado"
        onEditClick={handleEditCar}
      >
        <StyledForm className="car">
          <div className="item-">
            <span>Placa:</span>
            <p>{car.placa}</p>
          </div>
          <div className="item-">
            <span>País:</span>
            <p>{car.country}</p>
          </div>
          <div className="item-">
            <span>Chassi:</span>
            <p>{car.chassi}</p>
          </div>
          <div className="item-">
            <span>Marca:</span>
            <p>{car.marca}</p>
          </div>
          <div className="item-">
            <span>Modelo:</span>
            <p>{car.modelo}</p>
          </div>
          <div className="item-">
            <span>Ano Fabricação:</span>
            <p>{car.anoFab}</p>
          </div>
          <div className="item-">
            <span>Ano Modelo:</span>
            <p>{car.anoModelo}</p>
          </div>
          <div className="item-">
            <span>Carroceria:</span>
            <p>{car.carroceria}</p>
          </div>
          <div className="item-">
            <span>Renavam:</span>
            <p>{car.renavam}</p>
          </div>
          <div className="item-">
            <span>Código de Segurança:</span>
            <p>{car.codSeguranca}</p>
          </div>
          <div className="item-">
            <span>Estado:</span>
            <p>{car.uf}</p>
          </div>
          <div className="item-">
            <span>Cidade:</span>
            <p>{car.city}</p>
          </div>
          <div className="item-">
            <span>Emissão:</span>
            <p>{format(
              new Date(zonedTimeToUtc(car.emissao, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
          <div className="item-">
            <span>RNTRC:</span>
            <p>{car.rntrc}</p>
          </div>
          <div className="item-">
            <span>Transferencia:</span>
            <p>{format(
              new Date(zonedTimeToUtc(car.transferencia, timeZone)), 'dd/MM/yyyy', { timeZone }
            )}</p>
          </div>
        </StyledForm>
      </Card>

      <Card
        title="Proprietário"
        onEditClick={handleEditOwner}
      >
        <StyledForm className="address">
          <div>
            <span>CPF / CNPJ:</span>
            <p>{formatterCPForCNPJ(owner.document)}</p>
          </div>
          <div className="item-1">
            <span>Nome / Razão Social:</span>
            <p>{owner.companyName}</p>
          </div>
          <div>
            <span>País do Documento:</span>
            <p>{owner.docCountry}</p>
          </div>
          <div className="item-2">
            <span>CEP:</span>
            <p>{owner.cep}</p>
          </div>
          <div className="item-3">
            <span>Rua:</span>
            <p>{owner.logradouro}</p>
          </div>
          <div>
            <span>Número:</span>
            <p>{owner.numero}</p>
          </div>
          <div>
            <span>Bairro:</span>
            <p>{owner.bairro}</p>
          </div>
          <div>
            <span>Cidade:</span>
            <p>{owner.localidade}</p>
          </div>
          <div>
            <span>Estado:</span>
            <p>{owner.uf}</p>
          </div>
          <div>
            <span>Complemento:</span>
            <p>{owner.complemento}</p>
          </div>
          {owner.phones.map((phone, index) => renderPhones(phone, index))}
        </StyledForm>
      </Card>
    </section>
  )
}

CarOwnerCards.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CarOwnerCards
