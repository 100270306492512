import styled from '@emotion/styled'

export const StyledForm = styled.form`
  label {
    display: inline-block;
    width: 100%;

    @media (min-width: 520px){
      &.name, &.birthdate, &.userType,
      &.country, &.maritalStatus, &.userGenre,
      &.ufBirth, &.cityOfBirth, &.rg, &.rgIssue,
      &.issueDpto, &.ufIssue, &.pais {
        width: calc(50% - 5px);
      }

      &:nth-of-type(even) {
        margin-right: 10px;
      }
    }

    @media (min-width: 840px) {
      &:nth-of-type(even) {
        margin-right: 0;
      }

      &.cpf, &.birthdate,
      &.userGenre, &.fatherName, &.userType.second,
      &.ufBirth, &.cityOfBirth,
      &.rgIssue, &.rg, &.issueDpto {
        margin-right: 10px;
      }

      &.name, &.motherName, &.fatherName {
        width: calc(66.666665% - 5px);
      }

      &.cpf {
        width: calc(33.3333332% - 5px);
      }

      &.birthdate, &.userType, &.country,
      &.ufBirth, &.cityOfBirth, &.rg, &.rgIssue,
      &.issueDpto, &.ufIssue, &.pais {
        width: calc(33.3333332% - 6.6666667px);
      }
    }

  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(3, calc(33.333333% - 10px));
    grid-gap: 15px;

    label {
      width: 100% !important;
    }
  }
`

export const StyledFormButton = styled.button`
  width: 100%;

  @media (min-width: 724px) {
    width: 250px;
  }
`

export const StyledAddPhone = styled.button`
  background-color: var(--color-bg-dark);
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  clear: both;
  display: block;

  i {
    margin-left: 10px;
  }

  @media (min-width: 460px) {
    width: 200px;
  }
`

export const StyledPhoneField = styled.div`
  position: relative;

  label {
    margin: 0 0 20px;
  }

  &.hasTrash {
    label {
      display: inline-block;
      width: calc(100% - 40px);
      margin-right: 10px;

      @media (min-width: 1280px) {
        width: calc(100% - 40px) !important;
      }
    }

    .fa-trash-alt {
      clear: right;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center;
      vertical-align: middle;
      font-size: 24px;
      line-height: 30px;
      margin-top: 30px;
      color: var(--color-gray-light);

      &:hover {
        color: var(--color-alert);
      }
    }
  }
`

export const StyledPhoneContainer = styled.div`
  @media (min-width: 460px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-gap: 20px;
  }

  @media (min-width: 724px) {
    display: grid;
    grid-template-columns: repeat(3, calc(33.3333333% - 13.3333334px));
    grid-gap: 20px;
  }
`
