import React from 'react'
import { deleteParams } from '@/constants/localStorage'
import {
  StyledContainer,
} from './styles'

const ForbiddenPage = () => {
  const onButtonConfirm = () => {
    deleteParams()
    window.location = "/login"
  }

  return (
    <StyledContainer>
      <i className="far fa-sad-tear" />
      <h4>Ah Não!</h4>
      <p>Parece que você perdeu conexão. Quer tentar reconectar?</p>
      <button className='button' onClick={onButtonConfirm}>Sim</button>
    </StyledContainer>
  )
}

export default ForbiddenPage
