import React, { useState, useEffect, useCallback } from 'react'
import RegisterContainer from '@/views/Register/components/RegisterContainer'
import Acordeon from '@/views/fragments/Acordeon'
import RegisterPF from '@/views/Register/components/RegisterPF'
import RegisterAddress from '@/views/Register/components/RegisterAddress'
import RegisterCnh from '@/views/Register/components/RegisterCnh'
import Modal from '@/views/fragments/Modal'
import { fakeRequest } from '@/constants/requests'

const DEFAULT_FORM = {
  basicData: {
    country: '76',
    phones: [''],
  },
  address: {},
  cnh: {},
}

const USER_TYPE = 'M'

const DEFAULT_STATUS = {
  pf: { show: false, locked: true },
  address: { show: false, locked: true },
  cnh: { show: false, locked: true }
}

const PhysicalPersonRegister = () => {
  const [formValues, setFormValues] = useState({})
  const [toShow, setToShow] = useState(DEFAULT_STATUS)
  const [showModal, setShowModal] = useState(false)

  const setDefaultValues = useCallback(() => {
    setToShow({
      ...DEFAULT_STATUS,
      pf: { show: true, locked: false }
    })
    setFormValues(DEFAULT_FORM)
  },[])

  useEffect(() => {
    setDefaultValues()
  },[setDefaultValues])

  const onSavePF = (form) => {
    setFormValues({
      ...formValues,
      basicData: form,
    })

    setToShow({
      ...toShow,
      pf: { show: false, locked: false },
      address: { show: true, locked: false },
    })
  }

  const onSaveAddress = (form) => {
    setFormValues({
      ...formValues,
      address: form,
    })

    if (formValues?.basicData?.userType === USER_TYPE) {
      setToShow({
        ...toShow,
        address: { show: false, locked: false },
        cnh: { show: true, locked: false },
      })
      return
    }

    fakeRequest({
      ...formValues,
      cnh: form,
    })

    setShowModal(true)
  }

  const onSaveCnh = (form) => {
    setFormValues({
      ...formValues,
      cnh: form,
    })

    fakeRequest({
      ...formValues,
      cnh: form,
    })

    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
    setDefaultValues()
  }

  const renderModal = () => (
    <Modal
      title="Sucesso!"
      subTitle="Cadastro efetuado."
      type='success'
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const handleNavClick = (nav) => {
    if (toShow[nav].locked) return

    setToShow({
      ...toShow,
      [nav]: {
        ...toShow[nav],
        show: !toShow[nav].show,
      },
    })
  }

  return (
    <RegisterContainer
      title="Cadastro"
      subTitle="Pessoa Física"
      label="Embarcador Selecionado"
      client="PARMENES INFORMATION TECHNOLOGY LTDA"
    >
      {showModal && renderModal()}
      <Acordeon
        title="Dados Básicos"
        icon="fas fa-address-card"
        locked={toShow.pf.locked}
        showContent={toShow.pf.show}
        handleClickNav={() => handleNavClick('pf')}
        >
        <RegisterPF
          basicData={formValues?.basicData}
          onSave={onSavePF}
        />
      </Acordeon>

      <Acordeon
        locked={toShow.address.locked}
        showContent={toShow.address.show}
        title="Endereço"
        icon="fas fa-home"
        handleClickNav={() => handleNavClick('address')}
      >
        <RegisterAddress
          address={formValues?.address}
          onSave={onSaveAddress}
          showSaveLabel={formValues?.basicData?.userType !== USER_TYPE}
        />
      </Acordeon>

      {formValues?.basicData?.userType === USER_TYPE && (
        <Acordeon
          title="CNH"
          icon="fas fa-car"
          locked={toShow.cnh.locked}
          showContent={toShow.cnh.show}
          handleClickNav={() => handleNavClick('cnh')}
        >
          <RegisterCnh
            cnh={formValues?.cnh}
            onSave={onSaveCnh}
          />
        </Acordeon>
      )} 

    </RegisterContainer>
  )
}

export default PhysicalPersonRegister
