import styled from '@emotion/styled'

export const StyledCard = styled.div`
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  padding: 20px 10px 25px 10px;
  position: relative;

  .pencil, .trash, .toggle {
    position: absolute;
    font-size: 18px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .toggle {
    top: 0;
    left: 0;

    .fa-toggle-on {
      color: var(--color-primary);
    }
  }

  .pencil {
    top: 0;
    right: 0;

    &:hover {
      color: var(--color-primary);
    }
  }

  .trash {
    bottom: 0;
    right: 0;
    
    &:hover {
      color: var(--color-alert);
    }
  }

  p {
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-of-type {
      margin: 0;
    }

    span {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
    }

    &.strong{
      font-weight: 600;
    }

    i {
      margin-right: 5px;
    }
  }
`
