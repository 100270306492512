import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { validateCNHFields } from '@/validators/registerValidations'
import { StyledFormButton } from '@/views/Register/fragments/styles'
import RegisterCnhForm from './RegisterCnhForm'
import { getOptions } from '@/constants/requests'
import { setupOptionsList } from '@/constants/setupOptions'

const RegisterCnh = (props) => {
  const { onSave, cnh, showSaveLabel } = props
  const [formValues, setFormValues] = useState({})
  const [validateList, setValidateList] = useState({})
  const [defaultOptions, setDefaultOptions] = useState(null)

  const setupOptions = useCallback(async () => {
    const options = await getOptions()
    const list = setupOptionsList(options)

    if (options.status.active) {
      setDefaultOptions(list)
    }
  }, [])

  useEffect(() => {
    setupOptions()
  }, [])

  useEffect(() => {
    setFormValues(cnh)
  },[cnh])

  const onChange = (form) => {
    setFormValues(form)
  }

  const handleValidateList = (form) => {
    setValidateList(form)
  }

  const onSaveClick = (e) => {
    e.preventDefault()

    const list = validateCNHFields(formValues)

    if (list.status) {
      setValidateList(list)
      return
    }

    onSave(formValues)
  }

  return (
    <>
      <RegisterCnhForm
        formValues={formValues}
        validateList={validateList}
        handleFormValues={onChange}
        handleValidateList={handleValidateList}
        defaultOptions={defaultOptions}
      />
      <StyledFormButton className="button" onClick={onSaveClick}>
        <i className="far fa-save" />
        {showSaveLabel ? 'Salvar' : 'Finalizar'}
      </StyledFormButton>
    </>
  )
}

RegisterCnh.propTypes = {
  onSave: PropTypes.func.isRequired,
  cnh: PropTypes.objectOf(PropTypes.any),
  showSaveLabel: PropTypes.bool,
}

RegisterCnh.defaultProps = {
  cnh: {},
  showSaveLabel: false,
}

export default RegisterCnh
