import styled from "@emotion/styled"

export const StyledWarning = styled.div`
  background-color: var(--color-warning);
  padding: 10px;
  color: var(--color-secondary);
  margin: 10px 0;
  font-weight: 600;
  text-align: center;
`
