import styled from '@emotion/styled'

export const ContainerSearch = styled.section`
  padding: 100px 0;

  aside {
    width: 100%;
    border-bottom: 2px solid var(--color-secondary);

    h3 {
      margin-bottom: 30px;
      background-color: var(--color-primary);
      line-height: 20px;
      padding: 10px;
      text-align: center;
      border-radius: 10px 10px 0 0;
      color: var(--color-light);
    }

    label, .button {
      margin: 0;
    }

    .button {
      margin: 10px 0 20px;
    }

    @media (min-width: 1024px) {
      width: 270px;
      display: inline-block;
      vertical-align: top;
    }
  }

  & > section {
    margin: 0;
    width: 100%;
    padding: 10px 0 0;

    & > section {
      width: 100%;
    }

    h4 {
      padding: 50px 0;
      font-size: 30px;
      color: var(--color-gray-light);
      text-align: center;

      i {
        font-size: 60px;
        display: block;
        margin-top: 20px;

      }
    }
    @media (min-width: 1024px) {
      padding: 0;
      width: calc(100% - 290px);
      display: inline-block;
      margin-left: 20px;
    }
  }

  @media (min-width: 1024px) {
    padding: 100px 10px 0;

    aside {
      border: none;
    }
  }

  @media (min-width: 1420px) {
    max-width: 1420px;
  }
`
