import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCard
} from './styles'

const EmailCard = (props) => {
  const {
    onDelete,
    index,
    email,
  } = props

  const handleRemove = () => {
    onDelete(index)
  }

  return (
    <StyledCard>
      <p title={'Email'}>
        <i className="far fa-envelope" />
        {email}
      </p>
      <div className='trash'
        onClick={() => handleRemove()}
      >
        <i className="far fa-trash-alt" />
      </div> 
    </StyledCard>
  )
}

EmailCard.propTypes = {
  email: PropTypes.any.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default EmailCard
