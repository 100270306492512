import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledSelected,
  StyledTopContainer,
  StyledInfo,
} from './styles'

const RegisterContainer = (props) => {
  const {
    children,
    title,
    subTitle,
    label,
    client,
  } = props

  return (
    <section className='container'>
      <StyledTopContainer>
        <StyledInfo>
          <h2>{title}</h2>
          <h4>
            <i className="fas fa-level-up-alt" />
            {subTitle}
          </h4>
        </StyledInfo>

        <StyledSelected>
          <span>{label}</span>
          <p>{client}</p>
        </StyledSelected>
      </StyledTopContainer>
      {children}
    </section>
  )
}

RegisterContainer.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
}

RegisterContainer.defaultProps = {
  children: null,
}

export default RegisterContainer
