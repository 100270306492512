import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
  EMBARCADOR_CADASTRO,
  TRANSPORTADOR_CADASTRO,
  PESSOA_FISICA_CADASTRO,
  VEICULO_PROPRIETARIO_CADASTRO,
  PESSOA_FISICA_CONSULTA,
  VEICULO_PROPRIETARIO_CONSULTA,
  TRANSPORTADOR_CONSULTA,
  EMBARCADOR_CONSULTA,
  LOGIN,
  FORBIDDEN,
  BUSCAR,
  NOVA_BUSCA,
  ANALYSIS_PROFILE,
  ANALYSIS_PROFILE_BUSCA,
  RELOGIN,
  MANUAL_BLOCK,
  BLACK_LIST,
  PLATE_CONSULT,
  CAR_LIST_CONFIG,
  PRIVILEGES,
  BILLINGS,
  MANAGERIAL,
  TERM,
} from './constants/parameters'
import Header from './views/Header'
import Modal from './views/fragments/Modal'
import Login from './views/Login'
import Administracao from './views/Administracao'
import ForbiddenPage from './views/ForbiddenPage'
import EmbarcadorRegister from './views/Register/EmbarcadorRegister'
import TransportadorRegister from './views/Register/TransportadorRegister'
import PhysicalPersonRegister from './views/Register/PhysicalPersonRegister'
import CarOwnerRegister from './views/Register/CarOwnerRegister'
import SearchPhysicalPerson from './views/Search/SearchPhysicalPerson'
import SearchCarOwner from './views/Search/SearchCarOwner'
import SearchTransportador from './views/Search/SearchTransportador'
import SearchEmbarcador from './views/Search/SearchEmbarcador'
import Pesquisa from './views/Search/UserSearch/Pesquisa'
import NovaPesquisa from './views/Search/UserSearch/NovaPesquisa'
import PlateConsult from './views/Search/PlateConsult'
import AnalysisProfile from './views/Register/AnalysisProfile'
import SearchAnalysisProfile from './views/Search/SearchAnalysisProfile'
import ReloginPage from './views/ReloginPage'
import ManualBlock from './views/Register/ManualBlock'
import BlackList from './views/Register/BlackList'
import CarListConfig from './views/Register/CarListConfig'
import {
  getAllParams,
  deleteParams,
  setParams,
} from './constants/localStorage'
import { getMenuProfile } from './constants/requests'
import Privileges from './views/Privileges'
import Billings from './views/Billings'
import Managerial from './views/Managerial'
import Term from './views/Term'

const INNER_WIDTH_PARAM = 1024

const DefaultRoute = () => {
  const [showHeader, setShowHeader] = useState(false)
  const [showMenu, setShowMenu] = useState(window.innerWidth > INNER_WIDTH_PARAM)
  const [costCentre, setCostCentre] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentUser, setCurrentUser] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const resize = useCallback(() => {
    window.addEventListener('resize', () => {
      const checkMobile = window.innerWidth <= INNER_WIDTH_PARAM

      return setShowMenu(!checkMobile)
    })
  }, [])

  useEffect(async () => {
    const {
      auth,
      costCentreName,
      user,
    } = getAllParams()

    resize()

    const data = await getMenuProfile()

    console.log(data)

    setIsAdmin(1)

    if (auth) {
      const currentURL = window.location.pathname
      if (currentURL === `/${LOGIN}`) return
      setShowHeader(true)
    }
    if (costCentreName) setCostCentre(costCentreName)
    if (user) setCurrentUser(user)

    const currentURL = window.location.pathname
    const url = `/${LOGIN}`

    if (url === currentURL) return

    if (!costCentreName) {
      deleteParams()
      return window.location = url
    }

  }, [])

  const onChangeCostCentre = (name) => {
    setCostCentre(name.label)
    setShowHeader(true)
    setParams('costCentre', name.label)
    setParams('cnpjCostCentre', name.cnpj)
  }

  const onConfirmLogout = () => {
    deleteParams()
    window.location = '/login'
  }

  const handleLogout = () => {
    setShowModal(true)
  }

  const renderModal = () => (
    <Modal
      closeModal={() => setShowModal(false)}
      subTitle="Deseja fazer o logout?"
    >
      <button
        className='button cancel'
        onClick={() => setShowModal(false)}
      >Não</button>

        <button
        className='button'
        onClick={onConfirmLogout}
      >Sim</button>
    </Modal>
  )

  const handleUserInfo = (token, user, profile) => {
    setParams('authentication', token)
    setParams('user', user)
    setParams('profile', profile)
    setCurrentUser(user)
  }

  const getProtectedRoute = (screen) => {
    if (screen === "blacklist") {
      return <BlackList isMobile={!showMenu} />
    } else if (screen === "administracao") {
      return <Administracao />
    } else if (screen === "embarcador-register") {
      return <EmbarcadorRegister />
    } else if (screen === "transportador-register") {
      return <TransportadorRegister />
    } else if (screen === "fisica-register") {
      return <PhysicalPersonRegister />
    }
    else if (screen === "proprietario-register") {
      return <CarOwnerRegister />
    }
    else if (screen === "proprietario-consulta") {
      return <SearchCarOwner />
    }
    else if (screen === "fisica-consult") {
      return <SearchPhysicalPerson />
    }
    else if (screen === "manual-block") {
      return <ManualBlock isMobile={!showMenu} />
    }
    else if (screen === "analise-perfil") {
      return <AnalysisProfile />
    }
    else if (screen === "analise-perfil-busca") {
      return <SearchAnalysisProfile />
    }
    else if (screen === "car-list-config") {
      return <CarListConfig />
    }
    else if (screen === "embarcador-consulta") {
      return <SearchEmbarcador />
    }
    else if (screen === "transportador-consulta") {
      return <SearchTransportador />
    }
    else if (screen === "privileges") {
      return <Privileges />
    }
    else if (screen === "billings") {
      return <Billings />
    }
    else if (screen === "managerial") {
      return <Managerial />
    }
    else if (screen === "term") {
      return <Term />
    }
    
  }

  const ProtectedRoutes = (data) => {
    if (isAdmin) {
      return getProtectedRoute(data.screen)
    }
  
    return <ForbiddenPage/>;
  };

  return (
    <Router>
      {showModal && renderModal()}
      <Header
        showMenu={showMenu}
        costCentre={costCentre}
        showHeader={showHeader}
        onLogoutClick={handleLogout}
        user={currentUser}
      />

      <Routes>
        <Route
          path="/"
          element={<ProtectedRoutes screen={"administracao"}></ProtectedRoutes>}
        />
        <Route
          path="/login"
          element={<Login
            costCentre={onChangeCostCentre}
            userInfo={handleUserInfo}
          />}
        />
        <Route
          path={`/${EMBARCADOR_CADASTRO}`}
          element={<ProtectedRoutes screen={"embarcador-register"}></ProtectedRoutes>}
        />
        <Route
          path={`/${TRANSPORTADOR_CADASTRO}`}
          element={<ProtectedRoutes screen={"transportador-register"}></ProtectedRoutes>}
        />
        <Route
          path={`/${PESSOA_FISICA_CADASTRO}`}
          element={<ProtectedRoutes screen={"fisica-register"}></ProtectedRoutes>}
        >
          <Route
            path={`:id`}
            element={<PhysicalPersonRegister />}
          />
        </Route>
        <Route
          path={`/${VEICULO_PROPRIETARIO_CADASTRO}`}
          element={<ProtectedRoutes screen={"proprietario-register"}></ProtectedRoutes>}
        />
        <Route
          path={`/${PESSOA_FISICA_CONSULTA}`}
          element={<ProtectedRoutes screen={"fisica-consult"}></ProtectedRoutes>}
        >
          <Route
            path={`:id`}
            element={<SearchPhysicalPerson />}
          />
        </Route>
        <Route
          path={`/${VEICULO_PROPRIETARIO_CONSULTA}`}
          element={<ProtectedRoutes screen={"proprietario-consulta"}></ProtectedRoutes>}
        />
        <Route
          path={`/${TRANSPORTADOR_CONSULTA}`}
          element={<ProtectedRoutes screen={"transportador-consulta"}></ProtectedRoutes>}
        />
        <Route
          path={`/${EMBARCADOR_CONSULTA}`}
          element={<ProtectedRoutes screen={"embarcador-consulta"}></ProtectedRoutes>}
        />
        <Route
          path={`/${FORBIDDEN}`}
          element={<ForbiddenPage />}
        />
        <Route
          path={`/${BUSCAR}`}
          element={<Pesquisa />}
        />
        <Route
          path={`/${NOVA_BUSCA}`}
          element={<NovaPesquisa />}
        />
        <Route
          path={`/${ANALYSIS_PROFILE}`}
          element={<ProtectedRoutes screen={"analise-perfil"}></ProtectedRoutes>}
        />
        <Route
          path={`/${ANALYSIS_PROFILE_BUSCA}`}
          element={<ProtectedRoutes screen={"analise-perfil-busca"}></ProtectedRoutes>}
        />
        <Route
          path={`/${RELOGIN}`}
          element={<ReloginPage />}
        />
        <Route
          path={`/${MANUAL_BLOCK}`}
          element={<ProtectedRoutes screen={"manual-block"}></ProtectedRoutes>}
        />
        <Route
          path={`/${BLACK_LIST}`}
          element={<ProtectedRoutes screen={"blacklist"}></ProtectedRoutes>}
        />
        <Route
          path={`/${PLATE_CONSULT}`}
          element={<PlateConsult />}
        />
        <Route
          path={`/${CAR_LIST_CONFIG}`}
          element={<ProtectedRoutes screen={"car-list-config"}></ProtectedRoutes>}
        />
        <Route
          path={`/${PRIVILEGES}`}
          element={<ProtectedRoutes screen={"privileges"}></ProtectedRoutes>}
        />
        <Route
          path={`/${BILLINGS}`}
          element={<ProtectedRoutes screen={"billings"}></ProtectedRoutes>}
        />
        <Route
          path={`/${MANAGERIAL}`}
          element={<ProtectedRoutes screen={"managerial"}></ProtectedRoutes>}
        />
        <Route
          path={`/${TERM}`}
          element={<ProtectedRoutes screen={"term"}></ProtectedRoutes>}
        />
      </Routes>
    </Router>
  )
}

export default DefaultRoute


