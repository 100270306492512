import styled from '@emotion/styled'

export const StyledSection = styled.section`
  max-width: 1000px;
  margin-top: 10px;

  .tela {
    display: flex;
  }

  .item {
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .card {
    border: solid 1px lightgray;  
    padding: 5px;
  }

  .principal {
    background: var(--color-primary);
    color: white;
  }
`