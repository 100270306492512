import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LOGO from '@/img/logo-mundial-risk.png'
import Menu from './Menu'
import Avatar from './Avatar'
import { useNavigate  } from 'react-router-dom'
import { LOGIN } from '@/constants/parameters'
import { RedirectPage } from '@/constants/redirectPage'
import { getPersistenceAuthentication } from '@/constants/requests'
import {
  StyledHeaderContainer,
  StyledLogo,
  StyledButtonMobile,
  StyledMenuMobile,
  StyledCostCentre,
} from './styles'
import { useCallback } from 'react'

const Header = (props) => {
  const {
    showMenu,
    costCentre,
    showHeader,
    onLogoutClick,
    user,
  } = props
  const navigate = useNavigate()

  const [openedMenu, setOpenedMenu] = useState(false)

  const checkPersistence = useCallback(async (currentURL) => {
    const persistence = await getPersistenceAuthentication()
    if (currentURL === `/${LOGIN}`) return
    if (!persistence) {
      window.location = LOGIN
    }
  }, [])

  useEffect(() => {
    const currentURL = window.location.pathname
    checkPersistence(currentURL)
  }, [])

  const handleMenuclick = () => {
    setOpenedMenu(false)
  }

  const renderCostCentre = () => (
    <StyledCostCentre title={costCentre}>
      <span>Centro de custos:</span>
      {costCentre ? costCentre : 'Não definido'}
    </StyledCostCentre>
  )

  return (
    <StyledHeaderContainer>
      {RedirectPage()}
      <StyledLogo onClick={() => navigate('/')}>
        <img src={LOGO} alt="logo" />
      </StyledLogo>
      {showHeader && (
        <>
          {showMenu ? (
            <>
              <Menu />
              {renderCostCentre()}
              <Avatar
                costCentre={costCentre}
                onLogoutClick={onLogoutClick}
                user={user}
              />
            </>
          ): (
            <>
              <StyledButtonMobile
                className={openedMenu ? 'active' : ''}
                onClick={() => setOpenedMenu(!openedMenu)}
              >
                <i className="fas fa-bars" />
              </StyledButtonMobile>
              {openedMenu && (
                <StyledMenuMobile>
                  <Avatar
                    showMenu={showMenu}
                    onLogoutClick={onLogoutClick}
                    user={user}
                  />
                  {renderCostCentre()}
                  <Menu 
                    onMenuClick={handleMenuclick}
                  />
                </StyledMenuMobile>
              )}
            </>
          )}
        </>
      )}
    </StyledHeaderContainer>
  )
}

Header.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  costCentre: PropTypes.string,
}

Header.defaultProps = {
  costCentre: null
}

export default Header
