import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledWarning
} from './styles'

const Warnin = (props) => {
  const {
    msg,
  } = props

  return (
    <StyledWarning>
      {msg}
    </StyledWarning>
  )
}

Warnin.propTypes = {
  msg: PropTypes.string.isRequired,
}

export default Warnin
