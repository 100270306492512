import React, { useState, useEffect, useCallback } from 'react'
import { getAllParams } from '@/constants/localStorage'
import RegisterPFForm from '@/views/Register/components/RegisterPF/RegisterPFForm'
import FormAddress from '@/views/Register/fragments/FormAddress'
import RegisterCarInputs from '@/views/Register/components/RegisterCarForm/RegisterCarInputs'
import RegisterOwnerInputs from '@/views/Register/components/RegisterOwnerForm/RegisterOwnerInputs'
import RegisterCnhForm from '@/views/Register/components/RegisterCnh/RegisterCnhForm'
import { validateCarListData, validateCompanyData } from '@/validators/registerValidations'
import {
  StyledContainer,
  StyledContent,
  StyledTab,
  ButtonsContent,
  StyledButtonSave,
} from './styles'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import { submitNewSearch } from '@/utils/setupParamsToSend'
import { getOptions, reqSubmitNewSearch } from '@/constants/requests'
import { setupOptionsList } from '@/constants/setupOptions'
import { useSelector } from 'react-redux'

const DEFAULT = {
  validate: {cars: []},
  form: {
    cars: [{
      car: {
        phones: ['']
      }
    }],
    basicInfo: {
      phones: ['']
    }
  }
}

const NovaPesquisa = () => {
  const [formValues, setFormValues] = useState(DEFAULT.form)
  const [validateList, setValidateList] = useState(DEFAULT.validate)
  const [cpfValidate, setCpfValidate] = useState({})
  const [validateEmail, setValidateEmail] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(1)
  const [okToSubimit, setOkToSubimit] = useState(false)
  const [formCheckedToSubmit, setFormCheckedToSubmit] = useState({})
  const [showMessageModal, setShowMessageModal] = useState(null)
  const [showMessageErrorModal, setShowMessageErrorModal] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [initialDisabled, setInitialDisabled] = useState(null)
  const [defaultOptions, setDefaultOptions] = useState(null)
  const [showcase, setShowcase] = useState([])
  const [isBond, setIsBond] = useState(false)
  const [isHelper, setIsHelper] = useState(false)

  const permission = useSelector(state => state.permission);

  useEffect(() => {
    if (!permission?.newRequest.view) {
      window.location.href = "/";
      return
    }
  }, [])


  const setupOptions = useCallback(async () => {
    const options = await getOptions()
    const list = setupOptionsList(options)

    if (options.status.active) {
      setDefaultOptions(list)
    }
  }, [])

  useEffect(() => {
    setupOptions()
  }, [])

  const onChangeValues = (form, name) => {
    setOkToSubimit(false)
    setFormValues({
      ...formValues,
      [name]: form
    })
  }

  const onChangeCarsValues = (form, name, index) => {
    setOkToSubimit(false)
    const newValue = [...formValues.cars]

    newValue[index] = {
      ...newValue[index],
      [name]: form
    }
    setFormValues({
      ...formValues,
      cars: newValue
    })
  }

  const handleBond = (bool) => {
    setIsBond(bool)
  }

  const handleHelper = (bool) => {
    setIsHelper(bool)
  }

  const onCpfValidate = (data) => {
    setCpfValidate(data)
  }

  const onValidateEmail = (data) => {
    setValidateEmail(data)
  }

  const onValidateList = (data) => {
    setValidateList(data)
  }

  const onValidateListCar = (data, index) => {
    const newList = [...validateList.cars]
    newList[index] = data

    setValidateList({
      ...validateList,
      cars: newList
    })
  }

  const validateCarsFilled = (index) => {
    const newList = [...validateList.cars]
    const form = formValues.cars[index]
    const list = validateCarListData({
      ...form.owner,
      ...form.car
    }, isBond)

    if (list.status) {
      newList[index] = list

      setValidateList({
        ...validateList,
        cars: newList
      })
      return
    }

    setOkToSubimit(true)
    setFormCheckedToSubmit({
      ...formCheckedToSubmit,
      veiculos: formValues.cars
    })
  }

  const onSaveAndCheckClick = (flux, index) => {
    if (flux === 'cars') {
      validateCarsFilled(index)
      return
    }

    const list = validateCompanyData({
      ...formValues.basicInfo,
      ...formValues.address,
      ...formValues.cnh
    }, isHelper, true)

    if (list.status) {
      setValidateList({
        ...validateList,
        ...list
      })
      return
    }

    setFormCheckedToSubmit({
      ...formCheckedToSubmit,
      motorista: {
        ...formValues.basicInfo,
        ...formValues.address,
        ...formValues.cnh,
      },
    })
    setOkToSubimit(true)
  }

  const handleHasRegistered = (data) => {
    const newForm = {
      ...formValues,
      basicInfo: {
        ...formValues.basicInfo || '',
        birthdate: data.nascimento || '',
        fatherName: data.pai || '',
        motherName: data.mae || '',
        name: data.nome || '',
        phones: [
          data.telefone1 || '',
          data.telefone2 || '',
          data.telefone3 || '',
        ],
        rg: data.rg || '',
        ufIssue: data.uf_rg || '',
        userType: data.tipo_pessoa || '',
        vinculo: data.vinculo || ''
      },
      address: {
        ...formValues.address,
        bairro: data.bairro_moradia || '',
        cep: data.cep || '',
        complemento: data.complemento || '',
        localidade: data.cidade_moradia || '',
        logradouro: data.logradouro || '',
        numero: data.numero || '',
        uf: data.uf_moradia || ''
      },
      cnh: {
        ...formValues.cnh,
        cnh: data.cnh || '',
        cnhCategory: data.categoria || '',
        cnhRegister: data.registro_cnh || '',
        firstCnh: data.primeira_cnh || '',
        renachCnh: data.renach_cnh || '',
        cityFromCnh: data.estado_cnh || '',
        cnhDateExpire: data.vencimento_cnh || '',
        securityCode: data.cod_seguranca_cnh || ''
      }
    }

    setInitialDisabled(true)

    setFormValues(newForm)
  }

  const renderDriver = () => (
    <>
      <RegisterPFForm
        isNewSearch
        validateList={validateList}
        formValues={formValues?.basicInfo || {}}
        onChangeValues={(form) => onChangeValues(form, 'basicInfo')}
        onCpfValidate={onCpfValidate}
        onValidateEmail={onValidateEmail}
        onValidateList={onValidateList}
        cpfValidate={cpfValidate}
        validateEmail={validateEmail}
        hasRegistered={handleHasRegistered}
        defaultOptions={defaultOptions}
        handleBond={handleBond}
        handleHelper={handleHelper}
      />
      <FormAddress
        formValues={formValues?.address || {}}
        setFormValues={(form) => onChangeValues(form, 'address')}
        validateList={validateList}
        setValidateList={onValidateList}
        defaultDisabled={initialDisabled}
        isFilled={initialDisabled}
      />
      {!isHelper && (
        <RegisterCnhForm
          formValues={formValues?.cnh || {}}
          validateList={validateList}
          handleFormValues={(form) => onChangeValues(form, 'cnh')}
          handleValidateList={onValidateList}
          defaultOptions={defaultOptions}
        />
      )}
      <StyledButtonSave>
        <button className='button' onClick={onSaveAndCheckClick}>
          Salvar
          <span>(Verificar preenchimento)</span>
        </button>
      </StyledButtonSave>
    </>
  )

  const handleHasPlate = async (data, index) => {
    const newCar = [...formValues.cars]

    newCar[index] = {
      ...newCar[index],
      car: {
        anoFab: data.veiculo.ano || '',
        anoModelo: data.veiculo.ano_modelo || '',
        carroceria: data.veiculo.carroceria || '',
        chassi: data.veiculo.chassi || '',
        city: data.veiculo.cidade || '',
        cor: data.veiculo.cor || '',
        marca: data.veiculo.marca || '',
        modelo:data.veiculo.modelo || '',
        phones: [
          data.proprietario.telefone1 || '',
          data.proprietario.telefone2 || '',
          data.proprietario.telefone3 || ''
        ],
        placa: data.veiculo.placa || '',
        renavam: data.veiculo.renavam || '',
        rntrc: data.veiculo.antt || '',
        uf_vehicle: data.veiculo.estado || '',
        vinculo: data.veiculo.vinculo || '',
        tipo_veiculo: data.veiculo.tipo_veiculo || '',
      },
      owner: {
        bairro: data.proprietario.bairro || '',
        cep: data.proprietario.cep || '',
        companyName: data.proprietario.nome_razao || '',
        complemento: data.proprietario.complemento || '',
        document: data.proprietario.cpf_cnpj || '',
        fatherName: data.proprietario.pai || '',
        localidade: data.proprietario.cidade || '',
        logradouro: data.proprietario.logradouro || '',
        motherName: data.proprietario.mae || '',
        numero: data.proprietario.numero || '',
        rg: data.proprietario.rg || '',
        uf:data.proprietario.estado || '',
        ufIssue: data.proprietario.estado_rg || '',
      }
    }

    const newShowcase = [...showcase]
    newShowcase[index] = {
      anoFab: data.veiculo.ano,
      anoModelo: data.veiculo.ano_modelo,
      marca: data.veiculo.marca,
      modelo: data.veiculo.modelo
    }

    setShowcase(newShowcase)
    setInitialDisabled(true)
    setFormValues({
      ...formValues,
      cars: newCar
    })
  }

  const handleHasOwner = (data, index) => {
    const newOwner = [...formValues.cars]

    newOwner[index] = {
      ...newOwner[index],
      owner: {
        bairro: data.bairro || '',
        cep: data.cep || '',
        companyName: data.nome_razao || '',
        complemento: data.complemento || '',
        document: data.cpf_cnpj || '',
        fatherName: data.pai || '',
        localidade: data.cidade || '',
        logradouro: data.logradouro || '',
        motherName: data.mae || '',
        numero: data.numero || '',
        rg: data.rg || '',
        uf:data.estado || '',
        ufIssue: data.estado_rg || '',
      }
    }

    setInitialDisabled(true)
    setFormValues({
      ...formValues,
      cars: newOwner
    })
  }

  const handleCopyButton = (item, index) => {
    const { address, basicInfo } = formValues
    const newForm = {
      bairro: address?.bairro,
      cep: address?.cep,
      companyName: basicInfo?.name,
      complemento: address?.complemento,
      document: basicInfo?.cpf,
      fatherName: basicInfo?.fatherName,
      localidade: address?.localidade,
      logradouro: address?.logradouro,
      motherName: basicInfo?.motherName,
      numero: address?.numero,
      rg: basicInfo?.rg,
      uf: address?.uf,
      ufIssue: basicInfo?.ufIssue
    }

    const newItem = [...formValues.cars]
    newItem[index] = {
      ...item,
      owner: newForm
    }

    setFormValues({
      ...formValues,
      cars: newItem
    })
    setInitialDisabled(true)
  }

  const handleChangeBrand = () => {
    setShowcase([])
  }

  const renderCar = (item, index) => {
    if (index + 2 !== currentIndex) return

    return (
      <div key={index}>
        <RegisterCarInputs
          formValues={item.car || {}}
          validateList={validateList?.cars[index]}
          handleFormValues={(form) => onChangeCarsValues(form, 'car', index)}
          handleValidateList={(el) => onValidateListCar(el, index)}
          defaultOptions={defaultOptions}
          hasPlate={(el) => handleHasPlate(el, index)}
          showcase={showcase[index]}
          brandHasChanged={handleChangeBrand}
          isTruck={index > 0}
          isBond={isBond}
        />
        <RegisterOwnerInputs
          formValues={item.owner || {}}
          validateList={validateList?.cars[index]}
          handleFormValues={(form) => onChangeCarsValues(form, 'owner', index)}
          handleValidateList={(el) => onValidateListCar(el, index)}
          initialDisabled={initialDisabled}
          hasRegister={(el) => handleHasOwner(el, index)}
          isNewSearch={formValues?.basicInfo.cpf}
          onCopyButtonClick={() => handleCopyButton(item, index)}
        />
        <StyledButtonSave>
          <button
            className='button'
            onClick={() => onSaveAndCheckClick('cars', index)}
          >
            Salvar
            <span>(Verificar preenchimento)</span>
          </button>
        </StyledButtonSave>
      </div>
    )
  }

  const validateTab = (form) => {
    const len = form.length
    setCurrentIndex(len)
    let bool = false
    const vehicleLength = formCheckedToSubmit.veiculos?.length || 0

    if (len === 2 && vehicleLength === 1) {
      bool = false
    }

    if (len === 3 && vehicleLength === 2) {
      bool = false
    }

    if (len === 4 && vehicleLength === 3) {
      bool = false
    }

    if (bool) {
      setShowMessageModal("Preencher os dados do veiculo aberto, antes de carregar formulario de novo veículo - Verifique se já salvou os dados")
    }

    return bool
  }

  const addCar = () => {
    const newList = {
      car: {
        phones: ['']
      }
    }
    const form = [...formValues.cars]
    form.push(newList)

    if (validateTab(form)) return

    setFormValues({
      ...formValues,
      cars: form,
    })
    setCurrentIndex(form.length + 1)
  }

  const renderModal = () => (
    <DefaultModalAlert
      closeModal={() => setShowMessageModal(null)}
      msg={showMessageModal}
    />
  )

  const handleCloseModal = () => {
    window.location = '/monitor'
    setShowSuccessModal(false)
  }

  const handleCloseErrorModal = () => {
    setShowMessageErrorModal(null)
    setShowErrorModal(false)
  }

  const renderSuccessModal = () => (
    <DefaultModalAlert
      closeModal={handleCloseModal}
      msg="Pesquisa enviada com sucesso"
    />
  )

  const renderErrorModal = () => (
    <DefaultModalAlert
      closeModal={handleCloseErrorModal}
      msg={showMessageErrorModal}
    />
  )

  const renderCarTab = () => {
    const qtdd = formValues.cars.length
    if (qtdd < 1) return

    return formValues.cars.map((item, i) => (
      <li key={i}
        onClick={() => setCurrentIndex(i + 2)}
        className={currentIndex === i + 2 ? 'active' : ''}
      >
        {i === 0 && (
          <>
            <i className="fas fa-truck" />
            <span>Cavalo/Veículo</span>
          </>
        )}
        {(i > 0 && (formValues.cars[0]?.car?.tipo_veiculo === "3" || formValues.cars[0]?.car?.tipo_veiculo === 3))  && (
          <>
            <i className="fas fa-truck" />
            <span>Carreta</span> {i}
          </>
        )} 
      </li>
    ))
  }

  const onCancelClick = () => {
    setFormValues(DEFAULT.form)
    setCurrentIndex(1)
    setValidateList(DEFAULT.validate)
    setInitialDisabled(null)
    setDefaultOptions(null)
    setShowcase([])
  }

  const onSubmitClick = async () => {
    const params = getAllParams()

    const data = {
      usuario: params.user,
      costCentre: params.costCentreName,
      cnpj_centro_custos: params.cnpjCostCentre,
      ...formCheckedToSubmit
    }
    
    const newdata = submitNewSearch(data, isBond)
    const submit = await reqSubmitNewSearch(newdata)

    if (submit.status.active) {
      setShowSuccessModal(true)
      return
    }
    
    setShowErrorModal(true)
    setShowMessageErrorModal(`Erro: ${submit.status.mensagem}`)
  }

  return (
    <StyledContainer>
      {showMessageModal && renderModal()}
      {showSuccessModal && renderSuccessModal()}
      {showErrorModal && renderErrorModal()}
      <h2>Solicitar Pesquisa</h2>
      <StyledTab>
        <li
          onClick={() => setCurrentIndex(1)}
          className={currentIndex === 1 ? 'active' : ''}
        >
          <i className="fas fa-user" />
          <span>Motorista</span>
        </li>
        {!isHelper && renderCarTab()}
        {((formValues.cars[0]?.car?.tipo_veiculo === "3" || formValues.cars[0]?.car?.tipo_veiculo === 3) && !isHelper && formValues.cars.length < 4) && (
          <li onClick={addCar}>
            <i className="fas fa-plus" />
            <span>Add Carreta</span>
          </li>
        )}
      </StyledTab>

      <StyledContent>
        {(!currentIndex || currentIndex === 1) ? renderDriver()
          : formValues.cars.map((item, index) => renderCar(item, index))}
      </StyledContent>

      <ButtonsContent>
        <button
          className='button cancel'
          onClick={onCancelClick}
        >Cancelar</button>
        <button
          className='button'
          onClick={onSubmitClick}
          disabled={!okToSubimit}
        >Enviar Pesquisa</button>
      </ButtonsContent>
    </StyledContainer>
  )
}

export default NovaPesquisa
