import React, { useState,  } from 'react'
import { FILTER_TYPE, PER_PAGE } from '@/constants/optionsValues'
import SelectText from '@/views/fragments/form/SelectText'
import { useEffect } from 'react'
import { useCallback } from 'react'
import CheckboxField from '@/views/fragments/form/CheckboxField'
import {
  postResearch,
  reqManualSearch,
  reqSolicitation,
  putSolicitation
} from '@/constants/requests'
import Pagination from '@/views/fragments/Pagination'
import DefaultModalAlert from '@/utils/modalTypes/DefaultModalAlert'
import RowList from './components/RowList'
import VehicleOwnerLink from './components/VehicleOwnerLink'
import ModalSolicitation from './components/ModalSolicitation'
import InputText from '@/views/fragments/form/InputText'
import {
  StyledContainer,
  StyledcontentBox,
  QttPerPage,
  StyledFilter
} from './styles'
import { useSelector } from 'react-redux'


const defaultValues = {
  pagina: 1,
  total_por_pagina: 15
}

const defaultFilter = {
  "tipo": null,
  "documento": null,
  "conformidade": "N",
  "aberta": "N",
  "analise": "N",
  "desacordo": "N",
  "cancelada": "N",
  "dataDe": null,
  "dataAte": null,
}

const STATUS = {
  ok: 'EM CONFORMIDADE',
  notOk: 'DESACORDO'
}

const defaultForm = {
  pessoa: {
    situacao_ok: 'N',
  },
  veiculos: []
}

const Pesquisa = () => {
  const [load, setLoad] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSolicitationModal, setShowSolicitationModal] = useState(false)
  const [info, setInfo] = useState(null)
  const [formValues, setFormValues] = useState(defaultForm)
  const [pagination, setPagination] = useState(defaultValues)
  const [filter, setFilter] = useState(defaultFilter)
  const [listResearch, setListResearch] = useState([])
  const [showListResearch, setShowListResearch] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)
  const [showPersonInfo, setShowPersonInfo] = useState(false)
  const [showCarInfo, setShowCarInfo] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(null)
  const [showEntireItem, setShowEntireItem] = useState([])
  const [hasVariance, setHasVariance] = useState([])
  const [showPeopleContent, setShowPeopleContent] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [formSolicitation, setFormSolictation] = useState({})
  const [reqInfoSolicitation, setReqInfoSolicitation] = useState({})
  const [showMessageErrorModal, setShowMessageErrorModal] = useState(null)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [filterForm, setFilterForm] = useState({})
  const [showFilter, setShowFilter] = useState(false)
  const [isManual, setIsManual] = useState(false)
  
  const permission = useSelector(state => state.permission);

  const getResearchList = useCallback(async (data) => {
    const req = await postResearch(data)

    if ((Number(req.paginacao?.total_de_registros) < 1) || (req.status.codigo === "w105")) {
      setShowListResearch(false)
      setListResearch([])
      setPagination(defaultValues)
    } else {
      setPagination(req.paginacao)
      const newObj = req.protocolos.map(e => {
      
        var startTime = new Date(); 
        var solicitacaoTime = new Date(e.data_solicitacao);
        var finalizacaoTime = new Date(e.data_finalizacao);
        var difference;
        
        const status = ['EM CONFORMIDADE', 'DESACORDO', 'CANCELADO'];
        if (status.includes(e.status_nome)) {
          difference = finalizacaoTime.getTime() - solicitacaoTime.getTime();
        } else {
          difference = startTime.getTime() - solicitacaoTime.getTime();
        }

        // var solicitacao = new Date(e.data_solicitacao);
        const today = new Date();
        
        if(Object.hasOwn(e, 'pessoa')){
          if (e.vinculo_pessoa === 'Agregado') {
            today.setDate(today.getDate() + Number(e.tempo_agregado));
            e.pessoa.tempo = Number(e.tempo_agregado);
          } else if (e.vinculo_pessoa === 'Frota') {
            today.setDate(today.getDate() + Number(e.tempo_frota));
            e.pessoa.tempo = Number(e.tempo_frota);
          } else if (e.vinculo_pessoa === 'Terceiro') {
            today.setDate(today.getDate() + Number(e.tempo_terceiro));
            e.pessoa.tempo = Number(e.tempo_terceiro);
          }
        }
        
        if(e.pessoa) {
          e.pessoa.prazo = today.toISOString().split('T')[0]
        }

        if (e.veiculos) {
          e.veiculos.map(j => {
            // solicitacaoTime = new Date(e.data_solicitacao);
            const today = new Date();
            if (j.vinculo === 'Agregado') {
              today.setDate(today.getDate() + Number(e.tempo_agregado));
              j.tempo = Number(e.tempo_agregado);
            } else if (j.vinculo === 'Frota') {
              today.setDate(today.getDate() + Number(e.tempo_frota));
              j.tempo = Number(e.tempo_frota);
            } else if (j.vinculo === 'Terceiro') {
              today.setDate(today.getDate() + Number(e.tempo_terceiro));
              j.tempo = Number(e.tempo_terceiro);
            }

            j.prazo = today.toISOString().split('T')[0]
          })
        }
        e.sla =  msToHMS(difference);
        
        return e
      })
      
      setListResearch(newObj)
      setShowListResearch(true)
    }
    
  }, [setPagination])
  
  function msToHMS( ms ) {
    let seconds = ms / 1000;
    const hours = parseInt( seconds / 3600 ); 
    seconds = seconds % 3600; 
    const minutes = parseInt( seconds / 60 ).toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false }); 
    seconds = (seconds % 60);
    seconds = ~~seconds
    seconds = seconds.toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false })
    let days = (hours / 24);
    let realHours = (hours % 24).toLocaleString('pt-BR', { minimumIntegerDigits: 2, useGrouping: false });
    return ( ~~days+" dias "+realHours+":"+minutes+":"+seconds);
  }

  useEffect(() => {

    if (!permission?.monitor.view) {
      window.location.href = "/";
      return
    }

    if (!load) {
      let dataRequest = {
        ...pagination,
        ...filter,
      }
      
      getResearchList(dataRequest) 
      setLoad(true)
    }
    
    const interval = setInterval(() => {
      let dataRequest = {
        ...pagination,
        ...filter,
      }
      getResearchList(dataRequest)      
    }, 120000)
    return () => clearInterval(interval)
  }, [])

  const onCloseModal = (typeSuccess) => {
    if (typeSuccess) {
      setShowInfoModal(null)
      return
    }
    setShowForm(false)
    setShowModal(false)
    setShowSolicitationModal(false)
  }

  const handleChangePerPage = (e) => {
    const { value } = e.target
    const data = {
      pagina: 1,
      total_por_pagina: Number(value)
    }
    getResearchList(data)
  }

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const onInputChange = (e, i) => {
    const {value, name} = e.target

    let newValue = value.replaceAll(String.fromCharCode(9), " ");
    newValue = newValue.replaceAll('\n', " ");

    if (name === 'licenciamento' && (Number(value) < 0 || Number(value) > 3000)) return

    // Para veículos
    if (i + 1) {
      const newCar = [...formValues.veiculos]

      newCar[i] = {
        ...newCar[i],
        [name]: newValue
      }

      if( name === 'prazo' ) {
        // Verifica se está no prazo
        let data = new Date(value)
        let newData = addDays(data, 1)
        let today = new Date()
  
        let prazoBool = today >= newData
  
        newCar[i] = {
          ...newCar[i],
          prazoBoolean: prazoBool
        }
      }

      return setFormValues({
        ...formValues,
        veiculos: newCar
      })
    } 

    // Para pessoas
    if( name === 'prazo' ) {
      // Verifica se está no prazo
      let data = new Date(value)
      let newData = addDays(data, 1)
      let today = new Date()

      let prazoBool = today >= newData

      return setFormValues({
        ...formValues,
        pessoa: {
          ...formValues.pessoa,
          [name]: newValue,
          prazoBoolean: prazoBool
        }
      })
    } else {
      return setFormValues({
        ...formValues,
        pessoa: {
          ...formValues.pessoa,
          [name]: newValue
        }
      })
    }
  }

  const onCheckboxClick = (name, value, i) => {
    if (i + 1) {
      const newCar = [...formValues.veiculos]
      newCar[i] = {
        ...newCar[i],
        [name]: value
      }

      return setFormValues({
        ...formValues,
        veiculos: newCar
      })
    }

    setFormValues({
      ...formValues,
      pessoa: {
        ...formValues.pessoa,
        [name]: value
      }
    })
  }

  const onSubmitClick = async () => {
    let obj = formValues;
    let newObject;

    newObject = {
      protocolo: obj.protocolo
    }

    if (obj.pessoa.status === "4") {
      newObject = {
        ...newObject,
        pessoa: {
          ...obj.pessoa
        }
      }

      delete newObject.pessoa.prazo
    } else {
      newObject = {
        ...newObject,
        pessoa: {
          ...obj.pessoa
        }
      }
    }

    if (obj?.veiculos?.find(e => e.status === "4")) {
      obj?.veiculos?.map((e) => {
        if(e.status === "4") {
          delete e.prazo
        }
      })

      newObject = {
        ...newObject,
        veiculos: {
          ...obj.veiculos
        }
      }
    } else {
      newObject = {
        ...newObject,
        veiculos: {
          ...obj.veiculos
        }
      }
    }

    const post = await reqManualSearch(newObject, isManual)

    if (post.status.active) {
      setFormValues(defaultForm)
      setShowModal(false)
      getResearchList(pagination) 
      setShowInfoModal(post.status.message)
      return
    }

    setShowMessageErrorModal(`Erro: ${post.status.mensagem}`)
    getResearchList(pagination) 
    setShowErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setShowMessageErrorModal(null)
    setShowErrorModal(false)
  }

  const renderErrorModal = () => (
    <DefaultModalAlert
      closeModal={handleCloseErrorModal}
      msg={showMessageErrorModal}
    />
  )

  const renderModal = () => (
    <VehicleOwnerLink
      itemToEdit={itemToEdit}
      onCloseModal={onCloseModal}
      onSubmitClick={onSubmitClick}
      showPersonInfo={showPersonInfo}
      setShowPersonInfo={setShowPersonInfo}
      formValues={formValues}
      onInputChange={onInputChange}
      onCheckboxClick={onCheckboxClick}
      showCarInfo={showCarInfo}
      setShowCarInfo={setShowCarInfo}
      isManual={isManual}
      permission={permission}
    />
  )

  const handleEditClick = (item, bool) => {
    setItemToEdit(item)

    let newList = item.veiculos?.map((el) => {
      const item = {
        veiculo_id: el.veiculo_id,
        rntrc_invalida: 'N',
        restricao_adm: 'N',
        habilitado: 'N',
        ativo: 'N'
      }
      return item
    })

    if (bool) {
      newList = item.veiculos?.map((el) => {
        const item = {
          veiculo_id: el.veiculo_id,
          prazo: el.prazo,
        }
        return item
      })
    }

    const newForm = {
      ...formValues,
      protocolo: item.protocolo,
      pessoa: bool ? {pessoa_id: item?.pessoa?.pessoa_id, prazo: item?.pessoa?.prazo,} : {
        ...formValues.pessoa,
        pessoa_id: item?.pessoa?.pessoa_id,
        prazo: item?.pessoa?.prazo,
      },
      veiculos: newList
    }
    
    setFormValues(newForm)
    setShowModal(true)
    setIsManual(bool || false)
  }

  const setupSolicitationFill = (data) => {
    const plates = []
    data?.veiculos.map((el) => {
      const hasNotOk = el?.steps?.some((elem) => elem.status === STATUS.notOk)
      if (hasNotOk) {
        plates.push(el.placa)
      }
    })
    setShowEntireItem(plates)
    setHasVariance(plates)
    setShowPeopleContent(data?.pessoa.status === STATUS.notOk)
  }

  const handleSolicitationClick = async (item) => {
    let data = await reqSolicitation({ protocolo: item.protocolo })

    // Para o form
    const vehicles = data?.veiculos.map((el) => {      
      return {
        prazoShow: el.prazo || '',
        prazoInput: (item?.veiculos.find(v => v.placa === el.placa)?.prazo || ''),
        vinculo: (item?.veiculos.find(v => v.placa === el.placa)?.vinculo || ''),
        tempo: (item?.veiculos.find(v => v.placa === el.placa)?.tempo || ''),
        PesquisaCriminal: el.PesquisaCriminal,
        PesquisaFinanceira: el.PesquisaFinanceira,
        steps: el.steps,
        placa: el.placa || ''
      }
    })

    // Os dois blocos de codigo abaixo são para informação
    // Verifica se o prazo é desacordo, se for utiliza o prazo calculado quando é buscado a pesquisa.
    data?.veiculos.map((el) => {
      el.prazoInput = (item?.veiculos.find(v => v.placa === el.placa)?.prazo || ''),
      el.vinculo = (item?.veiculos.find(v => v.placa === el.placa)?.vinculo || ''),
      el.tempo = (item?.veiculos.find(v => v.placa === el.placa)?.tempo || ''),
      el.prazoShow = el.prazo || '' 
    })

    // Verifica se o prazo é desacordo, se for utiliza o prazo calculado quando é buscado a pesquisa.
    data.pessoa.prazoInput = item?.pessoa?.prazo;
    data.pessoa.prazoShow = data?.pessoa?.prazo || '';
    data.pessoa.vinculo = item?.pessoa?.vinculo || '';
    data.pessoa.tempo = item?.pessoa?.tempo || '';

    const form = {
      protocolo: item?.protocolo || '',
      motivo: '',
      arquivo: '',
      pessoa: {
        tempo: item?.pessoa?.tempo || '',
        cpf: item?.pessoa?.cpf || '',
        prazoShow: data?.pessoa?.prazo || '',
        prazoInput: item?.pessoa?.prazo || '',
        vinculo: item?.pessoa?.vinculo || '',
      },
      veiculos: vehicles
    }

    setupSolicitationFill(data)
    setReqInfoSolicitation(data)
    setInfo(item)
    setShowSolicitationModal(true)
    setFormSolictation(form)
  }

  const handleChangePage = (page) => {
    const data = {
      total_por_pagina: pagination.total_por_pagina,
      pagina: page,
      ...filter
    }
    getResearchList(data)
  }

  const renderRow = (item, index) => (
    <RowList
      item={item}
      handleEditClick={handleEditClick}
      handleSolicitationClick={handleSolicitationClick}
      key={index}
      permission={permission}
    />
  )

  const onFilterChange = (e) => {
    const {name, value} = e.target
    
    let newValue = value.replaceAll(String.fromCharCode(9), " ");

    setFilterForm({
      ...filterForm,
      [name]: newValue
    })
  }

  const onStatusClick = (name, value) => {
    setFilterForm({
      ...filterForm,
      status: {
        ...filterForm.status,
        [name]: value
      }
    })
  }

  const renderLabel = () => {
    const name = FILTER_TYPE.find((el) => el.value === filterForm.type)

    return `Pesquisar por ${name ? name.label : 'Solicitação'}`
  }

  const handleSubmitFilter = async () => {
    let newForm = {
      tipo: filterForm?.type || null,
      documento: filterForm?.doc || null,
      conformidade: filterForm?.status?.conformidade || "N",
      aberta: filterForm?.status?.aberta || "N",
      analise: filterForm?.status?.analise || "N",
      desacordo: filterForm?.status?.desacordo || "N",
      cancelada: filterForm?.status?.cancelada || "N",
      dataDe: filterForm?.dataDe || null,
      dataAte: filterForm?.dataAte || null,
    }

    setFilter(newForm)
    
    let dataRequest = {
      ...pagination,
      ...newForm,
    }
    
    await getResearchList(dataRequest)    
  }

  const renderFilter = () => (
    <StyledFilter>
      <div>
        <SelectText
          label="Filtrar por:"
          options={FILTER_TYPE}
          name="type"
          notEmptyOption
          onChange={onFilterChange}
          value={filterForm?.type || ''}
        />
        <InputText
          label={renderLabel()}
          name="doc"
          value={filterForm?.doc || ''}
          onChange={onFilterChange}
        />
      </div>
      <div>
        <h5>Status</h5>
        <CheckboxField
          label='Em Conformidade'
          name='conformidade'
          value={filterForm?.status?.conformidade || 'N'}
          onClick={onStatusClick}
          className="lessMargin"
          hideDescription
        />
        <CheckboxField
          label='Aberta'
          name='aberta'
          value={filterForm?.status?.aberta || 'N'}
          onClick={onStatusClick}
          className="lessMargin"
          hideDescription
        />
        <CheckboxField
          label='Em Análise'
          name='analise'
          value={filterForm?.status?.analise || 'N'}
          onClick={onStatusClick}
          className="lessMargin"
          hideDescription
        />
        <CheckboxField
          label='Desacordo'
          name='desacordo'
          value={filterForm?.status?.desacordo || 'N'}
          onClick={onStatusClick}
          className="lessMargin"
          hideDescription
        />
        <CheckboxField
          label='Cancelada'
          name='cancelada'
          value={filterForm?.status?.cancelada || 'N'}
          onClick={onStatusClick}
          className="lessMargin"
          hideDescription
        />
      </div>
      <div>
        <InputText
          label="De"
          name="dataDe"
          type="date"
          value={filterForm?.dataDe || ''}
          onChange={onFilterChange}
        />
        <InputText
          label="Até"
          name="dataAte"
          type="date"
          value={filterForm?.dataAte || ''}
          onChange={onFilterChange}
        />
      </div>
      <div>
        <button
          className="button"
          onClick={handleSubmitFilter}
        >
          Filtrar
        </button>
      </div>
    </StyledFilter>
  )

  const renderInfoModal = () => (
    <DefaultModalAlert
      closeModal={() => onCloseModal(true)}
      msg={showInfoModal}
    />
  )

  const handleSubmitSolicitation = async () => {
    setLoading(true)

    let data = {
      ...formSolicitation
    }

    let prazoMudado = false;

    data.pessoa.prazo = (data.pessoa?.prazoModify) ? data.pessoa?.prazoInput : data.pessoa?.prazoShow;

    prazoMudado = (data.pessoa?.prazoModify) ? true : false;

    data.veiculos.forEach((veiculo) => {
      if (veiculo.prazoModify) {
        veiculo.prazo = veiculo.prazoInput;
        prazoMudado = true;
      } else {
        veiculo.prazo = veiculo.prazoShow;
      }
    });

    if (!prazoMudado) {
      setShowInfoModal('Necessário algum prazo ser alterado para envio da liberação manual!')
      setLoading(false)
      return
    }

    if (data.motivo === "" || data.motivo === null) {
      setShowInfoModal('Necessário colocar alguma justificativa para envio da liberação manual!')
      setLoading(false)
      return
    }

    if (data.arquivo === "") {
      setShowInfoModal('Arquivo de imagem/pdf não foi enviado!')
      setLoading(false)
      return
    }

    const submit = await putSolicitation(data)

    setLoading(false)
    if (submit.status.active) {
      onCloseModal()
      setShowInfoModal('Solicitação manual atualizada com sucesso!')
      getResearchList(pagination)
      return
    }

    setShowInfoModal('Houve um erro: ' + submit.status.mensagem)
  }

  const renderErrorSize = () => {
    setShowMessageErrorModal('O Arquivo é muito pesado. Suportado até 10mb')
    setShowErrorModal(true)
  }

  const renderErrorType = () => {
    setShowMessageErrorModal('O tipo de arquivo não é suportado. Tente apenas pdf, png, jpg, jpeg ou tiff')
    setShowErrorModal(true)
  }

  const renderSolicitationModal = () => (
    <ModalSolicitation
      item={reqInfoSolicitation}
      setReqInfoSolicitation={setReqInfoSolicitation}
      permission={permission}
      setShowPeopleContent={setShowPeopleContent}
      setShowForm={setShowForm}
      defaultStatus={STATUS}
      showPeopleContent={showPeopleContent}
      showEntireItem={showEntireItem}
      hasVariance={hasVariance}
      setShowEntireItem={setShowEntireItem}
      showForm={showForm}
      onCloseModal={onCloseModal}
      info={info}
      formSolicitation={formSolicitation}
      setFormSolictation={setFormSolictation}
      onConfirmSubmit={handleSubmitSolicitation}
      loading={loading}
      showErrorSize={renderErrorSize}
      showErrorType={renderErrorType}
    />
  )

  // Hook
  const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = React.useRef();
    
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(
      () => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;
        
        const eventListener = event => savedHandler.current(event);
        
        element.addEventListener(eventName, eventListener);
        
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] 
    );
  };

  const ESCAPE_KEYS = ['Enter'];

  function handler({ key }) {
    if (ESCAPE_KEYS.includes(String(key))) {
      handleSubmitFilter()
    }
  }

  useEventListener('keydown', handler);

  return (
    <StyledContainer>
      {showModal && renderModal()}
      {showInfoModal && renderInfoModal()}
      {showSolicitationModal && renderSolicitationModal()}
      {showErrorModal && renderErrorModal()}
      <h2>
        Pesquisa
        <div>
          <button className={showFilter ? 'active button' : 'button'} onClick={() => setShowFilter(!showFilter)}>Filtrar</button>
          <QttPerPage>
            <SelectText
              label="itens por página:"
              options={PER_PAGE}
              name="total_por_pagina"
              notEmptyOption
              onChange={handleChangePerPage}
              value={pagination.total_por_pagina}
            />
          </QttPerPage>
        </div>
      </h2>
      {showFilter && renderFilter()}
      
      {(showListResearch) ? (
        <StyledcontentBox>
          {listResearch.map((item, index) => renderRow(item, index))}
        </StyledcontentBox>
      ) : (
        <StyledcontentBox>
          <p>Não há solicitações.</p>
        </StyledcontentBox>
      )}

      <Pagination
        params={pagination}
        requestPage={handleChangePage}
      />
    </StyledContainer>
  )
}

export default Pesquisa
