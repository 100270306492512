import {
  PESSOA_FISICA_CADASTRO,
  // VEICULO_PROPRIETARIO_CADASTRO,
  PESSOA_FISICA_CONSULTA,
  //VEICULO_PROPRIETARIO_CONSULTA,
  TRANSPORTADOR_CONSULTA,
  BUSCAR,
  NOVA_BUSCA,
  ANALYSIS_PROFILE,
  ANALYSIS_PROFILE_BUSCA,
  ADMINISTRACAO,
  MANUAL_BLOCK,
  PRIVILEGES,
  BILLINGS,
  // BLACK_LIST,
  MANAGERIAL,
  PLATE_CONSULT,
  CAR_LIST_CONFIG,
  TERM,
} from './parameters'

export let MENU_DEFAULT = [{
    label: 'Administração',
    id: ADMINISTRACAO,
    icon: 'fas fa-warehouse',
    sub: [{
      label: 'Transportador / Embarcador',
      id: TRANSPORTADOR_CONSULTA,
    }, {
      label: 'Privilégios',
      id: PRIVILEGES
    }],
  }, {
    label: 'Cadastro',
    id: PESSOA_FISICA_CADASTRO,
    icon: 'far fa-address-book',
    sub: [
    //   {
    //   label: 'Pessoa Física',
    //   id: PESSOA_FISICA_CADASTRO,
    // },{
    //   label: 'Veículo / Proprietário',
    //   id: VEICULO_PROPRIETARIO_CADASTRO,
    // },
    {
      label: 'Perfil de Análise',
      id: ANALYSIS_PROFILE,
    },{
      label: 'Bloqueio Manual',
      id: MANUAL_BLOCK,
    },
    // {
    //   label: 'Black List',
    //   id: BLACK_LIST,
    // },
    {
      label: 'Edição Marca / Modelo',
      id: CAR_LIST_CONFIG,
    }],
  }, {
    label: 'Buscar',
    id: PESSOA_FISICA_CONSULTA,
    icon: 'fas fa-search',
    sub: [
    //   {
    //   label: 'Pessoa Física',
    //   id: PESSOA_FISICA_CONSULTA,
    // },{
    //   label: 'Veículo / Proprietário',
    //   id: VEICULO_PROPRIETARIO_CONSULTA,
    // }
    {
      label: 'Perfil de Análise',
      id: ANALYSIS_PROFILE_BUSCA,
    }],
  }, {
    label: 'Solicitações',
    id: NOVA_BUSCA,
    icon: 'fas cloud-download-alt',
    sub: [{
      label: 'Nova Pesquisa',
      id: NOVA_BUSCA,
    },{
      label: 'Monitor de Solicitações',
      id: BUSCAR,
    },{
      label: 'Consulta',
      id: PLATE_CONSULT,
    }, ],
  },
  {
    label: 'Relatórios',
    icon: 'fas fa-book',
    sub: [{
      label: 'Faturamento',
      id: BILLINGS,
    }, {
      label: 'Gerencial',
      id: MANAGERIAL,
    }, {
      label: 'Prazos - Clientes',
      id: TERM
    }],
  }
]

export const MENU_PERFIL_USUARIO = [{
    label: 'Monitor de Solicitações',
    id: BUSCAR,
    icon: 'fa-regular fa-rectangle-list',
    sub: null,
  }, {
    label: 'Nova Pesquisa',
    id: NOVA_BUSCA,
    icon: 'fa-solid fa-bars',
    sub: null,
  }, {
    label: 'Consulta',
    id: PLATE_CONSULT,
    icon: 'fa-regular fa-id-card',
    sub: null,
}]

export const ACTIVE_OR_NOT = [
  {
    label: 'Desativado',
    value: false,
  }, {
    label: 'Ativado',
    value: true,
  }
]

export const YES_OR_NOT = [
  {
    label: 'Não',
    value: 'N',
  }, {
    label: 'Sim',
    value: 'S',
  }
]

export const USER_GENRE = [
  {
    label: 'Masculino',
    value: 'Male',
  }, {
    label: 'Feminino',
    value: 'Female',
  }
]

export const MARITAL_STATUS = [
  {
    label: 'Casado(a)',
    value: 102,
  }, {
    label: 'Divorciado(a)',
    value: 103,
  }, {
    label: 'Separado(a)',
    value: 105,
  }, {
    label: 'Solteiro(a)',
    value: 101,
  }, {
    label: 'Viúvo(a)',
    value: 104,
  }
]

export const PER_PAGE = [
  {
    label: '15',
    value: 15,
  }, {
    label: '30',
    value: 30,
  }, {
    label: '60',
    value: 60,
  }, {
    label: '90',
    value: 90,
  }
]

export const FILTER_TYPE = [
  {
    label: 'Selecione uma opção...',
    value: '',
  },
  {
    label: 'Solicitação',
    value: 'solicitacao',
  }, {
    label: 'Placa',
    value: 'placa',
  }, {
    label: 'CPF',
    value: 'cpf',
  }, {
    label: 'Cliente',
    value: 'cliente',
  }, {
    label: 'Pessoa',
    value: 'pessoa',
  }
]

export const FILTER_MANUAL = [
  {
    label: 'Selecione uma opção...',
    value: '',
  },
  {
    label: 'Objeto',
    value: 1,
  }, {
    label: 'Nome',
    value: 2,
  }, {
    label: 'Cliente',
    value: 3,
  }
]

export const BLOCK_TYPE = [
  {
    label: 'Objeto',
    value: 'objeto',
  }, {
    label: 'Cliente',
    value: 'cliente',
  }
]

export const MANUAL_OPTIONS = [
  {
    label: 'Em Conformidade',
    value: 3,
  }, {
    label: 'Desacordo',
    value: 4,
  }
]

export const MANUAL_OPTIONS_BLOCK = [
  {
    label: 'Desacordo',
    value: 4,
  }
]

export const SEARCH_OR_CONSULT = [
  {
    label: 'Por Consulta',
    value: 'S'
  },
  {
    label: 'Por Pesquisa',
    value: 'N'
  }
]