import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomCheckBox } from './styles'

const CheckboxField = (props) => {
  const {
    label,
    value,
    name,
    onClick,
    className,
    hideDescription
  } = props

  const handleClick = () => {
    const newValue = value === 'S' ? 'N' : 'S'
    onClick(name, newValue)
  }

  return (
    <Field className={className}>
      {!hideDescription && (<span>{label}</span>)}
      {value === 'S' ? (
        <CustomCheckBox
          onClick={handleClick}
          className={hideDescription ? '' : 'description'}
        >
          <i className="fas fa-check-square toggleOn" />
          {hideDescription ? (<span>{label}</span>) : (<span>Sim</span>)}
        </CustomCheckBox>
      ) : (
        <CustomCheckBox
          onClick={handleClick}
          className={hideDescription ? '' : 'description'}
        >
          <i className="far fa-square" />
          {hideDescription ? (<span>{label}</span>) : (<span>Não</span>)}
        </CustomCheckBox>
      )}
    </Field>
  )
}

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  hideDescription: PropTypes.bool
}

CheckboxField.defaultProps = {
  className: '',
  hideDescription: false
}

export default CheckboxField
