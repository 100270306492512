import React, { useState } from 'react'
import { formatterCNPJ, formatterNumberOnly } from '@/utils/formatter'
import { MIN_LENGTH, MAX_LENGTH } from '@/utils/minMaxLength'
import InputText from '@/views/fragments/form/InputText'
import { TRANSPORTADOR } from '@/constants/mockOptions'
import TransportadorCards from '../fragments/TransportadorCards'
import ContainerTemplate from '../fragments/ContainerTemplate'
import Warnin from '@/views/fragments/Warnin'

const SearchTransportador = () => {
  const [documentNumber, setDocumentNumber] = useState('')
  const [registerFound, setRegisterFound] = useState(null)
  const [showWarning, setShowWarning] = useState(null)

  const onChange = (e, ml) => {
    const { value } = e.target
    const newValue = formatterNumberOnly(value)

    if (newValue.length > ml) return

    setDocumentNumber(newValue)
    setShowWarning(null)
  }

  const handleSave = (form) => {
    setRegisterFound(form)
  }

  const handleSearch = () => {
    if (MIN_LENGTH.eleven > documentNumber.length) {
      setShowWarning(`Você deve preencher ao menos ${MIN_LENGTH.eleven} dígitos`)
      return
    }

    setRegisterFound(TRANSPORTADOR)
  }

  return (
    <ContainerTemplate>
      <aside>
        <h3>Buscar Embarcador</h3>
        <InputText
          label="CNPJ"
          name="cnpj"
          className="cnpj"
          value={formatterCNPJ(documentNumber)}
          onChange={(e) => onChange(e, MAX_LENGTH.quatorze)}
        />
        {showWarning && (<Warnin msg={showWarning} />)}
        <button className='button' onClick={handleSearch}>
          <i className="fas fa-search" />
        </button>
      </aside>
      <section>
        {registerFound ? (
          <TransportadorCards
            values={registerFound}
            onSave={handleSave}
            showShipper={false}
          />
        ) : (
          <h4>
            Nenhum Embarcador Encontrado
            <i className="fas fa-dolly" />
          </h4>
        )}
      </section>
    </ContainerTemplate>
  )
}

export default SearchTransportador
