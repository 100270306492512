import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import RegisterCarInputs from './RegisterCarInputs'
import { validateCarFields } from '@/validators/registerValidations'
import { StyledFormButton } from '@/views/Register/fragments/styles'
import { getOptions } from '@/constants/requests'
import { setupOptionsList } from '@/constants/setupOptions'

const CarRegisterForm = (props) => {
  const { onSave, car } = props
  const [validateList, setValidateList] = useState({})
  const [formValues, setFormValues] = useState({})
  const [defaultOptions, setDefaultOptions] = useState(null)

  const setupOptions = useCallback(async () => {
    const options = await getOptions()
    const list = setupOptionsList(options)

    if (options.status.active) {
      setDefaultOptions(list)
    }
  }, [])

  useEffect(() => {
    setFormValues(car)
    setupOptions()
  },[car])

  const handleSaveClick = (e) => {
    e.preventDefault()

    const list = validateCarFields(formValues)
    if (list.status) {
      setValidateList(list)
      return
    }

    onSave(formValues)
  }

  const handleFormValues = (form) => {
    setFormValues(form)
  }

  const handleValidateList = (item) => {
    setValidateList(item)
  }

  return (
    <>
      <RegisterCarInputs
        handleFormValues={handleFormValues}
        handleValidateList={handleValidateList}
        validateList={validateList}
        defaultOptions={defaultOptions}
        formValues={formValues}
      />
      <StyledFormButton className="button" onClick={handleSaveClick}>
        <i className="far fa-save" />
        Finalizar
      </StyledFormButton>
    </>
  )
}

CarRegisterForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  car: PropTypes.objectOf(PropTypes.any),
}

CarRegisterForm.defaultProps = {
  car: {},
}

export default CarRegisterForm
