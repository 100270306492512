export const MAX_LENGTH = {
  sete: 7,
  oito: 8,
  nove: 9,
  dez: 10,
  onze: 11,
  doze: 12,
  treze: 13,
  quatorze: 14,
  quize: 15,
  dezessete: 17,
  dezoito: 18,
  vinte: 20,
  trinta: 30,
  quarenta: 40,
  tresmeiacinco: 365,
}

export const MIN_LENGTH = {
  five: 5,
  two: 2,
  one: 1,
  eleven: 11,
  nine: 9
}
