import React, { useState, useEffect, useCallback } from 'react'
import Modal from '@/views/fragments/Modal'
import Acordeon from '@/views/fragments/Acordeon'
import PropTypes from 'prop-types'
import RegisterCompanyData from '@/views/Register/components/RegisterCompanyData'
import RegisterUsers from '@/views/Register/components/RegisterUsers'
import Finance from '@/views/Register/fragments/Finance'
import AssociateShipper from '@/views/Register/fragments/AssociateShipper'
import RegisterEmails from '../components/RegisterEmails'
import { postTransportador } from '@/constants/requests'

const DEFAULT_STATUS = {
  company: { show: false, locked: false },
  shipper: { show: false, locked: false },
  user: { show: false, locked: false },
  emails: { show: false, locked: false },
  finance: { show: false, locked: false },
}

const TransportadorRegister = (props) => {
  const { registerFound } = props
  const [formValues, setFormValues] = useState({})
  const [toShow, setToShow] = useState(DEFAULT_STATUS)
  const [showModal, setShowModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')

  const setDefaultValues = useCallback(() => {
    setToShow({
      ...DEFAULT_STATUS,
      company: { show: true, locked: false }
    })

    if (registerFound) {
      setFormValues({
        ...formValues,
        company: registerFound.company
      })
    }
  },[])

  useEffect(() => {
    setDefaultValues()

    if (registerFound?.status?.tipo === 'novo') {
      setShowMessageModal(true)
      setMessageModal('Cliente não cadastrado, o formulário será aberto para inclusão.')
    }
  },[setDefaultValues])

  const handleSaveCompany = (form) => {
    setFormValues({
      ...formValues,
      company: form,
    })

    setToShow({
      ...toShow,
      company: { show: false, locked: false },
      shipper: { show: true, locked: false },
    })
  }

  const handleSaveShipper = (form) => {
    setFormValues({
      ...formValues,
      shipper: form,
    })

    setToShow({
      ...toShow,
      shipper: { show: false, locked: false },
      user: { show: true, locked: false },
    })
  }

  const handleSaveUser = (form) => {
    setFormValues({
      ...formValues,
      user: form,
    })

    setToShow({
      ...toShow,
      user: { show: false, locked: false },
      emails: { show: true, locked: false },
    })
  }

  const handleSaveEmails = (form) => {
    setFormValues({
      ...formValues,
      emails: form,
    })

    setToShow({
      ...toShow,
      emails: { show: false, locked: false },
      finance: { show: true, locked: false },
    })
  }

  const handleSaveFinance = async (form) => {
    const data = {
      ...formValues,
      finance: form,
    }

    setFormValues(data)

    const req = await postTransportador(data)

    if (req.status.active) {
      setShowModal(true)
      setTimeout(() => {window.location = '/transportador-consulta'}, 2000);
    }
  }

  const onCloseModal = () => {
    setShowModal(false)
    setShowMessageModal(false)
    setMessageModal('')
    //setDefaultValues()
  }

  const renderModal = () => (
    <Modal
      title="Sucesso!"
      subTitle="Cadastro efetuado."
      type='success'
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const renderMessageModal = () => (
    <Modal
      subTitle={messageModal}
      closeModal={onCloseModal}
    >
      <button
        className='button'
        onClick={() => onCloseModal()}
      >
        Ok
      </button>
    </Modal>
  )

  const handleNavClick = (nav) => {
    if (toShow[nav].locked) return

    setToShow({
      ...toShow,
      [nav]: {
        ...toShow[nav].locked,
        show: !toShow[nav].show,
      },
    })
  }

  return (
    <>
      {showMessageModal && renderMessageModal()}
      {showModal && renderModal()}
      <Acordeon
        locked={toShow.company.locked}
        showContent={toShow.company.show}
        title="Dados da Empresa"
        icon="far fa-building"
        handleClickNav={() => handleNavClick('company')}
      >
        <RegisterCompanyData
          onSave={handleSaveCompany}
          companyForm={formValues.company}
        />
      </Acordeon>
      {formValues?.company?.companyType === 'transportador' && (
        <Acordeon
          locked={toShow.shipper.locked}
          showContent={toShow.shipper.show}
          title="Associar Embarcadores"
          icon="fas fa-route"
          handleClickNav={() => handleNavClick('shipper')}
        >
          <AssociateShipper
            onSave={handleSaveShipper}
            shipper={formValues.shipper}
          />
        </Acordeon>
      )}

      <Acordeon
        locked={toShow.user.locked}
        showContent={toShow.user.show}
        title="Cadastro de Usuário"
        icon="fas fa-users"
        handleClickNav={() => handleNavClick('user')}
      >
        <RegisterUsers
          onSave={handleSaveUser}
          userList={formValues.user}
        />
      </Acordeon>

      <Acordeon
        locked={toShow.emails.locked}
        showContent={toShow.emails.show}
        title="Cadastro de Emails"
        icon="fas fa-users"
        handleClickNav={() => handleNavClick('emails')}
      >
        <RegisterEmails
          onSave={handleSaveEmails}
        />
      </Acordeon>

      <Acordeon
        locked={toShow.finance.locked}
        showContent={toShow.finance.show}
        title="Financeiro"
        icon="fas fa-hand-holding-usd"
        handleClickNav={() => handleNavClick('finance')}
      >
        <Finance
          onSave={handleSaveFinance}
          finance={formValues.finance}
        />
      </Acordeon>
    </>
  )
}

TransportadorRegister.propTypes = {
  registerFound: PropTypes.objectOf(PropTypes.object)
}

TransportadorRegister.defaultProps = {
  registerFound: null
}

export default TransportadorRegister
