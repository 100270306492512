import React from 'react'
import PropTypes from 'prop-types'
import { StyledCard } from './styles'

const Card = (props) => {
  const {
    children,
    title,
    onEditClick,
    isEditable,
  } = props

  const handleClick = () => {
    onEditClick()
  }

  return (
    <StyledCard>
      <h2>
        {title}
        {isEditable && (
          <button onClick={handleClick}>
            <i className='fas fa-pencil-alt' />
          </button>
        )}
      </h2>
      <div>
        {children}
      </div>
    </StyledCard>
  )
}

Card.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
  isEditable: PropTypes.bool,
}

Card.defaultProps = {
  isEditable: true,
  onEditClick: null,
}

export default Card
